import { ApplicationStatus, BusinessStatus, UserType } from 'db-schema'
import { useSession } from 'next-auth/react'
import { useMemo } from 'react'
import { isDefined } from 'utils'

/** Method to get information about a User status at Keep */

export interface InternalUserStatus {
  userExists: boolean
  userIsVerified: boolean
  userIsCardHolder: boolean
  userIsAccountant: boolean
  userIsAdminAccount: boolean
  userIsFounder: boolean
  userHasApplicationInfoRequest: boolean
  userHasOnboarded: boolean
  userHasApprovedApplication: boolean
  userHasSubmittedApplication: boolean
  userHasCompletedApplicationOnboarding: boolean
  userHasDeniedApplication: boolean
  userHasInReviewApplication: boolean
  userBusinessStatus: BusinessStatus
  userApplicationStatus: ApplicationStatus
  userHasPendingApproval: boolean
  userApprovalWasRejected: boolean
  userBusinessIsActive: boolean
  userIsMissingInformationOnApplicationV2: boolean
  userApplicationV2: boolean
}

const getInternalUserStatus = (session: ReturnType<typeof useSession>['data']): InternalUserStatus => {
  const user = session?.user
  const internalUser = user?.internal
  const application = internalUser?.application ?? internalUser?.business?.application
  const kycStatus =
    isDefined(internalUser) && isDefined(internalUser.kycStatusSnapshots) && internalUser?.kycStatusSnapshots.length > 0
      ? internalUser?.kycStatusSnapshots[0]
      : undefined

  const userExists = isDefined(internalUser?.id)
  const userIsVerified = isDefined(internalUser?.verifiedAt)
  const userHasApplicationInfoRequest = application?.status === ApplicationStatus.REQUEST_FOR_INFORMATION
  const userHasOnboarded = isDefined(internalUser?.onboardedAt)
  const userIsCardHolder = internalUser?.userType === UserType.CARDHOLDER
  const userIsAccountant = internalUser?.userType === UserType.ACCOUNTANT
  const userIsAdminAccount = internalUser?.userType === UserType.ACCOUNT_ADMIN
  const userIsFounder = internalUser?.userType === UserType.USER

  const userHasPendingApproval = isDefined(kycStatus) && kycStatus.status === 'PENDING'
  const userApprovalWasRejected = isDefined(kycStatus) && kycStatus.status === 'REJECTED'

  const userHasApprovedApplication = application?.status === ApplicationStatus.APPROVED
  const userHasSubmittedApplication = application?.status === ApplicationStatus.SUBMITTED
  const userHasDeniedApplication = application?.status === ApplicationStatus.DENIED
  const userHasInReviewApplication = application?.status === ApplicationStatus.IN_REVIEW
  const userBusinessStatus = internalUser?.business?.status ?? BusinessStatus.DRAFT
  const userApplicationStatus = application?.status ?? ApplicationStatus.DRAFT
  const userIsMissingInformationOnApplicationV2 =
    application?.version === 'V2' &&
    application.status === ApplicationStatus.DRAFT &&
    (internalUser?.firstName === null || internalUser?.lastName === null || internalUser?.phoneNumber === null)
  const userApplicationV2 = application?.version === 'V2'

  return {
    userExists,
    userIsVerified,
    userIsCardHolder,
    userIsAccountant,
    userIsAdminAccount,
    userIsFounder,
    userHasApplicationInfoRequest,
    userHasOnboarded,
    userHasApprovedApplication,
    userHasSubmittedApplication,
    userHasCompletedApplicationOnboarding: userHasOnboarded && userHasApprovedApplication,
    userHasDeniedApplication,
    userHasInReviewApplication,
    userBusinessStatus,
    userApplicationStatus,
    userHasPendingApproval,
    userApprovalWasRejected,
    userBusinessIsActive: userBusinessStatus === 'ACTIVE',
    userIsMissingInformationOnApplicationV2,
    userApplicationV2,
  }
}

export const useInternalUserStatus = () => {
  const { data: session } = useSession()
  return useMemo(() => getInternalUserStatus(session), [session])
}

export default getInternalUserStatus
