import { ReactNode } from 'react'

type HeaderProps = {
  children: ReactNode
}
const Header = ({ children }: HeaderProps) => {
  return <>{children}</>
}

export default Header
