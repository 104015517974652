import { ComponentProps } from 'react'
import { chakra } from 'ui-lib'

type TransactionsIconProps = ComponentProps<typeof chakra.svg> & {
  strokeWidth?: number
}

const TransactionsIcon = (props?: TransactionsIconProps) => {
  const strokeWidth = props?.strokeWidth ?? 2

  return (
    <chakra.svg
      width="20px"
      height="20px"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      marginTop="1px"
      {...props}
    >
      <path
        d="M17 17.002C16.4696 17.002 15.9609 16.7912 15.5858 16.4162C15.2107 16.0411 15 15.5324 15 15.002V9.26758M17 17.002C17.5304 17.002 18.0391 16.7909 18.4142 16.4158C18.7893 16.0407 19 15.532 19 15.0016V12.1756C19.0001 11.6311 18.889 11.0923 18.6735 10.5923C18.6686 10.581 18.6637 10.5696 18.6587 10.5583C18.2458 9.62394 17.1779 9.26758 16.1563 9.26758H15M17 17.002L4.99991 17.0017C2.7908 17.0016 1 15.2108 1 13.0017V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H13C13.5304 1 14.0391 1.21071 14.4142 1.58579C14.7893 1.96086 15 2.46957 15 3V9.26758M4.5 5H11.5M4.5 9H11.5M9.5 13H11.5"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </chakra.svg>
  )
}

export default TransactionsIcon
