import React from 'react'
import { Flex, FlexProps, Text } from 'ui-lib'

import isDefined from '@/src/utils/dataShaping/isDefined'

interface InfoCardProps {
  subTitle?: string
  title: string
  description: string
}

const InfoCard = ({ subTitle, title, description, ...flexProps }: InfoCardProps & FlexProps) => {
  return (
    <Flex {...flexProps} flexDirection="column" alignItems="start">
      {isDefined(subTitle) && (
        <Text textStyle="text.sm.regular" color="_primary.500" mb="4px">
          {subTitle}
        </Text>
      )}
      <Text textStyle="heading.md.medium" color="_primary.900" mb="24px">
        {title}
      </Text>
      <Text textStyle="text.lg.medium" color="_primary.900" whiteSpace="pre-line">
        {description}
      </Text>
    </Flex>
  )
}

export default InfoCard
