import Link from 'next/link'
import { Button, Card, CardBody, Flex, Text } from 'ui-lib'

import { SEGMENT_PAGE_VISITED } from '@/src/constants/segmentVisitedPages'
import { useInternalUser } from '@/src/hooks/misc'
import { useSegmentPageVisited } from '@/src/hooks/useSegmentPageVisited'
import CapitalIcon from '@/src/svgs/CapitalIcon'
import { isUnderwritingPeriodExpired } from '@/src/utils/capital'
import { formatCurrency } from '@/src/utils/formatCurrency'

type CapitalApprovedCardProps = {
  amountApproved?: number
}

const CapitalApprovedCard = ({ amountApproved }: CapitalApprovedCardProps) => {
  const trackPageVisited = useSegmentPageVisited()
  const user = useInternalUser()
  const underwrittenDate = user?.business?.underwrittenAt

  return (
    <Card
      py="48px"
      px="90px"
      borderRadius="16px"
      background="linear-gradient(180deg, rgba(0, 0, 0, 0) 44.39%, rgba(0, 0, 0, 0.2) 100%),linear-gradient(235.58deg, #DB57B6 0%, #6497C7 100%);"
    >
      <CardBody padding="0" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Flex
          width="56px"
          height="56px"
          backgroundColor="white"
          alignItems="center"
          justifyContent="center"
          borderRadius="24px"
          mb="16px"
        >
          <CapitalIcon />
        </Flex>
        <Text textStyle="heading.lg.semibold" color="white" mb="16px">
          Congratulations!
        </Text>
        <Text textStyle="heading.xs.regular" color="white" textAlign="center" mb="48px">
          You have been pre-approved for financing of up to <b>{formatCurrency(Number(amountApproved))}</b> to grow your
          business. Complete our 3 step process and get funds in your Keep CAD Account within 24 hours!
          <br />
          <br />
          Yep, it’s that simple!
        </Text>
        <Link
          href="/capital/apply"
          onClick={() => {
            trackPageVisited({
              type: SEGMENT_PAGE_VISITED.FINANCING_APP_STARTED,
              payload: {
                team: 'Capital',
                page: 'Capital Landing Screen',
                request_type: 'new',
                version: 'v1',
                capital_limit_expired: isUnderwritingPeriodExpired(underwrittenDate),
              },
            })
          }}
        >
          <Button size="xl" variant="solid" borderRadius="16px" padding="20px 24px 20px 24px">
            <Text textStyle="heading.xs.medium">Get started</Text>
          </Button>
        </Link>
      </CardBody>
    </Card>
  )
}

export default CapitalApprovedCard
