import { FeatureFlag } from 'db-schema'

import { BusinessWithFeatureFlags, FeatureFlagValidator } from './types'

type FlagValidatorMap = Record<FeatureFlag, FeatureFlagValidator>

export const FeatureFlags: Record<
  FeatureFlag | 'ENABLE_PERMISSION_SERVICE' | 'ENABLE_CURRENCY_CLOUD_SERVICE',
  FeatureFlagValidator
> = {
  ENABLE_PERMISSION_SERVICE: async () => {
    const { default: getConfig } = await import('next/config')
    const nextJsConfig = getConfig()
    if (nextJsConfig) {
      return nextJsConfig.serverRuntimeConfig.globalFeatureFlags.enablePermissionService
    } else {
      const { default: config } = await import('config')
      return config.featureFlags.enablePermissionService
    }
  },
  ENABLE_CURRENCY_CLOUD_SERVICE: async () => {
    const { default: getConfig } = await import('next/config')
    const nextJsConfig = getConfig()
    if (nextJsConfig) {
      return nextJsConfig.publicRuntimeConfig.globalFeatureFlags.enableCurrencyCloud
    } else {
      const { default: config } = await import('config')
      return config.featureFlags.enableCurrencyCloud
    }
  },

  // DB Feature flags
  ...Object.values(FeatureFlag).reduce(
    (accValidators, flag) => ({
      ...accValidators,
      [flag]: flagValidator(flag),
    }),
    {} as FlagValidatorMap
  ),
} as const

/**
 * Merge of config-based Feature flags and DB Feature flags
 */
export type KeepFeatureFlag = keyof typeof FeatureFlags

/**
 * All Available Feature flags, merge of config-based Feature flags and DB Feature flags
 */
export const KeepFeatureFlags: Record<KeepFeatureFlag, KeepFeatureFlag> = Object.assign(
  {},
  ...Object.keys(FeatureFlags).map((key) => ({ [key]: key }))
)

/**
 * Returns a validator function that validates if the received `business` has the received `flag` enabled.
 * @param flag Feature flag to validate. It must be a DB Feature flag
 * @returns Validator function
 */
export function flagValidator(flag: FeatureFlag): FeatureFlagValidator {
  return async (business?: BusinessWithFeatureFlags | null) => businessHasFeatureFlag(business, flag)
}

/**
 * Validates that the received `business` is defined and has the received DB `featureFlag` enabled.
 * @param business Business to validate
 * @param featureFlag Feature flag to validate. It must be a DB Feature flag
 * @returns `boolean` indicating if the `business` has the `featureFlag` enabled
 */
export function businessHasFeatureFlag(
  business: BusinessWithFeatureFlags | undefined | null,
  featureFlag: FeatureFlag
) {
  return !!business && business.featureFlags.some((item) => item.featureFlag === featureFlag)
}

/**
 * Validates that the received `business` is defined and has all of the received DB `featureFlag`s enabled.
 * @param business Business to validate
 * @param featureFlags Feature flags to validate. They must be DB Feature flags
 * @returns `boolean` indicating if the `business` has all of the `featureFlags` enabled
 */
export function businessHasFeatureFlags(
  business: BusinessWithFeatureFlags | undefined | null,
  ...featureFlags: FeatureFlag[]
) {
  return featureFlags.every((featureFlag) => businessHasFeatureFlag(business, featureFlag))
}
