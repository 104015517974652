import { chakra } from '@chakra-ui/react'
import React, { ComponentProps } from 'react'

const CircleCheckFill = (props?: ComponentProps<typeof chakra.svg>) => {
  return (
    <chakra.svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_814_7057)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 0.833328C4.9374 0.833328 0.833344 4.93738 0.833344 9.99999C0.833344 15.0626 4.9374 19.1667 10 19.1667C15.0626 19.1667 19.1667 15.0626 19.1667 9.99999C19.1667 4.93738 15.0626 0.833328 10 0.833328ZM13.9735 8.45014C14.2682 8.09658 14.2204 7.57111 13.8668 7.27647C13.5133 6.98184 12.9878 7.02961 12.6932 7.38317L9.11068 11.6821L7.25593 9.8274C6.9305 9.50196 6.40286 9.50196 6.07742 9.8274C5.75198 10.1528 5.75198 10.6805 6.07742 11.0059L8.57742 13.5059C8.74298 13.6715 8.97054 13.7597 9.20444 13.7491C9.43833 13.7385 9.65697 13.63 9.80686 13.4501L13.9735 8.45014Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_814_7057">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </chakra.svg>
  )
}

export default CircleCheckFill
