import React, { FC, PropsWithChildren } from 'react'
import { Flex, Text, TextProps } from 'ui-lib'

const TextRowTitle = ({ children, ...props }: PropsWithChildren<TextProps>) => (
  <Text textStyle="paragraph-md" color="text-secondary" {...props}>
    {children}
  </Text>
)

const TextRowValue = ({ children, ...props }: PropsWithChildren<TextProps>) => (
  <Text textStyle="title-xs" {...props}>
    {children}
  </Text>
)

export const TextRow: FC<PropsWithChildren> & {
  Title: typeof TextRowTitle
  Value: typeof TextRowValue
} = ({ children }: PropsWithChildren) => <Flex justify="space-between">{children}</Flex>

TextRow.Title = TextRowTitle
TextRow.Value = TextRowValue
