import { KeepFeatureFlag } from 'services/src/featureFlag/featureFlags'

import { useAppSelector } from '@/src/redux/hooks'

type UseFeatureFlagResult = Record<Partial<KeepFeatureFlag>, boolean>

/**
 * Validates that the received feature flags are enabled for the current user
 * @param flags Feature flags to validate
 * @returns Record of feature flags and their validation result
 */
export const useFeatureFlags = (...flags: KeepFeatureFlag[]): UseFeatureFlagResult => {
  const { flags: userFlags } = useAppSelector((state) => state.featureFlags)

  const result: Partial<UseFeatureFlagResult> = {}
  flags.map((flag) => {
    result[flag] = userFlags.includes(flag)
  })

  return result as UseFeatureFlagResult
}
