import { useApolloClient } from '@apollo/client'
import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { CurrencyCode } from 'services/utils/countries'

import { REWARD_ACTIONS, RewardActionValue } from '@/src/constants/bankTransfers'
import { CreateQuote, CreateQuoteVariables } from '@/src/graphql/generated/CreateQuote'
import {
  ConversionFixedSide,
  CreateQuoteInput,
  CurrencyCode as GQLCurrencyCode,
} from '@/src/graphql/generated/globalTypes'
import { CREATE_QUOTE_MUTATION } from '@/src/graphql/mutations/CreateQuote'
import isDefined from '@/src/utils/dataShaping/isDefined'

export interface Amount {
  numberValue: number
  stringValue: string
}

export type FixedSideValue = 'buy' | 'sell'

type CreateQuoteProps = {
  client: ReturnType<typeof useApolloClient>
  currencyFrom: CurrencyCode
  currencyTo: CurrencyCode
  fixedSide: 'buy' | 'sell'
  amountTo?: number
  amountFrom?: number
  rewardAction: RewardActionValue | null
  fromIntervalRefresh: boolean
}

export type FeeAmount = {
  amountAsString: string
  amountAsNumber: number
  currency: string
}

export type PaymentFeesAndRewards = {
  internationalBankingFee: FeeAmount | null
  savingsAmount: FeeAmount | null
  balanceAfterwards: FeeAmount | null
  finalAmount: FeeAmount | null
  convertingAmount?: FeeAmount | null
  sellAmount?: FeeAmount | null
  rewards: {
    earnedRewardsAmount: FeeAmount | null
    previousRewardsBalance: FeeAmount | null
    afterTransactionRewardsTotalPoints: FeeAmount | null
    earnedRewardsAsPoints: FeeAmount | null
  }
}

export const initialFeeDetails: PaymentFeesAndRewards = {
  convertingAmount: null,
  internationalBankingFee: null,
  savingsAmount: null,
  sellAmount: null,
  balanceAfterwards: null,
  finalAmount: null,
  rewards: {
    earnedRewardsAmount: null,
    previousRewardsBalance: null,
    afterTransactionRewardsTotalPoints: null,
    earnedRewardsAsPoints: null,
  },
}

const initialState = {
  exchangeRate: '',
  quoteCreatedAt: null as string | null,
  amountFrom: { numberValue: 0, stringValue: '' } as Amount,
  amountTo: { numberValue: 0.0, stringValue: '0.00' } as Amount,
  currencyFrom: null as CurrencyCode | null,
  currencyTo: null as CurrencyCode | null,
  fixedSide: null as 'buy' | 'sell' | null,
  quoteId: null as string | null,
  feesAndRewardsDetails: initialFeeDetails,
  rewardAction: REWARD_ACTIONS['earn'].value as RewardActionValue,
  createQuote: {
    loading: false,
    error: null as SerializedError | SerializedError[] | null,
    data: null as CreateQuote | null,
  },
}

export const createQuote = createAsyncThunk(
  'currencyConversion/createQuote',
  async (params: CreateQuoteProps, { rejectWithValue }) => {
    const { client, amountFrom, amountTo, currencyFrom, currencyTo, fixedSide, rewardAction } = params
    const amount = fixedSide === 'buy' ? amountTo : amountFrom
    if (!isDefined(amount) || Number.isNaN(amount)) {
      return rejectWithValue(
        `Unexpected amount, the fixed side is ${fixedSide} but the ${
          fixedSide === 'buy' ? 'amountTo' : 'amountFrom'
        } is ${amount}`
      )
    }
    const createQuoteParams: CreateQuoteInput = {
      amount,
      buyCurrency: GQLCurrencyCode[currencyTo],
      sellCurrency: GQLCurrencyCode[currencyFrom],
      fixedSide: fixedSide === 'buy' ? ConversionFixedSide.BUY : ConversionFixedSide.SELL,
      isEarningRewards: rewardAction === 'earn',
    }
    const { data, errors } = await client.mutate<CreateQuote, CreateQuoteVariables>({
      mutation: CREATE_QUOTE_MUTATION,
      variables: {
        body: createQuoteParams,
      },
    })

    if (isDefined(errors)) {
      return rejectWithValue(errors)
    }

    const { client: _client, ...paramsWithoutClient } = params

    return {
      data,
      ...paramsWithoutClient,
    }
  }
)

const currencyConversionSlice = createSlice({
  name: 'currencyConversion',
  initialState,
  reducers: {
    _setExchangeRate: (state, action: PayloadAction<string>) => {
      state.exchangeRate = action.payload
    },
    _setAmountFrom: (state, action: PayloadAction<Amount>) => {
      state.amountFrom = action.payload
    },
    _setAmountTo: (state, action: PayloadAction<Amount>) => {
      state.amountTo = action.payload
    },
    _setCurrencyFrom: (state, action: PayloadAction<CurrencyCode | null>) => {
      state.currencyFrom = action.payload
    },
    _setCurrencyTo: (state, action: PayloadAction<CurrencyCode | null>) => {
      state.currencyTo = action.payload
    },
    _setQuoteCreatedAt: (state, action: PayloadAction<string>) => {
      state.quoteCreatedAt = action.payload
    },
    _setFeeAndRewardsDetails: (state, action: PayloadAction<PaymentFeesAndRewards>) => {
      state.feesAndRewardsDetails = action.payload
    },
    _setRewardAction: (state, action: PayloadAction<RewardActionValue>) => {
      state.rewardAction = action.payload
    },
    _setFixedSide: (state, action: PayloadAction<'buy' | 'sell' | null>) => {
      state.fixedSide = action.payload
    },
    _setQuoteId: (state, action: PayloadAction<string | null>) => {
      state.quoteId = action.payload
    },
    _resetConversionData: (state) => {
      state.exchangeRate = initialState.exchangeRate
      state.quoteCreatedAt = initialState.quoteCreatedAt
      state.amountFrom = initialState.amountFrom
      state.amountTo = initialState.amountTo
      state.currencyFrom = initialState.currencyFrom
      state.currencyTo = initialState.currencyTo
      state.fixedSide = initialState.fixedSide
      state.quoteId = initialState.quoteId
      state.feesAndRewardsDetails = initialState.feesAndRewardsDetails
      state.rewardAction = initialState.rewardAction
    },
  },
  extraReducers: (builder) => {
    // createQuote reducers
    builder.addCase(createQuote.pending, (state, action) => {
      if (action.meta.arg.fromIntervalRefresh) {
        return
      }
      state.createQuote.loading = true
      state.createQuote.error = null
      state.createQuote.data = null
    })
    builder.addCase(createQuote.rejected, (state, action) => {
      if (action.meta.arg.fromIntervalRefresh) {
        return
      }
      state.createQuote.loading = false
      state.createQuote.error = action.error
      state.createQuote.data = null
      state.feesAndRewardsDetails = initialFeeDetails
      const fixedSide = action.meta.arg.fixedSide
      if (fixedSide === 'buy') {
        state.amountFrom = { numberValue: 0.0, stringValue: '' }
      } else {
        state.amountTo = { numberValue: 0.0, stringValue: '' }
      }
    })
    builder.addCase(createQuote.fulfilled, (state, action) => {
      const fromIntervalRefresh = action.payload.fromIntervalRefresh
      const fixedSide = action.payload.fixedSide
      if (!fromIntervalRefresh) {
        state.createQuote.loading = false
      }
      const quote = action.payload.data?.createQuote?.quote
      const feesAndRewards = action.payload.data?.createQuote?.feesAndRewards
      if (!isDefined(quote) || !isDefined(feesAndRewards)) {
        return
      }
      state.exchangeRate = feesAndRewards.exchangeRate.exchangeRate
      state.quoteCreatedAt = quote.createdAt
      state.quoteId = quote.id
      state.feesAndRewardsDetails = feesAndRewards
      state.createQuote.error = null
      if (fixedSide === 'sell') {
        state.amountTo = {
          numberValue: feesAndRewards.finalAmount.amountAsNumber,
          stringValue: feesAndRewards.finalAmount.amountAsString,
        }
      } else {
        if (isDefined(feesAndRewards.sellAmount)) {
          state.amountFrom = {
            numberValue: feesAndRewards.sellAmount.amountAsNumber,
            stringValue: feesAndRewards.sellAmount.amountAsString,
          }
        }
      }
    })
  },
})

export const {
  _setFeeAndRewardsDetails,
  _setExchangeRate,
  _setAmountFrom,
  _setAmountTo,
  _setCurrencyFrom,
  _setCurrencyTo,
  _setRewardAction,
  _setFixedSide,
  _setQuoteCreatedAt,
  _setQuoteId,
  _resetConversionData,
} = currencyConversionSlice.actions

export default currencyConversionSlice
