import Image from 'next/image'
import { Banner, Box, Button, Card, Flex, Icon, Link, ListIcon, ListItem, Text, UnorderedList } from 'ui-lib'

import { SEGMENT_EVENTS } from '@/src/constants/segmentEvents'
import { SEGMENT_PAGE_VISITED } from '@/src/constants/segmentVisitedPages'
import { StatsigFeatureGate, useFeatureGate } from '@/src/contexts/misc'
import { useEffectOnce, useServerTrackEvent } from '@/src/hooks/misc'

export const CapitalOnboarding = () => {
  const isOriginationFeeActive = useFeatureGate(StatsigFeatureGate.CAPITAL_ORIGINATION_FEE)
  const trackServerEvent = useServerTrackEvent()

  useEffectOnce(() => {
    if (isOriginationFeeActive) {
      trackServerEvent({
        type: SEGMENT_PAGE_VISITED.CAPITAL_HOME_PAGE_VISITED,
        payload: {
          team: 'Capital',
          page: 'Capital home page - no credit approval',
          total_credit_limit: '0',
          version: 'v2',
        },
      })
    }
  })

  const onContactSupport = () => {
    trackServerEvent({
      type: SEGMENT_EVENTS.CONTACT_SUPPORT_INITIATED,
      payload: {
        team: 'Capital',
      },
    })
  }

  return (
    <>
      <Flex
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={8}
        mt={4}
        display={{ base: 'none', md: 'flex' }}
      >
        <Text
          textStyle="headline-3"
          as="h1"
          display={{
            base: 'none',
            md: 'block',
          }}
        >
          Capital
        </Text>
      </Flex>

      <Banner size="sm" variant="success">
        <Banner.Image src="/capital/clock.jpg" />
        <Banner.Headline>
          Unlock up to $200,000 with Keep Capital — Discover what you qualify for today!
        </Banner.Headline>
        <Banner.Content>
          Write to us at
          <Link href="mailto:support@trykeep.com" color="text-info" mx="1" textDecor="underline">
            support@trykeep.com
          </Link>
          to know your offer.
        </Banner.Content>
      </Banner>

      <Card p="6" mt="6" display="flex" flexDir="row" justifyContent="space-between" position="relative">
        <Flex flexDir="column" justifyContent="space-evenly" gap="6">
          <Box>
            <Text
              textStyle="headline-4"
              mb="1"
              sx={{
                background: 'gradient.blueGreen',
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              Get Capital to Grow Faster
            </Text>
            <Text color="text-secondary">Join hundreds of businesses growing with Keep Capital.</Text>
          </Box>
          <UnorderedList
            border="0"
            p="0"
            styleType="none"
            _hover={{
              bg: 'transparent',
            }}
            m="0"
            spacing="2"
          >
            <ListItem textStyle="title-xs" color="text-secondary" display="flex" gap="2px">
              <ListIcon as={Icon} variant="success" icon="check" size="sm" />
              Up to $200,000 in Capital Financing
            </ListItem>
            <ListItem textStyle="title-xs" color="text-secondary" display="flex" gap="2px">
              <ListIcon as={Icon} variant="success" icon="check" size="sm" />
              Approvals within 72 hours
            </ListItem>
            <ListItem textStyle="title-xs" color="text-secondary" display="flex" gap="2px">
              <ListIcon as={Icon} variant="success" icon="check" size="sm" />
              No-dilution or hidden fees
            </ListItem>
            <ListItem textStyle="title-xs" color="text-secondary" display="flex" gap="2px">
              <ListIcon as={Icon} variant="success" icon="check" size="sm" />
              Clear & transparent terms
            </ListItem>
          </UnorderedList>

          <Button onClick={onContactSupport} as="a" href="mailto:support@trykeep.com" width="min-content">
            Contact support
          </Button>
        </Flex>

        <Box
          height="288px"
          width="auto"
          display={{
            base: 'none',
            md: 'block',
          }}
        >
          <Image
            width={0}
            height={0}
            style={{
              width: 'auto',
              height: '100%',
            }}
            sizes="100vw"
            src="/capital/onboarding.png"
            alt=""
          />
        </Box>
      </Card>
    </>
  )
}
