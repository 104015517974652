import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react'

const initialApplication = { amount: '0', repaymentInstallments: 0, reason: '', repaymentFrequency: '' }
interface Application {
  amount: string
  repaymentInstallments: number
  reason: string
  repaymentFrequency: string
}

interface CapitalContext {
  application: Application
  setApplication: Dispatch<SetStateAction<Application>>
  setReason: (reason: string) => void
  resetApplication: () => void
}

const initialContext: CapitalContext = {
  application: initialApplication,
  setApplication: () => {},
  setReason: () => {},
  resetApplication: () => {},
}

const CapitalApplicationContext = createContext(initialContext)

export const useCapitalApplicationContext = () => {
  return useContext(CapitalApplicationContext)
}

interface CapitalApplicationContextProviderProps {
  children: React.ReactNode
}

export const CapitalApplicationContextProvider = ({ children }: CapitalApplicationContextProviderProps) => {
  const [application, setApplication] = useState<Application>(initialApplication)

  const setReason = (reason: string) => {
    setApplication((prev) => ({ ...prev, reason }))
  }

  const resetApplication = () => {
    setApplication(initialApplication)
  }

  const value = {
    application,
    setApplication,
    setReason,
    resetApplication,
  }

  return <CapitalApplicationContext.Provider value={value}>{children}</CapitalApplicationContext.Provider>
}
