import { Money } from 'utils/currency'
import { ConstToUnion } from 'utils/types'

export type ValuesOf<T> = T[keyof T]

export type RiskLevel = 1 | 2 | 3 | 4

const SubmittedLoanStatus = {
  SUBMITTED: `SUBMITTED`,
}

const ActiveLoanStatus = {
  DEPOSITED: `DEPOSITED`,
}

const ClosedLoanStatus = {
  PAID: 'PAID',
  REFINANCED: 'REFINANCED',
  CANCELLED: 'CANCELLED',
  VOID: 'VOID',
}

// This Object groups the different kind of loan status
export const LoanStatus = {
  SubmittedLoanStatus,
  ActiveLoanStatus,
  ClosedLoanStatus,
}

export type LoanStatus =
  | ValuesOf<typeof SubmittedLoanStatus>
  | ValuesOf<typeof ActiveLoanStatus>
  | ValuesOf<typeof ClosedLoanStatus>

export const FinancingChannel = {
  CAPITAL: 'CAPITAL',
  STATEMENT_EXTENSION: 'STATEMENT_EXTENSION',
  STATEMENT_EXTENSION_V2: 'STATEMENT_EXTENSION_V2',
}

export type FinancingChannel = ConstToUnion<typeof FinancingChannel>

export const InstallmentStatus = {
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  PAID: 'PAID',
  OVERDUE: 'OVERDUE',
}

export type InstallmentStatus = ConstToUnion<typeof InstallmentStatus>

export const ApprovalStatus = {
  PENDING: 'PENDING',
  ENABLED: 'ENABLED',
  ERROR_ENABLING: 'ERROR_ENABLING',
  NOT_INTERESTED: 'NOT_INTERESTED',
  STUCK_ONBOARDING: 'STUCK_ONBOARDING',
}

export type ApprovalStatus = ConstToUnion<typeof ApprovalStatus>

export const RepaymentFrequency = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
} as const

export type RepaymentFrequency = ConstToUnion<typeof RepaymentFrequency>

export interface Approval {
  businessNameLegal: string
  businessId: string
  riskLevel: RiskLevel
  capitalCreditLimit: Money
  maxRepaymentMonths: number
  apr: number
  status: ApprovalStatus
  repaymentFrequency: RepaymentFrequency
  enabledAt?: Date
  statementExtensionEnabled: boolean
}

export interface Loan {
  id: string
  loanNumber: string
  businessId: string
  status: LoanStatus
  repaymentFrequency: RepaymentFrequency
  principalAmount: Money
  totalFee: Money
  termDurationInMonths: number
  apr: number
  totalRepayment: Money
  totalRepaymentFormula?: string
  outstandingBalance: Money
  outstandingBalanceFormula?: string
  agreementSignedDate?: Date
  installments?: Installment[]
  firstRepaymentDate?: Date
  financingChannel: FinancingChannel
  reason?: string
  cardDueDate?: Date
  depositedOn?: Date
  comments?: string
}

export interface LoanInput {
  options?: {
    withNotification?: boolean
  }
  data: {
    businessId: string
    amount: Money
    repaymentInstallments: number
    repaymentFrequency?: RepaymentFrequency
    financingChannel: FinancingChannel
    reason: string
    apr: number
    agreementSignedDate?: Date
    cardDueDate?: Date
    status?: LoanStatus
    startDate?: Date
    loanId?: string
    loanNumber?: string
    depositedOn?: Date
    comments?: string
  }
}

export interface Installment {
  id: string
  loanId: string
  businessId: string
  status: InstallmentStatus
  dateDue: Date
  amountDue: Money
  installmentNumber: number
  principalAmountDue: Money
  interestAmountDue: Money
}

export interface RepaymentInfo {
  totalFee: Money
  totalRepaymentAmount: Money
  amountLastPayment: Money
  amountPerRemainingPayments: Money
  principalAmountLastPayment: Money
  principalAmountPerRemainingPayments: Money
  feeLastPayment: Money
  feePerRemainingPayments: Money
  monthsDuration: number
}

export interface CanCreateLoanResult {
  result: boolean
  reason?: string
}

export interface CapitalInfo {
  availableCreditLimit: Money
  approval: Approval
}

export interface GetStatementExtensionEligibilityOutput {
  isEligible: boolean
  data?: {
    availableCapitalCredit: Money
    cardCreditLimit: Money
    cardBalance: Money
    statementDueDate: Date
  }
}
