import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const helpers = createMultiStyleConfigHelpers(['container', 'header', 'childrenContainer'])

export const ContainerWithTitleAndAction = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      borderRadius: 'lg',
      border: '1px solid var(--chakra-colors-_primary-100)',
      overflow: 'hidden',
      width: 'full',
      boxSizing: 'border-box',
    },
    header: {
      paddingY: '1rem',
      paddingX: '1.5rem',
      backgroundColor: '_primary.50',
      alignItems: 'center',
    },
    childrenContainer: {
      padding: '1.5rem',
    },
  },
})
