import { AnalyticsBrowser } from '@segment/analytics-next'
import getConfig from 'next/config'

import { NextConfig } from '@/types/helpers'

const config = getConfig() as NextConfig

export const analytics = AnalyticsBrowser.load(
  {
    writeKey: config?.publicRuntimeConfig?.segmentApiKey,
    cdnURL: config?.publicRuntimeConfig?.segmentCdnProxyUrl,
  },
  {
    obfuscate: true,
    integrations: {
      'Segment.io': {
        apiHost: config?.publicRuntimeConfig?.segmentApiProxyHost,
        protocol: 'https',
      },
    },
  }
)
