import { isFeatureGateEnabledAsync, StatsigFeatureGate } from '@try-keep/feature-flag'
import { FeatureFlag } from 'db-schema'
import { FeatureFlags } from 'services/src/featureFlag/featureFlags'
import { isDefined } from 'utils'

import { PageType } from '@/src/constants/pages'
import { InternalUser } from '@/types/next-auth'

export const checkHasRequiredFeatureFlags = async (user: InternalUser, page: PageType): Promise<boolean> => {
  if (!isDefined(page.featureFlags)) {
    return true
  }
  const flagsEnabled = await Promise.all(
    page.featureFlags.map(async (flag) => {
      try {
        if (!isDefined(user)) {
          return false
        }
        if (flag in FeatureFlags) {
          const flagValidator = FeatureFlags[flag as FeatureFlag]
          return flagValidator(user.business)
        }
        if (flag.toUpperCase() in StatsigFeatureGate) {
          return isFeatureGateEnabledAsync(flag as StatsigFeatureGate)
        }
      } catch (e) {
        console.error(`Error checking feature flag ${flag}: ${e}`)
        return false
      }
    })
  )
  return flagsEnabled.every((flag) => flag === true)
}
