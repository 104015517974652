import { useEffect, useRef } from 'react'
import { isDefined } from 'utils'

interface Input {
  when: boolean
}

export const useScrollTo = <T extends HTMLElement>({ when }: Input) => {
  const ref = useRef<T>(null)

  useEffect(() => {
    if (isDefined(ref.current) && when) {
      ref.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [when])

  return {
    ref,
  }
}
