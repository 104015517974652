import getConfig from 'next/config'
import { useEffect, useRef } from 'react'

import isDefined from '@/src/utils/dataShaping/isDefined'
import { NextConfig } from '@/types/helpers'

export const FACEBOOK_PIXEL_ID = '1916190982114345'
export const TRACKING_PAGES = ['/login', '/sign-up', '/sign-up/confirm-email']

export const useFacebookPixel = () => {
  const didImportPixel = useRef(false)

  useEffect(() => {
    // only run in browser
    if (typeof window === 'undefined') {
      return
    }

    const { publicRuntimeConfig } = getConfig() as NextConfig
    const env = publicRuntimeConfig.keepEnv
    const page = window.location.pathname

    // only run if in dev/prod environments
    if (!isDefined(env)) {
      return
    }

    // only track required pages
    if (!TRACKING_PAGES.includes(page)) {
      return
    }

    // only add to DOM once
    if (didImportPixel.current) {
      return
    }

    didImportPixel.current = true

    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(FACEBOOK_PIXEL_ID)
        ReactPixel.pageView()
      })
  }, [])
}
