import { ConstToUnion } from 'utils/types'

export const CANADIAN_PROVINCES = {
  ONTARIO: 'Ontario',
  BRITISH_COLUMBIA: 'British Columbia',
  ALBERTA: 'Alberta',
  MANITOBA: 'Manitoba',
  NOVA_SCOTIA: 'Nova Scotia',
  NEW_BRUNSWICK: 'New Brunswick',
  SASKATCHEWAN: 'Saskatchewan',
  NEWFOUNDLAND_AND_LABRADOR: 'Newfoundland and Labrador',
  PRINCE_EDWARD_ISLAND: 'Prince Edward Island',
  'NORTHWEST TERRITORIES': 'Northwest Territories',
  YUKON: 'Yukon',
  NUNAVUT: 'Nunavut',
  QUEBEC: 'Quebec',
} as const
export type CanadianProvince = ConstToUnion<typeof CANADIAN_PROVINCES>

export type StateOrProvince = {
  name: CanadianProvince
  code: string
}

const QUEBEC_WITH_ACCENT = 'Québec'
const QUEBEC_STATE_CODE = 'QC'
export const RESTRICTED_PROVINCES: string[] = [
  // Quebec is the only restricted province.
  // Yet in can be represented in two different ways. With and without the accent.
  CANADIAN_PROVINCES.QUEBEC,
  QUEBEC_WITH_ACCENT,
  QUEBEC_STATE_CODE,
]

export const RESTRICTED_PROVINCES_TO_LOWER = RESTRICTED_PROVINCES.map((e) => e.toLowerCase())

export const isRestrictedProvince = (province: string) => {
  return RESTRICTED_PROVINCES_TO_LOWER.includes(province.toLowerCase())
}

export const provinceMatchICase = (province: string): StateOrProvince | undefined => {
  const provinceToLower = province.toLowerCase()
  return CanadianProvinces.find((e) => {
    const code = e.code.toLowerCase()
    const name = e.name.toLowerCase()
    return code === provinceToLower || name === provinceToLower
  })
}

export const getStateOrProvinceByCode = (code: string) => {
  const lowerCaseCode = code.toLowerCase()
  return CanadianProvinces.find((province) => province.code.toLowerCase() === lowerCaseCode)
}

export const getStateOrProvinceByName = (name: string) => {
  const lowercaseName = name.toLowerCase()
  return CanadianProvinces.find((province) => {
    return province.name.toLowerCase() === lowercaseName
  })
}

export const CanadianProvinces: StateOrProvince[] = [
  {
    name: CANADIAN_PROVINCES['NORTHWEST TERRITORIES'],
    code: 'NT',
  },
  {
    name: CANADIAN_PROVINCES.ALBERTA,
    code: 'AB',
  },
  {
    name: CANADIAN_PROVINCES.BRITISH_COLUMBIA,
    code: 'BC',
  },
  {
    name: CANADIAN_PROVINCES.MANITOBA,
    code: 'MB',
  },
  {
    name: CANADIAN_PROVINCES.NEW_BRUNSWICK,
    code: 'NB',
  },
  {
    name: CANADIAN_PROVINCES.NEWFOUNDLAND_AND_LABRADOR,
    code: 'NL',
  },
  {
    name: CANADIAN_PROVINCES.NOVA_SCOTIA,
    code: 'NS',
  },
  {
    name: CANADIAN_PROVINCES.NUNAVUT,
    code: 'NU',
  },
  {
    name: CANADIAN_PROVINCES.ONTARIO,
    code: 'ON',
  },
  {
    name: CANADIAN_PROVINCES.PRINCE_EDWARD_ISLAND,
    code: 'PE',
  },
  {
    name: CANADIAN_PROVINCES.QUEBEC,
    code: 'QC',
  },
  {
    name: CANADIAN_PROVINCES.SASKATCHEWAN,
    code: 'SK',
  },
  {
    name: CANADIAN_PROVINCES.YUKON,
    code: 'YT',
  },
]
