// helpers/cleanPhoneNumber.ts
var cleanPhoneNumber = (phoneNumber) => {
  let formattedNumber = phoneNumber.replace(/\s+/g, "");
  if (!formattedNumber.startsWith("+")) {
    formattedNumber = `+${formattedNumber}`;
  }
  return formattedNumber;
};

export { cleanPhoneNumber };
