import { gql, useMutation } from '@apollo/client'

import { CreateQuote, CreateQuoteVariables } from '@/src/graphql/generated/CreateQuote'

export const CREATE_QUOTE_MUTATION = gql`
  mutation CreateQuote($body: CreateQuoteInput!) {
    createQuote(body: $body) {
      quote {
        id
        clientRate
        createdAt
      }
      feesAndRewards {
        sellAmount {
          amountAsString
          amountAsNumber
          currency
        }
        internationalBankingFee {
          amountAsString
          amountAsNumber
          currency
        }
        convertingAmount {
          amountAsString
          amountAsNumber
          currency
        }
        exchangeRate {
          exchangeRate
        }
        finalAmount {
          amountAsString
          amountAsNumber
          currency
        }
        savingsAmount {
          amountAsString
          amountAsNumber
          currency
        }
        balanceAfterwards {
          amountAsString
          amountAsNumber
          currency
        }
        rewards {
          earnedRewardsAmount {
            amountAsString
            amountAsNumber
            currency
          }
          previousRewardsBalance {
            amountAsString
            amountAsNumber
            currency
          }
          afterTransactionRewardsTotalPoints {
            amountAsString
            amountAsNumber
            currency
          }
          earnedRewardsAsPoints {
            amountAsString
            amountAsNumber
            currency
          }
        }
      }
    }
  }
`

export const useCreateQuote = () => useMutation<CreateQuote, CreateQuoteVariables>(CREATE_QUOTE_MUTATION)
