import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { Button, Flex, Icon, Text } from 'ui-lib'
import { Money } from 'utils'

import { useGetCapitalInfoQuery } from '@/gql'
import { SuccessProcessing } from '@/src/components/capital'
import { useFundingWithOriginationFee } from '@/src/contexts/capital'
import { StatsigFeatureGate, useFeatureGate } from '@/src/contexts/misc'
import { useInternalUserStatus } from '@/src/utils/user-session'

export const Complete = () => {
  const router = useRouter()
  const { data: session } = useSession()
  const { data } = useGetCapitalInfoQuery()
  const { createdFunding } = useFundingWithOriginationFee()
  const { userBusinessIsActive } = useInternalUserStatus()
  const hasCcAccessStatusApproved = Boolean(session?.user?.internal?.business?.ccAccessStatus)
  const disclaimer =
    !hasCcAccessStatusApproved &&
    `Note - It seems your Keep CAD account isn't active. Don’t worry, we'll reach out for additional KYC questions to set it up.`

  const principalAmount = createdFunding?.principalAmount.amountAsNumber ?? 0
  const originationFee = createdFunding?.originationFee.amountAsNumber ?? 0

  const isUnderwritingActive = useFeatureGate(StatsigFeatureGate.CAPITAL_UNDERWRITING) && userBusinessIsActive

  const isProcessing = isUnderwritingActive && data?.capitalInfo?.underwritingStatus === 'PENDING'

  if (isProcessing) {
    return <SuccessProcessing amount={Money.fromNumber(principalAmount - originationFee).toFormattedCurrencyString()} />
  }

  return (
    <Flex flexDirection="column" alignItems="center" justifyContent="center" width="100%" maxWidth="440px" gap="6">
      <Icon icon="check" p="4" bg="bg-inverse" borderRadius="full" variant="inverse" width="max-content" mb="8" />
      <Text textStyle="headline-4">You’re all set!</Text>
      <Flex flexDir="column">
        <Text textStyle="paragraph-lg" textAlign="center">
          {Money.fromNumber(principalAmount - originationFee).toFormattedCurrencyString()} will be deposited in your
          Keep CAD account within 1 business day. We will notify you once its done!
        </Text>
        {Boolean(disclaimer) && (
          <Text textStyle="paragraph-sm" textAlign="center" color="text-soft">
            {disclaimer}
          </Text>
        )}
      </Flex>
      <Button minWidth="50%" onClick={() => router.push('/capital')}>
        Okay
      </Button>
    </Flex>
  )
}
