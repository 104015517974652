import { getCookie } from 'cookies-next'
import { NextRouter, useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { useEffect } from 'react'

import { KeepCookie } from '@/src/constants/cookies'
import isDefined from '@/src/utils/dataShaping/isDefined'
import { analytics } from '@/src/utils/misc'
import { InternalUser } from '@/types/next-auth'

interface UseIdentifySegmentAnalyticsProps {
  extraData?: Record<string, unknown>
}

/**
 * Function to get the user traits to be sent to Segment
 * @param user Next Auth User
 * @param extraData Additional traits to be added to the user
 * @returns Object with the user traits
 */
const getUserTraits = (user: InternalUser, extraData: Record<string, unknown> | undefined, url: URL) => {
  const traits = {
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    phoneNumber: user?.phoneNumber,
    businessId: user?.businessId,
    userType: user?.userType,
    businessName: user?.business?.businessNameLegal,
    id: user?.id,
    gclid: url.searchParams.get('gclid'),
    fbclid: url.searchParams.get('fbclid'),
    ttclid: url.searchParams.get('ttclid'),
    twclid: url.searchParams.get('twclid'),
    ...extraData,
  }
  // Filter out properties with empty strings
  const filteredTraits = Object.fromEntries(
    Object.entries(traits).filter(([_, value]) => isDefined(value) && value !== '')
  )
  return filteredTraits
}

/**
 * Function to get the Segment options to be passed to the identify method
 * @returns Object with the Segment options
 */
const getSegmentOptions = (url: URL) => {
  const anonymousId = url.searchParams.get('anonymous_id')
  const options: Record<string, unknown> = {}

  // If anonymous_id is defined, pass it to the options
  if (isDefined(anonymousId) && typeof anonymousId === 'string') {
    options.anonymousId = anonymousId
  }

  // If the user is coming from a marketing campaign and is in the application process, pass the campaign data to the options
  if (isDefined(url.searchParams.get('utm_source')) && url.pathname.includes('/application')) {
    options.context = {
      campaign: {
        source: url.searchParams.get('utm_source'),
        medium: url.searchParams.get('utm_medium'),
        name: url.searchParams.get('utm_campaign'),
        term: url.searchParams.get('utm_term'),
        content: url.searchParams.get('utm_content'),
      },
    }
  }

  return options
}

/**
 * Function to get the URL with the current params and the marketing params
 * @param router Next Router to get the current params
 * @returns URL with all params
 */
const getUrl = (router: NextRouter) => {
  const cookie = getCookie(KeepCookie.ANALYTICS_UTM)
  const urlParams = cookie?.toString() ?? ''

  const nextParams = new URLSearchParams(router.query as Record<string, string>)
  const combinedParams = new URLSearchParams(urlParams)

  // Merge Next.js router params into the URL search params
  nextParams.forEach((value, key) => {
    combinedParams.set(key, value)
  })

  // Update the URL object with the combined params
  const url = new URL(`${window.location.origin}${window.location.pathname}?${combinedParams.toString()}`)
  return url
}

export const useIdentifySegmentAnalytics = ({ extraData }: UseIdentifySegmentAnalyticsProps = {}) => {
  const { data: session } = useSession()
  const router = useRouter()

  useEffect(() => {
    if (isDefined(session) && isDefined(session.user.internal)) {
      const url: URL = getUrl(router)
      const traits = getUserTraits(session.user.internal, extraData, url)
      const options = getSegmentOptions(url)
      analytics.identify(session.user.internal.id, traits, options)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname])
}
