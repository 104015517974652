import { createContext, useContext, useEffect, useState } from 'react'

import { logger } from '@/src/logging/logger'

export const RecaptchaContext = createContext<{
  execute: ExecuteFunction | undefined
}>({
  execute: undefined,
})

declare global {
  interface Window {
    grecaptcha: any
  }
}

export type RecaptchaProviderProps = {
  siteKey: string
  children: string | JSX.Element | JSX.Element[]
}

type ExecuteFunction = (action: string) => Promise<string>

export const RecaptchaProvider = (props: RecaptchaProviderProps) => {
  const [execute, setExecute] = useState<ExecuteFunction | undefined>(undefined)

  useEffect(() => {
    const script = document.createElement('script')
    const handleLoaded = () => {
      logger.info('recaptcha loaded')
      window.grecaptcha.ready(() => {
        logger.info('recaptcha ready')
        setExecute(() => (action: string) => window.grecaptcha.execute(props.siteKey, { action }))
      })
    }

    script.src = `https://www.google.com/recaptcha/api.js?render=${props.siteKey}`
    script.addEventListener('load', handleLoaded)
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
      document.querySelectorAll('.grecaptcha-badge').forEach((e) => e.remove())
    }
  }, [props.siteKey])

  return <RecaptchaContext.Provider value={{ execute }}>{props.children}</RecaptchaContext.Provider>
}

export const useRecaptcha = () => {
  return useContext(RecaptchaContext)
}
