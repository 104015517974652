import { extendTheme } from '@chakra-ui/react'

import { colors } from './colors'
import { components } from './components'
import { config } from './config'
import { fontSizes } from './font-sizes'
import { fontWeights } from './font-weights'
import { fonts } from './fonts'
import { legacyColors, legacyTextStyles } from './legacy'
import { radii } from './radii'
import { colorTokens } from './semantic-tokens'
import { shadows } from './shadows'
import { sizes } from './sizes'
import { space } from './space'
import { styles } from './styles'
import { textStyles } from './text-styles'

export const theme = extendTheme({
  textStyles: { ...legacyTextStyles, ...textStyles },
  config,
  components,
  fonts,
  styles,
  radii,
  colors: {
    ...legacyColors,
    ...colors,
  },
  semanticTokens: {
    colors: colorTokens,
  },
  space,
  sizes,
  fontSizes,
  fontWeights,
  shadows,
})
