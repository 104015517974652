import { Moment } from 'moment'

import { RepaymentFrequency } from '@/types/capital'
import { RepaymentFrequency as RepaymentFrequencyEnum } from '@/types/capital/enums'

export const getPaymentDueText = (startDate: Moment, repaymentFrequency: RepaymentFrequency) => {
  if (repaymentFrequency === RepaymentFrequencyEnum.DAILY) {
    return 'Every business day'
  }
  if (repaymentFrequency === RepaymentFrequencyEnum.WEEKLY) {
    return `${startDate.format('dddd')} of each week`
  }
  return `${startDate.date()} of each month`
}
