import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const helpers = createMultiStyleConfigHelpers(['container', 'span', 'img'])

export const Flag = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      display: 'inline-block',
      overflow: 'hidden',
      position: 'relative',
      boxSizing: 'border-box',
      '&[data-has-border="true"]': {
        border: '1px solid rgba(0, 0, 0, 0.1)',
      },
      '&[data-gradient="top-down"]': {
        border: '1px solid rgba(0, 0, 0, 0.1)',
        _before: {
          content: '""',
          width: '100%',
          height: '100%',
          position: 'absolute',
          display: 'block',
          mixBlendMode: 'overlay',
          boxSizing: 'border-box',
          backgroundImage: 'linear-gradient(0deg,rgba(0, 0, 0, 0.3) 2%,rgba(255, 255, 255, 0.7) 100%)',
        },
      },
      '&[data-gradient="real-linear"]': {
        border: '1px solid rgba(0, 0, 0, 0.1)',
        _before: {
          content: '""',
          width: '100%',
          height: '100%',
          position: 'absolute',
          display: 'block',
          mixBlendMode: 'overlay',
          boxSizing: 'border-box',
          backgroundImage:
            'linear-gradient(45deg,rgba(0, 0, 0, 0.2) 0%,rgba(39, 39, 39, 0.22) 11%,rgba(255, 255, 255, 0.3) 27%,rgba(0, 0, 0, 0.24) 41%,rgba(0, 0, 0, 0.55) 52%,rgba(255, 255, 255, 0.26) 63%,rgba(0, 0, 0, 0.27) 74%,rgba(255, 255, 255, 0.3) 100%)',
        },
      },
      '&[data-gradient="real-circular"]': {
        border: '1px solid rgba(0, 0, 0, 0.1)',
        _before: {
          content: '""',
          width: '100%',
          height: '100%',
          position: 'absolute',
          display: 'block',
          mixBlendMode: 'overlay',
          boxSizing: 'border-box',
          backgroundImage:
            'radial-gradient(50% 36% at 0 0, rgba(255, 255, 255, 0.3) 0%,rgba(0, 0, 0, 0.24) 11%,rgba(0, 0, 0, 0.55) 17%,rgba(255, 255, 255, 0.26) 22%,rgba(0, 0, 0, 0.17) 27%,rgba(255, 255, 255, 0.28) 31%,rgba(255, 255, 255, 0) 37%) center calc(50% - 8px) / 600% 600%, radial-gradient(50% 123% at 0 0,rgba(255, 255, 255, 0.3) 25%,rgba(0, 0, 0, 0.24) 48%,rgba(0, 0, 0, 0.55) 61%,rgba(255, 255, 255, 0.26) 72%,rgba(0, 0, 0, 0.17) 80%,rgba(255, 255, 255, 0.28) 88%,rgba(255, 255, 255, 0.3) 100%) center calc(50% - 8px) / 600% 600%',
        },
      },
    },
    span: {
      '&[data-has-border="true"]': {
        _before: {
          content: '""',
          width: '100%',
          height: '100%',
          position: 'absolute',
          display: 'block',
          boxSizing: 'border-box',
          border: '1px solid rgba(0, 0, 0, 0.1)',
        },
      },
    },
    img: {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  sizes: {
    s: {
      container: {
        width: '16px',
        height: '12px',
        '&[data-has-drop-shadow="true"]': {
          boxShadow: '0 0 1px 0.5px rgba(0, 0, 0, 0.1)',
        },
        '&[data-has-border-radius="true"]': {
          borderRadius: '1px',
          _before: {
            borderRadius: '1px',
          },
        },
      },
    },
    m: {
      container: {
        width: '20px',
        height: '15px',
        '&[data-has-drop-shadow="true"]': {
          boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1)',
        },
        '&[data-has-border-radius="true"]': {
          borderRadius: '1.5px',
          _before: {
            borderRadius: '1.5px',
          },
        },
      },
    },
    l: {
      container: {
        width: '32px',
        height: '24px',
        '&[data-has-drop-shadow="true"]': {
          boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.1)',
        },
        '&[data-has-border-radius="true"]': {
          borderRadius: '2px',
          _before: {
            borderRadius: '2px',
          },
        },
      },
    },
  },
  defaultProps: {
    size: 'l',
  },
})
