import { isDefined } from 'utils'

interface Options {
  path: string
}

export interface FileResult {
  url: string | null
  size: number | null
  type: string | null
  error: Error | null
  path: string | null
  name: string
}

export const useUploadFile = ({ path }: Options) => {
  const getPresignedUrl = async (file: File) => {
    const { name, type } = file
    const [fileName] = name.split('.')
    const extension = type.split('/')[1]

    try {
      const headers = {
        'Content-Type': 'application/json',
      }

      const body = {
        fileName,
        contentType: type,
        extension,
        path,
      }

      const response = await fetch('/api/assets/signed-url', {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
      })

      const result = await response.json()

      return result.data as {
        presignedUrl: string
      }
    } catch (error) {
      console.log('Failed to generate presigned url', error)
      return null
    }
  }

  const uploadFile = async (file: File): Promise<FileResult> => {
    const response = await getPresignedUrl(file)

    if (!isDefined(response)) {
      throw new Error('Failed to get presigned URL')
    }

    try {
      const res = await fetch(response.presignedUrl, {
        method: 'PUT',
        body: file,
      })

      // Remove the first slash of the pathname
      const url = new URL(res.url)
      const extractedPath = url.pathname.slice(1)

      return {
        url: res.url,
        type: file.type,
        size: file.size,
        error: null,
        path: extractedPath,
        name: file.name,
      }
    } catch (error) {
      return {
        url: null,
        type: file.type,
        size: file.size,
        error: error as Error,
        path: null,
        name: file.name,
      }
    }
  }

  const uploadFiles = async (files: File[]) => {
    const results = await Promise.all(files.map(uploadFile))

    return results
  }

  return {
    uploadFile,
    uploadFiles,
  }
}
