import { useApolloClient } from '@apollo/client'
import { AnyAction, createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { AccountNumberType, CurrencyCode, RoutingCodeType } from 'db-schema'
import { HYDRATE } from 'next-redux-wrapper'

import { FormattedFundingAccountType, PaymentType } from '@/src/constants/accounts'
import { CreateWallet, CreateWalletVariables } from '@/src/graphql/generated/CreateWallet'
import { GetWallets, GetWallets_wallets, GetWallets_wallets_fundingAccounts } from '@/src/graphql/generated/GetWallets'
import { WalletCurrencyCode } from '@/src/graphql/generated/globalTypes'
import { CREATE_WALLET_MUTATION } from '@/src/graphql/mutations/CreateWallet'
import { GET_WALLETS_QUERY } from '@/src/graphql/queries/GetWallets'
import { formatFundingAccounts } from '@/src/hooks/useAccounts/formatFundingAccounts'
import { AppState } from '@/src/redux/store'
import isDefined from '@/src/utils/dataShaping/isDefined'

export type FundingAccountType = {
  id: string
  paymentType: PaymentType
  bankAccount: {
    currency: CurrencyCode
    name: string
    accountHolderName: string
    accountNumber: string
    accountNumberType: AccountNumberType
    routingCode: string | null
    routingCodeType: RoutingCodeType | null
    address: string
    country: string
  }
}

export type AccountType = {
  id: string
  ccAccountId: string
  currency: CurrencyCode
  balance: {
    availableBalance: {
      amount: string
      amountAsNumber: number
    }
    currentBalance: {
      amount: string
      amountAsNumber: number
    }
  }
  fundingAccounts: FormattedFundingAccountType[]
}

export const fetchWallets = createAsyncThunk(
  'accounts/getAccounts',
  async (
    params: {
      client: ReturnType<typeof useApolloClient>
      force?: boolean
    },
    { getState, rejectWithValue }
  ) => {
    const { client, force } = params
    const state = getState() as AppState

    if (state.accounts.ready && force !== true) {
      return
    }

    const { data, error } = await client.query<GetWallets>({
      query: GET_WALLETS_QUERY,
      fetchPolicy: 'network-only',
    })

    if (isDefined(error)) {
      return rejectWithValue(error)
    }

    return data
  }
)

export const createWallet = createAsyncThunk(
  'banking/createWallet',
  async (
    params: {
      client: ReturnType<typeof useApolloClient>
      currency: CurrencyCode
    },
    { rejectWithValue }
  ) => {
    const { client, currency } = params

    const { data, errors } = await client.mutate<CreateWallet, CreateWalletVariables>({
      mutation: CREATE_WALLET_MUTATION,
      variables: {
        body: {
          currency: currency as WalletCurrencyCode,
        },
      },
    })

    if (isDefined(errors)) {
      return rejectWithValue(errors)
    }

    return data
  }
)

const accountsSlice = createSlice({
  name: 'accounts',
  initialState: {
    isAccountsLoading: true,
    ready: false,
    accounts: [] as AccountType[],
    createWallet: {
      loading: false,
      currency: null as CurrencyCode | null,
      error: null as SerializedError | SerializedError[] | null,
      data: null as AccountType | null,
    },
    selectedCurrency: null as CurrencyCode | null,
  },
  reducers: {
    setAccounts: (state, action: PayloadAction<AccountType[]>) => {
      state.accounts = action.payload
      state.ready = true
    },
    addAccount: (state, action: PayloadAction<AccountType>) => {
      state.accounts = [...state.accounts, action.payload]
    },
    setIsAccountsLoading: (state, action: PayloadAction<boolean>) => {
      state.isAccountsLoading = action.payload
    },
    setSelectedCurrency: (state, action: PayloadAction<CurrencyCode | null>) => {
      state.selectedCurrency = action.payload
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchWallets.pending, (state, action) => {
        state.isAccountsLoading = true
      })
      .addCase(fetchWallets.rejected, (state, action) => {
        state.isAccountsLoading = false
      })
      .addCase(fetchWallets.fulfilled, (state, action) => {
        const data = action.payload
        if (!isDefined(data) || !isDefined(data.wallets)) {
          return
        }
        state.isAccountsLoading = false
        const truthyWallets = data.wallets.filter((account) => Boolean(account)) as GetWallets_wallets[]

        const transformed = truthyWallets.map((elem) => ({
          ...elem,
          currency: elem.currency,
          balance: {
            availableBalance: {
              amount: elem.balance?.availableBalance?.amount ?? '0',
              amountAsNumber: elem.balance?.availableBalance?.amountAsNumber ?? 0,
            },
            currentBalance: {
              amount: elem.balance?.currentBalance?.amount ?? '0',
              amountAsNumber: elem.balance?.currentBalance?.amountAsNumber ?? 0,
            },
          },
          fundingAccounts: isDefined(elem.fundingAccounts)
            ? formatFundingAccounts(elem.fundingAccounts as GetWallets_wallets_fundingAccounts[])
            : [],
        }))

        state.accounts = transformed
      })
      .addCase(HYDRATE, (state, action: AnyAction) => {
        return {
          ...state,
          selectedCurrency: action.payload.accounts.selectedCurrency,
        }
      })
      .addCase(createWallet.fulfilled, (state, action) => {
        const data = action.payload
        if (!isDefined(data) || !isDefined(data.createWallet)) {
          return
        }
        const { createWallet } = data
        const createdWallet = {
          ...createWallet,
          currency: createWallet.currency,
          balance: {
            availableBalance: {
              amount: createWallet.balance?.availableBalance?.amount ?? '0',
              amountAsNumber: createWallet.balance?.availableBalance?.amountAsNumber ?? 0,
            },
            currentBalance: {
              amount: createWallet.balance?.availableBalance?.amount ?? '0',
              amountAsNumber: createWallet.balance?.availableBalance?.amountAsNumber ?? 0,
            },
          },
          fundingAccounts: isDefined(createWallet.fundingAccounts)
            ? formatFundingAccounts(createWallet.fundingAccounts as GetWallets_wallets_fundingAccounts[])
            : [],
        }

        const accounts = [...state.accounts, createdWallet].sort((a, b) => a.currency.localeCompare(b.currency))
        state.accounts = accounts
        state.createWallet.loading = false
        state.createWallet.error = null
        state.createWallet.data = createdWallet
      })
      .addCase(createWallet.pending, (state, action) => {
        state.createWallet.currency = action.meta.arg.currency
        state.createWallet.loading = true
        state.createWallet.error = null
        state.createWallet.data = null
      })
      .addCase(createWallet.rejected, (state, action) => {
        state.createWallet.loading = false
        state.createWallet.currency = null
        state.createWallet.error = action.error
        state.createWallet.data = null
      }),
})

export const { setAccounts, addAccount, setIsAccountsLoading, setSelectedCurrency } = accountsSlice.actions

export default accountsSlice
