import { Box, Flex, IconButton, Spinner, Text } from '@chakra-ui/react'
import React from 'react'
import { isDefined } from 'utils'

import { Icon } from '../../icon'
import { FileInputProps } from './types'

export const LoadingFileInput = ({ label }: Pick<FileInputProps, 'label'>) => (
  <>
    <Flex gap="2" align="center" display={{ base: 'flex', sm: 'none' }}>
      <Icon icon="file-icon" variant="secondary" size="lg" />
      <Box>
        <Text textStyle="title-xs">Uploading</Text>
        {isDefined(label) && (
          <Text textStyle="paragraph-sm" color="text-secondary">
            {label}
          </Text>
        )}
      </Box>
    </Flex>
    <IconButton
      aria-label="loading upload"
      size="md"
      icon={<Spinner color="icon-soft" />}
      bg="bg-disabled"
      display={{ base: 'flex', sm: 'none' }}
    />

    <Flex align="center" justify="center" width="full" display={{ base: 'none', sm: 'flex' }}>
      <Spinner />
    </Flex>
  </>
)
