import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'

import { Icon } from '../../icon'
import { FileInputProps } from './types'

export const DraggingContent = ({ label }: Pick<FileInputProps, 'label'>) => {
  return (
    <Flex justifyContent="center" alignItems="center" m="auto" gap="2">
      <Icon icon="file-icon" h="full" variant="info" size="lg" />
      <Box>
        <Text textStyle="title-xs">Drop the files here</Text>
        <Text textStyle="paragraph-sm">{label}</Text>
      </Box>
    </Flex>
  )
}
