import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const helpers = createMultiStyleConfigHelpers(['control', 'icon', 'container', 'label', 'mainText', 'supportingText'])

export const Checkbox = helpers.defineMultiStyleConfig({
  defaultProps: {
    colorScheme: 'primary',
  },
  baseStyle: {
    control: {
      borderRadius: 'xs',
      height: '16px',
      width: '16px',
      borderColor: '_primary.500',
    },
  },
  variants: {
    default: {
      mainText: {
        fontSize: '1.125rem',
        fontWeight: 400,
        lineHeight: '1.5rem',
        letterSpacing: '-0.02rem',
        marginInlineStart: '0.375rem',
      },
    },
    custom: {
      container: {
        width: 'full',
        alignItems: 'flex-start',
        paddingY: '1.25rem',
        paddingX: '1.625rem',
        border: '1px solid var(--chakra-colors-_primary-100)',
        borderRadius: 'lg',
      },
      mainText: {
        fontSize: '1.25rem',
        fontWeight: 600,
        lineHeight: '1.25',
        letterSpacing: '-0.02rem',
        marginInlineStart: '1.125rem',
        marginTop: '-0.125rem',
      },
      supportingText: {
        color: '_primary.500',
        fontSize: '1.125rem',
        fontWeight: 400,
        lineHeight: '1.5rem',
        letterSpacing: '-0.02rem',
        marginInlineStart: '1.125rem',
        marginTop: '0.25rem',
      },
    },
    // This a new variant that we are adding to prevent checkbox from being uppercased by default
    new: {
      container: {
        alignItems: 'start',
      },
      control: {
        mt: '0.25rem',
      },
      label: {
        textTransform: 'none',
        color: 'text-primary',
        textStyle: 'title-xs',
      },
    },
  },
})
