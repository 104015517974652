import { isAccountAdmin, isFounder, UserStatus } from '@try-keep/auth'

import { UserType } from '@/gql'

export const isOwnerUser = <T extends { userType: unknown; status: unknown }>(user: T) => {
  const userWithUserType = {
    userType: user.userType as UserType,
    status: user.status as UserStatus,
  }

  return isFounder(userWithUserType) || isAccountAdmin(userWithUserType)
}
