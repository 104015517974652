import { Box, Flex } from '@chakra-ui/react'
import React, { forwardRef } from 'react'

import { icons } from '../../assets/icons'
import { IconName, IconSize, IconVariant } from './types'

type IconProps = React.ComponentProps<typeof Flex> & {
  icon: IconName
  variant?: IconVariant
  size?: IconSize
}

const COLOR_BY_VARIANT: Partial<Record<IconVariant, string>> = {
  gradient: '',
  inherit: 'currentColor',
}

export const Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ icon, variant = 'primary', size = 'md', ...props }, ref) => {
    const boxSizeByIconSize = {
      sm: '16px',
      md: '24px',
      lg: '32px',
      xl: '40px',
      '2xl': '64px',
    }

    const color = COLOR_BY_VARIANT[variant] ?? `icon-${variant}`

    const IconComponent = (props: { className?: string }) => {
      const Component = icons[icon]
      const { width, height } = Component().props

      return (
        <Component
          width={boxSizeByIconSize[size]}
          height={boxSizeByIconSize[size]}
          viewBox={`0 0 ${width} ${height}`}
          {...props}
        />
      )
    }

    return (
      <Flex align="center" justify="center" {...props} ref={ref}>
        <Box
          as={IconComponent}
          sx={
            variant === 'original'
              ? {}
              : {
                  '*[stroke]': {
                    stroke: color,
                  },

                  '*[fill]': {
                    fill: color,
                  },
                }
          }
        />
      </Flex>
    )
  }
)

Icon.displayName = 'Icon'
