import { ComponentProps } from 'react'
import { chakra } from 'ui-lib'

const PaymentsIcon = (props?: ComponentProps<typeof chakra.svg>) => {
  return (
    <chakra.svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      marginTop="1px"
      {...props}
    >
      <path
        d="M1.66602 5.83268C1.66602 5.39065 1.84161 4.96673 2.15417 4.65417C2.46673 4.34161 2.89065 4.16602 3.33268 4.16602H16.666C17.108 4.16602 17.532 4.34161 17.8445 4.65417C18.1571 4.96673 18.3327 5.39065 18.3327 5.83268V14.166C18.3327 14.608 18.1571 15.032 17.8445 15.3445C17.532 15.6571 17.108 15.8327 16.666 15.8327H3.33268C2.89065 15.8327 2.46673 15.6571 2.15417 15.3445C1.84161 15.032 1.66602 14.608 1.66602 14.166V5.83268Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9993 15.8327C14.9993 14.9486 15.3505 14.1008 15.9757 13.4757C16.6008 12.8505 17.4486 12.4993 18.3327 12.4993M4.99935 4.16602C4.99935 5.05007 4.64816 5.89792 4.02304 6.52304C3.39792 7.14816 2.55007 7.49935 1.66602 7.49935"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </chakra.svg>
  )
}

export default PaymentsIcon
