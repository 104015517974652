import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys)

export const fieldNewStyle = {
  borderStyle: 'solid',
  borderWidth: '1px',
  fontSize: 'paragraph.lg',
  bgColor: 'bg-primary',

  _invalid: {
    borderColor: 'border-error',
  },

  _hover: {
    borderColor: 'border-primary',
  },

  _focusVisible: {
    borderColor: 'border-input-focus',
    boxShadow: 'input-focus',
    outlineColor: 'outline-input-focus',
    outlineOffset: '1px',
    outlineWidth: '4px',
  },

  _disabled: {
    bgColor: 'bg-disabled',
    textColor: 'text-disabled',
    borderColor: 'border-disabled',

    opacity: 1,
    _hover: {
      borderColor: 'border-disabled',
    },
    _placeholder: {
      textColor: 'text-disabled',
    },
  },
}

const baseStyle = definePartsStyle({})

const newStyle = definePartsStyle({
  field: fieldNewStyle,
})

export const Input = defineMultiStyleConfig({
  baseStyle,
  variants: {
    new: newStyle,
  },
})
