import { Divider, Flex, Skeleton, Text } from 'ui-lib'
import { isDefined, Money } from 'utils'

import { FinancingPlanCalculator, useFinancingPlanCalculatorContext } from '@/src/components/capital'
import { TextRow } from '@/src/components/misc'
import { SEGMENT_PAGE_VISITED } from '@/src/constants/segmentVisitedPages'
import { useFundingWithOriginationFee } from '@/src/contexts/capital'
import { useInternalUser, useServerTrackEvent } from '@/src/hooks/misc'
import { isUnderwritingPeriodExpired } from '@/src/utils/capital'

const DEFAULT_TERM_LENGTH = 3

type CapitalPlanFormProps = {
  approval: {
    maxRepaymentMonths: number
    repaymentFrequency: 'DAILY' | 'WEEKLY' | 'MONTHLY'
    availableRepayments: number[]
    capitalCreditLimit: number
    availableCreditLimit: number
  }
}

const roundAmount = (amount: number) =>
  amount > 1000 ? Math.floor(amount / 1000) * 1000 : amount > 100 ? Math.floor(amount / 100) * 100 : amount

const SummaryContent = () => {
  const { selectedRepaymentPlan, isLoading } = useFinancingPlanCalculatorContext()
  const monthlyPayment = Money.fromNumber(
    selectedRepaymentPlan?.amountPerRemainingPayments.amountAsNumber ?? 0
  ).toFormattedCurrencyString()
  return (
    <TextRow>
      <TextRow.Title>Monthly Payment</TextRow.Title>
      {!isDefined(selectedRepaymentPlan) || isLoading ? (
        <Skeleton w="110px" height="24px" />
      ) : (
        <TextRow.Value textStyle="title-sm">{monthlyPayment} /month</TextRow.Value>
      )}
    </TextRow>
  )
}

const CustomOption = ({ option }: { option: { label: string; value: string } }) => {
  const { getRepaymentPlan } = useFinancingPlanCalculatorContext()
  const monthlyAmount = Money.fromNumber(
    getRepaymentPlan(option.value)?.amountPerRemainingPayments.amountAsNumber ?? 0
  ).toFormattedCurrencyString()
  return (
    <Flex alignItems="center" justifyContent="space-between" gap="2">
      <Text color="text-primary" textStyle="paragraph-lg">
        {option?.label}
      </Text>
      <Text color="text-soft" textStyle="title-xs">
        {monthlyAmount} /month
      </Text>
    </Flex>
  )
}

export const CapitalPlanForm = ({ approval }: CapitalPlanFormProps) => {
  const trackServerEvent = useServerTrackEvent()
  const { goToNextStep, goToPreviousStep, fundingAmount, termLength } = useFundingWithOriginationFee()
  const initialFundingAmount = fundingAmount ?? roundAmount(approval.availableCreditLimit)

  const user = useInternalUser()
  const underwrittenDate = user?.business?.underwrittenAt

  const repayments = approval.availableRepayments
  const defaultTermLength = repayments.includes(DEFAULT_TERM_LENGTH)
    ? DEFAULT_TERM_LENGTH
    : repayments[Math.floor(repayments.length / 2)]
  const initialTermLength = termLength ?? defaultTermLength

  const serializeOption = (opt: number) => ({
    value: opt.toString(),
    label: `${opt} ${opt > 1 ? 'months' : 'month'}`,
  })

  const availableRepaymentOptions = approval?.availableRepayments.map(serializeOption)

  const onSubmit = ({ fundingAmount, termLength }: { fundingAmount: string; termLength: string }) => {
    trackServerEvent({
      type: SEGMENT_PAGE_VISITED.FINANCING_APP_PLAN_CONFIRMED,
      payload: {
        team: 'Capital',
        page: 'Capital Plan Page',
        term_length: termLength,
        financing_amount: fundingAmount,
        capital_limit_expired: isUnderwritingPeriodExpired(underwrittenDate),
        version: 'v2',
      },
    })

    goToNextStep({ fundingAmount: Number(fundingAmount), termLength: Number(termLength) })
  }

  return (
    <>
      <Text textStyle="headline-4" mb={8}>
        Choose your financing plan
      </Text>
      <FinancingPlanCalculator
        onCancel={goToPreviousStep}
        onSubmit={onSubmit}
        repaymentFrequency={approval.repaymentFrequency}
        maxRepaymentMonths={approval.maxRepaymentMonths}
        maxFundingAmount={approval.availableCreditLimit}
        initialFundingAmount={initialFundingAmount}
        initialTermLength={initialTermLength}
        mode="write"
        w={['full', '440px']}
        px={[4, 0]}
      >
        <FinancingPlanCalculator.AmountInput
          helperText={
            <>
              Enter an amount up to your available financing limit of{' '}
              <strong>{Money.fromNumber(approval.availableCreditLimit).toFormattedCurrencyString()}</strong>
            </>
          }
        />
        <FinancingPlanCalculator.TermLengthInput
          availableRepaymentOptions={availableRepaymentOptions}
          optionComponent={CustomOption}
        />
        <FinancingPlanCalculator.Summary mt={-3}>
          <Text textTransform="uppercase" textStyle="subheading-sm" color="text-secondary">
            Summary
          </Text>
          <FinancingPlanCalculator.FinancingAmount />
          <FinancingPlanCalculator.DividedFees />
          <FinancingPlanCalculator.AmountReceived />
          <Divider />
          <Text textTransform="uppercase" textStyle="subheading-sm" color="text-secondary">
            You&apos;ll pay
          </Text>
          <FinancingPlanCalculator.TotalFinancingFee />
          <FinancingPlanCalculator.TotalAmountToBePaid />
          <SummaryContent />
        </FinancingPlanCalculator.Summary>
        <FinancingPlanCalculator.CalculatorActionButtons />
      </FinancingPlanCalculator>
    </>
  )
}
