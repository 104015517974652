export type LegacyGradients = 'sunset' | 'lightYellowPink' | 'bluePink' | 'blueGreen' | 'yellowPink' | 'hotRed'

export const legacyColors = {
  primary: {
    300: 'rgba(24, 28, 32, 0.6)',
    400: 'rgba(24, 28, 32, 0.8)',
    500: 'rgb(24, 28, 32)',
  },
  grey: {
    100: '#F8F8F8',
    200: 'rgb(240,240,240)',
    300: '#99A1AA',
    400: '#84888C',
    500: '#A7AEB6',
    600: '#D8D8D8',
    700: '#ECECEC',
    800: '#F3F4F7',
    900: '#EAEAEA',

    alpha: {
      50: 'rgba(24,28,32,0.05)',
      100: 'rgba(24,28,32,0.1)',
      200: 'rgba(24,28,32,0.2)',
      300: 'rgba(24,28,32,0.3)',
      400: 'rgba(24,28,32,0.4)',
      500: 'rgba(24,28,32,0.5)',
      600: 'rgba(24,28,32,0.6)',
      700: 'rgba(24,28,32,0.7)',
      800: 'rgba(24,28,32,0.8)',
      900: 'rgba(24,28,32,0.9)',
    },
    'primary.alpha': {
      50: 'rgba(153, 161, 170, 0.05)',
      100: 'rgba(153, 161, 170, 0.1)',
      200: 'rgba(153, 161, 170, 0.2)',
      300: 'rgba(153, 161, 170, 0.3)',
      400: 'rgba(153, 161, 170, 0.4)',
      500: 'rgba(153, 161, 170, 0.5)',
      600: 'rgba(153, 161, 170, 0.6)',
      700: 'rgba(153, 161, 170, 0.7)',
      800: 'rgba(153, 161, 170, 0.8)',
      900: 'rgba(153, 161, 170, 0.9)',
    },
    'menu.selected': 'rgba(243, 244, 247, 1)',
  },

  black: {
    200: '#46494D',
    300: '#181C20',
    'card.body': '#212324',
  },
  red: {
    200: '#EA001B',
    300: '#CE1E1E',
    600: '#FBCCD1',
  },
  green: {
    300: '#54B948',
  },
  button: {
    grey: '#E2E6EB',
    disabled: '#D8DBE4',
    border: '#EAEAEA',
  },
  input: {
    border: {
      default: '#C4C4C4',
      hover: '#181C20',
      focus: '#2485F9',
      error: '#CE1E1E',
    },
  },
  table: {
    selected: '#F4F6F8',
    hovered: '#F3F4F7',
  },
  blue: {
    200: '#0B77F7',
    300: '#2485F9',
    400: '#748EC4',
    600: '#BDDAFD',
    700: '#E7F1FE',
  },
  pink: {
    200: '#FFEDE6',
    500: '#CB501C',
  },
  yellow: {
    400: '#FFF6DE',
    500: '#EABF4F',
  },
  gradients: {
    pricing: 'linear-gradient(230.74deg, #F2A259 31.51%, #F3305F 96.13%)',
    points: 'linear-gradient(233.24deg, #FFE6A5 -45.96%, #DB57B6 0.42%, #EDEF6E 133.92%)',
    yellowPink: 'linear-gradient(239.33deg, #DB57C6 37.62%, #D2C120 92.24%)',
    bluePink: 'linear-gradient(231.53deg, #DB57B6 12.47%, #6497C7 88.49%)',
    blueGreen: 'linear-gradient(231.53deg, #576CDB 12.47%, #64C774 88.49%)',
    hotRed: 'linear-gradient(230.74deg, #E28F43 31.51%, #F15379 96.13%)',
    verticalBar: 'linear-gradient(180deg, #FFE6A5 0%, #EEA2AD 47.7%, #DB57B6 100%)',
    avatar: 'radial-gradient(101.84% 279% at 6.46% 18.85%, #A0F9C3 0%, #FFC6EF 100%)',
    paymentAvatar: 'linear-gradient(180deg, rgba(186, 129, 233, 0.35) 0%, rgba(202, 150, 71, 0.35) 100%)',
  },
  error: {
    primary: '#CE1E1E',
    background: '#FBE9E8',
  },

  basic: {
    white: '#FFFFFF',
    black: '#000000',
  },
  _primary: {
    50: '#F4F4F6',
    100: '#DFE0E2',
    200: '#C4C6CA',
    300: '#B3B8BC',
    400: '#9CA3AB',
    500: '#7B858E',
    600: '#65727B',
    700: '#49535A',
    800: '#2C333A',
    900: '#181C20',
  },
  accent: {
    50: '#E9F2FF',
    100: '#D3E6FF',
    200: '#A7CCFF',
    300: '#7BB1FF',
    400: '#4D95FC',
    500: '#0B77F7',
    600: '#0867D7',
    700: '#0657B7',
    800: '#044799',
    900: '#02387C',
  },
  positive: {
    50: '#EEF6EB',
    100: '#DCEED7',
    200: '#BADCB0',
    300: '#97CA89',
    400: '#74B860',
    500: '#4EA62F',
    600: '#439027',
    700: '#387A20',
    800: '#2D6519',
    900: '#235112',
  },
  _error: {
    50: '#FEEBE8',
    100: '#FCD7D2',
    200: '#F5AFA6',
    300: '#EB877B',
    400: '#DE5B50',
    500: '#CE1E1E',
    600: '#B31919',
    700: '#981313',
    800: '#7F0E0E',
    900: '#660909',
  },
  warning: {
    50: '#FDF9EF',
    100: '#FBF3DF',
    200: '#F7E6BF',
    300: '#F3D99D',
    400: '#EECC79',
    500: '#EABF4F',
    600: '#CBA644',
    700: '#AE8D38',
    800: '#91752E',
    900: '#755E23',
  },
  _gradients: {
    sunset: 'linear-gradient(180deg, #FFE6A5 0%, #DB57B6 100%)',
    lightYellowPink: 'linear-gradient(235.35deg, #DB57B6 0%, #EFBB6E 100%)',
    bluePink: 'linear-gradient(235.58deg, #DB57B6 0%, #6497C7 100%)',
    blueGreen: 'linear-gradient(235.58deg, #576CDB 0%, #64C774 100%)',
    yellowPink: 'linear-gradient(230.91deg, #F139D4 0%, #D2C120 100%)',
    hotRed: 'linear-gradient(235.63deg, #E28F43 0%, #F15379 100%)',
  } satisfies Record<LegacyGradients, string>,
}
