import { Input as ChakraInput, InputGroup } from '@chakra-ui/react'
import React, { forwardRef } from 'react'
import { isDefined } from 'utils'

export type InputProps = React.ComponentProps<typeof ChakraInput> & {
  leftElement?: React.ReactNode
  rightElement?: React.ReactNode
}
export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ leftElement, rightElement, variant, size, ...props }, ref) => {
    return (
      <InputGroup size={size ?? 'lg'}>
        {isDefined(leftElement) && leftElement}
        <ChakraInput ref={ref} {...props} variant={variant ?? 'new'} size={size ?? 'lg'} fontSize="paragraph.lg" />
        {isDefined(rightElement) && rightElement}
      </InputGroup>
    )
  }
)
