import ColorfulBackgroundSVG from '@/src/svgs/backgrounds/colorful-background.svg'
import ColorfulBackgroundCenterSVG from '@/src/svgs/backgrounds/colorful-background-center.svg'

export const useColorfulBackground = () => {
  const getPageContainerProps = () => ({
    backgroundImage: `url(${ColorfulBackgroundSVG.src})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% -10px',
  })

  const getPageContainerCenterProps = () => ({
    backgroundImage: `url(${ColorfulBackgroundCenterSVG.src})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50%',
  })

  const getCardContainerProps = () => ({
    _before: {
      content: '""',
      position: 'absolute',
      inset: '-60px',
      background:
        'conic-gradient(from 90deg at 40% -111%,  rgba(219, 87, 182, 0.14), rgba(255, 230, 165, 0.14), rgba(219, 87, 182, 0.14));',
      filter: 'blur(80px)',
    },
    position: 'relative' as const,
  })

  return {
    getPageContainerProps,
    getCardContainerProps,
    getPageContainerCenterProps,
  }
}
