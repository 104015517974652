import getConfig from 'next/config'

import { NextConfig } from '@/types/helpers'

const isCypress: boolean = typeof window !== 'undefined' && (window as any).Cypress
const config = getConfig() as NextConfig

const getUrlForApolloClient = (config: NextConfig) => {
  const baseUrl = isCypress ? Cypress.env('apiUrl') : config?.publicRuntimeConfig?.graphqlServerUrl

  if (process.env.NEXT_PUBLIC_PROTOCOL === 'https' && !Boolean(baseUrl.includes('https'))) {
    throw new Error(
      "Detected HTTPS mismatch for environment variables NEXT_PUBLIC_PROTOCOL and publicRuntimeConfig.graphqlServerUrl. To fix, environment variables are using either 'http' or 'https'."
    )
  }

  return `${baseUrl}/graphql`
}

export const GRAPHQL_SERVER_URL = getUrlForApolloClient(config)

export const SHOULD_CONNECT_TO_APOLLO_DEV_TOOLS = isCypress ? false : config?.publicRuntimeConfig?.keepEnv !== 'prod'
