import { CurrencyCode } from 'db-schema'

export type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T
}

import isDefined from './dataShaping/isDefined'

const record: PartialRecord<CurrencyCode, Intl.NumberFormat> = {}

export const formatCurrency = (
  amount: number,
  currency: CurrencyCode = CurrencyCode.CAD,
  displayCurrencyCode: boolean = false,
  locale: string = 'en-us'
) => {
  let currencyFormatter: Intl.NumberFormat
  if (isDefined(record[currency])) {
    currencyFormatter = record[currency] as Intl.NumberFormat
  } else {
    currencyFormatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
      currencyDisplay: 'narrowSymbol',
    })
  }

  return currencyFormatter.format(amount)
}

export const getSymbolByCurrencyCode = (currencyCode: CurrencyCode) => {
  return formatCurrency(0, currencyCode).split('0')[0]
}

export const fromFormattedCurrencyString = (formattedString: string) =>
  parseFloat(formattedString.replace('$', '').replaceAll(',', '')) ?? 0

export const formatInputCurrency = (amount: number) => {
  return Number(formatCurrency(amount).replace('$', ''))
}

export const formatRewardAsCurrency = (points: number, currencyCode: CurrencyCode | null = 'CAD') => {
  return formatCurrency(points / 100, currencyCode ?? 'CAD', true)
}

export const formatRewardAsPoints = (points: number) => {
  return `${new Intl.NumberFormat('en-ca').format(points)} pts`
}

export const formatExchangeRate = (exchangeRate: number) => {
  if (exchangeRate === 0) {
    return '0'
  }

  return exchangeRate.toFixed(5)
}

export const numberInputFormatter = (
  currency: CurrencyCode | null,
  _stringValue: string | null | undefined,
  hasFocus: boolean,
  allowDecimals: boolean | undefined = true
) => {
  if (!isDefined(currency) || !isDefined(_stringValue)) {
    return ''
  }
  const stringValue = _stringValue.replace(/[^a-zA-Z0-9.]/g, '')
  const decimalFormatter = new Intl.NumberFormat('en-CA', {
    style: 'decimal',
  })
  const endsWithPoint = stringValue[stringValue.length - 1] === '.'
  const beforePoint = decimalFormatter.format(Number(stringValue.split('.')[0]))
  const afterPoint = stringValue.split('.')[1]

  if (!allowDecimals) {
    return beforePoint
  }

  if (!hasFocus && isDefined(afterPoint) && afterPoint.length === 1) {
    return beforePoint + '.' + afterPoint + '0'
  }

  if (hasFocus && isDefined(afterPoint) && afterPoint === '00') {
    return beforePoint
  }

  if (isDefined(afterPoint)) {
    return beforePoint + '.' + afterPoint.slice(0, 2)
  }

  if (hasFocus && endsWithPoint) {
    return beforePoint + '.'
  }

  if (hasFocus) {
    return beforePoint
  }

  return beforePoint + '.00'
}
