import { AccountNumberType, RoutingCodeType } from 'db-schema'
import { SupportedCurrenciesType } from 'services/src/currency/types'

import { DataGroup } from '@/src/components/molecules/GroupedDataTableGroup'

export type PaymentType = 'REGULAR' | 'PRIORITY'

export type FormattedFundingAccountType = {
  id: string
  title: string
  paymentType: PaymentType
  accountNumber: string
  fields: DataGroup[]
}

export type FundingAccountFieldsType = {
  label: string
  value: string
}

export type FundingAccountDetailsType = {
  label: string
  routingCodeType: RoutingCodeType
  paymentType: PaymentType
}

export type FundingAccountType = {
  [key in RoutingCodeType | PaymentType]?: FundingAccountDetailsType
}

export type FundingAccountsByCurrencyType = {
  [key in SupportedCurrenciesType]?: FundingAccountType
}

export const ACCOUNT_NUMBER_TYPE_LABEL: Record<AccountNumberType, string> = {
  ACCOUNT_NUMBER: 'Account #',
  IBAN: 'IBAN',
  CLABE: 'CLABE',
}

export const ROUTING_CODE_TYPE_LABEL: Record<RoutingCodeType, string> = {
  BIC_SWIFT: 'BIC',
  ROUTING_NUMBER: 'Routing number',
  ROUTING_CODE: 'Routing code',
  SORT_CODE: 'Sort code',
  WIRE_ROUTING_NUMBER: 'Routing number',
}

export const BIC_SWIFT_FUNDING_ACCOUNT: FundingAccountDetailsType = {
  label: 'International wires/SWIFT',
  routingCodeType: 'BIC_SWIFT',
  paymentType: 'PRIORITY',
}

export const FUNDING_ACCOUNTS_BY_CURRENCY: FundingAccountsByCurrencyType = {
  USD: {
    ROUTING_NUMBER: {
      label: 'ACH transfers',
      routingCodeType: 'ROUTING_NUMBER',
      paymentType: 'REGULAR',
    },
    WIRE_ROUTING_NUMBER: {
      label: 'US domestic wires',
      routingCodeType: 'WIRE_ROUTING_NUMBER',
      paymentType: 'PRIORITY',
    },
    BIC_SWIFT: BIC_SWIFT_FUNDING_ACCOUNT,
  },
  CAD: {
    ROUTING_CODE: {
      label: 'EFT transfers and domestic wires',
      routingCodeType: 'ROUTING_CODE',
      paymentType: 'REGULAR',
    },
    BIC_SWIFT: BIC_SWIFT_FUNDING_ACCOUNT,
  },
  GBP: {
    SORT_CODE: {
      label: 'Faster Payments Service transfers',
      routingCodeType: 'SORT_CODE',
      paymentType: 'REGULAR',
    },
    BIC_SWIFT: BIC_SWIFT_FUNDING_ACCOUNT,
  },
  // Both EUR funding accounts returned by CurrencyCloud use BIC_SWIFT as the routingCodeType,
  // therefore we need to use the paymentType to identify them
  EUR: {
    REGULAR: {
      label: 'SEPA payments',
      routingCodeType: 'BIC_SWIFT',
      paymentType: 'REGULAR',
    },
    PRIORITY: BIC_SWIFT_FUNDING_ACCOUNT,
  },
  MXN: {
    BIC_SWIFT: BIC_SWIFT_FUNDING_ACCOUNT,
  },
}
