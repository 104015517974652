import { defineStyleConfig } from '@chakra-ui/react'

export const Popover = defineStyleConfig({
  defaultProps: {
    variant: 'default',
  },
  variants: {
    default: {
      popper: {
        zIndex: 'popover',
      },
    },
    overlay: {
      popper: {
        zIndex: 'overlay',
      },
    },
  },
})
