import { mode } from '@chakra-ui/theme-tools'

// TODO: replace color references with the equivalent ones when switching to the new theme
export const Sidebar = {
  baseStyle: {
    container: (props: any) => ({
      bg: mode('white', 'primary.500')(props),
    }),
  },
}
