import { ApolloProvider } from '@apollo/client'
import { ReactNode } from 'react'

import { useApollo } from '@/gql'

interface Props {
  children: ReactNode
  pageProps: any
}

export const CustomApolloProvider = ({ children, pageProps }: Props) => {
  const client = useApollo(pageProps)

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
