import React, { forwardRef } from 'react'

import { Input, InputProps } from '../input'

export type DateInputProps = Omit<InputProps, 'type'> & {
  displayCalendar?: boolean
  type?: 'date' | 'datetime-local'
}

export const DateInput = forwardRef<HTMLInputElement, DateInputProps>(
  ({ displayCalendar = false, type = 'date', ...props }, ref) => (
    <Input
      ref={ref}
      type={type}
      textAlign="left"
      sx={
        displayCalendar
          ? {}
          : {
              '&::-webkit-calendar-picker-indicator': {
                display: 'none',
              },
              '&::-webkit-date-and-time-value': {
                display: 'block',
                textAlign: 'left',
              },
            }
      }
      {...props}
    />
  )
)
