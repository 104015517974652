import { ComponentProps } from 'react'
import { chakra } from 'ui-lib'

const CapitalIcon = (props?: ComponentProps<typeof chakra.svg>) => {
  return (
    <chakra.svg
      width="18px"
      height="22px"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.28571 21C7.07281 21 10.1429 18.0152 10.1429 14.3333V12.1111C10.1429 10.8838 9.11951 9.88889 7.85714 9.88889M10.0877 9.8353C10.0877 9.8353 5.40186 10.3802 2.94824 7.99476C0.494616 5.6093 1.05512 1.05359 1.05512 1.05359C1.05512 1.05359 5.74099 0.508654 8.19461 2.89412C10.6482 5.27959 10.0877 9.8353 10.0877 9.8353ZM16.9587 7.70686C16.9587 7.70686 13.4443 7.29816 11.604 9.08726C9.76382 10.8764 10.1842 14.2931 10.1842 14.2931C10.1842 14.2931 13.6986 14.7018 15.5388 12.9127C17.379 11.1236 16.9587 7.70686 16.9587 7.70686Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </chakra.svg>
  )
}

export default CapitalIcon
