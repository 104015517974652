import { sortBy } from 'lodash'
import React from 'react'
import { Box, Divider, Flex, Grid, Tag, Text } from 'ui-lib'
import moment from 'utils/moment'

import { FundingInstallment } from '@/gql'
import { INSTALLMENT_STATE_NAMES, INSTALLMENT_STATUS_STYLES } from '@/src/constants/capital'
import { InstallmentStatus } from '@/types/capital/enums'

type FundingInstallmentsProps = { installments: FundingInstallment[] }

export const FundingInstallments = ({ installments = [] }: FundingInstallmentsProps) => {
  return (
    <Flex flex={1} direction="column" gap="24px" padding="24px">
      <Text textStyle="title-sm" textColor="text-primary">
        Payment Schedule
      </Text>
      <Flex direction="column" gap="12px">
        <Grid templateColumns="repeat(5, minmax(0, 1fr))" width="100%">
          <Text textStyle="subheading-sm" textColor="text-soft" textTransform="uppercase">
            Due Date
          </Text>
          <Text textStyle="subheading-sm" textColor="text-soft" textTransform="uppercase">
            Principal Amount
          </Text>
          <Text textStyle="subheading-sm" textColor="text-soft" textTransform="uppercase">
            Fee amount
          </Text>
          <Text textStyle="subheading-sm" textColor="text-soft" textTransform="uppercase">
            Total amount due
          </Text>
          <Text textStyle="subheading-sm" textColor="text-soft" textTransform="uppercase">
            Status
          </Text>
        </Grid>
        <Divider />

        <Box>
          {sortBy(installments, 'installmentNumber').map((installment, i) => (
            <Grid templateColumns="repeat(5, minmax(0, 1fr))" key={i} alignItems="center">
              <Flex alignItems="center" gap="16px">
                <Flex direction="column" alignItems="center">
                  <Flex
                    direction="column"
                    alignItems="flex-start"
                    width="2px"
                    height="12px"
                    bg={i === 0 ? 'transparent' : 'bg-soft'}
                  />
                  <Flex
                    direction="column"
                    alignItems="flex-start"
                    width="10px"
                    height="10px"
                    borderRadius="50%"
                    my={2}
                    bg={installment.status === InstallmentStatus.PAID ? 'black' : 'bg-soft'}
                  />
                  <Flex
                    direction="column"
                    alignItems="flex-start"
                    width="2px"
                    height="12px"
                    bg={i + 1 === installments.length ? 'transparent' : 'bg-soft'}
                  />
                </Flex>
                <Text textStyle="paragraph-md">{moment(installment?.dateDue).utc().format('ll')}</Text>
              </Flex>
              <Text textStyle="paragraph-md">{installment?.principalAmountDue}</Text>
              <Text textStyle="paragraph-md">{installment?.interestAmountDue}</Text>
              <Text textStyle="paragraph-md">{installment?.amountDue}</Text>

              <Box>
                <Tag variant={INSTALLMENT_STATUS_STYLES[installment.status]}>
                  <Text textStyle="subheading-sm" textColor="none">
                    {INSTALLMENT_STATE_NAMES[installment.status] ?? installment.status}
                  </Text>
                </Tag>
              </Box>
            </Grid>
          ))}
        </Box>
      </Flex>
    </Flex>
  )
}
