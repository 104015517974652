import { chakra } from '@chakra-ui/react'
import React, { ComponentProps } from 'react'

const CircleInfoFill = (props?: ComponentProps<typeof chakra.svg>) => {
  return (
    <chakra.svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_814_7063)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 0.833344C4.9374 0.833344 0.833344 4.9374 0.833344 10C0.833344 15.0626 4.9374 19.1667 10 19.1667C15.0626 19.1667 19.1667 15.0626 19.1667 10C19.1667 4.9374 15.0626 0.833344 10 0.833344ZM9.58334 5.00001C9.12311 5.00001 8.75001 5.37311 8.75001 5.83334C8.75001 6.29358 9.12311 6.66668 9.58334 6.66668H10C10.4602 6.66668 10.8333 6.29358 10.8333 5.83334C10.8333 5.37311 10.4602 5.00001 10 5.00001H9.58334ZM8.33334 8.33334C7.87311 8.33334 7.50001 8.70644 7.50001 9.16668C7.50001 9.62691 7.87311 10 8.33334 10H9.16668V12.5H8.33334C7.87311 12.5 7.50001 12.8731 7.50001 13.3333C7.50001 13.7936 7.87311 14.1667 8.33334 14.1667H11.6667C12.1269 14.1667 12.5 13.7936 12.5 13.3333C12.5 12.8731 12.1269 12.5 11.6667 12.5H10.8333V9.16668C10.8333 8.70644 10.4602 8.33334 10 8.33334H8.33334Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_814_7063">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </chakra.svg>
  )
}

export default CircleInfoFill
