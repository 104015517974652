export const InstallmentStatus = {
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  PAID: 'PAID',
  FAILED: 'FAILED',
  REFINANCED: 'REFINANCED',
  CANCELLED: 'CANCELLED',
  VOID: 'VOID',
} as const

export const FinancingChannel = {
  CAPITAL: 'CAPITAL',
  STATEMENT_EXTENSION: 'STATEMENT_EXTENSION',
} as const

export const RepaymentFrequency = {
  MONTHLY: 'MONTHLY',
  WEEKLY: 'WEEKLY',
  DAILY: 'DAILY',
} as const

export const SubmittedFundingStatus = {
  SUBMITTED: `SUBMITTED`,
} as const

export const ActiveFundingStatus = {
  DEPOSITED: `DEPOSITED`,
} as const

export const ClosedFundingStatus = {
  PAID: `PAID`,
  REFINANCED: `REFINANCED`,
  VOID: 'VOID',
} as const

export const DeletedFundingStatus = {
  CANCELLED: `CANCELLED`,
} as const

export const FundingDiscountType = {
  FLAT_DISCOUNT: 'FLAT_DISCOUNT',
  REWARDS_DISCOUNT: 'REWARDS_DISCOUNT',
} as const
