import { CanadianProvinces } from 'services/utils/states'

export {
  CANADIAN_PROVINCES,
  isRestrictedProvince,
  provinceMatchICase,
  getStateOrProvinceByCode,
} from 'services/utils/states'

export interface Option {
  label: string
  value: string
}

export const PROVINCES_OPTIONS = CanadianProvinces.map<Option>((province) => ({
  label: province.name,
  value: province.code,
}))
