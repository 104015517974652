import { Button, Flex, FlexProps } from 'ui-lib'

import CheckIcon from '@/src/svgs/CheckIcon'

export const ActionButtons = (props: FlexProps) => {
  return (
    <Flex
      {...props}
      width="full"
      gap="4"
      flexDirection={{
        base: 'column',
        lg: 'row',
      }}
    >
      {props.children}
    </Flex>
  )
}

type ButtonProps = Pick<
  React.ComponentProps<typeof Button>,
  'isLoading' | 'isDisabled' | 'type' | 'onClick' | 'children'
> & {
  isSuccess?: boolean
}

const Primary = ({ isSuccess = false, ...props }: ButtonProps) => {
  return (
    <Button
      variant="primary"
      size="md"
      onClick={props.onClick}
      isLoading={props.isLoading}
      isDisabled={props.isLoading === true || props.isDisabled}
      flex={{ base: 'none', lg: '1' }}
      flexShrink="0"
      type={props.type}
      rightIcon={isSuccess ? <CheckIcon marginLeft=".5rem" animated /> : undefined}
      order={{ base: '0', lg: '1' }}
    >
      {props.children}
    </Button>
  )
}

const Secondary = (props: Omit<ButtonProps, 'isSuccess'>) => {
  return (
    <Button
      variant={{ base: 'tertiary', lg: 'outline' }}
      size="md"
      onClick={props.onClick}
      isDisabled={props.isLoading === true || props.isDisabled}
      flex={{ base: 'none', lg: '1' }}
      flexShrink="0"
      order={{ base: '1', lg: '0' }}
    >
      {props.children}
    </Button>
  )
}

ActionButtons.Primary = Primary
ActionButtons.Secondary = Secondary
