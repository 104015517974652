import NextImage from 'next/image'
import { Box, Button, Flex, HStack, Icon, Text } from 'ui-lib'

type ActivationBannerProps = React.ComponentProps<typeof Flex> & {
  children: React.ReactNode | React.ReactNode[]
}
export const ActionBanner = ({ children, ...props }: ActivationBannerProps) => {
  return (
    <Flex
      cursor="pointer"
      data-status="info"
      role="alert"
      justify="space-between"
      boxShadow="md"
      background="bg-primary"
      border="1px solid"
      borderColor="border-soft"
      borderRadius="md"
      p="4"
      flexWrap={{
        base: 'wrap',
        lg: 'nowrap',
      }}
      gap={{
        base: '4',
        lg: '0',
      }}
      {...props}
    >
      {children}
    </Flex>
  )
}

interface BodyProps {
  imageSrc: string
  successText?: string
  title: string
  description?: string
}
const Body = ({ imageSrc, title, description, successText }: BodyProps) => {
  return (
    <HStack
      gap={{
        base: '2',
        lg: '4',
      }}
      order="1"
    >
      <NextImage src={imageSrc} alt="" width={80} height={80} />
      <Box>
        {Boolean(successText) && (
          <Text textStyle="subheading-sm" color="text-success" textTransform="uppercase" mb="1">
            {successText}
          </Text>
        )}
        <Text
          textStyle={{
            base: 'title-xs',
            lg: 'title-sm',
          }}
          color="text-primary"
        >
          {title}
        </Text>
        {Boolean(description) && (
          <Text textStyle="paragraph-sm" color="text-secondary">
            {description}
          </Text>
        )}
      </Box>
    </HStack>
  )
}

const Action = (props: React.ComponentProps<typeof Button>) => {
  return (
    <Flex
      width={{
        base: 'full',
        lg: 'auto',
      }}
      pl={{
        base: '0',
        lg: '6',
      }}
      borderLeft="1px solid"
      borderColor={{
        base: 'transparent',
        lg: 'border-soft',
      }}
      align="center"
      justify={{
        base: 'flex-end',
        lg: 'flex-start',
      }}
      order="2"
    >
      <Button
        rightIcon={Boolean(props.rightIcon) ? props.rightIcon : <Icon icon="chevron-right" size="sm" />}
        variant="link"
        color="text-primary"
        {...props}
      />
    </Flex>
  )
}

ActionBanner.Body = Body
ActionBanner.Action = Action
