import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const helpers = createMultiStyleConfigHelpers([
  'arrow',
  'calendarContainer',
  'calendarIcon',
  'customHeader',
  'iconButton',
  'monthYear',
  'monthYearTrigger',
  'popoverContent',
  'popoverHeader',
  'popoverHeaderDate',
  'popoverHeaderDateContainer',
  'popoverHeaderLabel',
  'popoverTrigger',
  'selectButton',
  'stackDivider',
])

export const DateField = helpers.defineMultiStyleConfig({
  defaultProps: {
    size: 'lg',
  },
  baseStyle: {
    arrow: {
      transition: '0.3s',
      _expanded: {
        transform: 'rotate(180deg)',
      },
      _disabled: {
        color: '_primary.300',
      },
    },
    customHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 6,
    },
    iconButton: {
      borderRadius: '100%',
      height: '1.375rem',
      width: '1.375rem',
      minWidth: '1.375rem',
      color: 'basic.black',
      bgColor: 'basic.white',
      _hover: { bgColor: '_primary.100' },
      _active: { bgColor: '_primary.200' },
    },
    calendarContainer: {
      padding: 6,
      borderRadius: 'lg',
      width: '22rem',
      flexDirection: 'column',
      backgroundColor: 'basic.white',
      '&[data-selects-day="false"]': {
        maxHeight: '24.375rem',
        overflowY: 'scroll',
        '& .react-datepicker__month': {
          display: 'none',
        },
        '& .react-datepicker__day-names': {
          display: 'none',
        },
      },
      '&[data-selects-range="false"]': {
        boxShadow: 'lg',
        border: '1px solid var(--chakra-colors-_primary-50)',
      },
      '& .react-datepicker__month-container': {
        width: '100%',
      },
      '& .react-datepicker__navigation': {
        margin: 8,
      },
      '& .react-datepicker__header': {
        border: 'none',
        backgroundColor: 'basic.white',
        marginBottom: 5,
        padding: 0,
      },
      '& .react-datepicker__current-month': {
        marginBottom: 6,
      },
      '& .react-datepicker__month': {
        margin: 0,
        width: '100%',
      },
      '& .react-datepicker__week': {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 2,
      },
      '& .react-datepicker__day-names': {
        display: 'flex',
        justifyContent: 'space-between',
      },
      '& .react-datepicker__day-name': {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 600,
        lineHeight: '1.5rem',
        letterSpacing: '-0.02rem',
        color: '_primary.400',
        margin: 0,
      },
      '& .react-datepicker__day': {
        fontFamily: 'Inter',
        fontSize: '1.125rem',
        fontWeight: 400,
        lineHeight: '1.5rem',
        letterSpacing: '-0.02rem',
        margin: 0,
        width: 10,
        height: 10,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        borderRadius: 'sm',
        _hover: {
          borderRadius: 'sm',
        },
      },
      '& .react-datepicker__day--keyboard-selected': {
        color: 'basic.white',
        backgroundColor: 'accent.500',
        fontWeight: 600,
      },
      '& .react-datepicker__day--outside-month': {
        visibility: 'hidden',
      },
      '& .react-datepicker__day--in-range': {
        backgroundColor: 'accent.500',
        fontWeight: 600,
      },
      '& .react-datepicker__day--in-selecting-range': {
        backgroundColor: 'accent.300',
        fontWeight: 600,
      },
    },
    popoverContent: {
      boxShadow: 'lg',
      borderRadius: '12px',
      border: '1px solid var(--chakra-colors-_primary-50)',
      width: '22rem',
      backgroundColor: 'basic.white',
    },
    popoverHeader: {
      width: 'full',
      height: '16',
      borderBottom: '1px solid var(--chakra-colors-_primary-100)',
    },
    stackDivider: {
      transform: 'translateY(12px)',
      height: 'calc(100% - 24px)',
      borderColor: '_primary.100',
    },
    popoverTrigger: {
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'basic.white',
      position: 'relative',
      zIndex: 1,
      cursor: 'pointer',
      border: 'none',
      outline: 'none',
      boxShadow: 'none',
      height: '100%',
      width: '100%',
      _disabled: {
        backgroundColor: '_primary.50',
        cursor: 'not-allowed',
        pointerEvents: 'none',
      },
    },
    popoverHeaderDateContainer: {
      flex: 1,
      justifyContent: 'center',
      paddingX: 6,
      flexDirection: 'column',
    },
    popoverHeaderLabel: {
      transition: '0.2s',
      color: '_primary.500',
      fontSize: '1.125rem',
      fontWeight: 400,
      lineHeight: 6,
      '&[data-contracted="true"]': {
        fontSize: '0.75rem',
      },
    },
    popoverHeaderDate: {
      fontSize: '1.125rem',
      fontWeight: 400,
      lineHeight: 6,
    },
    selectButton: {
      marginTop: '6',
      borderRadius: 'md',
      height: '12',
      fontSize: '1.125rem',
      fontWeight: 500,
      letterSpacing: '-0.02rem',
    },
    monthYearTrigger: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
      fontWeight: 600,
      cursor: 'pointer',
      textDecoration: 'underline',
      textUnderlineOffset: '0.425rem',
      _hover: {
        color: '_primary.500',
      },
    },
    monthYear: {
      transition: '0.2s',
      backgroundColor: 'basic.white',
      color: 'accent.500',
      fontSize: '18px',
      _hover: {
        fontSize: '24px',
        backgroundColor: 'accent.100',
      },
    },
  },
  sizes: {
    sm: {
      calendarIcon: {
        height: 4,
        width: 4,
      },
      arrow: {
        height: '0.45rem',
        width: '0.75rem',
      },
      popoverTrigger: {
        fontSize: '1rem',
        paddingLeft: 10,
        paddingRight: 3,
        '&[data-clearable="true"]': {
          paddingRight: 10,
        },
      },
    },
    md: {
      calendarIcon: {
        height: 5,
        width: 5,
      },
      arrow: {
        height: '0.5rem',
        width: '0.75rem',
      },
      popoverTrigger: {
        fontSize: '1.125rem',
        paddingLeft: 12,
        paddingRight: 4,
        '&[data-clearable="true"]': {
          paddingRight: 12,
        },
      },
    },
    lg: {
      calendarIcon: {
        height: 6,
        width: 6,
      },
      arrow: {
        height: '0.625rem',
        width: '0.875rem',
      },
      popoverTrigger: {
        fontSize: '1.125rem',
        paddingLeft: 16,
        paddingRight: 6,
        '&[data-clearable="true"]': {
          paddingRight: 16,
        },
        '&[data-position="inside"]': {
          paddingTop: '1.06rem',
        },
      },
    },
  },
  variants: {},
})
