import { useCallback } from 'react'

import { useTrackEventMutation } from '@/gql'
import { TrackableEvent } from '@/src/constants/segmentEvents'
import { TrackablePageEvent } from '@/src/constants/segmentVisitedPages'

export const useServerTrackEvent = () => {
  const [trackEvent] = useTrackEventMutation()
  return useCallback(
    (segEvent: TrackableEvent | TrackablePageEvent) => {
      const { team, ...rest } = segEvent.payload
      trackEvent({
        variables: {
          event: segEvent.type,
          team: team,
          payload: JSON.stringify(rest),
        },
      })
    },
    [trackEvent]
  )
}
