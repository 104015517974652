import { StatsigFeatureGate } from '@try-keep/feature-flag'
import { Tag } from 'ui-lib'

import { useGetCapitalInfoQuery } from '@/gql'
import { useFeatureGate } from '@/src/contexts/misc'
import { useInternalUserStatus } from '@/src/utils/user-session'

export const CreditLimitStatusTag = () => {
  const { data } = useGetCapitalInfoQuery()
  const { userBusinessIsActive } = useInternalUserStatus()
  const isUnderwritingActive = useFeatureGate(StatsigFeatureGate.CAPITAL_UNDERWRITING) && userBusinessIsActive
  const underwritingStatus = data?.capitalInfo?.underwritingStatus

  if (underwritingStatus !== 'PENDING' || !isUnderwritingActive) {
    return null
  }

  return (
    <Tag h="fit-content" variant="neutral">
      UNDER REVIEW
    </Tag>
  )
}
