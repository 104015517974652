import { createSlice } from '@reduxjs/toolkit'

type AnimationDirection = 'forward' | 'backward' | null

const animationSlice = createSlice({
  name: 'animation',
  initialState: {
    animateForm: null as AnimationDirection,
  },
  reducers: {
    setAnimateForward(state) {
      state.animateForm = 'forward'
    },
    setAnimateBackward(state) {
      state.animateForm = 'backward'
    },
  },
})

export const { setAnimateBackward, setAnimateForward } = animationSlice.actions
export default animationSlice
