import { mode } from '@chakra-ui/theme-tools'

// TODO: replace color references with the equivalent ones when switching to the new theme
export const Button = {
  defaultProps: {
    variant: 'default',
  },
  baseStyle: {
    fontWeight: '500',
    _focus: {
      outlineStyle: 'solid',
      outlineColor: 'accent.500',
      outlineWidth: '4px',
    },
  },
  sizes: {
    xs: {
      height: '24px',
      fontSize: '12px',
      borderRadius: 'sm',
    },
    sm: {
      height: '32px',
      fontSize: '14px',
      borderRadius: 'sm',
    },
    md: {
      height: '40px',
      fontSize: '16px',
      borderRadius: 'md',
    },
    lg: {
      height: '48px',
      fontSize: '18px',
      borderRadius: 'md',
    },
    xl: {
      height: '64px',
      fontSize: '20px',
      letterSpacing: '-2%',
      borderRadius: 'lg',
    },
  },
  variants: {
    default: (props: any) => ({
      color: props.colorMode === 'dark' ? 'primary.500' : 'whiteAlpha.900',
      bg: props.colorMode === 'dark' ? 'whiteAlpha.900' : 'primary.500',
      _hover: {
        bg: props.colorMode === 'dark' ? 'whiteAlpha.800' : 'primary.400',
        _disabled: {
          bg: props.colorMode === 'dark' ? 'whiteAlpha.700' : 'primary.300',
        },
      },
      _active: {
        bg: props.colorMode === 'dark' ? 'whiteAlpha.700' : 'primary.300',
      },
      _focus: {
        outlineStyle: 'solid',
        outlineColor: 'blue.200',
        outlineWidth: '4px',
      },
    }),
    outline: (props: any) => ({
      _focus: {
        outlineStyle: 'solid',
        outlineColor: 'blue.200',
        outlineWidth: '4px',
      },
    }),
    ghostDisabled: (props: any) => ({
      color: props.colorMode === 'dark' ? 'primary.500' : 'whiteAlpha.900',
      bg: props.colorMode === 'dark' ? 'whiteAlpha.900' : 'primary.500',
      _hover: {
        bg: props.colorMode === 'dark' ? 'whiteAlpha.800' : 'primary.400',
      },
      _active: {
        bg: props.colorMode === 'dark' ? 'whiteAlpha.700' : 'primary.300',
      },
      '&[disabled]': {
        backgroundColor: '#F4F6F8',
        color: '#D6D9DC',
        opacity: 1,
      },
      '&:hover[disabled]': {
        backgroundColor: '#F4F6F8',
        color: '#D6D9DC',
        opacity: 1,
      },
      _focus: {
        outlineStyle: 'solid',
        outlineColor: 'blue.200',
        outlineWidth: '4px',
      },
    }),
    link: (props: any) => ({
      _focus: {
        outlineStyle: 'solid',
        outlineColor: 'blue.200',
        outlineWidth: '4px',
      },
    }),
    primary: (props: any) => ({
      color: mode('basic.white', 'basic.black')(props),
      bg: mode('basic.black', 'basic.white')(props),
      _active: {
        bg: mode('_primary.700', '_primary.200')(props),
      },
      _disabled: {
        color: mode('_primary.300', '_primary.600')(props),
        bg: mode('_primary.50', '_primary.900')(props),
        opacity: 1,
      },
      _hover: {
        bg: mode('_primary.800', '_primary.100')(props),
        _disabled: {
          color: mode('_primary.300', '_primary.600')(props),
          bg: mode('_primary.50', '_primary.900')(props),
        },
      },
    }),
    'primary.destructive': (props: any) => ({
      color: mode('basic.white', 'basic.black')(props),
      bg: mode('_error.500', '_error.400')(props),
      _active: {
        bg: mode('_error.700', '_error.200')(props),
      },
      _disabled: {
        color: mode('_error.200', '_error.700')(props),
        bg: mode('_error.50', '_error.900')(props),
        opacity: 1,
      },
      _hover: {
        bg: mode('_error.600', '_error.300')(props),
        _disabled: {
          color: mode('_error.200', '_error.700')(props),
          bg: mode('_error.50', '_error.900')(props),
        },
      },
    }),
    destructive: {
      color: 'text-error',
      bg: 'bg-error',
    },
    secondaryFill: (props: any) => ({
      color: mode('basic.black', 'basic.white')(props),
      bg: mode('_primary.50', '_primary.900')(props),
      _disabled: {
        color: mode('_primary.300', '_primary.600')(props),
        bg: mode('_primary.50', '_primary.900')(props),
        opacity: 1,
      },
      _hover: {
        bg: mode('_primary.100', '_primary.800')(props),
        _disabled: {
          color: mode('_primary.300', '_primary.600')(props),
          bg: mode('_primary.50', '_primary.900')(props),
        },
      },
    }),
    secondaryOutline: (props: any) => ({
      color: mode('basic.black', 'basic.white')(props),
      bg: mode('basic.white', 'basic.black')(props),
      border: '1px solid',
      borderColor: mode('_primary.100', '_primary.800')(props),
      _disabled: {
        color: mode('_primary.300', '_primary.600')(props),
        bg: mode('_primary.50', '_primary.900')(props),
        borderColor: mode('_primary.50', '_primary.900')(props),
        opacity: 1,
      },
      _hover: {
        bg: mode('_primary.50', '_primary.900')(props),
        _disabled: {
          color: mode('_primary.300', '_primary.600')(props),
          bg: mode('_primary.50', '_primary.900')(props),
          borderColor: mode('_primary.50', '_primary.900')(props),
        },
      },
    }),
    tertiary: (props: any) => ({
      color: mode('basic.black', 'basic.white')(props),
      bg: mode('basic.white', 'basic.black')(props),
      _disabled: {
        color: mode('_primary.300', '_primary.600')(props),
        bg: mode('_primary.50', '_primary.900')(props),
        opacity: 1,
      },
      _hover: {
        bg: mode('_primary.50', '_primary.100')(props),
        _disabled: {
          color: mode('_primary.300', '_primary.600')(props),
          bg: mode('_primary.50', '_primary.900')(props),
        },
      },
    }),
  },
}
