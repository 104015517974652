import { useFundingsByBusinessIdPaginatedQuery } from '@/gql'
import { FundingStatus } from '@/types/capital'

export const useHasDepositedFundings = () => {
  const { data, loading: isLoading } = useFundingsByBusinessIdPaginatedQuery({
    variables: {
      limit: 1,
      page: 1,
      status: [FundingStatus.ActiveFundingStatus.DEPOSITED],
    },
  })

  const { total: hasFundings } = data?.fundingsByBusinessIdPaginated ?? {}

  return {
    hasFundings: Boolean(hasFundings),
    isLoading,
  }
}
