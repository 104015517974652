import { defineStyle } from '@chakra-ui/react'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const helpers = createMultiStyleConfigHelpers(['container', 'title', 'description', 'icon', 'closeButton'])

const sizes = {
  sm: helpers.definePartsStyle({
    container: defineStyle({
      padding: '8px',
      textStyle: 'paragraph-sm',
    }),
    title: defineStyle({
      textStyle: 'title-xs',
    }),
    description: defineStyle({
      textStyle: 'paragraph-sm',
    }),
  }),
  md: helpers.definePartsStyle({
    container: defineStyle({
      padding: '16px',
      textStyle: 'paragraph-md',
    }),
    title: defineStyle({
      textStyle: 'title-sm',
    }),
    description: defineStyle({
      textStyle: 'paragraph-md',
    }),
  }),
}

export const Alert = helpers.defineMultiStyleConfig({
  defaultProps: {
    variant: 'info',
    size: 'sm',
  },
  baseStyle: {
    container: {
      position: 'relative',
      borderRadius: 'sm',
      border: '1px solid transparent',
      padding: '16px',
      lineHeight: '16px',

      '& *': {
        whiteSpace: 'normal',
      },
    },
    title: {
      lineHeight: '16px',
      fontWeight: 500,
      color: 'inherit',
    },
    description: {
      color: 'inherit',
      lineHeight: '16px',
    },
  },
  sizes,
  variants: {
    positive: {
      container: {
        background: 'bg-success',
        color: 'text-success',
        borderColor: 'border-success',
      },
      icon: {
        color: 'icon-success',
      },
    },
    info: {
      container: {
        background: 'bg-info',
        color: 'text-info',
        borderColor: 'border-info',
      },
      icon: {
        color: 'icon-info',
      },
    },
    warning: {
      container: {
        background: 'bg-warning',
        color: 'text-warning',
        borderColor: 'border-warning',
      },
      icon: {
        color: 'icon-warning',
      },
    },
    negative: {
      container: {
        background: 'bg-error',
        color: 'text-error',
        borderColor: 'border-error',
      },
      icon: {
        color: 'icon-error',
      },
    },
  },
})
