import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PageName } from '@/src/constants/pages'

const navigationSlice = createSlice({
  name: 'navigation',
  initialState: {
    pageName: 'home' as PageName,
  },
  reducers: {
    setPageName(state, action: PayloadAction<PageName>) {
      state.pageName = action.payload
    },
  },
})

export const { setPageName } = navigationSlice.actions
export default navigationSlice
