import { useSession } from 'next-auth/react'
import { useState } from 'react'
import { DEFAULT_COUNTRY, isDefined } from 'utils'

import { getPhoneCountryCode } from '@/src/utils/formatPhone'
import { ShippingAddressData } from '@/types/form-info'

/**
 *
 * @param isPrefilled Force to fill the address with the fillableAddress
 * @param fillableAddress Address to fill
 * @param initialAddress Address to use as default
 * @returns
 */
export const useFillableAddress = (
  isPrefilled: boolean,
  fillableAddress?: ShippingAddressData,
  initialAddress?: ShippingAddressData
) => {
  const { data: session } = useSession()

  const [isFilled, setIsFilled] = useState(isPrefilled)

  const phoneNumber = session?.user.internal?.phoneNumber
  const defaultAddress: ShippingAddressData | undefined = initialAddress ?? {
    addressLine1: '',
    addressLine2: '',
    city: '',
    country: DEFAULT_COUNTRY,
    postalCode: '',
    state: '',
    phone: {
      value: phoneNumber ?? undefined,
      countryCode: isDefined(phoneNumber) ? getPhoneCountryCode(phoneNumber) : undefined,
    },
  }

  return {
    defaultAddress,
    fillableAddress,
    isFilled,
    setIsFilled,
  }
}
