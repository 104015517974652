import { useCallback } from 'react'

import { TrackableEvent } from '@/src/constants/segmentEvents'
import { analytics } from '@/src/utils/misc'

export const useSegmentTrackEvents = () => {
  const trackEvent = useCallback((segEvent: TrackableEvent) => {
    analytics.track(segEvent.type, segEvent.payload)
  }, [])

  return trackEvent
}
