import React, { ReactNode } from 'react'
import { Box, Flex, FlexProps } from 'ui-lib'

import Body from '@/src/components/molecules/BoxWithHeader/Body'
import Header from '@/src/components/molecules/BoxWithHeader/Header'

type BoxWithHeaderProps = {
  children: ReactNode
}
const BoxWithHeader = ({ children, ...flexProps }: BoxWithHeaderProps & FlexProps) => {
  const headerComponent = React.Children.toArray(children).find(
    (child) => (child as React.ReactElement)?.type === Header
  )

  const bodyComponent = React.Children.toArray(children).find((child) => (child as React.ReactElement)?.type === Body)
  return (
    <Flex {...flexProps}>
      <Box
        border="1px solid"
        borderColor="_primary.100"
        borderRadius="16px"
        as="section"
        width="100%"
        overflow="hidden"
      >
        <Box bg="_primary.50" padding={6}>
          {headerComponent}
        </Box>
        <Flex flexDirection="column" width="100%" justifyContent="center" alignItems="center" padding="24px">
          {bodyComponent}
        </Flex>
      </Box>
    </Flex>
  )
}

export default BoxWithHeader
