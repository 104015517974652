import { useEffect, useRef } from 'react'

/**
 * React hook to get the previous value of a variable whenever it changes.
 * It's useful when you need to track changes of an external hook.
 * @example
 * ```
 * const { route } = useRouter()
 * const previousRoute = usePrevious(route)
 * if (previousRoute !== route) {
 *   // ...
 * }
 * ```
 * But you don't really need it if you're just tracking the previous value of your own `useState()`.
 * @example ```
 * const [value, setValue] = useState(0)
 * setValue((previousValue) => {
 *   // ...
 * })
 * ```
 */
const usePrevious = <T>(value: T) => {
  const currentValRef = useRef<T>(value)
  const prevValRef = useRef<T>(value)
  useEffect(() => {
    prevValRef.current = currentValRef.current
    currentValRef.current = value
  }, [value])
  return prevValRef.current as T
}

export default usePrevious
