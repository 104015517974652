import { Box, chakra, Flex, Radio, RadioGroup, useMultiStyleConfig, useRadio, useRadioGroup } from '@chakra-ui/react'
import React, { useContext } from 'react'

const RadioButtonContext = React.createContext<{ value: string | number } | null>(null)

type RadioButtonProps = Omit<React.ComponentProps<typeof Radio>, 'variant'>

export const RadioButton = React.forwardRef<HTMLInputElement, RadioButtonProps>((props, ref) => {
  const context = useContext(RadioButtonContext)
  const radioGroupValue = context?.value

  const { getInputProps, getLabelProps, getRadioProps } = useRadio(props)
  const input = getInputProps()
  const styles = useMultiStyleConfig('Radio', {
    variant: 'button',
  })
  const isPrimaryColor = radioGroupValue === props.value || !Boolean(radioGroupValue)

  return (
    <Box as="label" {...getLabelProps()} sx={styles.container}>
      <chakra.input {...input} ref={ref} />
      <Box
        {...getRadioProps()}
        sx={{
          ...styles.control,
          color: isPrimaryColor ? 'text-primary' : 'text-secondary',
        }}
      >
        {props.children}
      </Box>
    </Box>
  )
})

type RadioButtonGroupProps = React.ComponentProps<typeof RadioGroup> & {
  children: React.ReactElement<HTMLInputElement>[]
}

export const RadioButtonGroup = ({ children, ...props }: RadioButtonGroupProps) => {
  const { getRadioProps, getRootProps, value } = useRadioGroup(props)

  return (
    <RadioButtonContext.Provider value={{ value }}>
      <Flex {...getRootProps} width="full">
        {React.Children.map(children, (child) => {
          const radio = getRadioProps({ value: child?.props?.value })

          return React.cloneElement(child as React.ReactElement, { ...radio })
        })}
      </Flex>
    </RadioButtonContext.Provider>
  )
}
