import { switchAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(switchAnatomy.keys)

const gradient = definePartsStyle({
  thumb: {
    bg: 'icon-inverse',
  },
  track: {
    bg: 'icon-soft',
    _checked: {
      bg: 'gradient.bluePink',
    },
  },
})

export const Switch = defineMultiStyleConfig({ variants: { gradient } })
