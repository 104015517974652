import { createContext, useContext, useState } from 'react'

export type UploadedDocument = {
  name: string
  path: string
  size: number
  type: string
  url: string
}

export const DocumentUploaderContext = createContext<{
  uploadDocument: (file: File) => Promise<UploadedDocument>
  isLoading: boolean
  error: string | null
}>({ error: null, isLoading: false } as any)

async function getPressingedUrl(path: string, file: File) {
  try {
    const headers = {
      'Content-Type': 'application/json',
    }
    const fileName = file.name.split('.')

    const extension = fileName.pop()
    const name = fileName.join('').replaceAll(' ', '')

    const body = {
      fileName: name,
      contentType: file.type,
      extension: extension,
      path: path,
    }

    const response = await fetch('/api/assets/signed-url', {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    })

    const result = await response.json()
    return result.data
  } catch (error) {
    throw new Error('Failed to generate presigned url', { cause: error })
  }
}

async function sendDocument(url: string, uploadPath: string, file: File): Promise<UploadedDocument> {
  const fileName = file.name.replaceAll(' ', '')
  const newFile: UploadedDocument = {
    name: fileName,
    path: `${uploadPath}${fileName}`,
    size: file.size,
    type: file.type,
    url: ``,
  }

  const result = await fetch(url, {
    method: 'PUT',
    body: file,
  })

  if (!result.ok) {
    throw new Error('Failed to upload document to aws')
  }

  return newFile
}

export const DocumentUploaderProvider = (props: any) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const uploadDocument = async (file: File) => {
    try {
      setLoading(true)
      const { presignedUrl, objectUrl } = await getPressingedUrl(props.path, file)
      const doc = await sendDocument(presignedUrl, props.path, file)
      doc.url = objectUrl

      setLoading(false)

      return doc
    } catch (err: any) {
      setError(err.message)
      setLoading(false)
      throw err
    }
  }

  return (
    <DocumentUploaderContext.Provider
      value={{
        isLoading: loading,
        uploadDocument,
        error,
      }}
    >
      {props.children}
    </DocumentUploaderContext.Provider>
  )
}

export const useDocumentUploader = () => {
  return useContext(DocumentUploaderContext)
}
