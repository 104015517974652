import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const helpers = createMultiStyleConfigHelpers([
  'adornment',
  'formControl',
  'labelContainer',
  'label',
  'hStack',
  'hStackContainer',
  'input',
  'clearButtonContainer',
  'clearButton',
  'clearIcon',
  'inputContainer',
  'helperText',
  'errorContainer',
  'errorMessage',
  'stackDivider',
])

const outlineStyles = {
  boxShadow: '0 0 0 1px var(--chakra-colors-accent-500)',
  borderColor: 'accent.500',
  outlineColor: 'accent.100',
  outlineStyle: 'solid',
  outlineWidth: '4px',
  outlineOffset: '1px',
  _disabled: {
    borderColor: '_primary.50',
    backgroundColor: '_primary.50',
    outline: 'none',
    boxShadow: 'none',
  },
}

export const FieldContainer = helpers.defineMultiStyleConfig({
  defaultProps: {
    size: 'lg',
  },
  baseStyle: {
    adornment: {
      pointerEvents: 'none',
      height: '100%',
      position: 'absolute',
      zIndex: 2,
      alignItems: 'center',
      justifyContent: 'center',
      width: '1rem',
      top: 0,
      '&[data-position="left"]': {
        left: 0,
      },
      '&[data-position="right"]': {
        right: 0,
      },
      _disabled: {
        color: '_primary.300',
      },
    },
    labelContainer: {
      marginBottom: '0.75rem',
      alignItems: 'center',
    },
    inputContainer: {
      width: 'full',
      height: '100%',
    },
    input: {
      height: '100%',
      fontWeight: 400,
      fontFamily: 'Inter',
      border: 'none',
      outline: 'none',
      boxShadow: 'none',
      _disabled: {
        backgroundColor: '_primary.50',
        color: '_primary.300',
      },
    },
    label: {
      fontWeight: 600,
      lineHeight: '1.5rem',
      letterSpacing: '-2%',
      pointerEvents: 'none',
      color: 'black.300',
      marginBottom: 0,
      marginInlineEnd: '0.55rem',
      _invalid: {
        color: '_error.500',
      },
      _disabled: {
        opacity: 1,
        color: '_primary.300',
      },
    },
    formControl: {
      height: '100%',
    },
    hStack: {
      position: 'relative',
      overflow: 'hidden',
      zIndex: 1,
      backgroundColor: 'basic.white',
      transition: 'all 0.07s ease-in-out',
    },
    hStackContainer: {
      position: 'relative',
      width: '100%',
    },
    clearButtonContainer: {
      pointerEvents: 'none',
      height: '100%',
      position: 'absolute',
      zIndex: 2,
      alignItems: 'center',
      justifyContent: 'center',
      top: 0,
      right: 0,
    },
    clearButton: {
      pointerEvents: 'all',
      color: 'basic.black',
      backgroundColor: 'transparent',
      borderRadius: '100%',
      _hover: { bg: '_primary.100' },
      _active: { bg: '_primary.200' },
      _focusWithin: { outline: 'none' },
    },
    helperText: {
      marginTop: 3,
      textStyle: 'text.sm.regular',
      color: '_primary.500',
    },
    errorContainer: {
      position: 'relative',
      zIndex: 0,
      width: 'full',
      alignItems: 'flex-end',
      borderBottomRadius: 'lg',
      height: '3.125rem',
      outline: '5px solid var(--chakra-colors-_error-50)',
      backgroundColor: '_error.50',
    },
    errorMessage: {
      color: '_error.500',
      textStyle: 'text.sm.regular',
      marginLeft: '1.5rem',
      marginBottom: '0.5rem',
    },
    stackDivider: {
      borderColor: '_primary.100',
      height: '2.5rem',
      alignSelf: 'center',
    },
  },
  sizes: {
    sm: {
      label: {
        fontSize: '1.125rem',
      },
      input: {
        fontSize: '1rem',
        borderRadius: 'md',
        paddingX: '1.08rem',
        '&[data-left-element="true"]': {
          paddingLeft: '2.5rem',
        },
        '&[data-right-element="true"]': {
          paddingRight: '2.5rem',
        },
      },
      adornment: {
        width: '3rem',
      },
      clearButtonContainer: {
        width: '3rem',
        '&[data-right-element="true"]': {
          right: '1.5rem',
        },
      },
      clearIcon: {
        h: '0.5rem',
        w: '0.5rem',
      },
      errorContainer: {
        marginTop: '-1rem',
      },
      hStack: {
        height: '2.5rem',
        borderRadius: 'md',
      },
    },
    md: {
      label: {
        fontSize: '1.125rem',
      },
      input: {
        fontSize: '1.125rem',
        borderRadius: 'md',
        paddingX: '1.104rem',
        '&[data-left-element="true"]': {
          paddingLeft: '3rem',
        },
        '&[data-right-element="true"]': {
          paddingRight: '3rem',
        },
      },
      adornment: {
        width: '3.25rem',
      },
      clearButtonContainer: {
        width: '3.25rem',
        '&[data-right-element="true"]': {
          right: '1.75rem',
        },
      },
      clearIcon: {
        h: '0.625rem',
        w: '0.625rem',
      },
      errorContainer: {
        marginTop: '-1rem',
      },
      hStack: {
        height: '3rem',
        borderRadius: 'md',
      },
    },
    lg: {
      adornment: {
        width: '4.5rem',
      },
      label: {
        '&[data-position="outside"]': {
          fontSize: '1.25rem',
        },
        '&[data-position="inside"]': {
          transition: 'all 0.2s ease-in-out',
          zIndex: 2,
          fontSize: '1.125rem',
          fontWeight: 400,
          position: 'absolute',
          top: '1.187rem',
          left: '1.625rem',
          color: '_primary.400',
          _invalid: {
            color: '_error.500',
          },
          '&[data-left-element="true"]': {
            left: '4rem',
          },
          '&[data-contracted="true"]': {
            top: '0.625rem',
            lineHeight: '1.125rem',
            fontSize: '0.75rem',
          },
        },
      },
      input: {
        fontSize: '1.125rem',
        borderRadius: 'lg',
        paddingX: '1.625rem',
        '&[data-left-element="true"]': {
          paddingLeft: '4rem',
        },
        '&[data-right-element="true"]': {
          paddingRight: '4rem',
        },
        '&[data-clearable="true"]': {
          paddingRight: '4rem',
        },
        '&[data-right-element="true"]&[data-clearable="true"]': {
          paddingRight: '6rem',
        },
        '&[data-position="inside"]': {
          paddingTop: '1.06rem',
          _placeholder: {
            color: 'transparent',
          },
          _focus: {
            _placeholder: {
              color: '_primary.400',
            },
          },
        },
      },
      clearButtonContainer: {
        width: '4.5rem',
        '&[data-right-element="true"]': {
          right: '2rem',
        },
      },
      clearIcon: {
        h: '0.75rem',
        w: '0.75rem',
      },
      errorContainer: {
        marginTop: '-0.75rem',
      },
      hStack: {
        height: '4rem',
        borderRadius: 'lg',
      },
    },
  },
  variants: {
    outline: {
      hStack: {
        border: '1px solid var(--chakra-colors-_primary-100)',
        backgroundColor: 'basic.white',
        _hover: {
          borderColor: '_primary.900',
        },
        _disabled: {
          borderColor: '_primary.50',
          backgroundColor: '_primary.50',
          outline: 'none',
        },
        _focusWithin: outlineStyles,
        '&[data-show-outline="true"]': outlineStyles,
        _invalid: {
          boxShadow: '0 0 0 1px var(--chakra-colors-_error-500)',
          borderColor: '_error.500',
          outlineColor: '_error.50',
          outlineStyle: 'solid',
          outlineWidth: '4px',
          outlineOffset: '1px',
        },
      },
    },
    unstyled: {
      hStack: {
        height: '100%',
        border: 'none',
        outline: 'none',
        boxShadow: 'none',
        _hover: {
          border: 'none',
          outline: 'none',
          boxShadow: 'none',
        },
        _disabled: {
          border: 'none',
          outline: 'none',
          boxShadow: 'none',
        },
        _focusVisible: {
          border: 'none',
          outline: 'none',
          boxShadow: 'none',
        },
        _invalid: {
          border: 'none',
          outline: 'none',
          boxShadow: 'none',
        },
      },
    },
  },
})
