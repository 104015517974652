import { Box, Grid } from '@chakra-ui/react'
import React from 'react'
import { isDefined } from 'utils'

export type AdornmentsLayoutProps = React.ComponentProps<typeof Box> & {
  leftElement?: React.ReactNode
  rightElement?: React.ReactNode
  children: React.ReactNode
}

/**
 * Function to determine the layout of the card depending on the presence of left and right elements
 * @param determineLayoutProps
 * @returns {string}
 */
const determineLayout = ({
  leftElement,
  rightElement,
}: Pick<AdornmentsLayoutProps, 'leftElement' | 'rightElement'>) => {
  const hasLeftElement = isDefined(leftElement)
  const hasRightElement = isDefined(rightElement)
  const layout = [
    { value: '1fr', conditions: !hasLeftElement && !hasRightElement },
    { value: 'auto 1fr', conditions: hasLeftElement && !hasRightElement },
    { value: '1fr auto', conditions: !hasLeftElement && hasRightElement },
    { value: 'auto 1fr auto', conditions: hasLeftElement && hasRightElement },
  ]

  return layout.filter(({ conditions }) => conditions).map(({ value }) => value)
}

export const AdornmentsLayout = ({ leftElement, rightElement, children, ...props }: AdornmentsLayoutProps) => {
  return (
    <Grid
      gap="3"
      templateColumns={determineLayout({ leftElement, rightElement })}
      alignItems="center"
      width="full"
      {...props}
    >
      {isDefined(leftElement) && leftElement}
      <Box>{children}</Box>
      {isDefined(rightElement) && rightElement}
    </Grid>
  )
}
