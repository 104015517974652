import { Box, Button, Flex, Text, VisuallyHiddenInput } from '@chakra-ui/react'
import React, { useRef } from 'react'
import { isDefined } from 'utils'

import { Icon } from '../../icon'
import { FileInputProps, FileType } from './types'

type IdleContentProps = Pick<
  FileInputProps,
  'accept' | 'id' | 'isDisabled' | 'isInvalid' | 'isRequired' | 'label' | 'maxFiles'
> & {
  onFilesChange: (files: FileList) => void
}

export const IdleContent = ({
  label,
  maxFiles = 1,
  accept,
  onFilesChange,
  isInvalid,
  isRequired,
  isDisabled,
  id,
}: IdleContentProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const getInputProps = () => ({
    type: 'file',
    multiple: maxFiles > 1,
    accept: accept.map((type) => FileType[type]).join(','),
    ref: inputRef,
    disabled: isDisabled,
    'aria-invalid': isInvalid,
    id,
    required: isRequired,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!isDefined(event.target.files)) {
        return
      }

      onFilesChange(event.target.files)
      // This is required due to the input is not a controlled component
      // and we need to clear the value after the files change
      inputRef.current!.value = ''
    },
  })

  return (
    <>
      <Flex gap="2" align="center">
        <Icon icon="file-icon" variant="secondary" size="lg" />
        <Box>
          <Text textStyle="title-xs">Drop the files here or upload</Text>
          {isDefined(label) && (
            <Text textStyle="paragraph-sm" color="text-secondary">
              {label}
            </Text>
          )}
        </Box>
      </Flex>
      <Button
        variant="secondaryFill"
        leftIcon={<Icon size="sm" icon="plus" variant="inherit" />}
        size={{ base: 'md', sm: 'sm' }}
        isDisabled={isDisabled}
        onClick={() => {
          inputRef.current?.click()
        }}
      >
        Upload
      </Button>
      <VisuallyHiddenInput {...getInputProps()} />
    </>
  )
}
