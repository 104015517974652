import { useRouter } from 'next/router'
import { Button, Flex, Icon, Link, Text } from 'ui-lib'

export const SuccessProcessing = ({ amount }: { amount: string }) => {
  const router = useRouter()
  return (
    <Flex flex="1" minH="100vh" maxW="400px" m="auto" flexDir="column" alignItems="center" justifyContent="center">
      <Icon icon="check" p="4" bg="bg-inverse" borderRadius="full" variant="inverse" width="max-content" />
      <Text textStyle="headline-4" display="block" mt="16" mb="3" textAlign="center">
        Your Capital request of {amount} is being evaluated by our team.
      </Text>
      <Text textStyle="title-sm" color="text-secondary" mb="6" textAlign="center" maxW="440px">
        Once approved, the funds will be deposited into your Keep CAD account within 1 business day. We&apos;ll notify
        you as soon as it&apos;s done!
      </Text>
      <Text textStyle="paragraph-sm" color="text-secondary" mb="6" textAlign="center" maxW="440px">
        Note - It seems your Keep CAD account isn&apos;t active. Don&apos;t worry, we&apos;ll reach out for additional
        KYC questions to set it up.
      </Text>
      <Button as={Link} href="/capital">
        Okay
      </Button>
    </Flex>
  )
}
