import { radioAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const helpers = createMultiStyleConfigHelpers(radioAnatomy.keys)

export const Radio = helpers.defineMultiStyleConfig({
  defaultProps: {
    colorScheme: 'primary',
  },
  variants: {
    new: {
      label: {
        textTransform: 'none',
        color: 'text-primary',
        marginInlineStart: '3',
      },
    },
    button: {
      container: {
        flex: 1,

        '&:not(:first-child):not(:last-child) > div': {
          borderRadius: '0',
          borderLeft: '0',
        },

        '&:first-child > div': {
          borderTopRightRadius: '0',
          borderBottomRightRadius: '0',
        },

        '&:last-child > div': {
          borderTopLeftRadius: '0',
          borderBottomLeftRadius: '0',
          borderLeft: '0',
        },
      },
      control: {
        width: 'full',
        px: '4',
        py: '2',
        height: '40px',
        borderRadius: '8px',
        zIndex: -1,
        bg: 'bg-primary',
        border: '1px solid',
        borderColor: 'border-soft',
        cursor: 'pointer',
        textStyle: 'title-xs',
        textAlign: 'center',

        _checked: {
          bg: 'bg-neutral',
          borderColor: 'border-soft',
          color: 'text-primary',
        },

        '&::before': {
          display: 'none !important',
        },

        '&[data-focus-visible], &:focus-visible': {
          boxShadow: 'border-focus',
          width: '97%',
          whiteSpace: 'nowrap',
        },
      },
    },
    card: {
      container: {
        border: '1px solid',
        transition: 'border-width 0.5s ease, border-color 0.5s ease',
        borderColor: 'border-soft',
        borderRadius: 'lg',
        py: '3',
        px: '4',
        gap: '4',
        spacing: 0,
        width: 'full',
        alignItems: 'start',
        cursor: 'pointer',

        _checked: {
          borderColor: 'border-primary',
          borderWidth: '2px',
        },
      },
    },
  },
})
