import { Flex, Text } from 'ui-lib'
import { Money } from 'utils/currency'

import { CapitalInfo } from '@/gql'
import CapitalApprovedSection from '@/src/components/molecules/capital/CapitalApprovedSection'
import { SEGMENT_PAGE_VISITED } from '@/src/constants/segmentVisitedPages'
import { StatsigFeatureGate, useFeatureGate } from '@/src/contexts/misc'
import { useEffectOnce, useInternalUser, useServerTrackEvent } from '@/src/hooks/misc'
import { isUnderwritingPeriodExpired } from '@/src/utils/capital'
import { useInternalUserStatus } from '@/src/utils/user-session'

import { CreditLimitStatusTag } from './credit-limit-status-tag'
import { GetStartedBanner } from './get-started-banner'
import { HowItWorksSection } from './how-it-works'
import { UnderwritingBanner } from './underwriting-banner'

interface CapitalEmptyStateProps {
  capitalInfo: CapitalInfo
}
export const CapitalEmptyState = ({ capitalInfo }: CapitalEmptyStateProps) => {
  const { userBusinessIsActive } = useInternalUserStatus()
  const isOriginationFeeActive = useFeatureGate(StatsigFeatureGate.CAPITAL_ORIGINATION_FEE)
  const isUnderwritingActive = useFeatureGate(StatsigFeatureGate.CAPITAL_UNDERWRITING) && userBusinessIsActive
  const preApprovedCapital = capitalInfo?.approval.capitalCreditLimit ?? '0'
  const trackServerEvent = useServerTrackEvent()
  const user = useInternalUser()
  const underwrittenDate = user?.business?.underwrittenAt

  useEffectOnce(() => {
    if (isOriginationFeeActive) {
      trackServerEvent({
        type: SEGMENT_PAGE_VISITED.CAPITAL_HOME_PAGE_VISITED,
        payload: {
          team: 'Capital',
          page: 'Capital home page',
          total_credit_limit: preApprovedCapital,
          version: 'v2',
        },
      })
    }
  })

  if (isOriginationFeeActive) {
    return (
      <>
        <Flex
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={8}
          mt={4}
          display={{ base: 'none', md: 'flex' }}
        >
          <Text textStyle="headline-3">Capital</Text>
        </Flex>

        {isUnderwritingActive ? (
          <>
            <UnderwritingBanner />
            <Flex flexDir="column" gap={3} align="flex-start" mt={6}>
              <Text textStyle="title-md" color="text-secondary">
                Total Credit Limit
              </Text>
              <Flex gap="3" alignItems="center">
                <Text textStyle="headline-3">{preApprovedCapital}</Text>
                <CreditLimitStatusTag />
              </Flex>
            </Flex>
          </>
        ) : (
          <GetStartedBanner
            preApprovedCapital={preApprovedCapital}
            getStartedLink="/capital/v2/get-capital"
            onGetStarted={() =>
              trackServerEvent({
                type: SEGMENT_PAGE_VISITED.FINANCING_APP_STARTED,
                payload: {
                  team: 'Capital',
                  page: 'Capital Landing Screen',
                  request_type: 'new',
                  version: 'v2',
                  capital_limit_expired: isUnderwritingPeriodExpired(underwrittenDate),
                },
              })
            }
          />
        )}

        <HowItWorksSection />
      </>
    )
  }

  return (
    <CapitalApprovedSection
      amountApproved={Money.fromFormattedCurrencyString(capitalInfo?.approval?.capitalCreditLimit ?? '0').toNumber()}
    />
  )
}
