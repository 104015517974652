// helpers/isValidAddress.ts
var isValidAddress = (address) => {
  const poBoxRegex = /\b(?:P(?:ost)?.? *O(?:ffice)?.? *B(?:ox)?)\b|\bRPO\b|\bStn\b|\b(?:p\.?o\.?|box) *\d+/i;
  return !poBoxRegex.test(address);
};
var isValidPostalCode = (country, pc) => {
  if (country === "CAN") {
    if (!pc.match(/^[a-zA-Z]\d[a-zA-Z] \d[a-zA-Z]\d$/)) {
      return false;
    }
  }
  return true;
};

export { isValidAddress, isValidPostalCode };
