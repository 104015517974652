import { ReactNode } from 'react'
import { Resolver, SubmitHandler } from 'react-hook-form'
import { BoxProps, isDefined } from 'ui-lib'
import { Money } from 'utils'
import { baseObjectOutputType, z, ZodObject, ZodRawShape } from 'zod'

import { CalculateRepaymentPlanWithOriginationFee } from '@/gql'

export const getCalculatePlanSchema = (maxFundingAmount?: number | null) =>
  z.object({
    termLength: z.string({ message: 'Term length is required' }).min(1, { message: 'Term length is required' }),
    fundingAmount: z
      .string()
      .min(1, { message: 'Funding amount is required' })
      .refine((data) => Number(data) > 0, {
        message: 'The amount cannot be 0 or less than 0',
      })
      .refine(
        (data) => !isDefined(maxFundingAmount) || (isDefined(maxFundingAmount) && Number(data) <= maxFundingAmount),
        {
          message: `The amount cannot be more than ${
            isDefined(maxFundingAmount) && Money.fromNumber(maxFundingAmount).toFormattedCurrencyString()
          }`,
        }
      ),
  })

const calculatePlanSchema = getCalculatePlanSchema()

export type FormValues = z.infer<typeof calculatePlanSchema>

type ValidatedZodObject<T extends ZodRawShape> = z.objectUtil.addQuestionMarks<baseObjectOutputType<T>, any>

type ValidatedFormObject<T extends ZodRawShape> =
  | FormValues
  | {
      [k in keyof ValidatedZodObject<T>]: ValidatedZodObject<T>[k]
    }

export type FormResolver<T extends ZodRawShape> = Resolver<ValidatedFormObject<T>> | undefined

export type OnSubmit<T extends ZodRawShape> = SubmitHandler<ValidatedFormObject<T>>

export interface FinancingCalculatorContextType<T extends ZodRawShape> {
  termLength: string
  mode: 'read' | 'write'
  fundingAmount: number | null
  maxFundingAmount: number | null | undefined
  onCancel: (formValues?: FormValues & z.infer<ZodObject<T>>) => void
  isLoading: boolean
  selectedRepaymentPlan: CalculateRepaymentPlanWithOriginationFee | undefined
  getRepaymentPlan: (termLength: string) => CalculateRepaymentPlanWithOriginationFee | undefined
  showSkeleton: () => void
}

export type CalculatorBaseProps<T extends ZodRawShape> = Omit<BoxProps, 'onSubmit'> & {
  onSubmit: (formValues: FormValues & z.infer<ZodObject<T>>) => void
  onCancel: () => void
  children: ReactNode
  repaymentFrequency: string
  maxRepaymentMonths: number
  maxFundingAmount?: number
  customSchema?: ZodObject<T>
  initialTermLength: number | null
  initialFundingAmount?: number | null
  mode: 'write' | 'read'
}
