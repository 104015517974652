// TODO: replace color references with the equivalent ones when switching to the new theme
export const Heading = {
  baseStyle: {
    letterSpacing: '-0.02em',
  },
  variants: {
    form: (props: any) => ({
      fontWeight: 600,
      fontSize: '28px',
      lineHeight: '32px',
      textAlign: 'center',
      marginBottom: '40px',
    }),
    title: (props: any) => ({
      fontWeight: 600,
      marginBottom: 10,
      marginTop: 10,
    }),
    subtitle: (props: any) => ({
      fontSize: '20px',
      lineHeight: '24px',
      color: '#181C20',
      marginBottom: 5,
    }),
  },
}
