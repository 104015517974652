import 'react-phone-input-2/lib/bootstrap.css'

import { Box } from '@chakra-ui/react'
import { cx } from '@chakra-ui/utils'
import React, { CSSProperties, forwardRef } from 'react'
import ReactPhoneInput, { CountryData } from 'react-phone-input-2'
import { cleanPhoneNumber, isDefined } from 'utils'

import { Size } from '../../../types/design-system'
import classes from './phone-input.module.css'
import { CountryCodeAlpha } from './types'

type PhoneFieldProps = React.ComponentProps<typeof Box> & {
  id?: string
  inputStyle?: CSSProperties
  onChange?: (value: string, data: CountryData) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  value?: string | null | undefined
  isDisabled?: boolean
  isInvalid?: boolean
  country?: CountryCodeAlpha
  size?: Size
  countryCodeEditable?: boolean
}

export const PhoneInput = forwardRef(
  (
    {
      id,
      inputStyle,
      isInvalid,
      onChange,
      value,
      isDisabled = false,
      country = 'CA',
      size = Size.MD,
      onFocus,
      onBlur,
      countryCodeEditable = false,
      ...props
    }: PhoneFieldProps,
    ref
  ) => {
    const hasValue = Boolean(value)

    return (
      <>
        <Box {...props} align="center" position="relative">
          {isDefined(ReactPhoneInput) && (
            <ReactPhoneInput
              country={country.toLowerCase()}
              specialLabel=""
              preferredCountries={['ca', 'us']}
              priority={{ ca: 0, us: 1 }}
              value={value}
              countryCodeEditable={countryCodeEditable}
              disabled={isDisabled}
              inputStyle={inputStyle}
              buttonClass={classes['button']}
              inputClass={cx(classes['input'], classes[size], hasValue && classes['has-value'])}
              inputProps={{
                id,
                'aria-invalid': isInvalid,
                'aria-disabled': isDisabled,
                'data-form-type': 'phone',
                'data-cy': 'phoneInput',
                type: 'tel',
                ref: isDefined(ref) ? ref : undefined,
                name: 'phoneInput',
              }}
              onChange={(value, data) => {
                onChange?.(cleanPhoneNumber(value), data as CountryData)
              }}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          )}
        </Box>
        {/* The following classes are not editable via props, therefore they need to be targeted directly */}
        <style>{`
        .react-tel-input .selected-flag:focus:before,
        .react-tel-input .selected-flag.open:before {
          outline: 0px;
          border: 0px;
          box-shadow: none;
        }
        .react-tel-input .selected-flag {
          ${isDisabled ? 'cursor: not-allowed; opacity: 0.3;' : ''}
        }
      `}</style>
      </>
    )
  }
)
