import { gql, useMutation } from '@apollo/client'

import { CreateWallet, CreateWalletVariables } from '@/src/graphql/generated/CreateWallet'

export const CREATE_WALLET_MUTATION = gql`
  mutation CreateWallet($body: CreateWalletInput!) {
    createWallet(body: $body) {
      id
      ccAccountId
      currency
      balance {
        availableBalance {
          amount
          amountAsNumber
        }
        currentBalance {
          amount
          amountAsNumber
        }
      }
      fundingAccounts {
        id
        paymentType
        bankAccount {
          currency
          accountHolderName
          name
          accountNumber
          accountNumberType
          routingCode
          routingCodeType
          address
          country
        }
      }
    }
  }
`

export const useCreateWalletMutation = () => useMutation<CreateWallet, CreateWalletVariables>(CREATE_WALLET_MUTATION)
