/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountNumberType {
  ACCOUNT_NUMBER = 'ACCOUNT_NUMBER',
  CLABE = 'CLABE',
  IBAN = 'IBAN',
}

export enum AccountingIntegrationType {
  RUTTER_QBO = 'RUTTER_QBO',
}

export enum BankAccountRoutingCodeType {
  BIC_SWIFT = 'BIC_SWIFT',
  ROUTING_CODE = 'ROUTING_CODE',
  ROUTING_NUMBER = 'ROUTING_NUMBER',
  SORT_CODE = 'SORT_CODE',
  WIRE_ROUTING_NUMBER = 'WIRE_ROUTING_NUMBER',
}

export enum BankPaymentStatusType {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  CREATED = 'CREATED',
  FAILED = 'FAILED',
}

export enum BankPaymentType {
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING',
}

export enum BankTransferDirection {
  HOUSE_TO_SUB = 'HOUSE_TO_SUB',
  SUB_TO_HOUSE = 'SUB_TO_HOUSE',
}

export enum BankTransferType {
  BALANCE_CORRECTION = 'BALANCE_CORRECTION',
  CURRENCY_CONVERSION_FLUCTUATION = 'CURRENCY_CONVERSION_FLUCTUATION',
  FEE = 'FEE',
  FUNDING = 'FUNDING',
  FX = 'FX',
  INVOICE_FACTORING_DEPOSIT = 'INVOICE_FACTORING_DEPOSIT',
  INVOICE_FACTORING_PAYMENT = 'INVOICE_FACTORING_PAYMENT',
  OTHER = 'OTHER',
  REFUND = 'REFUND',
  REWARD_REDEMPTION = 'REWARD_REDEMPTION',
  STATEMENT_PAYMENT = 'STATEMENT_PAYMENT',
}

export enum BankingPaymentMethodsType {
  ACH = 'ACH',
  EFT = 'EFT',
  FED_WIRE = 'FED_WIRE',
  FPS = 'FPS',
  PESONET = 'PESONET',
  SEPA = 'SEPA',
  SPEI = 'SPEI',
  SWIFT = 'SWIFT',
  TARGET2 = 'TARGET2',
}

export enum CCAccessStatus {
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  IN_REVIEW = 'IN_REVIEW',
  NOT_REQUESTED = 'NOT_REQUESTED',
  REQUESTED = 'REQUESTED',
}

/**
 * Card types
 */
export enum CardType {
  PHYSICAL = 'PHYSICAL',
  VIRTUAL = 'VIRTUAL',
}

export enum ConversionFixedSide {
  BUY = 'BUY',
  SELL = 'SELL',
}

export enum ConversionQuoteFixedSide {
  buy = 'buy',
  sell = 'sell',
}

export enum CurrencyCode {
  AED = 'AED',
  AFN = 'AFN',
  ALL = 'ALL',
  AMD = 'AMD',
  ANG = 'ANG',
  AOA = 'AOA',
  ARS = 'ARS',
  AUD = 'AUD',
  AWG = 'AWG',
  AZN = 'AZN',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BGN = 'BGN',
  BHD = 'BHD',
  BIF = 'BIF',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BOV = 'BOV',
  BRL = 'BRL',
  BSD = 'BSD',
  BTN = 'BTN',
  BWP = 'BWP',
  BYN = 'BYN',
  BZD = 'BZD',
  CAD = 'CAD',
  CDF = 'CDF',
  CHE = 'CHE',
  CHF = 'CHF',
  CHW = 'CHW',
  CLF = 'CLF',
  CLP = 'CLP',
  CNH = 'CNH',
  CNY = 'CNY',
  COP = 'COP',
  COU = 'COU',
  CRC = 'CRC',
  CUC = 'CUC',
  CUP = 'CUP',
  CVE = 'CVE',
  CZK = 'CZK',
  DJF = 'DJF',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  EGP = 'EGP',
  ERN = 'ERN',
  ETB = 'ETB',
  EUR = 'EUR',
  FJD = 'FJD',
  FKP = 'FKP',
  GBP = 'GBP',
  GEL = 'GEL',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GNF = 'GNF',
  GTQ = 'GTQ',
  GYD = 'GYD',
  HKD = 'HKD',
  HNL = 'HNL',
  HRK = 'HRK',
  HTG = 'HTG',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISK = 'ISK',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KGS = 'KGS',
  KHR = 'KHR',
  KMF = 'KMF',
  KPW = 'KPW',
  KRW = 'KRW',
  KWD = 'KWD',
  KYD = 'KYD',
  KZT = 'KZT',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  LRD = 'LRD',
  LSL = 'LSL',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MKD = 'MKD',
  MMK = 'MMK',
  MNT = 'MNT',
  MOP = 'MOP',
  MRU = 'MRU',
  MUR = 'MUR',
  MVR = 'MVR',
  MWK = 'MWK',
  MXN = 'MXN',
  MXV = 'MXV',
  MYR = 'MYR',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIO = 'NIO',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  OMR = 'OMR',
  PAB = 'PAB',
  PEN = 'PEN',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PYG = 'PYG',
  QAR = 'QAR',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  SBD = 'SBD',
  SCR = 'SCR',
  SDG = 'SDG',
  SEK = 'SEK',
  SGD = 'SGD',
  SHP = 'SHP',
  SLE = 'SLE',
  SOS = 'SOS',
  SRD = 'SRD',
  SSP = 'SSP',
  STN = 'STN',
  SVC = 'SVC',
  SYP = 'SYP',
  SZL = 'SZL',
  THB = 'THB',
  TJS = 'TJS',
  TMT = 'TMT',
  TND = 'TND',
  TOP = 'TOP',
  TRY = 'TRY',
  TTD = 'TTD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  USD = 'USD',
  USN = 'USN',
  UYI = 'UYI',
  UYU = 'UYU',
  UZS = 'UZS',
  VED = 'VED',
  VEF = 'VEF',
  VND = 'VND',
  VUV = 'VUV',
  WST = 'WST',
  XAF = 'XAF',
  XCD = 'XCD',
  XDR = 'XDR',
  XOF = 'XOF',
  XPF = 'XPF',
  XSU = 'XSU',
  XUA = 'XUA',
  YER = 'YER',
  ZAR = 'ZAR',
  ZMW = 'ZMW',
  ZWL = 'ZWL',
}

export enum DBPaymentTypeMapping {
  PRIORITY = 'PRIORITY',
  REGULAR = 'REGULAR',
}

export enum EftInitiatorRole {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export enum FieldName {
  accountNumber = 'accountNumber',
  bankAccountType = 'bankAccountType',
  bankCountry = 'bankCountry',
  bankName = 'bankName',
  beneficiaryAddress = 'beneficiaryAddress',
  beneficiaryCity = 'beneficiaryCity',
  beneficiaryCompanyName = 'beneficiaryCompanyName',
  beneficiaryCountry = 'beneficiaryCountry',
  beneficiaryDateOfBirth = 'beneficiaryDateOfBirth',
  beneficiaryEntityType = 'beneficiaryEntityType',
  beneficiaryFirstName = 'beneficiaryFirstName',
  beneficiaryIdentificationType = 'beneficiaryIdentificationType',
  beneficiaryIdentificationValue = 'beneficiaryIdentificationValue',
  beneficiaryLastName = 'beneficiaryLastName',
  beneficiaryPostcode = 'beneficiaryPostcode',
  beneficiaryStateOrProvince = 'beneficiaryStateOrProvince',
  bicSwift = 'bicSwift',
  currency = 'currency',
  defaultBeneficiary = 'defaultBeneficiary',
  email = 'email',
  iban = 'iban',
  paymentTypes = 'paymentTypes',
  routingCodeType1 = 'routingCodeType1',
  routingCodeType2 = 'routingCodeType2',
  routingCodeValue1 = 'routingCodeValue1',
  routingCodeValue2 = 'routingCodeValue2',
}

export enum IdentificationType {
  CITIZENSHIP_PAPERS = 'CITIZENSHIP_PAPERS',
  CLAVE_UNICA_DE_REGISTRO_DE_POBLACION = 'CLAVE_UNICA_DE_REGISTRO_DE_POBLACION',
  CREDENTIAL_DE_ELECTOR = 'CREDENTIAL_DE_ELECTOR',
  DRIVERS_LICENSE = 'DRIVERS_LICENSE',
  DRIVERS_LICENSE_CANADIAN = 'DRIVERS_LICENSE_CANADIAN',
  EMPLOYER_IDENTIFICATION_NUMBER = 'EMPLOYER_IDENTIFICATION_NUMBER',
  EXISTING_CREDIT_CARD_DETAILS = 'EXISTING_CREDIT_CARD_DETAILS',
  GREEN_CARD = 'GREEN_CARD',
  INCORPORATION_NUMBER = 'INCORPORATION_NUMBER',
  MATRICULA_CONSULAR = 'MATRICULA_CONSULAR',
  NATIONAL_ID = 'NATIONAL_ID',
  NONE = 'NONE',
  OTHERS = 'OTHERS',
  PASSPORT = 'PASSPORT',
  REGISTRO_FEDERAL_DE_CONTRIBUYENTES = 'REGISTRO_FEDERAL_DE_CONTRIBUYENTES',
  SOCIAL_INSURANCE_NUMBER = 'SOCIAL_INSURANCE_NUMBER',
  SOCIAL_SECURITY_NUMBER = 'SOCIAL_SECURITY_NUMBER',
  VISA = 'VISA',
}

export enum InvitationStatus {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  REVOKED = 'REVOKED',
}

export enum LegalEntityType {
  company = 'company',
  individual = 'individual',
}

/**
 * Ascending (asc) or (descending) ordering
 */
export enum OrderBy {
  asc = 'asc',
  desc = 'desc',
}

export enum PaymentPriority {
  PRIORITY = 'PRIORITY',
  STANDARD = 'STANDARD',
}

export enum PaymentType {
  priority = 'priority',
  regular = 'regular',
}

export enum PendingCardStatus {
  CANCELLED_ISSUANCE = 'CANCELLED_ISSUANCE',
  ENROLLMENT_PENDING = 'ENROLLMENT_PENDING',
  ISSUED = 'ISSUED',
}

export enum Polarity {
  NEGATIVE = 'NEGATIVE',
  POSITIVE = 'POSITIVE',
}

/**
 * Spend Limit Period Options
 */
export enum SpendLimitPeriod {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
}

export enum StatementPaymentAccountType {
  CC_TRANSFER = 'CC_TRANSFER',
  EFT_TRANSACTION = 'EFT_TRANSACTION',
  PAYMENT_ADJUSTMENT = 'PAYMENT_ADJUSTMENT',
  PAYMENT_EXTENSION = 'PAYMENT_EXTENSION',
}

export enum StatementPaymentStatus {
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  InProgress = 'InProgress',
  Rejected = 'Rejected',
}

export enum TransactionEntityType {
  Purchase = 'Purchase',
  Refund = 'Refund',
  StatementPayment = 'StatementPayment',
}

export enum TransactionPurchaseStatus {
  PENDING = 'PENDING',
  REVERSED = 'REVERSED',
  SETTLED = 'SETTLED',
}

export enum UserType {
  ACCOUNTANT = 'ACCOUNTANT',
  ACCOUNT_ADMIN = 'ACCOUNT_ADMIN',
  ADMIN = 'ADMIN',
  CARDHOLDER = 'CARDHOLDER',
  USER = 'USER',
}

export enum WalletCurrencyCode {
  CAD = 'CAD',
  EUR = 'EUR',
  GBP = 'GBP',
  MXN = 'MXN',
  PHP = 'PHP',
  USD = 'USD',
}

export enum WalletTransactionEntityType {
  BankPayment = 'BankPayment',
  BankTransfer = 'BankTransfer',
  Conversion = 'Conversion',
  PendingBankPayment = 'PendingBankPayment',
}

export interface AboutBusinessInput {
  businessCategory: string
  businessDBA?: string | null
  businessDescription: string
  businessHasWebsite: boolean
  businessNameLegal: string
  businessPhone: string
  businessType: string
  businessWebsite?: string | null
  dateOfIncorporation?: string | null
  incorporationNumber: string
  isMoneyBusiness: boolean
  numberOfEmployees: number | null
  minNumberOfEmployees: number
  maxNumberOfEmployees: number | null
  supportingDocuments?: (DocumentInput | null)[] | null
}

export interface AcceptInvitationMutationInput {
  agreedToCHA: boolean
  invitationToken: string
  userPassword: string
}

export interface AcceptKycInvitationMutationInput {
  address1: string
  address2?: string | null
  agreedToCHA: boolean
  city: string
  country: string
  dateOfBirth: string
  firstName: string
  invitationToken: string
  lastName: string
  licenseBack: DocumentInput
  licenseFront: DocumentInput
  postalCode: string
  selfiePicture: DocumentInput
  state: string
  userPassword: string
  workingPosition: string
}

export interface AccountData {
  agreedPad?: boolean | null
  id: string
  isPrimary?: boolean | null
}

export interface AdditionalInformationInput {
  politicalPerson: string
  sin?: string | null
}

export interface BeneficiaryInput {
  aba?: string | null
  accountNumber?: string | null
  address1: string
  address2?: string | null
  bankAccountHolderName: string
  bankCountry: string
  beneficiaryCity: string
  beneficiaryCompanyName?: string | null
  beneficiaryCountry: string
  beneficiaryDateOfBirth?: string | null
  beneficiaryEntityType: LegalEntityType
  beneficiaryFirstName?: string | null
  beneficiaryIdentificationType?: IdentificationType | null
  beneficiaryIdentificationValue?: string | null
  beneficiaryLastName?: string | null
  beneficiaryPostcode: string
  beneficiaryStateOrProvince: string
  bicSwift?: string | null
  branchNumber?: string | null
  clabe?: string | null
  currency: CurrencyCode
  email: string
  iban?: string | null
  institutionNumber?: string | null
  paymentMethod: string
  paymentType: PaymentType
  sortCode?: string | null
}

export interface BusinessAddressInput {
  businessAddressLine1: string
  businessAddressLine2?: string | null
  businessCity: string
  businessCountry: string
  businessPostalCode: string
  businessProvince: string
  usePersonalAddressAsBusinessAddress: boolean
}

export interface BusinessDirectorsInput {
  addedSelfAsDirector: boolean
  directors?: DirectorInput[] | null
  noMoreDirectors: boolean
}

export interface BusinessOwnersInput {
  addedSelfAsOwner: boolean
  noMoreOwners: boolean
  owners?: OwnerInput[] | null
  selfOwnerNature?: string | null
  selfOwnerNatureDescription?: string | null
}

export interface CancelConversionInput {
  conversionId: string
  reason?: string | null
}

export interface CancelPaymentMutationInput {
  paymentId: string
}

export interface CancelStatementPaymentInput {
  eftTransactionId: string
}

export interface ChangePasswordMutationInput {
  newPassword: string
  oldPassword: string
}

export interface CompleteConnectionParams {
  accountingIntegrationId: string
}

export interface CreateCardInput {
  existingCardholder?: ExistingCardholderInput | null
  newCardholder?: NewCardholderInput | null
  nickname?: string | null
  shippingAddress?: GenericAddressInput | null
  spendLimit?: CreateSpendLimitInput | null
  type: CardType
}

export interface CreateConversionInput {
  currencyCloudTermAgreement: boolean
  quoteId: string
  reason: string
}

export interface CreateConversionQuoteInput {
  amount: number
  currencyFrom: CurrencyCode
  currencyTo: CurrencyCode
  fixedSide: ConversionQuoteFixedSide
}

export interface CreateLoanInput {
  amount: number
  financingChannel: string
  reason: string
  repaymentInstallments: number
}

export interface CreateMultiCurrencyPaymentMutationInput {
  beneficiaryId: string
  currencyCloudTermAgreement: boolean
  isEarningRewards: boolean
  priority: PaymentPriority
  quoteId: string
  reason: string
  reference: string
  uniqueRequestId: string
}

export interface CreateQuoteInput {
  amount: number
  buyCurrency: CurrencyCode
  conversionDate?: Scalars.DateTime | null
  conversionDatePreference?: string | null
  fixedSide: ConversionFixedSide
  isEarningRewards: boolean
  sellCurrency: CurrencyCode
}

export interface CreateSameCurrencyPaymentMutationInput {
  amount: number
  beneficiaryId: string
  currency: CurrencyCode
  isEarningRewards: boolean
  priority: PaymentPriority
  quoteId: string
  reason: string
  reference: string
  uniqueRequestId: string
}

/**
 * Spend limit for the new card.
 */
export interface CreateSpendLimitInput {
  amount: number
  period: SpendLimitPeriod
}

export interface CreateStatementPaymentInput {
  accountId: string
  accountType: StatementPaymentAccountType
  amount: Scalars.Money
  requestId: string
}

export interface CreateWalletInput {
  currency: WalletCurrencyCode
}

export interface DirectorInput {
  details: PersonalInformationInput
  directorId: string
  isOwnerToo?: boolean | null
}

export interface DocumentInput {
  name: string
  path: string
  size: number
  type: string
  url: string
}

/**
 * Link an existing cardholder to the new card.
 */
export interface ExistingCardholderInput {
  userId: string
}

export interface GenericAddressInput {
  addressLine1: string
  addressLine2?: string | null
  city: string
  country: string
  postalCode: string
  state: string
}

export interface IdentityVerificationDocumentInput {
  base64: string
  documentType?: string | null
  path?: string | null
  url?: string | null
}

export interface IdentityVerificationInput {
  idBack?: IdentityVerificationDocumentInput | null
  idFront?: IdentityVerificationDocumentInput | null
  idType?: string | null
  identitySelfie?: IdentityVerificationDocumentInput | null
  oneTimeToken?: string | null
}

export interface IncorporationDocumentInput {
  base64: string
  name: string
  path?: string | null
  size: number
  type: string
  url?: string | null
}

export interface IncorporationDocumentsInput {
  documents?: IncorporationDocumentInput[] | null
}

export interface InviteUserMutationInput {
  newUserData: NewUserInput
  userType: UserType
}

export interface MultiCurrencyPaymentQuoteInput {
  priority: PaymentPriority
  quote: CreateQuoteInput
}

/**
 * Create a new cardholder for the new card.
 */
export interface NewCardholderInput {
  dateOfBirth?: string | null
  email: string
  firstName: string
  lastName: string
  phoneNumber: string
  userType: UserType
}

/**
 * Information needed to create a new User record.
 */
export interface NewUserInput {
  dateOfBirth?: string | null
  email: string
  firstName: string
  lastName: string
  phoneNumber: string
}

export interface OwnerInput {
  addAsDirector: boolean
  additionalInformation: AdditionalInformationInput
  details: PersonalInformationInput
  homeAddress: GenericAddressInput
  ownerId: string
  ownershipNature: string
  ownershipNatureDescription?: string | null
}

export interface PersonalAddressInput {
  addressLine1: string
  addressLine2?: string | null
  city: string
  country: string
  postalCode: string
  state: string
  useAsBusinessAddress: boolean
}

export interface PersonalInformationInput {
  dateOfBirth?: string | null
  emailAddress?: string | null
  firstName: string
  lastName: string
  phoneNumber?: string | null
  socialNetworks?: (string | null)[] | null
}

export interface RedeemRewardCashbackMutationInput {
  amount: Scalars.RewardScalar
}

export interface RegisterUserMutationInput {
  agreePrivacyPolicy: boolean
  agreeTOS: boolean
  email: string
  password: string
}

export interface RelationshipToTheBusinessInput {
  authorizedSigner?: SignerInformationInput | null
  authorizedToSign: string
  customPosition?: string | null
  position: string
}

/**
 * Allows renaming a pending card
 */
export interface RenamePendingCardInput {
  id: string
  nickname: string
}

export interface RequestCurrencyCloudAccessInput {
  id: string
}

export interface ResetPasswordMutationInput {
  password: string
  token: string
}

export interface ReviewInput {
  isReview?: boolean | null
}

export interface RutterAdditionalData {
  publicToken: string
}

export interface SameCurrencyPaymentQuoteInput {
  amount: number
  currency: CurrencyCode
  isEarningRewards: boolean
  priority: PaymentPriority
}

export interface SaveFlinksLoginInput {
  loginId: string
  reconnect: boolean
}

export interface SendRecoveryEmailInput {
  email: string
}

export interface SendVerificationEmailFromTokenMutationInput {
  token: string
}

export interface SendVerificationEmailMutationInput {
  email: string
}

export interface SignerInformationInput {
  email?: string | null
  firstName?: string | null
  lastName?: string | null
}

export interface StartAccountingIntegrationConnectionParams {
  additionalData?: RutterAdditionalData | null
  businessId: string
  integrationType: AccountingIntegrationType
}

export interface SubmitApplicationDeviceInput {
  blackboxId: string
}

export interface SubmitApplicationInput {
  device: SubmitApplicationDeviceInput
}

export interface UpdateAddressDataInput {
  address1: string
  address2?: string | null
  city: string
  country: string
  postalCode: string
  state: string
}

export interface UpdateApplicationDataInput {
  ABOUT_BUSINESS_4?: AboutBusinessInput | null
  ADDITIONAL_INFORMATION_2?: AdditionalInformationInput | null
  BUSINESS_ADDRESS_5?: BusinessAddressInput | null
  BUSINESS_DIRECTORS_7?: BusinessDirectorsInput | null
  BUSINESS_OWNERS_6?: BusinessOwnersInput | null
  IDENTITY_VERIFICATION_10?: IdentityVerificationInput | null
  INCORPORATION_DOCUMENTS_8?: IncorporationDocumentsInput | null
  PERSONAL_ADDRESS_3?: PersonalAddressInput | null
  PERSONAL_INFORMATION_1?: PersonalInformationInput | null
  PLAID_9?: boolean | null
  RELATIONSHIP_TO_THE_BUSINESS_40?: RelationshipToTheBusinessInput | null
  REVIEW_11?: ReviewInput | null
  nextStep: Scalars.OnboardingStep
}

export interface UpdateCardSpendLimitInput {
  amount: number
  cardId: string
  period: SpendLimitPeriod
}

export interface UpdateNotificationSettingsMutationInput {
  notifyCardClosed: boolean
  notifyCardIssued: boolean
  notifyCardSpendLimitReached: boolean
  notifyCreditLimitBelowThreshold: boolean
  notifyOnPurchaseReversal: boolean
  notifyOnlinePurchase: boolean
  notifyOnlinePurchaseDeclined: boolean
  notifyPosPurchase: boolean
  notifyPosPurchaseDeclined: boolean
  notifyStatementAvailable: boolean
}

export interface UpdatePlaidAccountsInput {
  plaidAccounts: AccountData[]
}

/**
 * Allows updating the spend limit of a pending card
 */
export interface UpdateSpendLimitPendingCardInput {
  id: string
  spendLimitAmount?: string | null
  spendLimitPeriod?: SpendLimitPeriod | null
}

export interface ValidatePaymentMutationInput {
  beneficiaryId: string
  priority: PaymentPriority
  quoteId: string
  reason: string
  reference: string
}

export interface VerifyAccountMutationInput {
  token: string
}

export interface flinksConnectionTakingTooLongInput {
  businessId: string
  connectionTime: number
  loginId?: string | null
  requestId?: string | null
  step: string
}

//==============================================================
// END Enums and Input Objects
//==============================================================
