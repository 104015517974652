import { Box, Button, Flex, Text, useMultiStyleConfig } from '@chakra-ui/react'
import React from 'react'

import { Icon } from '../../icon'
import { humanizeFileSize } from './utils'

interface FileItemProps {
  file: File
  onRemove: (file: File) => void
  isDisabled: boolean
}

const formatFilename = (filename: string) => {
  const maxLength = 20
  const extension = filename.split('.').pop()

  if (filename.length <= maxLength) {
    return filename
  }

  const truncatedName = filename.substring(0, 15)
  const formattedName = `${truncatedName}...${extension}`

  return formattedName
}

export const FileItem = ({ file, onRemove, isDisabled }: FileItemProps) => {
  const styles = useMultiStyleConfig('FileInput')

  return (
    <Flex sx={styles.item} w="full">
      <Flex gap="2" align="center" w="100%">
        <Icon icon="file-icon" variant="secondary" size="lg" />
        <Box overflow={'hidden'}>
          <Text
            textStyle="title-xs"
            textDecoration="underline"
            whiteSpace={'nowrap'}
            overflow={'hidden'}
            maxW={'75ch'}
            textOverflow={'ellipsis'}
          >
            {formatFilename(file.name)}
          </Text>
          <Text textStyle="paragraph-sm" color="text-secondary">
            {humanizeFileSize(file.size)}
          </Text>
        </Box>
      </Flex>
      <Button
        size={{ base: 'md', sm: 'sm' }}
        w={{ base: 'full', sm: 'auto' }}
        variant="destructive"
        onClick={() => onRemove(file)}
        isDisabled={isDisabled}
      >
        Delete
      </Button>
    </Flex>
  )
}
