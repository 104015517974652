import { useApolloClient } from '@apollo/client'
import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'
import { CurrencyCode } from 'services/utils/countries'

import { CreateQuoteForMultiCurrencyPaymentDocument, CreateQuoteForMultiCurrencyPaymentMutation } from '@/gql'
import {
  PaymentSpeedValue,
  RecipientRelationValue,
  RecipientTypeValue,
  REWARD_ACTIONS,
  RewardActionValue,
} from '@/src/constants/bankTransfers'
import { CreateQuoteForMultiCurrencyPaymentVariables } from '@/src/graphql/generated/CreateQuoteForMultiCurrencyPayment'
import { BeneficiaryInput } from '@/src/graphql/generated/globalTypes'
import {
  SingleCurrencyPaymentQuote,
  SingleCurrencyPaymentQuoteVariables,
} from '@/src/graphql/generated/SingleCurrencyPaymentQuote'
import { SINGLE_CURRENCY_PAYMENT_QUOTE_MUTATION } from '@/src/graphql/mutations/SingleCurrencyPaymentQuote'
import { getPaymentInputForMultiCurrencyPayment, getPaymentInputForSameCurrencyPayment } from '@/src/utils/bankTransfer'
import isDefined from '@/src/utils/dataShaping/isDefined'

import { Amount, FixedSideValue, PaymentFeesAndRewards } from './currencyConversion.slice'

let abortController: AbortController | null = null

export const defaultBeneficiary = {
  _disabledRecipientCountry: 'US' as any,
  beneficiaryFirstName: 'Joe',
  beneficiaryLastName: 'Shmo',
  bankAccountHolderName: 'Joe Shmo',
  email: 'shmo@gmail.com',
  beneficiaryEntityType: 'individual' as any,
  currency: 'USD' as any,
  bankCountry: 'US',
  paymentMethod: 'ACH',
  paymentType: 'regular' as any,
  beneficiaryCountry: 'US',
  accountNumber: '123457',
  aba: '123456789',
  address1: '123 Main St',
  address2: '',
  beneficiaryCity: 'Shmotown',
  beneficiaryStateOrProvince: 'NJ',
  beneficiaryPostcode: '07011',
}

export const initialFeeDetails: PaymentFeesAndRewards = {
  internationalBankingFee: null,
  finalAmount: null,
  savingsAmount: null,
  rewards: {
    earnedRewardsAmount: null,
    previousRewardsBalance: null,
    afterTransactionRewardsTotalPoints: null,
    earnedRewardsAsPoints: null,
  },
  balanceAfterwards: null,
}

export interface CreateQuoteForSingleCurrencyPaymentParams {
  client: ReturnType<typeof useApolloClient>
  amountFrom: number
  currencyFrom: CurrencyCode
  paymentSpeed: PaymentSpeedValue
  rewardAction: RewardActionValue
  fromIntervalRefresh: boolean
}

export const createQuoteForSingleCurrencyPayment = createAsyncThunk(
  'bankTransfer/createQuoteForSingleCurrencyPayment',
  async (params: CreateQuoteForSingleCurrencyPaymentParams, { rejectWithValue }) => {
    const { client, ...paramsWithoutClient } = params

    abortController?.abort()
    abortController = new AbortController()

    const { data, errors } = await client.mutate<SingleCurrencyPaymentQuote, SingleCurrencyPaymentQuoteVariables>({
      mutation: SINGLE_CURRENCY_PAYMENT_QUOTE_MUTATION,
      variables: {
        body: getPaymentInputForSameCurrencyPayment(paramsWithoutClient),
      },
      context: {
        fetchOptions: {
          signal: abortController.signal,
        },
      },
    })

    if (isDefined(errors)) {
      return rejectWithValue(errors)
    }

    return {
      data,
      ...paramsWithoutClient,
    }
  }
)

export interface CreateQuoteForMultiCurrencyPaymentParams {
  client: ReturnType<typeof useApolloClient>
  amountFrom: number
  amountTo: number
  currencyFrom: CurrencyCode
  currencyTo: CurrencyCode
  fixedSide: FixedSideValue
  fromIntervalRefresh: boolean
  rewardAction: RewardActionValue
  paymentSpeed: PaymentSpeedValue
}

export const createQuoteForMultiCurrencyPayment = createAsyncThunk(
  'bankTransfer/createQuoteForMultiCurrencyPayment',
  async (params: CreateQuoteForMultiCurrencyPaymentParams, { rejectWithValue }) => {
    const { client, ...paramsWithoutClient } = params

    abortController?.abort()
    abortController = new AbortController()

    const { data, errors } = await client.mutate<
      CreateQuoteForMultiCurrencyPaymentMutation,
      CreateQuoteForMultiCurrencyPaymentVariables
    >({
      mutation: CreateQuoteForMultiCurrencyPaymentDocument,
      variables: {
        body: getPaymentInputForMultiCurrencyPayment(paramsWithoutClient),
      },
      context: {
        fetchOptions: {
          signal: abortController.signal,
        },
      },
    })

    if (isDefined(errors)) {
      return rejectWithValue(errors)
    }

    return {
      data,
      ...paramsWithoutClient,
    }
  }
)

const bankTransferSlice = createSlice({
  name: 'bankTransfer',
  initialState: {
    existingBeneficiaryData: {
      data: null as BeneficiaryInput | null,
      id: null as string | null,
    },
    createBeneficiaryFormData: {
      data: null as BeneficiaryInput | null,
    },
    recipientType: null as RecipientTypeValue | null,
    recipientRelation: null as RecipientRelationValue | null,
    recipientDescription: null as string | null,
    quoteId: null as string | null,
    quoteCreatedAt: null as string | null,
    paymentSpeed: 'standard' as PaymentSpeedValue,
    exchangeRate: 0,
    competitorsExchangeRate: null as number | null,
    amountFrom: { numberValue: 0.0, stringValue: '0.00' } as Amount,
    amountTo: { numberValue: 0.0, stringValue: '0' } as Amount,
    currencyFrom: null as CurrencyCode | null,
    currencyTo: null as CurrencyCode | null,
    feeAndRewardsDetails: initialFeeDetails,
    fixedSide: null as FixedSideValue | null,
    memo: '' as string,
    eta: '' as string,
    beneficiaryFormAction: null as 'create' | 'existing' | null,
    rewardAction: REWARD_ACTIONS['earn'].value as RewardActionValue,
    isLoading: false as boolean,
    isFetchingQuotes: false as boolean,
    error: null as SerializedError | SerializedError[] | null,
  },
  reducers: {
    _useExistingBeneficiary: (
      state,
      action: PayloadAction<{
        beneficiary: BeneficiaryInput | null
        id: string | null
      }>
    ) => {
      state.existingBeneficiaryData.data = action.payload.beneficiary
      state.existingBeneficiaryData.id = action.payload.id
      state.beneficiaryFormAction = 'existing'
    },
    _setCreateBeneficiaryFormData: (state, action: PayloadAction<BeneficiaryInput | null>) => {
      state.createBeneficiaryFormData.data = action.payload
      state.beneficiaryFormAction = 'create'
    },
    _setBeneficiaryFormAction: (state, action: PayloadAction<'create' | 'existing' | null>) => {
      state.beneficiaryFormAction = action.payload
    },
    _setPaymentSpeed: (state, action: PayloadAction<PaymentSpeedValue>) => {
      state.paymentSpeed = action.payload
    },
    _setRecipientRelation: (state, action: PayloadAction<RecipientRelationValue>) => {
      state.recipientRelation = action.payload
    },
    _setRecipientDescription: (state, action: PayloadAction<string>) => {
      state.recipientDescription = action.payload
    },
    _setExchangeRate: (state, action: PayloadAction<number>) => {
      state.exchangeRate = action.payload
    },
    _setCompetitorsExchangeRate: (state, action: PayloadAction<number>) => {
      state.competitorsExchangeRate = action.payload
    },
    _setAmountFrom: (state, action: PayloadAction<Amount>) => {
      state.amountFrom = action.payload
    },
    _setAmountTo: (state, action: PayloadAction<Amount>) => {
      state.amountTo = action.payload
    },
    _setCurrencyFrom: (state, action: PayloadAction<CurrencyCode | null>) => {
      state.currencyFrom = action.payload
    },
    _setCurrencyTo: (state, action: PayloadAction<CurrencyCode | null>) => {
      state.currencyTo = action.payload
    },
    _setEta: (state, action: PayloadAction<string>) => {
      state.eta = action.payload
    },
    _setQuoteId: (state, action: PayloadAction<string>) => {
      state.quoteId = action.payload
    },
    _setQuoteCreatedAt: (state, action: PayloadAction<string>) => {
      state.quoteCreatedAt = action.payload
    },
    _setMemo: (state, action: PayloadAction<string>) => {
      state.memo = action.payload
    },
    _setRewardAction: (state, action: PayloadAction<RewardActionValue>) => {
      state.rewardAction = action.payload
    },
    _setFixedSide: (state, action: PayloadAction<FixedSideValue | null>) => {
      state.fixedSide = action.payload
    },
    _setPaymentFeeAndRewards: (state, action: PayloadAction<PaymentFeesAndRewards>) => {
      state.feeAndRewardsDetails = action.payload
    },
    _setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    _setIsFetchingQuotes: (state, action: PayloadAction<boolean>) => {
      state.isFetchingQuotes = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, action: any) => {
      return {
        ...state, // use previous state
        ...action.payload.bankTransfer,
        createBeneficiaryFormData: state.createBeneficiaryFormData,
      }
    })

    builder.addCase(createQuoteForSingleCurrencyPayment.pending, (state) => {
      state.error = null
    })

    builder.addCase(createQuoteForSingleCurrencyPayment.rejected, (state, action) => {
      if (action.meta.arg.fromIntervalRefresh) {
        return
      }
      state.error = action.error
      state.isLoading = false
      state.isFetchingQuotes = false
      state.feeAndRewardsDetails = initialFeeDetails
      state.amountFrom = { numberValue: 0.0, stringValue: '' }
    })

    builder.addCase(createQuoteForSingleCurrencyPayment.fulfilled, (state, action) => {
      if (!action.meta.arg.fromIntervalRefresh) {
        state.isLoading = false
      }
      const result = action.payload.data?.createQuoteForSingleCurrencyPayment
      const quote = result?.quote
      const feesAndRewards = result?.feesAndRewards
      if (!isDefined(quote) || !isDefined(feesAndRewards)) {
        return
      }
      state.quoteCreatedAt = quote.createdAt
      state.quoteId = quote.id
      state.feeAndRewardsDetails = feesAndRewards
      state.error = null
      state.isFetchingQuotes = false
    })

    builder.addCase(createQuoteForMultiCurrencyPayment.pending, (state) => {
      state.error = null
    })

    builder.addCase(createQuoteForMultiCurrencyPayment.rejected, (state, action) => {
      if (action.meta.arg.fromIntervalRefresh) {
        return
      }
      state.error = action.error
      state.isLoading = false
      state.isFetchingQuotes = false
      state.feeAndRewardsDetails = initialFeeDetails
      const fixedSide = action.meta.arg.fixedSide
      if (fixedSide === 'sell') {
        state.amountTo = { numberValue: 0.0, stringValue: '' }
      } else {
        state.amountFrom = { numberValue: 0.0, stringValue: '' }
      }
    })

    builder.addCase(createQuoteForMultiCurrencyPayment.fulfilled, (state, action) => {
      if (!action.meta.arg.fromIntervalRefresh) {
        state.isLoading = false
      }
      const fixedSide = action.payload.fixedSide
      const quote = action.payload.data?.createQuoteForMultiCurrencyPayment?.quote
      const feesAndRewards = action.payload.data?.createQuoteForMultiCurrencyPayment?.feesAndRewards
      if (!isDefined(quote) || !isDefined(feesAndRewards)) {
        return
      }
      state.exchangeRate = Number(feesAndRewards.exchangeRate.exchangeRate)
      state.competitorsExchangeRate = isDefined(feesAndRewards.exchangeRate.competitorsExchangeRate)
        ? Number(feesAndRewards.exchangeRate.competitorsExchangeRate)
        : null
      state.quoteCreatedAt = quote.createdAt
      state.quoteId = quote.id
      state.feeAndRewardsDetails = feesAndRewards
      state.error = null
      state.isFetchingQuotes = false
      if (fixedSide === 'sell') {
        state.amountTo = {
          numberValue: feesAndRewards.finalAmount.amountAsNumber > 0 ? feesAndRewards.finalAmount.amountAsNumber : 0,
          stringValue: feesAndRewards.finalAmount.amountAsNumber > 0 ? feesAndRewards.finalAmount.amountAsString : '',
        }
      } else if (isDefined(feesAndRewards.sellAmount)) {
        state.amountFrom = {
          numberValue: feesAndRewards.sellAmount.amountAsNumber,
          stringValue: feesAndRewards.sellAmount.amountAsString,
        }
      }
    })
  },
})

export const {
  _useExistingBeneficiary,
  _setPaymentSpeed,
  _setRecipientRelation,
  _setRecipientDescription,
  _setBeneficiaryFormAction,
  _setExchangeRate,
  _setCompetitorsExchangeRate,
  _setAmountFrom,
  _setAmountTo,
  _setCurrencyFrom,
  _setCurrencyTo,
  _setEta,
  _setMemo,
  _setCreateBeneficiaryFormData,
  _setRewardAction,
  _setPaymentFeeAndRewards,
  _setFixedSide,
  _setQuoteId,
  _setQuoteCreatedAt,
  _setIsLoading,
  _setIsFetchingQuotes,
} = bankTransferSlice.actions

export default bankTransferSlice
