import { cardAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const helpers = createMultiStyleConfigHelpers([...cardAnatomy.keys])

export const Card = helpers.defineMultiStyleConfig({
  baseStyle: {},
  variants: {
    active: {
      container: {
        padding: 6,
        border: '1.5px solid',
        borderRadius: 'lg',
        borderLeftColor: 'border-primary',
        borderRightColor: 'border-primary',
        borderTopColor: 'border-primary',
        borderBottomColor: 'border-primary',
      },
      body: {
        padding: 0,
        width: 'full',
      },
      header: {
        padding: 0,
        width: 'full',
      },
    },
    outline: {
      container: {
        padding: 6,
        border: '1px solid',
        borderRadius: 'lg',
        borderLeftColor: 'border-secondary',
        borderRightColor: 'border-secondary',
        borderTopColor: 'border-secondary',
        borderBottomColor: 'border-secondary',
        boxShadow: 'sm',
        textAlign: 'left',
        justifyContent: 'start',
      },
      body: {
        padding: 0,
        width: 'full',
      },
      header: {
        padding: 0,
        width: 'full',
      },
    },
    transparent: {
      container: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        border: 'none',
      },
    },
  },
  defaultProps: {},
})
