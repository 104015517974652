import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const helpers = createMultiStyleConfigHelpers([
  'container',
  'bodyContainer',
  'categoryTitle',
  'headline',
  'content',
  'imageContainer',
  'image',
  'tag',
])

export const Banner = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      gap: 4,
      padding: 4,
      border: '1px solid',
      borderRadius: 'lg',
      alignItems: 'center',
    },
    image: {
      objectFit: 'cover',
    },
    imageContainer: {
      borderRadius: 'md',
      overflow: 'hidden',
    },
    bodyContainer: {
      flexDir: 'column',
      justifyContent: 'center',
    },
    categoryTitle: {
      textStyle: 'subheading-sm',
      background: 'gradient.bluePink',
      textTransform: 'uppercase',
      backgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    headline: {
      textStyle: 'title-md',
      color: 'text-primary',
    },
    content: {
      textStyle: 'paragraph-md',
      color: 'text-secondary',
    },
    tag: {
      width: 'fit-content',
      marginTop: 1,
    },
  },
  sizes: {
    sm: {
      headline: {
        textStyle: 'title-xs',
      },
      content: {
        textStyle: 'paragraph-sm',
      },
      bodyContainer: {
        gap: '0',
      },
      imageContainer: {
        boxSize: '48px',
      },
    },
    md: {
      headline: {
        textStyle: 'title-sm',
      },
      content: {
        textStyle: 'paragraph-sm',
      },
      bodyContainer: {
        gap: '2px',
      },
      imageContainer: {
        boxSize: '60px',
      },
    },
    lg: {
      headline: {
        textStyle: 'title-md',
      },
      content: {
        textStyle: 'paragraph-md',
      },
      bodyContainer: {
        gap: '6px',
      },
      imageContainer: {
        boxSize: '80px',
      },
    },
  },
  variants: {
    neutral: {
      container: {
        borderColor: 'border-soft',
        background: 'bg-primary',
      },
      tag: {
        background: 'bg-soft',
      },
    },
    secondary: {
      container: {
        borderColor: 'border-soft',
        background:
          'linear-gradient(92deg, rgba(237, 239, 241, 0.18) -1.44%, rgba(237, 239, 241, 0.18) 30.5%, #FFF 57.56%, rgba(237, 239, 241, 0.35) 98.06%), #FFF',
      },
      tag: {
        background: 'icon-secondary',
      },
    },
    success: {
      container: {
        borderColor: 'border-success',
        background: 'bg-success',
      },
      tag: {
        background: 'icon-success', //Cannot use bg-success because it mixes with the banner bg color
        color: 'text-inverse',
      },
    },
    warning: {
      container: {
        borderColor: 'border-warning',
        background: 'bg-warning',
      },
      tag: {
        background: 'icon-warning', //Cannot use bg-warning because it mixes with the banner bg color
        color: 'text-inverse',
      },
    },
    error: {
      container: {
        borderColor: 'border-error',
        background: 'bg-error',
      },
      tag: {
        background: 'icon-error', //Cannot use bg-error because it mixes with the banner bg color
        color: 'text-inverse',
      },
    },
    info: {
      container: {
        borderColor: 'border-info',
        background: 'bg-info',
      },
      tag: {
        color: 'text-inverse',
        background: 'icon-info', // Cannot use bg-info because it mixed with the banner bg color
      },
    },
    'look-at-me': {
      container: {
        position: 'relative',
        borderColor: 'transparent', // This lets the ::before pseudo-element's take over the border
        background: '#FFF6FC',

        _before: {
          content: '""',
          position: 'absolute',
          inset: 0,
          borderRadius: 'lg',
          padding: '2px', // This determines the border width
          background: 'border-gradient-1',
          mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
          maskComposite: 'exclude',
          zIndex: 1, // Ensure the pseudo-element is behind the text
          pointerEvents: 'none', // Make the pseudo-element non-interactive
        },
      },
      categoryTitle: {
        background: '#A964B4',
        color: 'text-inverse',
        backgroundClip: 'none',
        WebkitTextFillColor: 'initial',
        paddingY: 1,
        paddingX: 2,
        width: 'fit-content',
        borderRadius: 'sm',
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'neutral',
  },
})
