import { TrackablePageEvent } from '@/src/constants/segmentVisitedPages'
import { analytics } from '@/src/utils/misc'

export const useSegmentPageVisited = () => {
  const trackEvent = (segEvent: TrackablePageEvent) => {
    analytics.track(segEvent.type, segEvent.payload)
  }

  return trackEvent
}
