import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const helpers = createMultiStyleConfigHelpers([
  'inputGroup',
  'labelContainer',
  'label',
  'inputContainer',
  'input',
  'wrapper',
  'adornment',
  'clearButtonContainer',
  'clearButton',
  'clearIcon',
  'helperText',
  'errorContainer',
  'errorMessage',
])

export const StyledInput = helpers.defineMultiStyleConfig({
  defaultProps: {
    size: 'lg',
  },
  baseStyle: {
    labelContainer: {
      marginBottom: '0.75rem',
      alignItems: 'center',
    },
    label: {
      fontWeight: 600,
      lineHeight: '1.5rem',
      letterSpacing: '-2%',
      pointerEvents: 'none',
      color: 'black.300',
      marginBottom: 0,
      marginInlineEnd: '0.55rem',
      _invalid: {
        color: '_error.500',
      },
      _disabled: {
        opacity: 1,
        color: '_primary.300',
      },
    },
    wrapper: {
      width: 'full',
    },
    inputGroup: {
      zIndex: 1,
    },
    input: {
      fontWeight: 400,
      fontFamily: 'Inter',
      borderColor: '_primary.100',
      backgroundColor: 'basic.white',
      zIndex: 1,
      _placeholder: {
        color: '_primary.500',
      },
      _hover: {
        borderColor: '_primary.900',
      },
      _disabled: {
        borderColor: '_primary.50',
        backgroundColor: '_primary.50',
        _placeholder: {
          color: '_primary.300',
        },
      },
      _focusVisible: {
        boxShadow: '0 0 0 1px var(--chakra-colors-accent-500)',
        borderColor: 'accent.500',
        outlineColor: 'accent.100',
        outlineStyle: 'solid',
        outlineWidth: '4px',
        outlineOffset: '1px',
      },
      _invalid: {
        boxShadow: '0 0 0 1px var(--chakra-colors-_error-500)',
        borderColor: '_error.500',
        outlineColor: '_error.50',
        outlineStyle: 'solid',
        outlineWidth: '4px',
        outlineOffset: '1px',
      },
    },
    adornment: {
      pointerEvents: 'none',
      height: '100%',
    },
    clearButtonContainer: {
      pointerEvents: 'none',
      height: '100%',
    },
    clearButton: {
      pointerEvents: 'all',
      color: 'basic.black',
      backgroundColor: 'transparent',
      borderRadius: '100%',
      _hover: { bg: '_primary.100' },
      _active: { bg: '_primary.200' },
      _focusWithin: { outline: 'none' },
    },
    helperText: {
      marginTop: 3,
      textStyle: 'text.sm.regular',
      color: '_primary.500',
    },
    errorContainer: {
      position: 'relative',
      zIndex: 0,
      width: 'full',
      alignItems: 'flex-end',
      borderBottomRadius: 'lg',
      height: '3.125rem',
      outline: '5px solid var(--chakra-colors-_error-50)',
      backgroundColor: '_error.50',
    },
    errorMessage: {
      color: '_error.500',
      textStyle: 'text.sm.regular',
      marginLeft: '1.5rem',
      marginBottom: '0.5rem',
    },
  },
  sizes: {
    sm: {
      label: {
        fontSize: '1.125rem',
      },
      input: {
        height: '2.5rem',
        fontSize: '1rem',
        borderRadius: 'md',
        paddingX: '1.08rem',
        '&[data-left-element="true"]': {
          paddingLeft: '2.5rem',
        },
        '&[data-right-element="true"]': {
          paddingRight: '2.5rem',
        },
      },
      adornment: {
        width: '3rem',
      },
      clearButtonContainer: {
        width: '3rem',
        '&[data-right-element="true"]': {
          right: '1.5rem',
        },
      },
      clearIcon: {
        h: '0.5rem',
        w: '0.5rem',
      },
      errorContainer: {
        marginTop: '-1rem',
      },
    },
    md: {
      label: {
        fontSize: '1.125rem',
      },
      input: {
        height: '3rem',
        fontSize: '1.125rem',
        borderRadius: 'md',
        paddingX: '1.104rem',
        '&[data-left-element="true"]': {
          paddingLeft: '3rem',
        },
        '&[data-right-element="true"]': {
          paddingRight: '3rem',
        },
      },
      adornment: {
        width: '3.25rem',
      },
      clearButtonContainer: {
        width: '3.25rem',
        '&[data-right-element="true"]': {
          right: '1.75rem',
        },
      },
      clearIcon: {
        h: '0.625rem',
        w: '0.625rem',
      },
      errorContainer: {
        marginTop: '-1rem',
      },
    },
    lg: {
      label: {
        '&[data-position="outside"]': {
          fontSize: '1.25rem',
        },
        '&[data-position="inside"]': {
          transition: 'all 0.2s ease-in-out',
          zIndex: 2,
          fontSize: '1.125rem',
          fontWeight: 400,
          position: 'absolute',
          top: '1.187rem',
          left: '1.625rem',
          color: '_primary.500',
          _invalid: {
            color: '_error.500',
          },
          '&[data-left-element="true"]': {
            left: '4rem',
          },
          '&[data-contracted="true"]': {
            top: '0.625rem',
            lineHeight: '1.125rem',
            fontSize: '0.75rem',
          },
        },
      },
      input: {
        height: '4rem',
        fontSize: '1.125rem',
        borderRadius: 'lg',
        paddingX: '1.625rem',
        '&[data-left-element="true"]': {
          paddingLeft: '4rem',
        },
        '&[data-right-element="true"]': {
          paddingRight: '4rem',
        },
        '&[data-clearable="true"]': {
          paddingRight: '4rem',
        },
        '&[data-right-element="true"]&[data-clearable="true"]': {
          paddingRight: '6rem',
        },
        '&[data-position="inside"]': {
          paddingTop: '1.06rem',
          _placeholder: {
            color: 'transparent',
          },
          _focus: {
            _placeholder: {
              color: '_primary.500',
            },
          },
        },
      },
      adornment: {
        width: '4.5rem',
      },
      clearButtonContainer: {
        width: '4.5rem',
        '&[data-right-element="true"]': {
          right: '2rem',
        },
      },
      clearIcon: {
        h: '0.75rem',
        w: '0.75rem',
      },
      errorContainer: {
        marginTop: '-0.75rem',
      },
    },
  },
  variants: {
    textArea: {
      inputContainer: {
        position: 'relative',
      },
      input: {
        paddingX: 6,
        paddingY: 5,
        minHeight: '8.5rem',
        '&[data-position="inside"]': {
          paddingTop: 10,
          _disabled: {
            _placeholder: {
              color: '_primary.300',
            },
          },
        },
      },
      label: {
        '&[data-position="inside"]': {
          top: '1px',
          left: '1px',
          margin: 0,
          paddingLeft: 6,
          paddingTop: 5,
          borderTopLeftRadius: 'lg',
          width: 'calc(100% - 2px - var(--chakra-space-6))',
          backgroundColor: 'basic.white',
          _disabled: { backgroundColor: '_primary.50' },
          '&[data-contracted="true"]': {
            top: '1px',
          },
        },
      },
      clearButton: {
        position: 'absolute',
        top: 5,
        right: 6,
        zIndex: 3,
      },
    },
    phoneField: {
      inputContainer: { position: 'relative' },
      label: { '&[data-position="inside"]': { left: 16 } },
      clearButton: {
        position: 'absolute',
        top: 'calc(50% - 0.750rem)',
        right: 6,
        zIndex: 3,
      },
    },
  },
})
