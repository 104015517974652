import range from 'lodash.range'
import React, { useMemo } from 'react'
import { Button, ChevronRightIcon, Flex, IconButton, Text } from 'ui-lib'

const EDGE_LEFT_PAGES = 2
const EDGE_RIGHT_PAGES = 1
const PAGES_PER_SIDE = 8
const PAGES_IN_MIDDLE = 6
const MAX_PAGES = 10

interface Props {
  currentPage: number
  totalPages: number
  onPageChange: (page: number) => void
  isDisabled?: boolean
}

export const SimplePagination = ({ currentPage, totalPages, onPageChange, isDisabled = false }: Props) => {
  const pages = range(1, totalPages + 1)

  const segments = useMemo(() => {
    const segments = []
    let startOfSegment = PAGES_PER_SIDE
    while (startOfSegment < totalPages) {
      const endOfSegment = startOfSegment + PAGES_IN_MIDDLE
      segments.push(pages.slice(startOfSegment, startOfSegment + PAGES_IN_MIDDLE))
      startOfSegment = endOfSegment
    }
    return segments
  }, [pages, totalPages])

  const getPageSegment = (page: number) => {
    const segment = segments.find((segment) => segment.includes(page))
    return {
      start: segment?.[0] ?? page,
      end: segment?.[segment.length - 1] ?? page + PAGES_IN_MIDDLE,
    }
  }

  const isValidTotalPages = totalPages <= MAX_PAGES

  const currentPageIsOnLeftSide = currentPage <= PAGES_PER_SIDE

  const currentPageIsOnRightSide = !currentPageIsOnLeftSide && currentPage > totalPages - PAGES_PER_SIDE + 1

  const currentPageIsInMiddle = !currentPageIsOnLeftSide && !currentPageIsOnRightSide

  let slicedPages: number[] = []

  if (isValidTotalPages) {
    slicedPages = pages
  } else if (currentPageIsOnLeftSide) {
    slicedPages = [...pages.slice(0, PAGES_PER_SIDE), ...pages.slice(totalPages - EDGE_RIGHT_PAGES, totalPages)]
  } else if (currentPageIsOnRightSide) {
    slicedPages = [...pages.slice(0, EDGE_LEFT_PAGES), ...pages.slice(totalPages - PAGES_PER_SIDE, totalPages)]
  } else if (currentPageIsInMiddle) {
    const { start, end } = getPageSegment(currentPage)
    slicedPages = [
      ...pages.slice(0, EDGE_LEFT_PAGES),
      ...pages.slice(start - 1, end),
      ...pages.slice(totalPages - EDGE_RIGHT_PAGES, totalPages),
    ]
  }

  const shouldDisplayRightDots = (index: number) => {
    return (
      !isValidTotalPages &&
      (currentPageIsOnLeftSide || currentPageIsInMiddle) &&
      index === slicedPages.length - 1 - EDGE_RIGHT_PAGES
    )
  }

  const shouldDisplayLeftDots = (index: number) => {
    return !isValidTotalPages && (currentPageIsOnRightSide || currentPageIsInMiddle) && index === EDGE_LEFT_PAGES - 1
  }

  return (
    <Flex w="full" justifyContent={{ base: 'center', lg: 'space-between' }} alignItems="center">
      <Text display={{ base: 'none', lg: 'block' }} textStyle="paragraph-md" textColor="text-secondary">
        Display 1 to 10 rows
      </Text>
      <Flex alignItems="center">
        <IconButton
          variant="ghost"
          icon={<ChevronRightIcon boxSize="6" transform="rotate(180deg)" />}
          onClick={() => onPageChange(currentPage - 1)}
          isDisabled={currentPage <= 1 || isDisabled}
          _focus={{ outline: 'none' }}
          aria-label="chevron-left"
        />
        {slicedPages.map((page, index) => (
          <React.Fragment key={page}>
            <Button
              variant="ghost"
              size="sm"
              maxW="8"
              bgColor={currentPage === page ? 'bg-disabled' : 'transparent'}
              border="1px solid"
              borderColor={currentPage === page ? 'border-secondary' : 'transparent'}
              onClick={() => onPageChange(page)}
              isDisabled={isDisabled}
              mx="0.5"
              _focus={{ outline: 'none' }}
            >
              {page}
            </Button>
            {shouldDisplayRightDots(index) && <Text mx="4">...</Text>}
            {shouldDisplayLeftDots(index) && <Text mx="4">...</Text>}
          </React.Fragment>
        ))}
        <IconButton
          variant="ghost"
          icon={<ChevronRightIcon boxSize="6" />}
          onClick={() => onPageChange(currentPage + 1)}
          isDisabled={currentPage >= totalPages || isDisabled}
          _focus={{ outline: 'none' }}
          aria-label="chevron-left"
        />
      </Flex>
    </Flex>
  )
}
