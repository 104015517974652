import { ConfirmActionProvider } from '@/src/contexts/misc'
import {
  useDatadog,
  useDebugAccessToken,
  useFacebookPixel,
  useFetchWallets,
  useIdentifySegmentAnalytics,
  useIncidentNotifications,
  useInitializeLegacyFeatureFlags,
  useLogRocket,
  usePageSegmentAnalytics,
  useReloadSessionOnRouteChange,
} from '@/src/hooks/misc'

/**
 * This component is responsible for initializing all the necessary services and
 * configurations that are required for the application to run.
 *
 * This component needs access to session and apollo contexts
 */
export const AppInitializers = ({ children }: { children: React.ReactElement[] | React.ReactElement }) => {
  useDebugAccessToken()
  useFacebookPixel()
  usePageSegmentAnalytics()
  useReloadSessionOnRouteChange()
  useIdentifySegmentAnalytics()
  useFetchWallets()
  useIncidentNotifications()
  useInitializeLegacyFeatureFlags()
  useDatadog()
  useLogRocket()

  return <ConfirmActionProvider>{children}</ConfirmActionProvider>
}
