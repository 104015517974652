import { useApolloClient } from '@apollo/client'
import { useCallback, useEffect } from 'react'

import { useAppDispatch } from '@/src/redux/hooks'
import { fetchWallets } from '@/src/redux/slices/accounts.slice'

import { useFeatureFlags } from '../useFeatureFlags'

export const useFetchWallets = () => {
  const { CURRENCY_CLOUD_ACCESS } = useFeatureFlags('CURRENCY_CLOUD_ACCESS')
  const dispatch = useAppDispatch()
  const client = useApolloClient()

  const performFetch = useCallback(() => {
    if (CURRENCY_CLOUD_ACCESS === true) {
      dispatch(
        fetchWallets({
          client,
        })
      )
    }
  }, [CURRENCY_CLOUD_ACCESS, client, dispatch])

  const refetch = useCallback(async () => {
    if (CURRENCY_CLOUD_ACCESS === true) {
      await dispatch(
        fetchWallets({
          client,
          force: true,
        })
      )
    }
  }, [CURRENCY_CLOUD_ACCESS, client, dispatch])

  // Fetch wallets if currency cloud access is enabled
  useEffect(() => {
    performFetch()
  }, [CURRENCY_CLOUD_ACCESS, client, dispatch, performFetch])

  return {
    refetch,
  }
}
