import React from 'react'
import { Button, Flex, FlexProps } from 'ui-lib'

import { Icon } from '../icon'

export const ActionButtons = (props: FlexProps) => {
  return (
    <Flex
      width="full"
      gap="4"
      flexDirection={{
        base: 'column',
        lg: 'row',
      }}
      {...props}
    >
      {props.children}
    </Flex>
  )
}

type ButtonProps = React.ComponentProps<typeof Button> & {
  isSuccess?: boolean
}

const Primary = ({ isSuccess = false, isLoading = false, size = 'md', ...props }: ButtonProps) => {
  return (
    <Button
      variant={props.variant ?? 'primary'}
      size={size}
      onClick={props.onClick}
      isLoading={isLoading}
      isDisabled={isLoading || props.isDisabled}
      flex={{ base: 'none', lg: '1' }}
      flexShrink="0"
      type={props.type}
      rightIcon={isSuccess ? <Icon icon="check" variant="inherit" /> : undefined}
      order={{ base: '0', lg: '1' }}
      {...props}
    >
      {props.children}
    </Button>
  )
}

const Secondary = ({ isLoading = false, size = 'md', ...props }: Omit<ButtonProps, 'isSuccess'>) => {
  return (
    <Button
      variant="outline"
      size={size}
      onClick={props.onClick}
      isLoading={isLoading}
      isDisabled={isLoading || props.isDisabled}
      flex={{ base: 'none', lg: '1' }}
      flexShrink="0"
      order={{ base: '1', lg: '0' }}
      {...props}
    >
      {props.children}
    </Button>
  )
}

ActionButtons.Primary = Primary
ActionButtons.Secondary = Secondary
