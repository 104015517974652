import { useEffect, useRef } from 'react'

export const useEffectOnce = (callback: () => void) => {
  const hasRun = useRef<boolean>(false)

  useEffect(() => {
    if (!hasRun.current) {
      callback()
      hasRun.current = true
    }
  }, [hasRun, callback])
}
