import { useState } from 'react'

export interface Pagination {
  currentPage: number
  take: number
  totalPages: number
}

export const usePagination = (initialValues?: Pagination) => {
  const [pagination, setPagination] = useState({
    currentPage: initialValues?.currentPage ?? 1,
    take: initialValues?.take ?? 1,
    totalPages: initialValues?.totalPages ?? 1,
  })

  const changePage = (page: number) => {
    setPagination((prev) => ({
      ...prev,
      currentPage: page,
    }))
  }

  return {
    pagination,
    changePage,
  }
}
