import { ComponentProps } from 'react'
import { chakra } from 'ui-lib'

const SelectedArrow = (props?: ComponentProps<typeof chakra.svg>) => {
  return (
    <chakra.svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 6.58579V1.41421C0 0.523309 1.07714 0.0771402 1.70711 0.707105L4.29289 3.29289C4.68342 3.68342 4.68342 4.31658 4.29289 4.70711L1.70711 7.29289C1.07714 7.92286 0 7.47669 0 6.58579Z"
        fill="currentColor"
      />
    </chakra.svg>
  )
}

export default SelectedArrow
