import { Flex, useMultiStyleConfig, VStack } from '@chakra-ui/react'
import React from 'react'

import { DraggingContent } from './dragging-content'
import { LoadingFileInput } from './file-input-loading'
import { FileItem } from './file-item'
import { IdleContent } from './idle-content'
import { FileInputProps } from './types'
import { useFileInput } from './use-file-input'

export const FileInput = ({
  accept,
  label,
  id,
  value,
  isInvalid = false,
  isLoading = false,
  isDisabled = false,
  isRequired,
  maxFiles = 1,
  maxFileSize,
  onError,
  onFilesChange,
  requiresNameSanitization = true,
}: FileInputProps) => {
  const { files, isDragging, isIdle, isMultiple, getRootProps, handleFilesChange, removeFile } = useFileInput({
    value,
    accept,
    isDisabled,
    maxFileSize,
    onError,
    maxFiles,
    onFilesChange,
    requiresNameSanitization,
  })

  const hasValue = files.length > 0
  const shouldDisplayIdleContent = isIdle && !isLoading
  const shouldDisplayDraggingContent = isDragging && !isLoading
  const shouldDisplayFileItem = hasValue && !isLoading && !isMultiple

  const getVariant = () => {
    if (isDisabled) {
      return 'disabled'
    }

    if (isInvalid) {
      return 'invalid'
    }

    if (isDragging && !isLoading) {
      return 'dragging'
    }

    if (isLoading) {
      return 'loading'
    }
  }

  const styles = useMultiStyleConfig('FileInput', {
    variant: getVariant(),
  })

  return (
    <>
      {shouldDisplayFileItem ? (
        <FileItem file={files[0]} onRemove={removeFile} isDisabled={isDisabled} />
      ) : (
        <Flex {...getRootProps()} sx={styles.container}>
          {shouldDisplayIdleContent && (
            <IdleContent
              accept={accept}
              onFilesChange={handleFilesChange}
              label={label}
              maxFiles={maxFiles}
              isRequired={isRequired}
              isInvalid={isInvalid}
              isDisabled={isDisabled}
              id={id}
            />
          )}

          {shouldDisplayDraggingContent && <DraggingContent label={label} />}

          {isLoading && <LoadingFileInput label={label} />}
        </Flex>
      )}

      {isMultiple && (
        <VStack spacing="2" width="full">
          {files.map((file) => (
            <FileItem key={file.name} file={file} onRemove={removeFile} isDisabled={isDisabled} />
          ))}
        </VStack>
      )}
    </>
  )
}
