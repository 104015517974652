import { Radio as ChakraRadio, RadioProps as ChakraRadioProps } from '@chakra-ui/react'
import React, { forwardRef } from 'react'

export interface RadioButtonProps extends Omit<ChakraRadioProps, 'children' | 'variant'> {
  text: string | React.ReactNode
  value: string
  defaultChecked?: boolean
}

export const Radio = forwardRef<HTMLInputElement, RadioButtonProps>((props, ref) => (
  <ChakraRadio variant="new" ref={ref} {...props}>
    {props.text}
  </ChakraRadio>
))
