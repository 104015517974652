export const textStyles = {
  'headline-1': {
    fontSize: 'heading.h1',
    fontWeight: 'semibold',
    lineHeight: '48px',
    letterSpacing: '-0.02em',
  },
  'headline-2': {
    fontSize: 'heading.h2',
    fontWeight: 'semibold',
    lineHeight: '40px',
    letterSpacing: '-0.01em',
  },
  'headline-3': {
    fontSize: 'heading.h3',
    fontWeight: 'semibold',
    lineHeight: '40px',
    letterSpacing: '-0.01em',
  },
  'headline-4': {
    fontSize: 'heading.h4',
    fontWeight: 'semibold',
    lineHeight: '32px',
    letterSpacing: '-0.01em',
  },
  'title-lg': {
    fontSize: 'title.lg',
    fontWeight: 'medium',
    lineHeight: '28px',
    letterSpacing: '-0.01em',
  },
  'title-md': {
    fontSize: 'title.md',
    fontWeight: 'medium',
    lineHeight: '24px',
    letterSpacing: '-0.01em',
  },
  'title-sm': {
    fontSize: 'title.sm',
    fontWeight: 'medium',
    lineHeight: '24px',
    letterSpacing: '0',
  },
  'title-xs': {
    fontSize: 'title.xs',
    fontWeight: 'medium',
    lineHeight: '20px',
    letterSpacing: '0',
  },
  'subheading-lg': {
    fontSize: 'subheading.lg',
    fontWeight: 'medium',
    lineHeight: '20px',
    letterSpacing: '0.06em',
  },
  'subheading-md': {
    fontSize: 'subheading.md',
    fontWeight: 'medium',
    lineHeight: '16px',
    letterSpacing: '0.06em',
  },
  'subheading-sm': {
    fontSize: 'subheading.sm',
    fontWeight: 'medium',
    lineHeight: '12px',
    letterSpacing: '0.06em',
  },
  'paragraph-lg': {
    fontSize: 'paragraph.lg',
    fontWeight: 'regular',
    lineHeight: '24px',
    letterSpacing: '-0.01em',
  },
  'paragraph-md': {
    fontSize: 'paragraph.md',
    fontWeight: 'regular',
    lineHeight: '20px',
    letterSpacing: '0',
  },
  'paragraph-sm': {
    fontSize: 'paragraph.sm',
    fontWeight: 'regular',
    lineHeight: '20px',
    letterSpacing: '0',
  },
}
