import { formAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

import { textStyles } from '../text-styles'

const helpers = createMultiStyleConfigHelpers([...formAnatomy.keys, 'errorMessage'])

export const FormControl = helpers.defineMultiStyleConfig({
  baseStyle: {},
  defaultProps: {
    variant: 'legacy',
  },
  variants: {
    legacy: {
      container: {
        label: {
          textTransform: 'capitalize',
        },
      },
    },
    new: {
      container: {
        label: {
          textStyle: 'subheading-md',
          color: 'text-secondary',
          textTransform: 'uppercase',
        },
      },
      helperText: {
        ...textStyles['paragraph-sm'],
        color: 'text-secondary',
      },
      errorMessage: {
        ...textStyles['paragraph-sm'],
        color: 'text-error',
      },
    },
  },
})
