import baseMoment from 'moment-timezone'

const moment = baseMoment
moment.tz.setDefault('UTC')

const overriddenDateTime = process.env.OVERRIDE_DATETIME
if (overriddenDateTime) {
  moment.now = () => new Date(overriddenDateTime).getTime()
}

export default moment
