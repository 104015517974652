import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const helpers = createMultiStyleConfigHelpers(['container', 'item'])

const commonStyle = {
  border: '1px solid',
  borderColor: 'border-secondary',
  padding: '4',
  borderRadius: 'md',
  height: { base: '136px', sm: '80px' },
  gap: { base: 4, sm: 0 },
  bg: 'bg-primary',
  justifyContent: 'space-between',
  alignItems: { base: 'inherit', sm: 'center' },
  flexDir: { base: 'column', sm: 'row' },
}

export const FileInput = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      ...commonStyle,
      mb: '2',
    },
    item: commonStyle,
  },
  defaultProps: {},
  variants: {
    disabled: {
      container: {
        bg: 'bg-primary',
        borderColor: 'border-secondary',
        cursor: 'not-allowed',
        opacity: 0.7,
      },
    },
    loading: {
      container: {
        bg: { base: 'bg-primary', sm: 'bg-disabled' },
        borderColor: 'border-soft',
      },
    },
    dragging: {
      container: {
        bg: 'bg-info',
        borderColor: 'border-info',
        border: '1px dashed',
        p: {
          color: 'text-info',
        },
      },
    },
    invalid: {
      container: {
        borderColor: 'border-error',
        bg: 'bg-primary',

        p: {
          color: 'text-error',
        },
      },
    },
  },
})
