import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OnboardingStep } from 'db-schema'
import { HYDRATE } from 'next-redux-wrapper'

import isDefined from '@/src/utils/dataShaping/isDefined'
import { UpdateApplicationBody } from '@/types/data'
import {
  AboutBusinessData,
  AdditionalInformationData,
  BusinessAddressData,
  BusinessDirectorsData,
  BusinessOwnersData,
  IdentityVerificationData,
  IdentityVerificationDocument,
  IncorporationDocumentsData,
  PersonalAddressData,
  PersonalInformationData,
  RelationshipToTheBusinessData,
  ReviewData,
} from '@/types/form-info'

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: {
    step: '',
    [OnboardingStep.INTRODUCTION_0]: {},
    [OnboardingStep.DISCLOSURE_00]: {},
    [OnboardingStep.PERSONAL_INFORMATION_1]: {
      dateOfBirth: null as string | null,
      firstName: '',
      lastName: '',
      phoneNumber: '',
      socialNetworks: [''],
    } as Partial<PersonalInformationData>,
    [OnboardingStep.ADDITIONAL_INFORMATION_2]: {
      politicalPerson: '',
      sin: '',
    } as Partial<AdditionalInformationData>,
    [OnboardingStep.PERSONAL_ADDRESS_3]: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      postalCode: '',
      state: '',
      country: 'CAN',
      useAsBusinessAddress: false,
    } as Partial<PersonalAddressData>,
    [OnboardingStep.RELATIONSHIP_TO_THE_BUSINESS_40]: {
      position: '',
      authorizedToSign: '',
    } as Partial<RelationshipToTheBusinessData>,
    [OnboardingStep.ABOUT_BUSINESS_4]: {
      businessCategory: '',
      businessDBA: '',
      businessDescription: '',
      businessNameLegal: '',
      businessPhone: '',
      businessType: '',
      businessWebsite: '',
      dateOfIncorporation: null as string | null,
      incorporationNumber: '',
      isMoneyBusiness: 'no',
    } as Partial<AboutBusinessData>,
    [OnboardingStep.BUSINESS_ADDRESS_5]: {
      businessAddressLine1: '',
      businessAddressLine2: '',
      businessCity: '',
      businessPostalCode: '',
      businessProvince: '',
      businessCountry: 'CAN',
    } as Partial<BusinessAddressData>,
    [OnboardingStep.BUSINESS_OWNERS_6]: {
      owners: [],
      addedSelfAsOwner: false,
      noMoreOwners: false,
    } as Partial<BusinessOwnersData>,
    [OnboardingStep.BUSINESS_DIRECTORS_7]: {
      directors: [],
      addedSelfAsDirector: false,
      noMoreDirectors: false,
    } as Partial<BusinessDirectorsData>,
    [OnboardingStep.INCORPORATION_DOCUMENTS_8]: {
      documents: [],
    } as Partial<IncorporationDocumentsData>,
    [OnboardingStep.PLAID_9]: {},
    [OnboardingStep.IDENTITY_VERIFICATION_10]: {} as Partial<IdentityVerificationData>,
    [OnboardingStep.REVIEW_11]: {
      isReview: false,
    } as Partial<ReviewData>,
    [OnboardingStep.BUSINESS_DETAILS]: {},
    [OnboardingStep.INCORPORATION_DETAILS]: {},
    [OnboardingStep.BUSINESS_ADDRESS]: {},
    [OnboardingStep.LIST_OF_OWNERS]: {},
    [OnboardingStep.APPLICANT_IDENTITY]: {},
    [OnboardingStep.IDENTITY_VERIFICATION]: {},
    [OnboardingStep.FINANCIAL_DETAILS]: {},
    [OnboardingStep.REVIEW]: {},
    [OnboardingStep.CARD_SETUP]: {},
    [OnboardingStep.PRODUCTS_SELECTION]: {},
    [OnboardingStep.CARD_SETUP_INTRO]: {},
  },
  reducers: {
    setStep(state, action: PayloadAction<OnboardingStep>) {
      state.step = action.payload
    },
    setStepInfo(state, action: PayloadAction<UpdateApplicationBody>) {
      if (!isDefined(action.payload)) {
        return
      }
      for (const stepName in OnboardingStep) {
        const stepPayload = action.payload[stepName as OnboardingStep]
        if (isDefined(stepPayload)) {
          state[stepName as OnboardingStep] = stepPayload
        }
      }
    },
    setIdentityVerificationData(state, action: PayloadAction<Partial<IdentityVerificationData>>) {
      state.IDENTITY_VERIFICATION_10 = {
        ...state.IDENTITY_VERIFICATION_10,
        ...action.payload,
      }
    },
    setIdentitySelfie(state, action: PayloadAction<IdentityVerificationDocument>) {
      state.IDENTITY_VERIFICATION_10.identitySelfie = action.payload
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      const nextState = {
        ...state,
        ...action.payload.onboarding,
        IDENTITY_VERIFICATION_10: {
          ...action.payload.onboarding.IDENTITY_VERIFICATION_10,
        },
      }
      // If identitySelfie exists in client side, merge it with server side state
      if (isDefined(state.IDENTITY_VERIFICATION_10.identitySelfie)) {
        nextState.IDENTITY_VERIFICATION_10.identitySelfie = state.IDENTITY_VERIFICATION_10.identitySelfie
      }
      return nextState
    },
  },
})

export const { setStep, setStepInfo, setIdentityVerificationData } = onboardingSlice.actions
export default onboardingSlice
