import {
  chakra,
  Flex,
  FormControl as ChakraFormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  useMultiStyleConfig,
} from '@chakra-ui/react'
import React from 'react'
import { isDefined } from 'utils'

import { IconTooltip } from '../../icon-tooltip'
import { Checkbox } from '../checkbox'
import { DateInput } from '../date-input'
import { FileInput } from '../file-input'
import { Input } from '../input'
import { NumberInput } from '../number-input'
import { PasswordInput } from '../password-input'
import { Radio } from '../radio'
import { RadioButton, RadioButtonGroup } from '../radio-button-group'
import { RadioCard, RadioCardGroup } from '../radio-card'
import { RadioGroup } from '../radio-group'
import { AsyncSelect, Select } from '../select'
import { Textarea } from '../textarea'

export const Form = ({ children, ...props }: React.ComponentProps<typeof chakra.form>) => {
  return <chakra.form {...props}>{children}</chakra.form>
}

const ErrorMessage = (props: React.ComponentProps<typeof FormErrorMessage>) => {
  const styles = useMultiStyleConfig('Form', { variant: 'new' })

  return <FormErrorMessage sx={styles.errorMessage} {...props} />
}

export type FieldProps = Pick<
  React.ComponentProps<typeof ChakraFormControl>,
  'isDisabled' | 'isRequired' | 'isReadOnly' | 'label' | 'isInvalid'
> & {
  id: string
  helperText?: string | React.ReactNode
  tooltip?: string | React.ReactNode
  errorMessage?: string
  children: React.ReactElement
}

const Field = ({ children, label, helperText, tooltip, errorMessage, ...props }: FieldProps) => {
  const isInvalid = isDefined(errorMessage)

  return (
    <ChakraFormControl variant="new" isInvalid={isInvalid} {...props}>
      <Flex alignItems="center">
        <FormLabel>{label}</FormLabel>
        <Flex mb={2}>
          {isDefined(tooltip) && <IconTooltip label={tooltip} icon="info-fill" id={`tooltip-button-${props.id}`} />}
        </Flex>
      </Flex>
      {children}
      {isDefined(helperText) && !isInvalid && <FormHelperText>{helperText}</FormHelperText>}
      {isDefined(errorMessage) && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </ChakraFormControl>
  )
}

const FormControl = React.forwardRef<HTMLDivElement, React.ComponentProps<typeof ChakraFormControl>>((props, ref) => (
  <ChakraFormControl {...props} variant="new" ref={ref} />
))

Form.Label = FormLabel
Form.HelperText = FormHelperText
Form.ErrorMessage = ErrorMessage
Form.FormControl = FormControl
Form.Field = Field
Form.Select = Select
Form.AsyncSelect = AsyncSelect
Form.Input = Input
Form.NumberInput = NumberInput
Form.Checkbox = Checkbox
Form.Textarea = Textarea
Form.RadioGroup = RadioGroup
Form.Radio = Radio
Form.PasswordInput = PasswordInput
Form.FileInput = FileInput
Form.DateInput = DateInput
Form.RadioButtonGroup = RadioButtonGroup
Form.RadioButton = RadioButton
Form.RadioCard = RadioCard
Form.RadioCardGroup = RadioCardGroup
