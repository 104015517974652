import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ConstToUnion } from 'utils/types'

import { CardOption } from '@/src/components/atoms/TransactionsFilter/SelectCard'
import { TransactionFilterOption } from '@/src/constants/transactions'

export const PaginatedContent = {
  TRANSACTIONS: 'TRANSACTIONS',
  REWARDS_HISTORY: 'REWARDS_HISTORY',
  REWARDS_TRANSACTIONS: 'REWARDS_TRANSACTIONS',
  ACCOUNT_TRANSACTIONS: 'ACCOUNT_TRANSACTIONS',
} as const

export type PaginatedContent = ConstToUnion<typeof PaginatedContent>

export type PaginationValues = {
  cancelTransactionId: string | null
  selectedTransactionId: string | null
  selectedRowId: string | null
  selectedCard: CardOption | null
  selectedFilter: TransactionFilterOption[]
  startDate: Date | null
  endDate: Date | null
  itemsPerPage: number
  currentPage: number
  totalPages: number
}

export const initialValues: PaginationValues = {
  cancelTransactionId: null,
  selectedTransactionId: null,
  selectedRowId: null,
  selectedCard: null,
  selectedFilter: [],
  startDate: null,
  endDate: null,
  itemsPerPage: 10,
  currentPage: 1,
  totalPages: 0,
}

const paginationSlice = createSlice({
  name: 'pagination',
  initialState: {
    [PaginatedContent.TRANSACTIONS]: initialValues,
    [PaginatedContent.REWARDS_HISTORY]: initialValues,
    [PaginatedContent.REWARDS_TRANSACTIONS]: initialValues,
    [PaginatedContent.ACCOUNT_TRANSACTIONS]: initialValues,
  },
  reducers: {
    setPaginationValues(state, action: PayloadAction<Partial<PaginationValues> & { contentType: PaginatedContent }>) {
      const { contentType, ...payload } = action.payload
      state[contentType] = {
        ...state[contentType],
        ...payload,
      }
    },
  },
})

export const { setPaginationValues } = paginationSlice.actions
export default paginationSlice
