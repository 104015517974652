import { Box, Divider, Flex, Grid, Image, Text } from 'ui-lib'

const HOW_IT_WORKS = [
  {
    id: 'card-home-1',
    image: '/images/capital/schedule.svg',
    header: 'Choose your plan',
    description:
      'Select how much funding you need. Pick a repayment plan that works best for you. Get your money within 1 business day!',
  },
  {
    id: 'card-home-2',
    image: '/images/capital/enrollment-hands-coins.svg',
    header: 'Get more capital',
    description: 'Withdraw your remaining funds at any time. The credit limit resets with each completed repayment!',
  },
  {
    id: 'card-home-3',
    image: '/images/capital/cut-price.svg',
    header: 'No hidden fee',
    description:
      'No hidden fee - no surprises. And you can even lower your fees by up to 1/3rd with your Keep rewards.',
  },
  {
    id: 'card-home-4',
    image: '/images/capital/chart-line-up.svg',
    header: 'Funding that grows',
    description: 'Grow your business with Keep Capital. When your business grows, your credit limit grows too!',
  },
]

export const HowItWorksSection = () => {
  return (
    <>
      <Text color="text-secondary" textStyle="title-md" mt={8} mb={2}>
        How it works
      </Text>
      <Divider />
      <Grid gap={8} mt={8} templateColumns={{ base: 'auto', lg: 'auto auto' }}>
        {HOW_IT_WORKS.map(({ header, description, id, image }) => (
          <Flex overflow="hidden" gap={2} key={id} maxW="485px">
            <Image width="32px" height="32px" alt="ImageStep" objectFit="cover" src={image} />
            <Box>
              <Text color="text-primary" textStyle="title-sm">
                {header}
              </Text>
              <Text mt={2} color="text-secondary" textStyle="paragraph-md">
                {description}
              </Text>
            </Box>
          </Flex>
        ))}
      </Grid>
    </>
  )
}
