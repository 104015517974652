import { useApolloClient } from '@apollo/client'
import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { CurrencyCode } from 'services/utils/countries'
import { Money } from 'utils'

import {
  ConversionQuoteFixedSide,
  CreateConversionQuoteDocument,
  CreateConversionQuoteMutation,
  CreateConversionQuoteMutationVariables,
  CreateCurrencyConversionDocument,
  CreateCurrencyConversionMutation,
  CurrencyCode as GqlCurrencyCode,
  NoEligibleRewardsReason,
} from '@/gql'
import { SEGMENT_EVENTS } from '@/src/constants/segmentEvents'
import isDefined from '@/src/utils/dataShaping/isDefined'
import { analytics } from '@/src/utils/misc'

export interface Amount {
  numberValue: number
  stringValue: string
}

export function amountFromMoney(money: Money): Amount {
  return {
    numberValue: money.toNumber(),
    stringValue: money.toFormattedCurrencyString(),
  }
}

export type FixedSideValue = 'buy' | 'sell'

type CreateQuoteProps = {
  client: ReturnType<typeof useApolloClient>
  currencyFrom: CurrencyCode
  currencyTo: CurrencyCode
  fixedSide: 'buy' | 'sell'
  amountTo?: number
  amountFrom?: number
  fromIntervalRefresh: boolean
}

const initialState = {
  competitorsClientRate: 0.0,
  exchangeRate: '',
  quoteCreatedAt: null as string | null,
  amountFrom: { numberValue: 0, stringValue: '' } as Amount,
  amountTo: { numberValue: 0.0, stringValue: '0.00' } as Amount,
  currencyFrom: null as CurrencyCode | null,
  currencyTo: null as CurrencyCode | null,
  fixedSide: null as 'buy' | 'sell' | null,
  quoteId: null as string | null,
  createConversionQuote: {
    loading: false,
    error: null as SerializedError | SerializedError[] | null,
    data: null as CreateConversionQuoteMutation | null,
  },
  createCurrencyConversion: {
    loading: false,
    error: null as SerializedError | SerializedError[] | null,
    data: null as CreateConversionQuoteMutation | null,
  },
  rewards: {
    isRedeeming: false,
    eligibleRedeemablePoints: null as Amount | null,
    eligibleRedeemableAmount: null as Amount | null,
    eligibleRedeemableCurrency: null as CurrencyCode | null,
    noEligibleRewardsReason: null as NoEligibleRewardsReason | null,
  },
}

export const createConversionQuote = createAsyncThunk(
  'currencyConversion/createConversionQuote',
  async (params: CreateQuoteProps, { rejectWithValue }) => {
    const { client, amountFrom, amountTo, currencyFrom, currencyTo, fixedSide } = params
    const amount = fixedSide === 'buy' ? amountTo : amountFrom
    if (!isDefined(amount) || Number.isNaN(amount)) {
      return rejectWithValue(
        `Unexpected amount, the fixed side is ${fixedSide} but the ${
          fixedSide === 'buy' ? 'amountTo' : 'amountFrom'
        } is ${amount}`
      )
    }

    const { data, errors } = await client.mutate<CreateConversionQuoteMutation, CreateConversionQuoteMutationVariables>(
      {
        mutation: CreateConversionQuoteDocument,
        variables: {
          body: {
            amount,
            currencyFrom: currencyFrom as GqlCurrencyCode,
            currencyTo: currencyTo as GqlCurrencyCode,
            fixedSide: fixedSide === 'buy' ? ConversionQuoteFixedSide.Buy : ConversionQuoteFixedSide.Sell,
          },
        },
      }
    )

    if (isDefined(errors)) {
      return rejectWithValue(errors)
    }

    const { ...paramsWithoutClient } = params

    return {
      data,
      ...paramsWithoutClient,
    }
  }
)

export const createCurrencyConversion = createAsyncThunk(
  'currencyConversion/createCurrencyConversion',
  async (params: CreateQuoteProps, { rejectWithValue }) => {
    const { client } = params

    const { data, errors } = await client.mutate<CreateCurrencyConversionMutation>({
      mutation: CreateCurrencyConversionDocument,
    })

    if (isDefined(errors)) {
      return rejectWithValue(errors)
    }

    const { ...paramsWithoutClient } = params

    return {
      data,
      ...paramsWithoutClient,
    }
  }
)

const currencyConversionV2Slice = createSlice({
  name: 'currencyConversionV2',
  initialState,
  reducers: {
    _setExchangeRate: (state, action: PayloadAction<string>) => {
      state.exchangeRate = action.payload
    },
    _setCompetitorsClientRate: (state, action: PayloadAction<number>) => {
      state.competitorsClientRate = action.payload
    },
    _setAmountFrom: (state, action: PayloadAction<Amount>) => {
      state.amountFrom = action.payload
    },
    _setAmountTo: (state, action: PayloadAction<Amount>) => {
      state.amountTo = action.payload
    },
    _setCurrencyFrom: (state, action: PayloadAction<CurrencyCode | null>) => {
      state.currencyFrom = action.payload
    },
    _setCurrencyTo: (state, action: PayloadAction<CurrencyCode | null>) => {
      state.currencyTo = action.payload
    },
    _setQuoteCreatedAt: (state, action: PayloadAction<string>) => {
      state.quoteCreatedAt = action.payload
    },
    _setFixedSide: (state, action: PayloadAction<'buy' | 'sell' | null>) => {
      state.fixedSide = action.payload
    },
    _setQuoteId: (state, action: PayloadAction<string | null>) => {
      state.quoteId = action.payload
    },
    _setIsRedeemingRewards: (state, action: PayloadAction<boolean>) => {
      state.rewards.isRedeeming = action.payload
    },
    _resetConversionData: (state) => {
      state.exchangeRate = initialState.exchangeRate
      state.competitorsClientRate = initialState.competitorsClientRate
      state.quoteCreatedAt = initialState.quoteCreatedAt
      state.amountFrom = initialState.amountFrom
      state.amountTo = initialState.amountTo
      state.currencyFrom = initialState.currencyFrom
      state.currencyTo = initialState.currencyTo
      state.fixedSide = initialState.fixedSide
      state.quoteId = initialState.quoteId
      state.rewards = initialState.rewards
    },
  },
  extraReducers: (builder) => {
    // createQuote reducers
    builder.addCase(createConversionQuote.pending, (state) => {
      state.createConversionQuote.loading = true
      state.createConversionQuote.error = null
      state.createConversionQuote.data = null
    })
    builder.addCase(createConversionQuote.rejected, (state, action) => {
      state.createConversionQuote.loading = false
      state.createConversionQuote.error = action.error
      state.createConversionQuote.data = null
      const fixedSide = action.meta.arg.fixedSide
      if (fixedSide === 'buy') {
        state.amountFrom = { numberValue: 0.0, stringValue: '' }
      } else {
        state.amountTo = { numberValue: 0.0, stringValue: '' }
      }
    })
    builder.addCase(createConversionQuote.fulfilled, (state, action) => {
      state.createConversionQuote.loading = false
      const fixedSide = action.payload.fixedSide
      const quote = action.payload.data?.createConversionQuote

      if (!isDefined(quote)) {
        return
      }

      const buyAmount = Money.fromFormattedCurrencyString(quote.adjustedBuyAmount)
      const sellAmount = Money.fromFormattedCurrencyString(quote.adjustedSellAmount)
      const eligibleRedeemablePoints = isDefined(quote.eligibleRedeemablePoints)
        ? Money.fromFormattedCurrencyString(quote.eligibleRedeemablePoints)
        : null
      const eligibleRedeemableAmount =
        isDefined(quote.eligibleRedeemableAmount) && isDefined(quote.eligibleRedeemableCurrency)
          ? Money.fromFormattedCurrencyString(quote.eligibleRedeemableAmount, quote.eligibleRedeemableCurrency)
          : null

      state.competitorsClientRate = quote.competitorsClientRate
      state.exchangeRate = quote.adjustedClientRate.toString()
      state.quoteCreatedAt = quote.createdAt.toString()
      state.quoteId = quote.id

      state.createConversionQuote.error = null
      if (fixedSide === 'sell') {
        state.amountTo = amountFromMoney(buyAmount)
      } else {
        state.amountFrom = amountFromMoney(sellAmount)
      }

      state.rewards = {
        isRedeeming: state.rewards.isRedeeming,
        eligibleRedeemablePoints: isDefined(eligibleRedeemablePoints)
          ? amountFromMoney(eligibleRedeemablePoints)
          : null,
        eligibleRedeemableAmount: isDefined(eligibleRedeemableAmount)
          ? amountFromMoney(eligibleRedeemableAmount)
          : null,
        eligibleRedeemableCurrency: quote.eligibleRedeemableCurrency ?? null,
        noEligibleRewardsReason: quote.noEligibleRewardsReason ?? null,
      }

      analytics.track(SEGMENT_EVENTS.CURRENCY_CONVERSION_REQUESTED, {
        sell_currency: state.currencyFrom,
        sell_currency_amount: state.amountFrom.stringValue,
        buy_currency: state.currencyTo,
        buy_currency_amount: state.amountTo.stringValue,
        exchange_rate: state.exchangeRate,
      })
    })

    builder.addCase(createCurrencyConversion.pending, (state) => {
      state.createCurrencyConversion.loading = true
      state.createCurrencyConversion.error = null
      state.createCurrencyConversion.data = null
    })

    builder.addCase(createCurrencyConversion.rejected, (state, action) => {
      state.createConversionQuote.loading = false
      state.createConversionQuote.error = action.error
      state.createConversionQuote.data = null
    })

    builder.addCase(createCurrencyConversion.fulfilled, (state) => {
      state.createConversionQuote.loading = false
      state.createCurrencyConversion.error = null
    })
  },
})

export const {
  _setCompetitorsClientRate,
  _setExchangeRate,
  _setAmountFrom,
  _setAmountTo,
  _setCurrencyFrom,
  _setCurrencyTo,
  _setFixedSide,
  _setQuoteCreatedAt,
  _setQuoteId,
  _resetConversionData,
  _setIsRedeemingRewards,
} = currencyConversionV2Slice.actions

export default currencyConversionV2Slice
