import { ConstToUnion } from 'utils/types'

export const Size = {
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
} as const

export type Size = ConstToUnion<typeof Size>

export const Variant = {
  OUTLINE: 'outline',
  UNSTYLED: 'unstyled',
} as const

export type Variant = ConstToUnion<typeof Variant>

export const LabelPosition = {
  INSIDE: 'inside',
  OUTSIDE: 'outside',
} as const

export type LabelPosition = ConstToUnion<typeof LabelPosition>

export const TagVariant = {
  NEUTRAL: 'neutral',
  BLACK: 'black',
  LOOK_AT_ME: 'lookAtMe',
  POSITIVE: 'positive',
  INFO: 'info',
  WARNING: 'warning',
  NEGATIVE: 'negative',
}

export type TagVariant = ConstToUnion<typeof TagVariant>
