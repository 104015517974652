import { chakra, Flex } from 'ui-lib'

import KeepLogoSVG from '@/src/svgs/logoInset.svg'

export const KeepLogo = () => {
  return (
    <Flex
      backgroundColor={'black'}
      h={14}
      justifyContent="center"
      alignItems="center"
      w={14}
      borderRadius="14px"
      p={2.5}
    >
      <chakra.img width="48px" src={KeepLogoSVG.src} />
    </Flex>
  )
}
