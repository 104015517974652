import { useSession } from 'next-auth/react'
import { useEffect } from 'react'
import { isDefined } from 'utils'

import { useAppDispatch } from '@/src/redux/hooks'
import { setFeatureFlags } from '@/src/redux/slices/featureFlags.slice'
import { setSettingsPages } from '@/src/redux/slices/settings.slice'
import { setSidebarPages } from '@/src/redux/slices/sidebar.slice'

export const useInitializeLegacyFeatureFlags = () => {
  const { data: session } = useSession()
  const user = session?.user?.internal
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isDefined(user) && isDefined(user?.business?.featureFlags)) {
      dispatch(setFeatureFlags(user))
      dispatch(setSidebarPages(user))
      dispatch(setSettingsPages(user))
    }
  }, [dispatch, user])
}
