import { gql, useLazyQuery, useQuery } from '@apollo/client'

import { GetWallets } from '@/src/graphql/generated/GetWallets'

export const GET_WALLETS_QUERY = gql`
  query GetWallets {
    wallets {
      id
      ccAccountId
      currency
      balance {
        availableBalance {
          amount
          amountAsNumber
        }
        currentBalance {
          amount
          amountAsNumber
        }
      }
      fundingAccounts {
        id
        paymentType
        bankAccount {
          currency
          accountHolderName
          name
          accountNumber
          accountNumberType
          routingCode
          routingCodeType
          address
          country
        }
      }
    }
  }
`

export const useGetWallets = () => useQuery<GetWallets>(GET_WALLETS_QUERY)

export const useLazyGetWallets = () => useLazyQuery<GetWallets>(GET_WALLETS_QUERY)
