export const Header = {
  baseStyle: {
    h: 14,
    borderBottom: '1px solid #EDEFF1',
    w: '100%',
    py: 2.5,
    px: { sm: 2, md: 8 },
    zIndex: 'docked',
  },
}
