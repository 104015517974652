import parsePhoneNumber, { CountryCode, isValidNumberForRegion } from 'libphonenumber-js'
import { isDefined } from 'utils'

export const isValidPhoneForRegion = (phoneNumber: string | undefined, regionCode: string | undefined) => {
  if (!isDefined(phoneNumber) || !isDefined(regionCode)) {
    return false
  }

  try {
    return isValidNumberForRegion(phoneNumber, regionCode.toUpperCase() as CountryCode)
  } catch (error) {
    return false
  }
}

const formatPhone = (phoneNumber: string) => {
  try {
    const parsedPhone = parsePhoneNumber(phoneNumber)
    const countryCode = parsedPhone?.countryCallingCode

    if (!isDefined(parsedPhone)) {
      return phoneNumber
    }

    const result =
      countryCode === '1' ? `+${countryCode} ${parsedPhone?.formatNational()}` : parsedPhone?.formatInternational()

    return result
  } catch (error) {
    return phoneNumber
  }
}

/**
 *
 * @param phoneNumber Phone number to extract the code
 * @returns Country code like 'US' or 'CA' or undefined
 */
export const getPhoneCountryCode = (phoneNumber: string) => {
  try {
    const value = phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`
    const parsedPhone = parsePhoneNumber(value)

    if (!isDefined(parsedPhone) || !parsedPhone?.isValid()) {
      return undefined
    }

    return parsedPhone?.country
  } catch (e) {
    return undefined
  }
}

export default formatPhone
