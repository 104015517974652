import { ApolloClient, createHttpLink, from, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import merge from 'deepmerge'
import isEqual from 'lodash.isequal'
import { useSession } from 'next-auth/react'
import { useMemo } from 'react'
import { SHOULD_CONNECT_TO_APOLLO_DEV_TOOLS, GRAPHQL_SERVER_URL } from '@/src/utils/misc'
import getConfig from 'next/config'
import { NextConfig } from '@/types/helpers'

const config = getConfig() as NextConfig

const APOLLO_CACHE_KEY = '__APOLLO_STATE__'

import { isDefined } from 'utils'

let cachedApolloClient: ApolloClient<unknown>

export const createApolloClient = (graphqlUrl: string, accessToken: unknown) => {
  const httpLink = createHttpLink({
    uri: graphqlUrl,
    credentials: 'include',
  })

  const authLink = setContext(async (_, context) => {
    const { headers } = context as { headers: Headers }
    const modifiedHeader = {
      headers: {
        ...headers,
        authorization: isDefined(accessToken) ? `Bearer ${accessToken}` : '',
      },
    }
    return modifiedHeader
  })

  const client = new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from([authLink, httpLink]),
    cache: new InMemoryCache(),
    connectToDevTools: SHOULD_CONNECT_TO_APOLLO_DEV_TOOLS,
  })

  return client
}

export function initializeApollo(accessToken: unknown, initialState?: unknown) {
  const newApolloClient = createApolloClient(GRAPHQL_SERVER_URL, accessToken)

  // The initial state gets hydrated here
  if (isDefined(initialState)) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = isDefined(cachedApolloClient) ? cachedApolloClient.extract() : {}

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache as any, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
      ],
    })
    // Restore the cache with the merged data
    newApolloClient.cache.restore(data as NormalizedCacheObject)
  }

  // For SSG and SSR there's no need to cache the apollo client
  if (typeof window === 'undefined') {
    return newApolloClient
  }

  cachedApolloClient = newApolloClient

  return newApolloClient
}

export function addApolloState<T extends { props: {} }>(
  client: ApolloClient<unknown>,
  pageProps: T = { props: {} } as T
) {
  const props = {
    ...pageProps.props,
    [APOLLO_CACHE_KEY]: client.cache.extract(),
  }
  pageProps.props = props
  return pageProps
}

export function useApollo(pageProps: Record<typeof APOLLO_CACHE_KEY, unknown>) {
  const { data: session } = useSession()

  const client = useMemo(
    () => initializeApollo(session?.accessToken ?? '', pageProps[APOLLO_CACHE_KEY]),
    [session?.accessToken, pageProps]
  )

  return client
}
