import { useState } from 'react'
import { sanitizeFileName } from 'ui-lib/src/utils'
import { isDefined } from 'utils'

import { DraggingStatus, FileInputProps } from './types'
import { getErrorMessage, getErrorType } from './utils'

export const useFileInput = ({
  accept,
  isDisabled = false,
  maxFiles = 1,
  maxFileSize,
  onError,
  onFilesChange,
  value,
  requiresNameSanitization = true,
}: Pick<
  FileInputProps,
  | 'maxFiles'
  | 'maxFileSize'
  | 'onFilesChange'
  | 'onError'
  | 'accept'
  | 'isDisabled'
  | 'value'
  | 'requiresNameSanitization'
>) => {
  const isControlled = isDefined(value)
  const [status, setStatus] = useState<DraggingStatus>('idle')
  const [files, setFiles] = useState<File[]>([])

  const isDragging = status === 'dragging'
  const isIdle = status === 'idle'

  const onDrag = () => setStatus('dragging')

  const offDrag = () => setStatus('idle')

  const handleFilesChange = (fileList: FileList) => {
    const filesValue = isControlled ? value : files
    const filesArray = Array.from(fileList)
    const error = getErrorType({
      accept,
      currentFiles: filesValue,
      newFiles: filesArray,
      maxFiles,
      maxFileSize,
    })
    const errorMessage = getErrorMessage(error, {
      maxFiles,
      maxFileSize,
    })

    if (isDefined(errorMessage) && isDefined(error)) {
      onError?.(error, errorMessage)
      return
    }

    let newFiles: File[] = filesArray

    if (requiresNameSanitization) {
      const dataTransfer = new DataTransfer()

      filesArray.forEach((file) => {
        const sanitizedName = sanitizeFileName(file.name)
        const extension = file.name.split('.').pop()
        const newFile = new File([file], `${sanitizedName}.${extension}`, { type: file.type })
        dataTransfer.items.add(newFile)
      })

      newFiles = Array.from(dataTransfer.files)
    }

    if (!isControlled) {
      setFiles((files) => [...files, ...newFiles])
    }

    onFilesChange?.([...filesValue, ...newFiles])
  }

  const removeFile = (file: File) => {
    const filesValue = isControlled ? value : files
    const updatedFiles = filesValue.filter((f) => f.name !== file.name)

    if (!isControlled) {
      setFiles(updatedFiles)
    }

    onFilesChange?.(updatedFiles)
  }

  const getRootProps = () =>
    isDisabled
      ? {}
      : {
          onDragEnter: onDrag,
          onDragOver: (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault()
            onDrag()
          },
          onDragLeave: offDrag,
          onDrop: (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault()
            offDrag()
            handleFilesChange(event.dataTransfer.files)
          },
        }

  return {
    files: isControlled ? value : files,
    isDragging,
    isIdle,
    isMultiple: maxFiles > 1,
    handleFilesChange,
    getRootProps,
    removeFile,
  }
}
