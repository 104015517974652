import { Card as ChakraCard, CardBody, CardHeader, CardProps } from '@chakra-ui/react'
import React from 'react'
import { isDefined } from 'utils'

import { AdornmentsLayout, AdornmentsLayoutProps } from '../layouts/'

const Card = ({ children, ...props }: CardProps) => {
  const isInteractive = isDefined(props.onClick)

  return (
    <ChakraCard as={isInteractive ? 'button' : ChakraCard} width={isInteractive ? 'full' : 'auto'} {...props}>
      {children}
    </ChakraCard>
  )
}

const Header = ({ leftElement, rightElement, alignItems, ...props }: AdornmentsLayoutProps) => (
  <CardHeader {...props}>
    <AdornmentsLayout alignItems={alignItems ?? 'center'} leftElement={leftElement} rightElement={rightElement}>
      {props.children}
    </AdornmentsLayout>
  </CardHeader>
)

Card.Header = Header
Card.Body = CardBody

export { Card }
