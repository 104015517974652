// helpers/filterObjectEmptyValues.ts
function filterObjectEmptyValues(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value !== null && value !== void 0 && value !== "").map(([key, value]) => {
      if (typeof value === "object" && value !== null) {
        return [key, filterObjectEmptyValues(value)];
      }
      return [key, value];
    })
  );
}

export { filterObjectEmptyValues };
