import { useRouter } from 'next/router'
import { createContext, useContext, useEffect, useReducer } from 'react'
import { useToast } from 'ui-lib'
import { isDefined } from 'utils'

import { CreateCapitalFundingMutation, FundingWithInstallments, useCreateCapitalFundingMutation } from '@/gql'
import { SEGMENT_PAGE_VISITED } from '@/src/constants/segmentVisitedPages'
import { useConfirmAction } from '@/src/contexts/misc'
import { useInternalUser, useServerTrackEvent } from '@/src/hooks/misc'
import { isUnderwritingPeriodExpired } from '@/src/utils/capital'
import { LocalStorage } from '@/src/utils/misc'

const FUNDING_STORAGE_KEY = 'funding'

export type FundingStep = 'purpose' | 'financing-plan' | 'review' | 'complete'

interface State {
  purpose: string
  fundingAmount: number | null
  termLength: number | null
  currentStep: FundingStep
}

type StepParams = { purpose: string } | { fundingAmount: number; termLength: number }

interface FundingContextType extends State {
  createdFunding: CreateCapitalFundingMutation['createCapitalFunding'] | undefined | null
  goToNextStep: (params?: StepParams) => Promise<void>
  goToPreviousStep: () => void
  goToStep: (step: FundingStep) => void
}

type Action =
  | { type: 'setPurpose'; payload: string }
  | { type: 'setFinancingPlan'; payload: { fundingAmount: number; termLength: number } }
  | { type: 'setCurrentStep'; payload: FundingStep }
  | { type: 'setCreatedFunding'; payload: FundingWithInstallments }

const Context = createContext<FundingContextType | null>(null)

interface FundingWithOriginationFeeProviderProps {
  children: React.ReactNode
  onComplete: () => void
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'setPurpose':
      return { ...state, purpose: action.payload }
    case 'setFinancingPlan':
      return { ...state, ...action.payload }
    case 'setCurrentStep':
      return { ...state, currentStep: action.payload }
    default:
      return state
  }
}

const confirmOptions = {
  title: 'Exit without saving?',
  description: 'You have unsaved changes. Are you sure you want to exit?',
  primaryAction: {
    label: 'Exit',
  },
}

const DEFAULT_STATE: State = {
  purpose: '',
  fundingAmount: null,
  termLength: null,
  currentStep: 'purpose',
}

const initializeState = (): State => {
  const funding = LocalStorage.get(FUNDING_STORAGE_KEY)

  if (!isDefined(funding)) {
    return DEFAULT_STATE
  }

  return JSON.parse(funding) as State
}

export const FundingWithOriginationFeeProvider = (props: FundingWithOriginationFeeProviderProps) => {
  const trackServerEvent = useServerTrackEvent()
  const toast = useToast({})
  const router = useRouter()
  const confirm = useConfirmAction()
  const [state, dispatch] = useReducer(reducer, DEFAULT_STATE, initializeState)
  const user = useInternalUser()
  const underwrittenDate = user?.business?.underwrittenAt

  const [createFunding, { data, error }] = useCreateCapitalFundingMutation()
  const createdFunding = data?.createCapitalFunding

  const goToStep = (step: FundingStep) => {
    dispatch({ type: 'setCurrentStep', payload: step })
  }

  const goToNextStep = async (params?: StepParams) => {
    const nextStep = {
      purpose: 'financing-plan',
      'financing-plan': 'review',
      review: 'complete',
      complete: 'complete',
    } as const

    if (state.currentStep === 'purpose' && isDefined(params) && 'purpose' in params) {
      dispatch({ type: 'setPurpose', payload: params.purpose })
      goToStep(nextStep[state.currentStep])
    }

    if (state.currentStep === 'financing-plan' && isDefined(params) && 'fundingAmount' in params) {
      dispatch({
        type: 'setFinancingPlan',
        payload: {
          fundingAmount: params.fundingAmount,
          termLength: params.termLength,
        },
      })
      goToStep(nextStep[state.currentStep])
    }

    if (state.currentStep === 'review') {
      try {
        await createFunding({
          variables: {
            body: {
              reason: state.purpose,
              repaymentInstallments: state.termLength ?? 0,
              amount: state.fundingAmount ?? 0,
              repaymentFrequency: 'MONTHLY',
            },
          },
        })
        trackServerEvent({
          type: SEGMENT_PAGE_VISITED.FINANCING_REQUEST_SUCCESSFUL,
          payload: {
            team: 'Capital',
            page: 'Capital Financing Success Page',
            version: 'v2',
            capital_limit_expired: isUnderwritingPeriodExpired(underwrittenDate),
          },
        })
        goToStep(nextStep[state.currentStep])
      } catch {
        toast({
          title: 'Error trying create capital request.',
          position: 'top',
          status: 'error',
          duration: 3000,
          isClosable: true,
          description: `Failed to create capital request: ${error ?? 'Unknown error'}`,
        })
      }
    }

    if (nextStep[state.currentStep] === 'complete') {
      props.onComplete()
    }
  }

  const goToPreviousStep = (params?: StepParams) => {
    const previousStep = {
      purpose: 'purpose',
      'financing-plan': 'purpose',
      review: 'financing-plan',
      complete: 'review',
    } as const

    if (state.currentStep === 'purpose') {
      confirm(confirmOptions).then((result) => Boolean(result) && router.push('/capital'))
    }

    if (state.currentStep === 'financing-plan' && isDefined(params) && 'fundingAmount' in params) {
      dispatch({
        type: 'setFinancingPlan',
        payload: {
          fundingAmount: params.fundingAmount,
          termLength: params.termLength,
        },
      })
    }

    goToStep(previousStep[state.currentStep])
  }

  useEffect(() => {
    LocalStorage.set(FUNDING_STORAGE_KEY, JSON.stringify(state))
  }, [state])

  useEffect(() => {
    return () => {
      LocalStorage.remove(FUNDING_STORAGE_KEY)
    }
  }, [])

  useEffect(() => {
    if (state.currentStep == 'complete' && isDefined(LocalStorage.get(FUNDING_STORAGE_KEY))) {
      LocalStorage.remove(FUNDING_STORAGE_KEY)
    }
  }, [state.currentStep])

  return (
    <Context.Provider value={{ ...state, goToPreviousStep, goToNextStep, goToStep, createdFunding }}>
      {props.children}
    </Context.Provider>
  )
}

export const useFundingWithOriginationFee = () => {
  const context = useContext(Context)

  if (!isDefined(context)) {
    throw new Error('useFundingWithOriginationFee must be used within a FundingWithOriginationFeeProvider')
  }

  return context
}
