import React, { useRef, useState } from 'react'
import {
  ActionButtons,
  Icon,
  IconName,
  IconSize,
  IconVariant,
  ImageProps,
  Img,
  Modal,
  Text,
  useDisclosure,
} from 'ui-lib'
import { isDefined } from 'utils'

interface ConfirmActionOptions {
  title: string
  description?: string | React.ReactNode
  icon?: {
    name: IconName
    variant: IconVariant
    size?: IconSize
  }
  img?: ImageProps
  primaryAction: {
    label: string
    variant?: 'primary' | 'destructive'
  }
  secondaryAction?: {
    label: string
  }
}

interface ConfirmActionContextType {
  confirm: (options: ConfirmActionOptions) => Promise<boolean>
}

const ConfirmActionContext = React.createContext<ConfirmActionContextType | null>(null)

export const ConfirmActionProvider = ({ children }: { children: React.ReactNode }) => {
  const [modalProps, setModalProps] = useState<ConfirmActionOptions>()
  const primaryAction = useRef<() => void>()
  const secondaryAction = useRef<() => void>()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const confirm = async (options: ConfirmActionOptions) => {
    onOpen()

    return new Promise<boolean>((resolve) => {
      const handlePrimaryAction = () => {
        resolve(true)
        onClose()
      }

      const handleSecondaryAction = () => {
        resolve(false)
        onClose()
      }

      primaryAction.current = handlePrimaryAction
      secondaryAction.current = handleSecondaryAction

      setModalProps({
        secondaryAction: {
          label: 'Cancel',
        },
        ...options,
      })
    })
  }

  const mainButtonVariant = modalProps?.primaryAction?.variant === 'destructive' ? 'primary.destructive' : 'primary'

  return (
    <ConfirmActionContext.Provider value={{ confirm }}>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Body>
            {isDefined(modalProps?.icon) && (
              <Icon icon={modalProps?.icon.name} variant={modalProps?.icon?.variant} size={modalProps?.icon?.size} />
            )}
            {isDefined(modalProps?.img) && <Img mx="auto" mb="6" {...modalProps?.img} />}
            <Text textStyle="headline-4" textAlign="center" mt="3" mb="4">
              {modalProps?.title}
            </Text>
            {typeof modalProps?.description === 'function' ? (
              modalProps?.description
            ) : (
              <Text textAlign="center">{modalProps?.description}</Text>
            )}

            <ActionButtons mt="6">
              <ActionButtons.Secondary onClick={secondaryAction.current}>
                {modalProps?.secondaryAction?.label}
              </ActionButtons.Secondary>
              <ActionButtons.Primary onClick={primaryAction.current} variant={mainButtonVariant}>
                {modalProps?.primaryAction.label}
              </ActionButtons.Primary>
            </ActionButtons>
          </Modal.Body>
        </Modal.Content>
      </Modal>
      {children}
    </ConfirmActionContext.Provider>
  )
}

export const useConfirmAction = () => {
  const context = React.useContext(ConfirmActionContext)

  if (!isDefined(context)) {
    throw new Error('useConfirmAction must be used within a ConfirmActionProvider')
  }

  return context.confirm
}
