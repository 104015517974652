import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { isDefined } from 'utils'

import { DEFAULT_COUNTRY } from '@/src/constants/countries'
import { EMPLOYEE_INVITATION_STEP, EmployeeInvitationStep } from '@/src/constants/employeeInvitations'
import { UpdateKycFormBody } from '@/types/data'
import { KycAddressData, KycIdValidationData, KycPasswordData, KycPersonalData } from '@/types/form-info'

const kycFormSlice = createSlice({
  name: 'kycForm',
  initialState: {
    [EMPLOYEE_INVITATION_STEP.SET_PASSWORD]: {
      password: '',
      passwordConfirmation: '',
      agreedToPlatform: false,
    } as Partial<KycPasswordData>,
    [EMPLOYEE_INVITATION_STEP.PERSONAL_DETAILS]: {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      workingPosition: '',
    } as Partial<KycPersonalData>,
    [EMPLOYEE_INVITATION_STEP.HOME_ADDRESS]: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      country: DEFAULT_COUNTRY,
      postalCode: '',
    } as Partial<KycAddressData>,
    [EMPLOYEE_INVITATION_STEP.ID_VALIDATION]: {} as Partial<KycIdValidationData>,
  },
  reducers: {
    setStepInfo(state, action: PayloadAction<UpdateKycFormBody>) {
      if (!isDefined(action.payload)) {
        return
      }
      for (const stepName in EMPLOYEE_INVITATION_STEP) {
        const step = stepName as keyof typeof EMPLOYEE_INVITATION_STEP
        const stepPayload = action.payload[EMPLOYEE_INVITATION_STEP[step] as EmployeeInvitationStep]
        if (isDefined(stepPayload)) {
          state[EMPLOYEE_INVITATION_STEP[step] as EmployeeInvitationStep] = stepPayload
        }
      }
    },
  },
})

export const { setStepInfo } = kycFormSlice.actions
export default kycFormSlice
