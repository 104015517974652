import { AccountNumberType, CurrencyCode } from 'db-schema'
import { isAllowedCurrency } from 'services/src/currency/currencies'
import { CountryCode, getAlpha3Code } from 'services/utils/countries'

import {
  ACCOUNT_NUMBER_TYPE_LABEL,
  FormattedFundingAccountType,
  ROUTING_CODE_TYPE_LABEL,
} from '@/src/constants/accounts'
import { getCountryNameByCode } from '@/src/constants/countries'
import { FundingAccountType } from '@/src/redux/slices/accounts.slice'
import isDefined from '@/src/utils/dataShaping/isDefined'

import { getFundingAccountDetails } from './getFundingAccountDetails'

export const formatFundingAccounts = (fundingAccounts: FundingAccountType[]) =>
  fundingAccounts.flatMap(({ id, bankAccount, paymentType }) => {
    if (!isAllowedCurrency(bankAccount.currency)) {
      return []
    }

    const currency = bankAccount.currency

    const fundingAccountDetails = getFundingAccountDetails(currency, bankAccount.routingCodeType, paymentType)

    if (!isDefined(fundingAccountDetails)) {
      return []
    }

    const formattedFundingAccount: FormattedFundingAccountType = {
      id,
      // title of funding account (ACH, EFT, international wire, etc.)
      title: fundingAccountDetails.label,
      // priority or regular
      paymentType: fundingAccountDetails.paymentType,
      // account number
      accountNumber: bankAccount.accountNumber,
      fields: [
        {
          title: 'Bank details',
          data: [
            {
              label: 'Name',
              value: bankAccount.name,
            },
            {
              label: 'Address',
              value: bankAccount.address.slice(0, -4).split(',').join(',\n'),
            },
            {
              label: 'Country',
              value: getCountryNameByCode(getAlpha3Code(bankAccount.country) as CountryCode),
            },
          ],
        },
      ],
    }

    const accountDetails = {
      title: 'Account details',
      data: [
        {
          label: 'Name',
          value: bankAccount.accountHolderName,
        },
        {
          label: ACCOUNT_NUMBER_TYPE_LABEL[bankAccount.accountNumberType],
          value: bankAccount.accountNumber,
        },
      ],
    }

    if (
      bankAccount.currency === CurrencyCode.CAD &&
      bankAccount.accountNumberType === AccountNumberType.ACCOUNT_NUMBER
    ) {
      // Parse routing code into Transit #(XXXXX) and Institution #(XXX)
      // Canadians provide account #, transit #, and institution # separately
      accountDetails.data = [
        ...accountDetails.data,
        {
          label: 'Branch / Transit #',
          value: (bankAccount.routingCode ?? '').slice(4, 9),
        },
        {
          label: 'Institution #',
          value: (bankAccount.routingCode ?? '').slice(1, 4),
        },
      ]
    } else if (isDefined(bankAccount.routingCodeType)) {
      accountDetails.data = [
        ...accountDetails.data,
        {
          label: ROUTING_CODE_TYPE_LABEL[bankAccount.routingCodeType],
          value: bankAccount.routingCode ?? '',
        },
      ]
    }

    formattedFundingAccount.fields = [accountDetails, ...formattedFundingAccount.fields]

    return formattedFundingAccount
  })
