import { Box, IconButton, Textarea as ChakraTextarea, useMultiStyleConfig } from '@chakra-ui/react'
import React, { forwardRef } from 'react'
import { isDefined } from 'utils'

import { Icon } from '../../icon'

type TextareaProps = React.ComponentProps<typeof ChakraTextarea> & {
  onClear?: () => void
}

export const Textarea = forwardRef<HTMLInputElement, TextareaProps>(({ onClear, ...props }, ref) => {
  const styles = useMultiStyleConfig('Textarea', {
    variant: 'new',
  })

  return (
    <Box position="relative">
      <ChakraTextarea
        sx={{
          ...styles,
          paddingInlineEnd: isDefined(onClear) ? '8' : '4',
          borderRadius: 'md',
          paddingInlineStart: '4',
          py: '4',
          fontSize: 'paragraph.lg',
          minHeight: '120px',
        }}
        ref={ref}
        {...props}
      />
      {isDefined(onClear) && (
        <IconButton
          minWidth="auto"
          position="absolute"
          right="4"
          top="4"
          onClick={onClear}
          variant="link"
          aria-label="clear"
          icon={<Icon size="sm" icon="cross" variant="primary" />}
        />
      )}
    </Box>
  )
})
