import { getStatsigUser, KEEP_ENV_TO_STATSIG_ENV_MAP, StatsigFeatureGate } from '@try-keep/feature-flag'
import getConfig from 'next/config'
import { useSession } from 'next-auth/react'
import { StatsigProvider, StatsigSynchronousProvider, StatsigUser, useGate } from 'statsig-react'
import { isDefined } from 'utils'

import { User as GraphqlUser } from '@/gql'
import { NextConfig } from '@/types/helpers'

export const STATSIG_STATE_KEY = '__STATSIG_STATE__'
export { StatsigFeatureGate } from '@try-keep/feature-flag'

const { publicRuntimeConfig } = getConfig() as NextConfig

type User = Pick<GraphqlUser, 'id' | 'email' | 'businessId'> & {
  onboardedAt?: string | Date | null
  approvedAt?: string | Date | null
}

declare global {
  interface Window {
    cypressFeatureGateOverride: Record<string, { value: boolean }>
  }
}

export const useFeatureGate = (gate: StatsigFeatureGate) => {
  const isCypress: boolean = typeof window !== 'undefined' && (window as any).Cypress

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { value } = !isCypress ? useGate(gate) : window?.cypressFeatureGateOverride?.[gate] ?? { value: false }

  return value
}

interface FeatureGatesProviderProps {
  children: React.ReactNode
  pageProps: Record<string, unknown>
}

export const FeatureGatesProvider = ({ children, pageProps }: FeatureGatesProviderProps) => {
  const initializeValues = pageProps[STATSIG_STATE_KEY]
  const statsigEnvironment = KEEP_ENV_TO_STATSIG_ENV_MAP[publicRuntimeConfig.keepEnv ?? 'dev']

  const { data: session } = useSession()

  const sessionUser = session?.user?.internal
  if (!isDefined(publicRuntimeConfig.statsigApiKey) || !isDefined(sessionUser)) {
    return <>{children}</>
  }

  const statsigUser = getStatsigUser(sessionUser as User) as StatsigUser

  if (isDefined(initializeValues)) {
    return (
      <StatsigSynchronousProvider
        sdkKey={publicRuntimeConfig.statsigApiKey}
        user={statsigUser}
        initializeValues={initializeValues}
        options={{
          environment: {
            tier: statsigEnvironment,
          },
        }}
      >
        {children}
      </StatsigSynchronousProvider>
    )
  }

  return (
    <StatsigProvider
      sdkKey={publicRuntimeConfig.statsigApiKey}
      user={statsigUser}
      options={{
        environment: {
          tier: statsigEnvironment,
        },
      }}
    >
      {children}
    </StatsigProvider>
  )
}
