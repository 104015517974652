import { numberInputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

import { fieldNewStyle } from './Input'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(numberInputAnatomy.keys)

const baseStyle = definePartsStyle({})

const newStyle = definePartsStyle({
  field: fieldNewStyle,
})

export const NumberInput = defineMultiStyleConfig({
  baseStyle,
  variants: {
    new: newStyle,
  },
})
