export const colors = {
  basic: {
    white: '#FFFFFF',
    black: '#000000',
  },
  neutral: {
    50: '#F5F8F8',
    100: '#EDEFF1',
    200: '#DFE4E6',
    300: '#CAD5D7',
    400: '#B4C0C5',
    500: '#A0ADB4',
    600: '#808A93',
    700: '#616A72',
    800: '#52595D',
    900: '#303436',
  },
  info: {
    50: '#E9F2FF',
    100: '#D3E6FF',
    200: '#A7CCFF',
    300: '#7BB1FF',
    400: '#4D95FC',
    500: '#0B77F7',
    600: '#0867D7',
    700: '#0657B7',
    800: '#044799',
    900: '#02387C',
  },
  positive: {
    50: '#EEFBF4',
    100: '#D7F4E2',
    200: '#B1E9C9',
    300: '#6AD09D',
    400: '#4ABD87',
    500: '#27A26C',
    600: '#198256',
    700: '#146847',
    800: '#12533A',
    900: '#08261C',
  },
  error: {
    50: '#FEF2F2',
    100: '#FFE1E1',
    200: '#FFC9C9',
    300: '#FEA3A3',
    400: '#FB6E6E',
    500: '#F34141',
    600: '#E02222',
    700: '#BD1818',
    800: '#9C1818',
    900: '#460909',
  },
  warning: {
    50: '#FEFEE8',
    100: '#FFFEC2',
    200: '#FFFB85',
    300: '#FFF145',
    400: '#FCE113',
    500: '#ECC806',
    600: '#CC9D02',
    700: '#A27006',
    800: '#86580D',
    900: '#432505',
  },
  gradient: {
    sunset: 'linear-gradient(180deg, #FFE6A5 0%, #DB57B6 100%)',
    lightYellowPink: 'linear-gradient(235.35deg, #DB57B6 0%, #EFBB6E 100%)',
    bluePink: 'linear-gradient(236deg, #DB57B6 0%, #6497C7 100%)',
    blueGreen: 'linear-gradient(236deg, #576CDB 0%, #64C774 100%)',
    yellowPink: 'linear-gradient(231deg, #F139D4 0%, #D2C120 100%)',
    hotRed: 'linear-gradient(236deg, #E28F43 0%, #F15379 100%)',
  },
}
