import { CurrencyCode } from 'db-schema'

import { PaymentSpeedValue, RewardActionValue } from '@/src/constants/bankTransfers'
import {
  ConversionFixedSide,
  CurrencyCode as GQLCurrencyCode,
  MultiCurrencyPaymentQuoteInput,
  PaymentPriority as GQLPaymentPriority,
  SameCurrencyPaymentQuoteInput,
} from '@/src/graphql/generated/globalTypes'
import { FixedSideValue } from '@/src/redux/slices/currencyConversion.slice'

export const getPaymentInputForSameCurrencyPayment = ({
  amountFrom,
  currencyFrom,
  paymentSpeed,
  rewardAction,
}: {
  amountFrom: number
  currencyFrom: CurrencyCode
  paymentSpeed: PaymentSpeedValue
  rewardAction: RewardActionValue
}): SameCurrencyPaymentQuoteInput => {
  return {
    amount: amountFrom,
    priority: GQLPaymentPriority[paymentSpeed.toUpperCase() as Uppercase<typeof paymentSpeed>],
    isEarningRewards: rewardAction === 'earn',
    currency: GQLCurrencyCode[currencyFrom],
  }
}

export const getPaymentInputForMultiCurrencyPayment = ({
  amountFrom,
  amountTo,
  currencyFrom,
  currencyTo,
  fixedSide,
  paymentSpeed,
  rewardAction,
}: {
  amountFrom: number
  amountTo: number
  currencyTo: CurrencyCode
  currencyFrom: CurrencyCode
  paymentSpeed: PaymentSpeedValue
  rewardAction: RewardActionValue
  fixedSide: FixedSideValue
}): MultiCurrencyPaymentQuoteInput => {
  return {
    quote: {
      amount: fixedSide === 'buy' ? amountTo : amountFrom,
      buyCurrency: GQLCurrencyCode[currencyTo],
      sellCurrency: GQLCurrencyCode[currencyFrom],
      fixedSide: fixedSide === 'buy' ? ConversionFixedSide.BUY : ConversionFixedSide.SELL,
      isEarningRewards: rewardAction === 'earn',
    },
    priority: GQLPaymentPriority[paymentSpeed.toUpperCase() as Uppercase<typeof paymentSpeed>],
  }
}
