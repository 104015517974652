import { Tooltip as ChakraTooltip, TooltipProps } from '@chakra-ui/react'
import React from 'react'

const Tooltip = (props: TooltipProps) => {
  return (
    <ChakraTooltip
      hasArrow
      placement="bottom"
      bg="black.300"
      borderRadius="16px"
      padding="11px 14px"
      maxWidth="15rem"
      gutter={14}
      boxShadow="0px 5px 19px 10px #0A0A0A0D, 0px 14px 40px -12px #0000004D"
      {...props}
    />
  )
}

export default Tooltip
