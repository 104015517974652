import { createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'

const plaidAccountSlice = createSlice({
  name: 'plaidAccount',
  initialState: {
    waitingForPlaidSuccess: false,
    connectingPlaid: false,
    relinkingAccounts: false,
    successfulRelink: false,
  },
  reducers: {
    startLinkingPlaid(state) {
      state.waitingForPlaidSuccess = true
      state.connectingPlaid = true
    },
    handlePlaidModalClosed(state) {
      state.connectingPlaid = false
    },
    startRelinkingAccounts(state) {
      state.waitingForPlaidSuccess = false
      state.relinkingAccounts = true
    },
    finishRelinkingAcounts(state) {
      state.relinkingAccounts = false
      state.successfulRelink = true
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => {
      state = { ...action.payload.linkedAccounts }
      return state
    },
  },
})

export const { startLinkingPlaid, handlePlaidModalClosed, startRelinkingAccounts, finishRelinkingAcounts } =
  plaidAccountSlice.actions
export default plaidAccountSlice
