import { chakra } from '@chakra-ui/react'
import React, { ComponentProps } from 'react'

const Pencil = (props?: ComponentProps<typeof chakra.svg>) => {
  return (
    <chakra.svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.5 6.5L17.5 9.5M16.5714 3.80953L4.33295 16.048C4.05375 16.3272 3.86344 16.6828 3.786 17.07L3 21L6.93002 20.214C7.3172 20.1366 7.6728 19.9463 7.952 19.6671L20.1905 7.42857C21.1898 6.4292 21.1898 4.8089 20.1905 3.80953C19.1911 2.81015 17.5708 2.81015 16.5714 3.80953Z"
        stroke="currentColor"
        strokeWidth="2"
      />
    </chakra.svg>
  )
}

export default Pencil
