import { InputGroup, NumberInput as ChakraNumberInput, NumberInputField } from '@chakra-ui/react'
import React, { forwardRef } from 'react'
import { isDefined } from 'utils'

type NumberInputProps = React.ComponentProps<typeof NumberInputField> &
  Pick<
    React.ComponentProps<typeof ChakraNumberInput>,
    'precision' | 'variant' | 'isInvalid' | 'max' | 'min' | 'value' | 'size'
  > & {
    leftElement?: React.ReactNode
    rightElement?: React.ReactNode
  }

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  ({ rightElement, leftElement, variant, isInvalid, max, min, value, size, ...props }, ref) => {
    const pl = isDefined(leftElement) ? '10' : 'auto'
    const pr = isDefined(rightElement) ? '10' : 'auto'

    return (
      <ChakraNumberInput
        isInvalid={isInvalid}
        variant={variant ?? 'new'}
        size={size ?? 'lg'}
        max={max}
        min={min}
        value={value}
      >
        <InputGroup>
          {isDefined(leftElement) && leftElement}
          <NumberInputField ref={ref} {...props} pl={pl} pr={pr} />
          {isDefined(rightElement) && rightElement}
        </InputGroup>
      </ChakraNumberInput>
    )
  }
)
