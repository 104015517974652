import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { UserType } from 'db-schema'

import { ACCOUNTANT_PAGES, CARD_HOLDER_PAGES, PageType, SETTINGS_PAGES } from '@/src/constants/pages'
import { checkHasRequiredFeatureFlags } from '@/src/utils/misc'
import { InternalUser } from '@/types/next-auth'

export const setSettingsPages = createAsyncThunk('settings/setSettingsPages', async (user: InternalUser) => {
  // Return pages based on user type
  if (user?.userType === UserType.CARDHOLDER) {
    return CARD_HOLDER_PAGES.filter(({ route }) => route.includes('settings'))
  }

  if (user?.userType === UserType.ACCOUNTANT) {
    return ACCOUNTANT_PAGES.filter(({ route }) => route.includes('settings'))
  }

  // Filter pages based on enabled feature flags
  const pages = await Promise.all(
    SETTINGS_PAGES.map(async (page) => {
      const hasRequiredFeatureFlags = await checkHasRequiredFeatureFlags(user, page)

      if (hasRequiredFeatureFlags) {
        return page
      }
    })
  )

  const filteredPages = pages.filter((page) => page !== undefined) as PageType[]

  return filteredPages
})

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    pages: [] as PageType[],
    ready: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setSettingsPages.fulfilled, (state, action) => {
      state.pages = action.payload
      state.ready = true
    })
  },
})

// export const {} = settingsSlice.actions
export default settingsSlice
