import '@datadog/browser-logs/bundle/datadog-logs'

import { datadogLogs } from '@datadog/browser-logs'
import getConfig from 'next/config'

import { NextConfig } from '@/types/helpers'

const { publicRuntimeConfig } = getConfig() as NextConfig

export const initLogger = (env?: string) => {
  datadogLogs.init({
    clientToken: publicRuntimeConfig.dataDogClientToken,
    site: publicRuntimeConfig.dataDogSite,
    env: env ?? 'local',
    service: 'web',
    forwardErrorsToLogs: true,
    forwardConsoleLogs: 'all',
    sessionSampleRate: 100,
  })
}

export const logger = datadogLogs.logger
