import moment, { Moment } from 'moment'
import { useMemo, useRef, useState } from 'react'
import { RepaymentFrequency } from 'services/src/capital/types'
import { Box, Button, Checkbox, Divider, Flex, Icon, Link, Text } from 'ui-lib'
import { isDefined } from 'utils'

import {
  CalculateRepaymentDates,
  CalculateRepaymentPlan,
  useCalculateRepaymentDatesQuery,
  useCalculateRepaymentPlanQuery,
} from '@/gql'
import Body from '@/src/components/molecules/BoxWithHeader/Body'
import BoxWithHeader from '@/src/components/molecules/BoxWithHeader/BoxWithHeader'
import Header from '@/src/components/molecules/BoxWithHeader/Header'
import { SEGMENT_EVENTS } from '@/src/constants/segmentEvents'
import { FINANCING_CHANNEL, SEGMENT_PAGE_VISITED } from '@/src/constants/segmentVisitedPages'
import { useInternalUser } from '@/src/hooks/misc'
import { useSegmentPageVisited } from '@/src/hooks/useSegmentPageVisited'
import { useSegmentTrackEvents } from '@/src/hooks/useSegmentTrackEvents'
import { getRepaymentDurationByRepaymentFrequency, isUnderwritingPeriodExpired } from '@/src/utils/capital'

type ConfirmFinancingPlanSectionProps = {
  startDate?: Date
  amount: string
  repaymentInstallments: number
  apr: number
  email: string
  isCreatingLoan: boolean
  repaymentFrequency: RepaymentFrequency
  onGetCapitalClicked?: () => void
  onBack?: () => void
  reason: string
  feeDiscountRate: number
}

export const getPaymentDueTextByRepaymentFrequency = (startDate: Moment, repaymentFrequency: RepaymentFrequency) => {
  if (repaymentFrequency === RepaymentFrequency.DAILY) {
    return 'Every business day (Monday to Friday)'
  }
  if (repaymentFrequency === RepaymentFrequency.WEEKLY) {
    return `${startDate.format('dddd')} of each week`
  }
  return `${startDate.date()} of each month`
}

const getAmountTobePaidTextByRepaymentFrequency = (repaymentFrequency: RepaymentFrequency) => {
  const amountToBePaidText = 'Amount to be paid each'
  if (repaymentFrequency === RepaymentFrequency.DAILY) {
    return `${amountToBePaidText} business day`
  }
  if (repaymentFrequency === RepaymentFrequency.WEEKLY) {
    return `${amountToBePaidText} week`
  }
  return `${amountToBePaidText} month`
}

const ConfirmFinancingPlanSection = ({
  startDate,
  onGetCapitalClicked,
  amount,
  repaymentInstallments,
  repaymentFrequency,
  apr,
  email,
  isCreatingLoan,
  onBack,
  reason,
  feeDiscountRate,
}: ConfirmFinancingPlanSectionProps) => {
  const trackEvent = useSegmentTrackEvents()
  const trackPageVisited = useSegmentPageVisited()
  const [checkedAgreements, setCheckedAgreements] = useState([false, false])
  const isGetCapitalButtonClickable = useMemo(() => checkedAgreements.every(Boolean), [checkedAgreements])
  const startDateRef = useRef(moment(startDate).toISOString())
  const [plan, setPlan] = useState<CalculateRepaymentPlan | null>()
  const [dates, setDates] = useState<CalculateRepaymentDates | null>()
  const user = useInternalUser()
  const underwrittenDate = user?.business?.underwrittenAt

  useCalculateRepaymentPlanQuery({
    variables: {
      apr,
      repaymentFrequency,
      repaymentInstallments: repaymentInstallments,
      fundingAmount: Number(amount),
      feeDiscountRate: feeDiscountRate,
    },
    onCompleted: (data) => {
      setPlan(data.calculateRepaymentPlan)
    },
  })

  useCalculateRepaymentDatesQuery({
    variables: {
      repaymentFrequency,
      repaymentInstallments,
      startDate: startDateRef.current,
    },
    onCompleted(data) {
      setDates(data.calculateRepaymentDates)
    },
  })

  const isDiscountApplied = feeDiscountRate > 0
  const { first, last } = dates ?? {}

  const { principalAmount, totalFee, totalRepaymentAmount, amountPerRemainingPayments, preDiscountTotalFee } =
    plan ?? {}

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      maxWidth="500px"
      gap="48px"
      paddingBottom="48px"
    >
      <Text width="100%" textStyle="headline-3" color="text-primary">
        Confirm your financing plan
      </Text>
      <BoxWithHeader width="100%">
        <Header>
          <Text textStyle="title-lg" color="text-primary">
            Financing plan
          </Text>
        </Header>
        <Body>
          <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" gap="12px">
            <Row name="Financing amount" value={principalAmount} />
            <Row
              name="Total fee"
              value={
                isDiscountApplied ? (
                  <>
                    <Text as="del" color="text-soft">
                      {preDiscountTotalFee}
                    </Text>{' '}
                    <Text as="span" color="text-success">
                      {totalFee}
                    </Text>
                  </>
                ) : (
                  totalFee
                )
              }
            />
            <Row
              name="Total amount to be paid"
              value={
                <Flex>
                  <Icon display={isDiscountApplied ? 'block' : 'none'} icon="tag-fill" variant="success" />
                  {totalRepaymentAmount}
                </Flex>
              }
            />
            <Row
              name={getAmountTobePaidTextByRepaymentFrequency(repaymentFrequency)}
              value={amountPerRemainingPayments}
            />
            <Divider />
            <Row
              name="Payments due on"
              value={getPaymentDueTextByRepaymentFrequency(moment(first), repaymentFrequency)}
            />
            <Row
              name="Term length"
              value={getRepaymentDurationByRepaymentFrequency(repaymentInstallments, repaymentFrequency)}
            />
            <Row name="Final payment date" value={moment(last).format('ll')} />
            {reason !== '' && isDefined(reason) && (
              <>
                <Divider />
                <Row name="Purpose of funding" value={reason} />
              </>
            )}
          </Flex>
        </Body>
      </BoxWithHeader>
      <Flex flexDirection="column" width="100%">
        <Text textStyle="title-lg" color="text-primary" mb="24px">
          Check the boxes to sign and confirm your plan
        </Text>
        <Flex width="100%" gap="12px" mb="12px">
          <Checkbox
            sx={{
              '& > span.chakra-checkbox__control': {
                mt: 1,
                alignSelf: 'flex-start',
              },
            }}
            size="lg"
            isChecked={checkedAgreements[0]}
            onChange={(e) => {
              setCheckedAgreements([e.target.checked, checkedAgreements[1]])
              trackEvent({
                type: SEGMENT_EVENTS.FINANCING_APP_FINANCING_AGREEMENT_TOGGLED,
                payload: {
                  team: 'Capital',
                  state: e.target.checked,
                  financingChannel: FINANCING_CHANNEL.CAPITAL,
                },
              })
            }}
          >
            <Text textStyle="title-xs">
              I hereby confirm and agree to sign the{' '}
              <Link
                href="/agreements/finance-agreement.pdf"
                target="_blank"
                textStyle="title-xs"
                color="accent.500"
                onClick={() => {
                  trackEvent({
                    type: SEGMENT_EVENTS.FINANCING_APP_FINANCING_AGREEMENT_DOWNLOADED,
                    payload: {
                      team: 'Capital',
                      financingChannel: FINANCING_CHANNEL.CAPITAL,
                    },
                  })
                }}
              >
                Finance Agreement.
              </Link>
            </Text>
          </Checkbox>
        </Flex>
        <Flex width="100%" gap="12px" mb="12px" alignItems="start">
          <Checkbox
            sx={{
              '& > span.chakra-checkbox__control': {
                mt: 1,
                alignSelf: 'flex-start',
              },
            }}
            size="lg"
            isChecked={checkedAgreements[1]}
            onChange={(e) => {
              setCheckedAgreements([checkedAgreements[0], e.target.checked])
              trackEvent({
                type: SEGMENT_EVENTS.FINANCING_APP_PAD_AGREEMENT_TOGGLED,
                payload: {
                  team: 'Capital',
                  state: e.target.checked,
                  financingChannel: FINANCING_CHANNEL.CAPITAL,
                },
              })
            }}
          >
            <Text textStyle="title-xs">
              I hereby confirm and agree to sign the{' '}
              <Link
                href="/agreements/pre-authorized-debit-agreement.pdf"
                target="_blank"
                textStyle="title-xs"
                color="accent.500"
                onClick={() => {
                  trackEvent({
                    type: SEGMENT_EVENTS.FINANCING_APP_PAD_AGREEMENT_DOWNLOADED,
                    payload: {
                      team: 'Capital',
                      financingChannel: FINANCING_CHANNEL.CAPITAL,
                    },
                  })
                }}
              >
                Pre Authorized Debit Agreement.
              </Link>
            </Text>
          </Checkbox>
        </Flex>
      </Flex>
      <Box width="100%">
        <Flex width="100%" gap={4}>
          <Button onClick={onBack} width="100%" size="md" variant="outline" type="submit">
            Back
          </Button>
          <Button
            size="md"
            width="100%"
            onClick={() => {
              onGetCapitalClicked?.()
              trackPageVisited({
                type: SEGMENT_PAGE_VISITED.FINANCING_DETAILS_CONFIRMED,
                payload: {
                  team: 'Capital',
                  page: 'Financing Details Confirmation',
                  financingChannel: FINANCING_CHANNEL.CAPITAL,
                  version: 'v1',
                  capital_limit_expired: isUnderwritingPeriodExpired(underwrittenDate),
                },
              })
            }}
            isDisabled={!isGetCapitalButtonClickable || isCreatingLoan}
          >
            {!isCreatingLoan ? `Get Capital` : 'Working...'}
          </Button>
        </Flex>
        <Flex mt={3} width="100%" justify="start">
          <Text textStyle="text.sm.regular" color="_primary.500">
            Copy of the agreements will be sent to {email}
          </Text>
        </Flex>
      </Box>
    </Flex>
  )
}

type Row = {
  name: string
  value: React.ReactNode
}
const Row = ({ name, value }: Row) => {
  return (
    <Flex width="100%" justifyContent="space-between">
      <Text textStyle="paragraph-md" color="text-secondary">
        {name}
      </Text>
      <Text textAlign="end" maxW="200px" as="div" textStyle="paragraph-lg" color="text-primary">
        {value}
      </Text>
    </Flex>
  )
}

export default ConfirmFinancingPlanSection
