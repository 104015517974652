import { mode } from '@chakra-ui/theme-tools'

// TODO: replace color references with the equivalent ones when switching to the new theme
export const styles = {
  global: (props: any) => ({
    body: {
      bg: mode('white', 'primary.500')(props),
    },
    '.react-datepicker__tab-loop': {
      position: 'absolute',
    },
    '.react-datepicker-popper.react-datepicker-popper': {
      zIndex: '4',
    },

    '.MuiTextField-root .MuiInputLabel-outlined': {
      transform: 'translate(10px, 16px)',
      fontSize: '14px',
    },

    '.MuiTextField-root .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(15px, 6px)',
      fontSize: '8px',
    },

    '.MuiTextField-root .MuiOutlinedInput-root': {
      fontWeight: 500,
    },

    '.MuiTextField-root .MuiOutlinedInput-root.Mui-focused': {
      outlineStyle: 'solid',
      outlineColor: '#E7F1FE',
      outlineWidth: '4px',
    },

    '.MuiTextField-root .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#2485F9',
      borderWidth: '2px',
    },

    '.MuiAutocomplete-root .MuiInputLabel-outlined': {
      transform: 'translate(10px, 16px)',
      fontSize: '14px',
    },

    '.MuiAutocomplete-root .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(15px, 6px)',
      fontSize: '8px',
    },
  }),
}
