import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers([
  'stepper',
  'step',
  'title',
  'description',
  'indicator',
  'separator',
  'icon',
  'number',
])

const separator = defineStyle({
  '&[data-status="complete"]': {
    background: 'button.disabled',
  },
  '&[data-status="active"]': {
    background: 'black.300',
  },
  '&[data-status="incomplete"]': {
    background: 'button.disabled',
  },
})

const baseStyle = definePartsStyle({
  separator,
  title: {
    fontSize: 'paragraph.md',
  },
})

export const Stepper = defineMultiStyleConfig({
  baseStyle,
  variants: {
    new: {
      indicator: {
        backgroundColor: 'red',
        boxSize: '5',
        fontSize: 'paragraph.md',
        fontWeight: 'semibold',
        '&[data-status="complete"]': {
          background: 'text-secondary',
        },
        border: 'none',
        '&[data-status="active"]': {
          background: 'text-primary',
        },
        '&[data-status="incomplete"]': {
          background: 'text-disabled',
        },
      },
      title: {
        '&[data-status="complete"]': {
          textColor: 'text-secondary',
        },
        '&[data-status="active"]': {
          textColor: 'text-primary',
        },
        '&[data-status="incomplete"]': {
          textColor: 'text-disabled',
        },
      },
    },
    form: {
      stepper: {
        gap: '2',
      },
      indicator: {
        '&[data-status="complete"]': {
          background: 'icon-active',
        },
        '&[data-status="active"]': {
          background: 'bg-primary',
        },
        '&[data-status="incomplete"]': {
          borderColor: 'icon-disabled',
          background: 'bg-primary',
        },
      },
      separator: {
        _horizontal: {
          mx: 0,
        },
        '&[data-status="complete"]': {
          background: 'icon-info',
        },
        '&[data-status="active"]': {
          background: 'icon-disabled',
        },
        '&[data-status="incomplete"]': {
          background: 'icon-disabled',
        },
      },
    },
  },
})
