import moment from 'moment'
import { useSession } from 'next-auth/react'
import { Icon, Tag } from 'ui-lib'

import { StatsigFeatureGate, useFeatureGate } from '@/src/contexts/misc'

export const NewTag = () => {
  const isOriginationFeeActive = useFeatureGate(StatsigFeatureGate.CAPITAL_ORIGINATION_FEE)
  const { data: session } = useSession()
  const isRecentOnboarding = moment().diff(session?.user.internal?.onboardedAt, 'days') <= 30

  if (!isOriginationFeeActive || !isRecentOnboarding) {
    return null
  }

  return (
    <Tag variant="blue" size="sm" rounded="full" fontWeight="semibold">
      New <Icon icon="sparkles" w="3" h="3" ml="1" variant="inverse" />
    </Tag>
  )
}
