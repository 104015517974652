import { ConstToUnion } from 'utils/types'

export const KeepCookie = {
  BANK_TRANSFER_STATUS: 'BANK_TRANSFER_STATUS',
  REDIRECTION_URL: 'REDIRECTION_URL',
  AUTH_ACTION: 'AUTH_ACTION',
  ANALYTICS_UTM: 'ANALYTICS_UTM',
  RECAPTCHA_TOKEN: 'RECAPTCHA_TOKEN',
  HUBSPOTUTK: 'hubspotutk', // This cookie keeps track of a visitor's identity. It is passed to HubSpot on form submission and used when deduplicating contacts.
  HUBSPOT__HSTC: '__hstc', // The main cookie for tracking visitors. It contains the domain, hubspotutk, initial timestamp (first visit), last timestamp (last visit), current timestamp (this visit), and session number (increments for each subsequent session).
  FAST_TRACK: 'FAST_TRACK',
  REFERRAL_CODE: 'REFERRAL_CODE', // used during google sign up
  RECOVERY_TOKEN: 'RECOVERY_TOKEN',
  MFA_CHALLENGE: 'MFA_CHALLENGE',
  REMEMBER_ME_MFA: 'REMEMBER_ME_MFA',
} as const
export type KeepCookie = ConstToUnion<typeof KeepCookie>

export const MFA_CHALLENGE_MAX_AGE = 60 * 10

export const REMEMBER_ME_MFA_MAX_AGE = 60 * 60 * 24 * 30

export const REMEMBER_ME_MFA_MAX_AGE_DAYS = REMEMBER_ME_MFA_MAX_AGE / (60 * 60 * 24)
