import { isDefined } from 'utils'

import { ErrorTypes, FileType } from './types'

export const humanizeFileSize = (size: number) => {
  return (size / 1024 / 1024).toFixed(2) + 'MB'
}

export const getErrorType = ({
  accept,
  currentFiles,
  newFiles,
  maxFiles,
  maxFileSize,
}: {
  accept: FileType[]
  currentFiles: File[]
  newFiles: File[]
  maxFiles: number
  maxFileSize?: number
}): ErrorTypes | null => {
  const isDuplicated = newFiles.some((file) => currentFiles.some((f) => f.name === file.name))

  const exceededMaxFiles = currentFiles.length + newFiles.length > maxFiles

  const isOversized = isDefined(maxFileSize) && currentFiles.some((file) => file.size > maxFileSize)

  const acceptedFiles = accept.map((type) => FileType[type]) as string[]
  const hasAnyFileType = acceptedFiles.includes(FileType.any)
  const isInvalidType = !hasAnyFileType && newFiles.some((file) => !acceptedFiles.includes(file.type))

  switch (true) {
    case isDuplicated:
      return ErrorTypes.DUPLICATED
    case exceededMaxFiles:
      return ErrorTypes.EXCEEDED_MAX_FILES
    case isOversized:
      return ErrorTypes.OVERSIZED
    case isInvalidType:
      return ErrorTypes.INVALID_TYPE
    default:
      return null
  }
}

export const getErrorMessage = (
  errorType: ErrorTypes | null,
  params: {
    maxFiles: number
    maxFileSize: number
  }
): string | null => {
  const errorMessages = {
    [ErrorTypes.DUPLICATED]: 'File with the same name already exists.',
    [ErrorTypes.EXCEEDED_MAX_FILES]: `Maximum number of files exceeded. ${params.maxFiles} files allowed.`,
    [ErrorTypes.OVERSIZED]: `File size exceeds the maximum limit. ${humanizeFileSize(params.maxFileSize)}`,
    [ErrorTypes.INVALID_TYPE]: 'Invalid file type.',
  }

  if (isDefined(errorType)) {
    return errorMessages[errorType]
  }

  return null
}
