import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const enrollmentSlice = createSlice({
  name: 'enrollment',
  initialState: {
    enrollment: {
      repaymentFrequency: '',
      repaymentInstallments: 0,
      enrollmentEnable: true,
      repaymentInstallmentsFormatted: '',
    },
  },
  reducers: {
    storeEnrollment: (
      state,
      action: PayloadAction<{
        repaymentFrequency: string
        repaymentInstallments: number
        enrollmentEnable: boolean
        repaymentInstallmentsFormatted: string
      }>
    ) => {
      state.enrollment = action.payload
    },
    clearEnrollment: (state) => {
      state.enrollment = {
        repaymentFrequency: '',
        repaymentInstallments: 0,
        enrollmentEnable: true,
        repaymentInstallmentsFormatted: '',
      }
    },
  },
})

export const { storeEnrollment, clearEnrollment } = enrollmentSlice.actions

export default enrollmentSlice
