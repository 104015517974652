import { isDefined } from './chunk-GVNWCKLH.mjs';

// obfuscateObject.ts
var SENSITIVE = [
  "password",
  "secret",
  "authorization",
  "auth",
  "token",
  "apiKey",
  "cookie",
  "accessToken",
  "session",
  "license"
];
var TRASH_KEYWORDS = ["_events"];
var MAX_STRING_LENGTH = 500;
var MAX_OBJECT_KEYS = 50;
var isSensitive = (key) => typeof key === "string" && SENSITIVE.find((sensitive) => key.toLowerCase().includes(sensitive.toLowerCase())) !== void 0;
var isTrash = (key) => typeof key === "string" && TRASH_KEYWORDS.some((keyword) => key.toLowerCase().includes(keyword));
var truncateValue = (value) => {
  if (typeof value === "string") {
    return value.length > MAX_STRING_LENGTH ? `${value.slice(0, MAX_STRING_LENGTH)}... (truncated)` : value;
  }
  return value;
};
var obfuscateValue = () => {
  return "*".repeat(10);
};
var obfuscate = (obj, depth = 0, maxDepth = 10, processedKeys = 0, seen = /* @__PURE__ */ new Set()) => {
  if (depth > maxDepth) {
    return "MAX DEPTH REACHED (truncated)";
  }
  if (Array.isArray(obj)) {
    return obj.map((item) => obfuscate(item, depth + 1, maxDepth, processedKeys, seen));
  } else if (isDefined(obj) && typeof obj === "object") {
    if (seen.has(obj)) {
      return "[Circular]";
    }
    seen.add(obj);
    const keys = [...Object.keys(obj), ...Object.getOwnPropertySymbols(obj)];
    return keys.reduce((acc, key, index) => {
      let value = obj[key];
      if (processedKeys + index >= MAX_OBJECT_KEYS) {
        acc["MAX OBJECT KEYS REACHED"] = "(truncated)";
        return acc;
      }
      if (isTrash(key)) {
        value = "trash (truncated)";
      } else if (isSensitive(key)) {
        if (Array.isArray(value)) {
          value = value.map(() => obfuscateValue());
        } else {
          value = obfuscateValue();
        }
      } else if (typeof value === "string") {
        value = truncateValue(value);
      } else {
        value = obfuscate(value, depth + 1, maxDepth, processedKeys + index, seen);
      }
      acc[key] = value;
      return acc;
    }, {});
  } else if (typeof obj === "string") {
    return truncateValue(obj);
  }
  return obj;
};
var obfuscateObject = (obj) => {
  return obfuscate(obj);
};

export { SENSITIVE, obfuscate, obfuscateObject };
