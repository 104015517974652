import { round } from 'lodash'
import { isDefined } from 'ui-lib'

import { NullOrUndefined } from '@/types'
import { RepaymentFrequency } from '@/types/capital'
import { RepaymentFrequency as RepaymentFrequencyEnum } from '@/types/capital/enums'

import { date } from './date'

export function mapMonthsToWeeks(months: number) {
  if (months <= 3) {
    return round(months * 4)
  }
  return round(months * 4.3333, 0)
}

export const getRepaymentDurationByRepaymentFrequency = (
  repaymentInstallments: number,
  repaymentFrequency: RepaymentFrequency
) => {
  if (RepaymentFrequencyEnum.DAILY === repaymentFrequency) {
    return `${repaymentInstallments} business days `
  }
  if (RepaymentFrequencyEnum.WEEKLY === repaymentFrequency) {
    return `${repaymentInstallments} weeks`
  }
  return `${repaymentInstallments} month`
}

export const isUnderwritingPeriodExpired = (underwrittenAt: Date | NullOrUndefined) => {
  if (!isDefined(underwrittenAt)) {
    return false
  }

  return date().diff(underwrittenAt, 'days') > 30
}
