import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { reloadSession } from '@/src/utils/session'

export const useReloadSessionOnRouteChange = () => {
  const router = useRouter()

  useEffect(() => {
    reloadSession()
  }, [router.pathname])
}
