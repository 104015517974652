import ConfettiExplosion, { ConfettiProps } from 'react-confetti-explosion'

type CustomConfettiProps = ConfettiProps & {
  showConfetti: boolean
}

export const Confetti = ({ showConfetti, ...props }: CustomConfettiProps) => {
  return showConfetti ? (
    <ConfettiExplosion duration={5000} force={0.3} width={2600} height={5000} particleCount={250} {...props} />
  ) : (
    <></>
  )
}
