import { FundingWithInstallments } from '@/gql'
import { PlainStatementExtension } from '@/types/capital'

export const SEGMENT_PAGE_VISITED = {
  // Capital
  CAPITAL_HOME_PAGE_VISITED: 'Viewed Capital Page',
  // Underwriting v2
  BANK_CONNECTION_STARTED: 'Bank Connection Started',
  // Pricing model v2
  FINANCING_PURPOSE_ADDED: 'Financing Purpose Added',
  FINANCING_REQUEST_SUCCESSFUL: 'Financing Request Successful',
  FINANCING_APP_EXITED: 'Financing Application Exited',
  // Pricing model v1
  FINANCING_APP_STARTED: 'Financing Application Started',
  FINANCING_APP_EXITED_INITIATED: 'Financing Application Exited Initiated',
  FINANCING_APP_EXITED_RESUMED: 'Financing Application Exited Resumed',
  FINANCING_APP_PLAN_SELECTED: 'Financing Application Plan Selected',
  FINANCING_APP_PLAN_CONFIRMED: 'Financing Application Plan Confirmed',
  FINANCING_LANDING_VIEWED: 'Financing Landing Viewed',
  FINANCING_DETAILS_CONFIRMED: 'Financing Details Confirmed',
  LOAN_DETAILS_VIEWED: 'Loan Details Viewed',
  ACTIVE_LOANS_VIEWED: 'Active Loans Viewed',
  STATEMENT_EXTENSIONS_DETAILS_VIEWED: 'Statement Extensions Details Viewed',
  SELECTED_PAYMENT_OPTION: 'Selected Payment Option',
  ACTIVE_STATEMENT_EXTENSIONS_VIEWED: 'Active Statement Extensions Viewed',
  STATEMENT_EXTENSION_VIEW_PAGE: 'Viewed Statement Extension Page',
} as const

export enum FINANCING_CHANNEL {
  STATEMENT_EXTENSION = 'STATEMENT_EXTENSION',
  CAPITAL = 'CAPITAL',
}

type LoanTracker = Omit<Partial<FundingWithInstallments>, 'totalFee'> & {
  totalFee: number
}

type BasicTrackPage<T, P> = {
  type: T
  payload: {
    team: string
    page: string
  } & P
}

export type ActiveLoansViewedEvent = BasicTrackPage<
  typeof SEGMENT_PAGE_VISITED.ACTIVE_LOANS_VIEWED,
  {
    totalCreditLimit: number
    availableCredit: number
    loans: LoanTracker[]
    loadTime?: number
  }
>

export type StatementExtensionsViewedEvent = BasicTrackPage<
  typeof SEGMENT_PAGE_VISITED.ACTIVE_STATEMENT_EXTENSIONS_VIEWED,
  {
    active: Omit<PlainStatementExtension, 'installments'>[]
    closed: Omit<PlainStatementExtension, 'installments'>[]
  }
>

export type FinancialAppPlanEvent = BasicTrackPage<
  typeof SEGMENT_PAGE_VISITED.FINANCING_APP_PLAN_SELECTED | typeof SEGMENT_PAGE_VISITED.FINANCING_APP_PLAN_CONFIRMED,
  | {
      repaymentInstallments: number
      repaymentsDuration: string
      payment: string
      repaymentFrequency: string
      financingChannel: FINANCING_CHANNEL
      capital_limit_expired: boolean
      version?: 'v1'
    }
  | {
      financing_amount: string
      term_length: string
      capital_limit_expired: boolean
      version: 'v2'
    }
>

export type FinancialAppEvent = BasicTrackPage<
  | typeof SEGMENT_PAGE_VISITED.FINANCING_APP_EXITED
  | typeof SEGMENT_PAGE_VISITED.FINANCING_APP_EXITED_INITIATED
  | typeof SEGMENT_PAGE_VISITED.FINANCING_APP_EXITED_RESUMED
  | typeof SEGMENT_PAGE_VISITED.FINANCING_APP_STARTED
  | typeof SEGMENT_PAGE_VISITED.BANK_CONNECTION_STARTED
  | typeof SEGMENT_PAGE_VISITED.FINANCING_LANDING_VIEWED
  | typeof SEGMENT_PAGE_VISITED.FINANCING_DETAILS_CONFIRMED,
  {
    loadTime?: number
    capital_limit_expired: boolean
    financingChannel?: FINANCING_CHANNEL
    request_type?: 'new' | 'recurring'
    version?: 'v1' | 'v2'
  }
>

export type LoanDetailsViewedEvent = BasicTrackPage<
  typeof SEGMENT_PAGE_VISITED.LOAN_DETAILS_VIEWED,
  { loan: LoanTracker }
>

export type StatementExtensionDetailsViewedEvent = BasicTrackPage<
  typeof SEGMENT_PAGE_VISITED.STATEMENT_EXTENSIONS_DETAILS_VIEWED,
  { extension: PlainStatementExtension }
>

export type SelectedPaymentOptionEvent = BasicTrackPage<
  typeof SEGMENT_PAGE_VISITED.SELECTED_PAYMENT_OPTION,
  { paymentOption: string }
>

export type StatementExtensionViewPage = BasicTrackPage<
  typeof SEGMENT_PAGE_VISITED.STATEMENT_EXTENSION_VIEW_PAGE,
  {
    auto_extension: boolean | string
    extension_outstanding_balance: string
    existing_extensions: string[]
  }
>

export type ViewedCapitalHomePageEvent = BasicTrackPage<
  typeof SEGMENT_PAGE_VISITED.CAPITAL_HOME_PAGE_VISITED,
  {
    total_credit_limit: string
    version: 'v1' | 'v2'
  }
>

export type NewCapitalPurposeAddedEvent = BasicTrackPage<
  typeof SEGMENT_PAGE_VISITED.FINANCING_PURPOSE_ADDED,
  {
    purpose: string
    version: 'v2'
    capital_limit_expired: boolean
  }
>

export type CompletedFinancingRequestEvent = BasicTrackPage<
  typeof SEGMENT_PAGE_VISITED.FINANCING_REQUEST_SUCCESSFUL,
  {
    version: 'v2'
    capital_limit_expired: boolean
  }
>

export type TrackablePageEvent =
  | ActiveLoansViewedEvent
  | FinancialAppPlanEvent
  | FinancialAppEvent
  | LoanDetailsViewedEvent
  | StatementExtensionsViewedEvent
  | StatementExtensionDetailsViewedEvent
  | SelectedPaymentOptionEvent
  | StatementExtensionViewPage
  | ViewedCapitalHomePageEvent
  | NewCapitalPurposeAddedEvent
  | CompletedFinancingRequestEvent
