import { gql, useMutation } from '@apollo/client'

import {
  SingleCurrencyPaymentQuote,
  SingleCurrencyPaymentQuoteVariables,
} from '@/src/graphql/generated/SingleCurrencyPaymentQuote'

export const SINGLE_CURRENCY_PAYMENT_QUOTE_MUTATION = gql`
  mutation SingleCurrencyPaymentQuote($body: SameCurrencyPaymentQuoteInput!) {
    createQuoteForSingleCurrencyPayment(body: $body) {
      quote {
        id
        clientRate
        createdAt
      }
      feesAndRewards {
        internationalBankingFee {
          amountAsString
          amountAsNumber
          currency
        }
        finalAmount {
          amountAsString
          amountAsNumber
          currency
        }
        balanceAfterwards {
          amountAsString
          amountAsNumber
          currency
        }
        savingsAmount {
          amountAsString
          amountAsNumber
          currency
        }
        rewards {
          earnedRewardsAmount {
            amountAsString
            amountAsNumber
            currency
          }
          previousRewardsBalance {
            amountAsString
            amountAsNumber
            currency
          }
          afterTransactionRewardsTotalPoints {
            amountAsString
            amountAsNumber
            currency
          }
          earnedRewardsAsPoints {
            amountAsString
            amountAsNumber
            currency
          }
        }
      }
    }
  }
`

export const useCreateQuoteForSingleCurrencyPayment = () =>
  useMutation<SingleCurrencyPaymentQuote, SingleCurrencyPaymentQuoteVariables>(SINGLE_CURRENCY_PAYMENT_QUOTE_MUTATION)
