import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { analytics } from '@/src/utils/misc'

export const usePageSegmentAnalytics = () => {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      analytics.page(
        {
          page: url,
        },
        undefined,
        {
          queryParams: router.query,
        }
      )
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => router.events.off('routeChangeComplete', handleRouteChange)
  }, [router.events, router.query])
}
