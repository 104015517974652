import { getSession } from 'next-auth/react'
import { useEffect } from 'react'

import isDefined from '@/src/utils/dataShaping/isDefined'

export const useDebugAccessToken = () => {
  useEffect(() => {
    if (window.location.href.includes('localhost')) {
      const debug = async () => {
        const session = await getSession()
        const token = session?.accessToken as string
        if (isDefined(token)) {
          try {
            await navigator.clipboard.writeText(token)
            console.log('The token is now on your clipboard')
          } catch (error) {
            console.warn('Could not copy token to clipboard', { error })
          }
        } else {
          console.error('No active user, login to get a token')
        }
        return token
      }
      window.debug = () => {
        debug()
      }
    }
  }, [])
}
