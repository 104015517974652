import { ConstToUnion } from 'utils/types'

import { PageType } from './pages'

/**
 * Generates a list of pages for the invitation flow based on the invitation token.
 *
 * @param invitationToken Unique token for the invitation
 * @returns A list of pages for the invitation flow
 */
export const getInvitationPages = (invitationToken: string, kyc: boolean): PageType[] => {
  const initialPages: PageType[] = [
    {
      section: 'Invitation - Card Holder Agreement',
      route: `/employee-invitation/${invitationToken}`,
      page: 'employee-invitation-cha',
    },
    {
      section: 'Invitation - Set Password',
      route: `/employee-invitation/${invitationToken}/set-password`,
      page: 'employee-invitation-set-password',
    },
  ]

  if (kyc) {
    return [
      ...initialPages,
      {
        section: 'Invitation - Personal Details',
        route: `/employee-invitation/${invitationToken}/personal-details`,
        page: 'employee-invitation-personal-details',
      },
      {
        section: 'Invitation - Home Address',
        route: `/employee-invitation/${invitationToken}/home-address`,
        page: 'employee-invitation-home-address',
      },
      {
        section: 'Invitation - ID Validation',
        route: `/employee-invitation/${invitationToken}/id-validation`,
        page: 'employee-invitation-id-validation',
      },
    ]
  }

  return initialPages
}
export const INVITATIONS_ACTIVE_STEP_BY_PAGE: Record<string, number> = {
  'employee-invitation-set-password': 0,
  'employee-invitation-personal-details': 1,
  'employee-invitation-home-address': 2,
  'employee-invitation-id-validation': 3,
}

export const EMPLOYEE_INVITATION_STEP = {
  SET_PASSWORD: 'employee-invitation-set-password',
  PERSONAL_DETAILS: 'employee-invitation-personal-details',
  HOME_ADDRESS: 'employee-invitation-home-address',
  ID_VALIDATION: 'employee-invitation-id-validation',
} as const

export type EmployeeInvitationStep = ConstToUnion<typeof EMPLOYEE_INVITATION_STEP>

/**
 * Generates a list of steps for the invitation flow based on the invitation token.
 *
 * @param invitationToken Unique token for the invitation
 * @returns The list of steps for the invitation flow
 */
export const getInvitationSteps = (invitationToken: string, kyc: boolean) => {
  const initialSteps = [
    {
      label: 'Set Password',
      route: `/employee-invitation/${invitationToken}/set-password`,
    },
    {
      label: 'Personal details',
      route: `/employee-invitation/${invitationToken}/personal-details`,
    },
    {
      label: 'Home address',
      route: `/employee-invitation/${invitationToken}/home-address`,
    },
  ]

  if (kyc) {
    return [
      ...initialSteps,
      // KYC pages
      {
        label: 'ID Validation',
        route: `/employee-invitation/${invitationToken}/id-validation`,
      },
    ]
  }

  return initialSteps
}
