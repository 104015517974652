import moment from 'moment'
import { Divider, Grid, isDefined, Skeleton, Text } from 'ui-lib'
import { Money } from 'utils'
import { z } from 'zod'

import { FinancingPlanCalculator, useFinancingPlanCalculatorContext } from '@/src/components/capital'
import { TextRow } from '@/src/components/misc'
import { getPaymentDueTextByRepaymentFrequency } from '@/src/components/molecules/capital/ConfirmFinancingPlanSection'
import { useFundingWithOriginationFee } from '@/src/contexts/capital'

type CapitalConfirmationFormProps = {
  approval: {
    maxRepaymentMonths: number
    repaymentFrequency: 'DAILY' | 'WEEKLY' | 'MONTHLY'
    availableRepayments: number[]
  }
}

export const confirmPlanSchema = z.object({
  confirmedFinancialAgreement: z.literal(true, { message: 'You need to confirm and agree' }),
  confirmedDebitAgreement: z.literal(true, { message: 'You need to confirm and agree' }),
})

const Summary = ({ repaymentFrequency }: { repaymentFrequency: 'DAILY' | 'WEEKLY' | 'MONTHLY' }) => {
  const { selectedRepaymentPlan } = useFinancingPlanCalculatorContext()
  const monthlyPayment = Money.fromNumber(
    selectedRepaymentPlan?.amountPerRemainingPayments.amountAsNumber ?? 0
  ).toFormattedCurrencyString()

  const formatDate = (date: Date) => moment(date).format('MMM D, YYYY')
  const lastPayment = selectedRepaymentPlan?.lastPaymentDate
  const paymentDueOn = getPaymentDueTextByRepaymentFrequency(moment(lastPayment), repaymentFrequency)

  return (
    <>
      <TextRow>
        <TextRow.Title textStyle="title-md" color="text-primary">
          Monthly Payment
        </TextRow.Title>
        {!isDefined(selectedRepaymentPlan) ? (
          <Skeleton w="110px" height="24px" />
        ) : (
          <TextRow.Value textStyle="title-md" mb={2}>
            {monthlyPayment} /month
          </TextRow.Value>
        )}
      </TextRow>
      <Grid bgColor="bg-neutral" gap={2} padding={4} rounded="md">
        <TextRow>
          <Text textStyle="paragraph-sm" color="text-secondary">
            Term length
          </Text>
          <Text textStyle="paragraph-sm">{selectedRepaymentPlan?.durationFormatted}</Text>
        </TextRow>
        <TextRow>
          <Text textStyle="paragraph-sm" color="text-secondary">
            Payments due on
          </Text>
          <Text textStyle="paragraph-sm">{paymentDueOn}</Text>
        </TextRow>
        <TextRow>
          <Text textStyle="paragraph-sm" color="text-secondary">
            Final payment date
          </Text>
          <Text textStyle="paragraph-sm">{formatDate(new Date(lastPayment ?? ''))}</Text>
        </TextRow>
      </Grid>
    </>
  )
}

export const CapitalConfirmationFormPage = ({ approval }: CapitalConfirmationFormProps) => {
  const { goToPreviousStep, goToNextStep, termLength, fundingAmount } = useFundingWithOriginationFee()

  return (
    <>
      <Text textStyle="headline-4" mb={8}>
        Confirm your financing plan
      </Text>
      <FinancingPlanCalculator
        onSubmit={() => goToNextStep()}
        onCancel={goToPreviousStep}
        repaymentFrequency={approval.repaymentFrequency}
        maxRepaymentMonths={approval.maxRepaymentMonths}
        initialFundingAmount={fundingAmount}
        initialTermLength={termLength}
        mode="read"
        w={['full', '440px']}
        customSchema={confirmPlanSchema}
      >
        <FinancingPlanCalculator.Summary>
          <Text textStyle="title-md" mb={2}>
            Financing plan
          </Text>
          <FinancingPlanCalculator.FinancingAmount />
          <FinancingPlanCalculator.AmountReceived />
          <FinancingPlanCalculator.TotalFinancingFee />
          <FinancingPlanCalculator.TotalAmountToBePaid />
          <Divider my={2} />
          <Summary repaymentFrequency={approval.repaymentFrequency} />
        </FinancingPlanCalculator.Summary>
        <FinancingPlanCalculator.Agreements />
        <FinancingPlanCalculator.CalculatorActionButtons />
      </FinancingPlanCalculator>
    </>
  )
}
