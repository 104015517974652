import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CurrencyCode } from 'services/utils/countries'

import { Amount } from './currencyConversion.slice'

const withdrawSlice = createSlice({
  name: 'withdraw',
  initialState: {
    amount: null as Amount | null,
    currency: 'CAD' as CurrencyCode | null,
    accountToId: null as string | null,
    eta: null as string | null,
  },
  reducers: {
    setAmount: (state, action: PayloadAction<Amount | null>) => {
      state.amount = action.payload
    },
    setCurrency: (state, action: PayloadAction<CurrencyCode | null>) => {
      state.currency = action.payload
    },
    setAccountToId: (state, action: PayloadAction<string | null>) => {
      state.accountToId = action.payload
    },
    setEta: (state, action: PayloadAction<string | null>) => {
      state.eta = action.payload
    },
  },
})

export const { setAmount, setCurrency, setAccountToId, setEta } = withdrawSlice.actions

export default withdrawSlice
