import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const helpers = createMultiStyleConfigHelpers(['control', 'menu', 'addOptionButton', 'placeholder', 'customOption'])

export const CustomSelect = helpers.defineMultiStyleConfig({
  baseStyle: {
    control: {
      padding: '5px 8px',
      border: '1px solid',
      borderRadius: 'md',
    },
    menu: {
      border: '1px solid',
      borderColor: 'border-soft',
      boxShadow: 'lg',
      borderRadius: 'md',
      background: 'bg-primary',
    },
    addOptionButton: {
      justifyContent: 'start',
      height: 'auto',
      width: 'full',
      padding: '5',
      _hover: {
        background: 'bg-neutral',
      },
    },
    placeholder: {
      position: 'absolute',
      textStyle: 'paragraph-lg',
      left: '10px',
    },
    customOption: {
      _hover: {
        background: 'bg-neutral',
      },
      px: '5',
      py: '3',
      width: 'full',
      mt: '0',
      height: 'full',
      borderRadius: 'md',
      cursor: 'pointer',
    },
  },
  variants: {
    default: {
      control: {
        background: 'bg-primary',
        borderColor: 'border-soft',
        _hover: {
          borderColor: 'border-primary',
        },
      },
      placeholder: {
        color: 'text-secondary',
      },
    },
    disabled: {
      control: {
        background: 'bg-disabled',
        borderColor: 'border-disabled',
      },
      placeholder: {
        color: 'text-disabled',
      },
    },
    invalid: {
      control: {
        borderColor: 'border-error',
      },
    },
    focused: {
      control: {
        borderColor: 'border-input-focus',
        outlineStyle: 'solid',
        outlineWidth: '4px',
        outlineOffset: '1px',
        outlineColor: 'outline-input-focus',
        borderStyle: 'solid',
        boxShadow: '0 0 0 1px var(--chakra-colors-border-input-focus)',
      },
    },
  },
  defaultProps: {
    variant: 'default',
  },
})
