import { IconButton, InputRightElement, useBoolean } from '@chakra-ui/react'
import React, { forwardRef, useEffect } from 'react'

import { Icon } from '../../icon'
import { Input } from '../input'

type PasswordInputProps = Omit<React.ComponentProps<typeof Input>, 'type' | 'rightElement' | 'paddingRight'> & {
  /**
   * When a form has been submitted we should reset the type to password
   * (Reference: https://design-system.service.gov.uk/components/password-input/).
   */
  isFormSubmitted: boolean
}

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(({ isFormSubmitted, ...props }, ref) => {
  const [isVisible, { toggle, off: hide }] = useBoolean(false)

  useEffect(() => {
    if (isFormSubmitted === true) {
      hide()
    }
  }, [isFormSubmitted])

  return (
    <Input
      {...props}
      ref={ref}
      type={isVisible ? 'text' : 'password'}
      paddingRight="12"
      data-private="redact"
      spellCheck="false"
      autoCapitalize="none"
      id={props.id}
      rightElement={
        <InputRightElement height="full">
          <IconButton
            aria-label={isVisible ? 'Hide password' : 'Show password'}
            aria-controls={props.id}
            variant="link"
            onClick={toggle}
            marginRight="4"
            icon={<Icon icon={isVisible ? 'eye-open' : 'eye-slashed'} variant="primary" />}
          />
        </InputRightElement>
      }
    />
  )
})
