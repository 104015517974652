import { Box, Button, createStylesContext, Flex, Img, Tag, TagProps, Text, useMultiStyleConfig } from '@chakra-ui/react'
import React from 'react'
import { isDefined } from 'utils'

import { Icon, IconName } from '../icon'
import { BannerSize, BannerVariant } from './types'

interface BaseProps {
  children: React.ReactNode
}

const [StylesProvider, useStyles] = createStylesContext('Banner')

interface BannerProps extends BaseProps {
  size?: BannerSize
  variant?: BannerVariant
}

const CategoryTitle = ({ children }: BaseProps) => {
  const styles = useStyles()

  return <Text sx={styles.categoryTitle}>{children}</Text>
}

const Headline = ({ children }: BaseProps) => {
  const styles = useStyles()

  return <Text sx={styles.headline}>{children}</Text>
}

const Content = ({ children }: BaseProps) => {
  const styles = useStyles()

  return <Text sx={styles.content}>{children}</Text>
}

const Image = (props: React.ComponentProps<typeof Img>) => {
  const styles = useStyles()

  return <Img sx={styles.image} {...props} />
}

type ActionButtonProps = React.ComponentProps<typeof Button> & {
  icon?: IconName
}
const ActionButton = (props: ActionButtonProps) => {
  return <Button variant="link" color="text-primary" rightIcon={<Icon icon="chevron-right" size="sm" />} {...props} />
}

const TagSection = ({ children, ...props }: TagProps) => {
  const styles = useStyles()

  return (
    <Tag size="sm" sx={styles.tag} {...props}>
      {children}
    </Tag>
  )
}

export const Banner = ({ children, size, variant }: BannerProps) => {
  const styles = useMultiStyleConfig('Banner', { size, variant })

  const allChildren = React.Children.toArray(children)

  const categoryTitle = allChildren.find(
    (child): child is React.ReactElement => React.isValidElement(child) && child.type === CategoryTitle
  )

  const headline = allChildren.find(
    (child): child is React.ReactElement => React.isValidElement(child) && child.type === Headline
  )

  const content = allChildren.find(
    (child): child is React.ReactElement => React.isValidElement(child) && child.type === Content
  )

  const image = allChildren.find(
    (child): child is React.ReactElement => React.isValidElement(child) && child.type === Image
  )

  const button = allChildren.find(
    (child): child is React.ReactElement => React.isValidElement(child) && child.type === ActionButton
  )

  const tag = allChildren.find(
    (child): child is React.ReactElement => React.isValidElement(child) && child.type === TagSection
  )

  return (
    <StylesProvider value={styles}>
      <Flex sx={styles.container} shadow="md">
        {isDefined(image) && <Box sx={styles.imageContainer}>{image}</Box>}

        <Flex sx={styles.bodyContainer} flex="1">
          {categoryTitle}
          {headline}
          {content}
          {tag}
        </Flex>

        {isDefined(button) && (
          <Flex alignItems="center" pl="6" borderLeft="1px solid" borderColor="border-soft">
            {button}
          </Flex>
        )}
      </Flex>
    </StylesProvider>
  )
}

Banner.CategoryTitle = CategoryTitle
Banner.Headline = Headline
Banner.Content = Content
Banner.Image = Image
Banner.Button = ActionButton
Banner.Tag = TagSection
