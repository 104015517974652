import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const helpers = createMultiStyleConfigHelpers(['container', 'content', 'title', 'description', 'arrow'])

export const RedeemRewardsOption = helpers.defineMultiStyleConfig({
  baseStyle: ({ colorMode }: any) => ({
    container: {
      alignItems: 'center',
      cursor: 'pointer',
      width: '100%',
      padding: '6',
      borderRadius: 'lg',
      border: '1px solid',
      borderColor: colorMode === 'light' ? '_primary.100' : '_primary.800',
      _hover: {
        bgColor: colorMode === 'light' ? '_primary.50' : '_primary.800',
      },
      _focus: {
        outlineStyle: 'solid',
        outlineWidth: '1',
        outlineColor: colorMode === 'light' ? 'accent.500' : 'accent.400',
      },
      _disabled: {
        color: colorMode === 'light' ? '_primary.300' : '_primary.800',
        bgColor: colorMode === 'light' ? '_primary.50' : '_primary.900',
        outlineStyle: 'none',
        cursor: 'not-allowed',
      },
    },
    content: {
      flex: 1,
      flexDirection: 'column',
      ml: '6',
    },
    title: {
      textStyle: 'heading.sm.medium',
      color: colorMode === 'light' ? '_primary.900' : '_primary.300',
      _disabled: {
        color: colorMode === 'light' ? '_primary.300' : '_primary.800',
      },
    },
    description: {
      mt: '1',
      textStyle: 'text.lg.regular',
      color: colorMode === 'light' ? '_primary.500' : '_primary.500',
      _disabled: {
        color: colorMode === 'light' ? '_primary.300' : '_primary.800',
      },
    },
    arrow: {
      w: '16px',
      h: '14px',
      transition: '0.2s',
      '[data-part-name="container"]:hover &': {
        transform: 'translateX(8px)',
      },
      _disabled: {
        color: colorMode === 'light' ? '_primary.300' : '_primary.800',
        '[data-part-name="container"]:hover &': {
          transform: 'translateX(0px)',
        },
      },
    },
  }),
})
