/**
 * Segment FE events dictionary
 */

import { CurrencyCode, UserType } from 'db-schema'
import { ConstToUnion } from 'utils/types'

import { CardType } from '@/gql'
import { NullOrUndefined } from '@/types'

import { FINANCING_CHANNEL } from './segmentVisitedPages'

export const SEGMENT_EVENTS = {
  // Cards
  CARD_SPEND_LIMIT_ADJUSTED: 'Card Spending Limits Adjusted',
  CARD_FROZEN: 'Card Frozen',
  CARD_UNFROZEN: 'Card Unfrozen',
  CARD_RENAMED: 'Card Renamed',
  CARD_ADDRESS_COPIED: 'Card Address Copied',
  CARD_INVITATION_RESENT: 'Card Invitation Resent',
  CARD_PAYMENT_CANCELLED: 'Card Payment Cancelled',
  CARD_PAYMENT_INITIATED: 'Card Payment Initiated',
  CARD_ADDED_TO_WALLET: 'Add Card to Wallet With Type Clicked',
  CARD_TRANSACTIONS_EXPORTED: 'Card Transactions Exported',
  BANKING_TRANSACTIONS_EXPORTED: 'Banking Transactions Exported',
  CARD_REQUESTED: 'Card Requested',
  CARD_HOLDER_SIGNED_UP: 'Card Holder Signed Up',
  CARD_DETAILS_VIEWED: 'Card Details Viewed',
  EMPLOYEE_INVITATION_RESENT: 'Employee Invitation Resent',
  ADD_STATEMENT_TO_CALENDAR: 'Add Statement To Calendar Clicked',
  // Capital
  FINANCING_APP_FINANCING_AGREEMENT_TOGGLED: 'Financing Application Financing Agreement Toggled',
  FINANCING_APP_FINANCING_AGREEMENT_DOWNLOADED: 'Financing Application Financing Agreement Downloaded',
  FINANCING_APP_PAD_AGREEMENT_TOGGLED: 'Financing Application PAD Agreement Toggled',
  FINANCING_APP_PAD_AGREEMENT_DOWNLOADED: 'Financing Application PAD Agreement Downloaded',
  FINANCING_APP_PURPOSE_OF_FUNDING: 'Financing Application Purpose of funding',

  // Accounting Integration
  ACCOUNTING_CONNECTION_CHECKED: 'Accounting Connection Checked',
  //Data and Risk
  LINKED_ACCOUNT_RECONNECT_STARTED: 'Linked Account Reconnect Started',
  // Banking
  CURRENCY_CONVERSION_REQUESTED: 'Currency Conversion Requested',
  CURRENCY_CONVERSION_LOCKED: 'Currency Conversion Locked',
  CURRENCY_CONVERSION_ACCEPTED: 'Currency Conversion Accepted',
  CURRENCY_CONVERSION_EXPIRED: 'Currency Conversion Expired',
  CURRENCY_CONVERSION_EDITED: 'Currency Conversion Edited',
  // Account
  TEAM_USER_INVITED: 'Tean User Invited',
  TEAM_USER_AGREEMENT_ACEPTED: 'Team User Agreement Accepted',
  TEAM_USER_PASSWORD_SET: 'Team User Password Set',
  TEAM_USER_KYC_SUBMITTED: 'Team User KYC Submitted',
  STATEMENT_EXTENSION_STARTED: 'Statement Extension Started',

  // INFO: Statement Extension Auto Enrollment
  STATEMENT_EXTENSION_DETAILS_CONFIRMED: 'Statement Extension Details Confirmed',
  STATEMENT_EXTENSION_PLAN_CONFIRMED: 'Statement Extension Plan Confirmed',
  STATEMENT_EXTENSIONS_SETTINGS_CHANGED: 'Extension Setting Changed',
  STATEMENT_EXTENSIONS_CALCULATOR_CARD_BALANCE: 'Calculator Card Balance Changed',
  STATEMENT_EXTENSIONS_CALCULATOR_TENURE_CHANGED: 'Calculator Tenure Changed',
  EXTENSION_PLAN_CHANGE_STARTED: 'Extension Plan Change Started',
  EXTENSION_PLAN_CHANGE_CONFIRMED: 'Extension Plan Change Confirmed',
  EXTENSION_PLAN_CHANGED: 'Extension Plan Changed',
  CONTACT_SUPPORT_INITIATED: 'Contact Support Initiated',
} as const

export const USER_TYPES = {
  FOUNDER: 'Founder',
  EMPLOYEE: 'Employee',
}

export type SegmentEvent = ConstToUnion<typeof SEGMENT_EVENTS>
export type SegmentUserTypes = ConstToUnion<typeof USER_TYPES>

type CardEventBasePayload = {
  card_id: string
  card_type: CardType | NullOrUndefined
  user_is_owner: boolean
  team: string
}

export type CardEvent = {
  type:
    | typeof SEGMENT_EVENTS.CARD_FROZEN
    | typeof SEGMENT_EVENTS.CARD_UNFROZEN
    | typeof SEGMENT_EVENTS.CARD_RENAMED
    | typeof SEGMENT_EVENTS.CARD_INVITATION_RESENT
  payload: CardEventBasePayload
}

export type CardSpendingLimitsAdjusted = {
  type: typeof SEGMENT_EVENTS.CARD_SPEND_LIMIT_ADJUSTED
  payload: CardEventBasePayload & {
    amount: number
    period: string
  }
}

export type CardAddressCopied = {
  type: typeof SEGMENT_EVENTS.CARD_ADDRESS_COPIED
  payload: CardEventBasePayload & {
    address_component: string
  }
}

export type EmployeeInvitationResent = {
  type: typeof SEGMENT_EVENTS.EMPLOYEE_INVITATION_RESENT
  payload: {
    user_id: string
    team: string
  }
}

export type AddStatementToCalendarClicked = {
  type: typeof SEGMENT_EVENTS.ADD_STATEMENT_TO_CALENDAR
  payload: {
    user_id: string
    recurrence: string
    due_date: string
    team: string
  }
}

export type ReconnectLinkedAccountsEvent = {
  type: typeof SEGMENT_EVENTS.LINKED_ACCOUNT_RECONNECT_STARTED
  payload: {
    plaid_account_id?: string
    team: string
  }
}

export type CardPaymentCancelledEvent = {
  type: typeof SEGMENT_EVENTS.CARD_PAYMENT_CANCELLED
  payload: {
    payment_origin: string
    amount_to_cancel: string
    team: string
  }
}

export type CardPaymentInitiatedEvent = {
  type: typeof SEGMENT_EVENTS.CARD_PAYMENT_INITIATED
  payload: {
    $amount: number
    bankAccountType: string
    team: string
  }
}

export type CardAddedToWallet = {
  type: typeof SEGMENT_EVENTS.CARD_ADDED_TO_WALLET
  payload: {
    type: 'apple' | 'google' | 'samsung'
    team: string
  }
}

export type CardTransactionsExportedEvent = {
  type: typeof SEGMENT_EVENTS.CARD_TRANSACTIONS_EXPORTED
  payload: Pick<CardEventBasePayload, 'team' | 'user_is_owner' | 'card_type'> & {
    type: 'csv' | 'qbo'
    card_id: string | undefined
  }
}

export type BankingTransactionsExportedEvent = {
  type: typeof SEGMENT_EVENTS.BANKING_TRANSACTIONS_EXPORTED
  payload: {
    type: 'csv' | 'qbo'
    currency: CurrencyCode
    user_id?: string
    team: string
  }
}

export type CardRequestedEvent = {
  type: typeof SEGMENT_EVENTS.CARD_REQUESTED
  payload: {
    user_id?: string
    card_holder_type: SegmentUserTypes
    card_holder?: string
    team: string
  }
}

export type CardHolderSignedUpEvent = {
  type: typeof SEGMENT_EVENTS.CARD_HOLDER_SIGNED_UP
  payload: {
    user_id?: string
    team: string
  }
}

export type CardDetailsViewed = {
  type: typeof SEGMENT_EVENTS.CARD_DETAILS_VIEWED
  payload: CardEventBasePayload & {
    card_holder_email?: string | null
  }
}

export type FinancialAppFinancialAgreementEvent = {
  type:
    | typeof SEGMENT_EVENTS.FINANCING_APP_FINANCING_AGREEMENT_TOGGLED
    | typeof SEGMENT_EVENTS.FINANCING_APP_FINANCING_AGREEMENT_DOWNLOADED
    | typeof SEGMENT_EVENTS.FINANCING_APP_PAD_AGREEMENT_TOGGLED
    | typeof SEGMENT_EVENTS.FINANCING_APP_PAD_AGREEMENT_DOWNLOADED
  payload: {
    team: string
    state?: boolean
    financingChannel?: FINANCING_CHANNEL
  }
}

export type FinancialAppFinancialStartedEvent = {
  type: typeof SEGMENT_EVENTS.STATEMENT_EXTENSION_STARTED
  payload: {
    team: string
    screen?: string
  }
}

export type AccountingConnectionChecked = {
  type: typeof SEGMENT_EVENTS.ACCOUNTING_CONNECTION_CHECKED
  payload: {
    connected: boolean
    connection_type: string
    team: string
  }
}

export type StorePurposeOfFunding = {
  type: typeof SEGMENT_EVENTS.FINANCING_APP_PURPOSE_OF_FUNDING
  payload: {
    team: string
    purpose: string
    financingChannel: FINANCING_CHANNEL
  }
}

export type BankingConversionActionEvent = {
  type: typeof SEGMENT_EVENTS.CURRENCY_CONVERSION_REQUESTED | typeof SEGMENT_EVENTS.CURRENCY_CONVERSION_ACCEPTED
  payload: {
    team: string
    sell_currency: CurrencyCode
    sell_currency_amount: string
    buy_currency: CurrencyCode
    buy_currency_amount: string
    exchange_rate: string
  }
}

export type BankingConversionClickEvent = {
  type:
    | typeof SEGMENT_EVENTS.CURRENCY_CONVERSION_LOCKED
    | typeof SEGMENT_EVENTS.CURRENCY_CONVERSION_EXPIRED
    | typeof SEGMENT_EVENTS.CURRENCY_CONVERSION_EDITED
  payload: {
    team: string
  }
}

export type AccountTeamEvent = {
  type:
    | typeof SEGMENT_EVENTS.TEAM_USER_INVITED
    | typeof SEGMENT_EVENTS.TEAM_USER_AGREEMENT_ACEPTED
    | typeof SEGMENT_EVENTS.TEAM_USER_PASSWORD_SET
    | typeof SEGMENT_EVENTS.TEAM_USER_KYC_SUBMITTED
  payload: {
    team: string
    role: UserType
  }
}

export type StatementExtensionDetailsConfirmed = {
  type: typeof SEGMENT_EVENTS.STATEMENT_EXTENSION_DETAILS_CONFIRMED
  payload: {
    team: string
    term_length: number
    auto_extension: boolean
    back_account: string
  }
}
export type StatementExtensionPlanConfirmed = {
  type: typeof SEGMENT_EVENTS.STATEMENT_EXTENSION_PLAN_CONFIRMED
  payload: {
    team: string
    term_length: number
    auto_extension: boolean
  }
}

export type StatementExtensionSettingsChanged = {
  type: typeof SEGMENT_EVENTS.STATEMENT_EXTENSIONS_SETTINGS_CHANGED
  payload: {
    team: string
    auto_extension: string
  }
}

export type StatementExtensionsCardBalanceChanged = {
  type: typeof SEGMENT_EVENTS.STATEMENT_EXTENSIONS_CALCULATOR_CARD_BALANCE
  payload: {
    team: string
    card_balance: number
  }
}

export type StatementExtensionsTenureChanged = {
  type: typeof SEGMENT_EVENTS.STATEMENT_EXTENSIONS_CALCULATOR_TENURE_CHANGED
  payload: {
    team: string
    term_length: number
  }
}

export type ExtensionPlanChangeStarted = {
  type: typeof SEGMENT_EVENTS.EXTENSION_PLAN_CHANGE_STARTED
  payload: {
    team: string
    existing_plan: string
  }
}

export type ExtensionPlanChangeConfirmed = {
  type: typeof SEGMENT_EVENTS.EXTENSION_PLAN_CHANGE_CONFIRMED
  payload: {
    team: string
    existing_plan: string
    new_plan: string
  }
}

export type ExtensionPlanChanged = {
  type: typeof SEGMENT_EVENTS.EXTENSION_PLAN_CHANGED
  payload: {
    team: string
    existing_plan: string
  }
}

export type RequestBankingAccessStarted = {
  type: 'Request Banking Access Started'
  payload: {
    team: string
    businessId: string
  }
}

export type RequestBankingAccessCompleted = {
  type: 'Request Banking Access Completed'
  payload: {
    team: string
    businessId: string
  }
}

export type ContactSupportInitiated = {
  type: typeof SEGMENT_EVENTS.CONTACT_SUPPORT_INITIATED
  payload: {
    team: string
  }
}

export type TrackableEvent =
  | CardEvent
  | CardPaymentCancelledEvent
  | CardPaymentInitiatedEvent
  | CardTransactionsExportedEvent
  | CardAddedToWallet
  | CardSpendingLimitsAdjusted
  | CardAddressCopied
  | EmployeeInvitationResent
  | BankingTransactionsExportedEvent
  | CardRequestedEvent
  | CardHolderSignedUpEvent
  | CardDetailsViewed
  | ReconnectLinkedAccountsEvent
  | FinancialAppFinancialAgreementEvent
  | AccountingConnectionChecked
  | StorePurposeOfFunding
  | FinancialAppFinancialStartedEvent
  | BankingConversionActionEvent
  | BankingConversionClickEvent
  | AccountTeamEvent
  | AddStatementToCalendarClicked
  | StatementExtensionDetailsConfirmed
  | StatementExtensionPlanConfirmed
  | StatementExtensionSettingsChanged
  | StatementExtensionsCardBalanceChanged
  | StatementExtensionsTenureChanged
  | ExtensionPlanChangeStarted
  | ExtensionPlanChangeConfirmed
  | ExtensionPlanChanged
  | RequestBankingAccessStarted
  | RequestBankingAccessCompleted
  | ContactSupportInitiated
