import React from 'react'
import { Flex, Icon, Tag, Text, Tooltip } from 'ui-lib'
import { isDefined, Money } from 'utils'

import { TextRow } from '@/src/components/misc'
import { FundingDiscountType, PartialCapitalFunding, PlainDiscount } from '@/types/capital'

type FundingCardDetailsProps = {
  funding: PartialCapitalFunding
  totalAmount: string
}

export const OriginationFeeFundingCardDetails = ({ funding, totalAmount }: FundingCardDetailsProps) => {
  const isDiscountApplied = isDefined(funding.feeDiscountRate) && funding.feeDiscountRate > 0

  const discounts = funding.discounts?.reduce(
    (acc, crr) => ({
      ...acc,
      [crr.type]: crr,
    }),
    {} as { [key in FundingDiscountType]: PlainDiscount }
  )

  const feeLessFlatDiscount = Money.fromNumber(funding.preDiscountTotalFee.amountAsNumber)
    .sub(Money.fromFormattedCurrencyString(discounts?.FLAT_DISCOUNT?.amount ?? '0'))
    .toFormattedCurrencyString()

  return (
    <Flex flex={1} direction="column" gap="6" padding="6">
      <Text textStyle="title-sm" textColor="text-primary">
        Financing Details
      </Text>
      <Flex direction="column" gap="2">
        <TextRow>
          <TextRow.Title>Financing amount</TextRow.Title>
          <TextRow.Value textStyle="paragraph-md">{funding.principalAmount.amount}</TextRow.Value>
        </TextRow>
        <TextRow>
          <TextRow.Title>Amount received</TextRow.Title>
          <TextRow.Value textStyle="paragraph-md">
            {Money.fromNumber(
              funding.principalAmount.amountAsNumber - funding.originationFee.amountAsNumber
            ).toFormattedCurrencyString()}
          </TextRow.Value>
        </TextRow>
        <TextRow>
          <TextRow.Title>
            <Flex gap={2}>
              Financing fee
              <Tag variant="blue" size="sm" rounded="full" fontWeight="semibold">
                {(((funding?.apr ?? 0) / 12) * 100).toFixed(2)}% monthly
              </Tag>
            </Flex>
          </TextRow.Title>
          <TextRow.Value textStyle="paragraph-md">
            {isDefined(discounts?.FLAT_DISCOUNT) ? (
              <>
                <Text as="del" color="text-soft">
                  {funding.preDiscountTotalFee.amount}
                </Text>{' '}
                <Text as="span" color="text-success">
                  {feeLessFlatDiscount}
                </Text>
              </>
            ) : (
              funding.preDiscountTotalFee.amount
            )}
          </TextRow.Value>
        </TextRow>
        {isDefined(discounts?.REWARDS_DISCOUNT) && (
          <TextRow>
            <TextRow.Title display="flex" alignItems="center" gap={1}>
              Rewards redemption discount
              <Tooltip label="Rewards discount is applied on the fee amount.">
                <Icon size="sm" icon="info-fill" />
              </Tooltip>
            </TextRow.Title>

            <TextRow.Value
              textStyle="paragraph-md"
              css={{
                background: 'linear-gradient(236deg, #DB57B6 0%, #6497C7 100%)',
                backgroundClip: 'text',
                '-webkit-background-clip': 'text',
                '-webkit-text-fill-color': 'transparent',
              }}
            >
              - {discounts?.REWARDS_DISCOUNT.amount}
            </TextRow.Value>
          </TextRow>
        )}
        <TextRow>
          <TextRow.Title>Total amount to be paid</TextRow.Title>
          <TextRow.Value textStyle="title-sm">
            <Flex>
              <Icon display={isDiscountApplied ? 'block' : 'none'} icon="tag-fill" variant="success" />
              {totalAmount}
            </Flex>
          </TextRow.Value>
        </TextRow>
      </Flex>
    </Flex>
  )
}
