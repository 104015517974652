import { chakra } from '@chakra-ui/react'
import React, { ComponentProps } from 'react'

const CircleAlertFill = (props?: ComponentProps<typeof chakra.svg>) => {
  return (
    <chakra.svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_814_7067)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 0.833344C4.9374 0.833344 0.833344 4.9374 0.833344 10C0.833344 15.0626 4.9374 19.1667 10 19.1667C15.0626 19.1667 19.1667 15.0626 19.1667 10C19.1667 4.9374 15.0626 0.833344 10 0.833344ZM10.8333 5.83334C10.8333 5.37311 10.4602 5.00001 10 5.00001C9.53977 5.00001 9.16668 5.37311 9.16668 5.83334V10.8333C9.16668 11.2936 9.53977 11.6667 10 11.6667C10.4602 11.6667 10.8333 11.2936 10.8333 10.8333V5.83334ZM10.8333 13.75C10.8333 13.2898 10.4602 12.9167 10 12.9167C9.53977 12.9167 9.16668 13.2898 9.16668 13.75V14.1667C9.16668 14.6269 9.53977 15 10 15C10.4602 15 10.8333 14.6269 10.8333 14.1667V13.75Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_814_7067">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </chakra.svg>
  )
}

export default CircleAlertFill
