import { ComponentProps } from 'react'
import { chakra, keyframes } from 'ui-lib'

const CheckIconAnimation = keyframes`
  from {
    stroke-dashoffset: 32.53;
  }
  to {
    stroke-dashoffset: 0;
  }
`

type CheckIconProps = ComponentProps<typeof chakra.svg> & {
  animated?: boolean
  animationDuration?: string
}

const CheckIcon = ({ animated = false, animationDuration, ...props }: CheckIconProps) => {
  return (
    <chakra.svg
      width="27"
      height="19"
      viewBox="0 0 27 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        path: {
          strokeDasharray: 32.53,
          animation: animated ? `${CheckIconAnimation} ${animationDuration ?? '.3s'} ease-in-out forwards` : 'none',
        },
      }}
      {...props}
    >
      <path
        d="M2 10.5L9 17.5L25 1.5"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </chakra.svg>
  )
}

export default CheckIcon
