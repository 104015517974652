import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type TooltipData = {
  label: string
  value?: string
}

const stepperSlice = createSlice({
  name: 'stepper',
  initialState: {
    tooltipDataByStep: [] as TooltipData[][],
  },
  reducers: {
    setTooltipData: (state, action: PayloadAction<{ activeStep: number; tooltipData: TooltipData[] }>) => {
      state.tooltipDataByStep[action.payload.activeStep] = action.payload.tooltipData
    },
    resetTooltipData: (state) => {
      state.tooltipDataByStep = []
    },
  },
})

export const { setTooltipData, resetTooltipData } = stepperSlice.actions

export default stepperSlice
