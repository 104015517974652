export const fontSizes = {
  heading: {
    h1: '40px',
    h2: '36px',
    h3: '32px',
    h4: '24px',
  },
  title: {
    lg: '20px',
    md: '18px',
    sm: '16px',
    xs: '14px',
  },
  subheading: {
    lg: '14px',
    md: '12px',
    sm: '11px',
  },
  paragraph: {
    lg: '16px',
    md: '14px',
    sm: '12px',
  },
}
