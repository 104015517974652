import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(['filledTrack'])

const baseStyle = definePartsStyle({})

export const Progress = defineMultiStyleConfig({
  baseStyle,
  variants: {
    primary: {
      filledTrack: {
        backgroundColor: 'icon-primary',
      },
    },
  },
})
