import { CurrencyCode } from 'db-schema'

import { isAllowedCurrency } from '../src/currency/currencies'
export { CurrencyCode } from 'db-schema'

export const getCountryNameByCode = (code: string) =>
  COUNTRIES_LIST.find((country) => (code.length === 3 ? country.code === code : country.code2 === code))?.name as string

export const getCountryByCode = (code: string) =>
  COUNTRIES_LIST.find((country) =>
    code.length === 3 ? country.code === code : country.code2 === code
  ) as CountryListType

export const getCurrencyCodeByCountryCode = (code: CountryCode) =>
  Currencies.find((c) => code === c.code)?.currencyCode as CurrencyCode

export interface CountryListType {
  name: string
  /**
   * Country code in ISO 3166-1 alpha-3 format
   */
  code: CountryCode
  /**
   * Country code in ISO 3166-1 alpha-2 format
   */
  code2: string
  currencyName?: string
  currencyCode?: CurrencyCode
  decimalPlaces?: number
}

// TODO: Change it to be a json to improve performance, a lot of `.find()` and `.filter()` used in this large array
export const COUNTRIES_LIST: CountryListType[] = [
  {
    name: 'Afghanistan',
    code: 'AFG',
    code2: 'AF',
  },
  {
    name: 'Åland Islands',
    code: 'ALA',
    code2: 'AX',
  },
  {
    name: 'Albania',
    code: 'ALB',
    code2: 'AL',
  },
  {
    name: 'Algeria',
    code: 'DZA',
    code2: 'DZ',
  },
  {
    name: 'American Samoa',
    code: 'ASM',
    code2: 'AS',
  },
  {
    name: 'Andorra',
    code: 'AND',
    code2: 'AD',
  },
  {
    name: 'Angola',
    code: 'AGO',
    code2: 'AO',
  },
  {
    name: 'Anguilla',
    code: 'AIA',
    code2: 'AI',
  },
  {
    name: 'Antarctica',
    code: 'ATA',
    code2: 'AQ',
  },
  {
    name: 'Antigua and Barbuda',
    code: 'ATG',
    code2: 'AG',
  },
  {
    name: 'Argentina',
    code: 'ARG',
    code2: 'AR',
  },
  {
    name: 'Armenia',
    code: 'ARM',
    code2: 'AM',
  },
  {
    name: 'Aruba',
    code: 'ABW',
    code2: 'AW',
  },
  {
    name: 'Australia',
    code: 'AUS',
    code2: 'AU',
    currencyCode: CurrencyCode.AUD,
    currencyName: 'Australian Dollar',
    decimalPlaces: 2,
  },
  {
    name: 'Austria',
    code: 'AUT',
    code2: 'AT',
  },
  {
    name: 'Azerbaijan',
    code: 'AZE',
    code2: 'AZ',
  },
  {
    name: 'Bahamas',
    code: 'BHS',
    code2: 'BS',
  },
  {
    name: 'Bahrain',
    code: 'BHR',
    code2: 'BH',
  },
  {
    name: 'Bangladesh',
    code: 'BGD',
    code2: 'BD',
  },
  {
    name: 'Barbados',
    code: 'BRB',
    code2: 'BB',
  },
  {
    name: 'Belarus',
    code: 'BLR',
    code2: 'BY',
  },
  {
    name: 'Belgium',
    code: 'BEL',
    code2: 'BE',
  },
  {
    name: 'Belize',
    code: 'BLZ',
    code2: 'BZ',
  },
  {
    name: 'Benin',
    code: 'BEN',
    code2: 'BJ',
  },
  {
    name: 'Bermuda',
    code: 'BMU',
    code2: 'BM',
  },
  {
    name: 'Bhutan',
    code: 'BTN',
    code2: 'BT',
  },
  {
    name: 'Bolivia',
    code: 'BOL',
    code2: 'BO',
  },
  {
    name: 'Bosnia and Herzegovina',
    code: 'BIH',
    code2: 'BA',
  },
  {
    name: 'Botswana',
    code: 'BWA',
    code2: 'BW',
  },
  {
    name: 'Bouvet Island',
    code: 'BVT',
    code2: 'BV',
  },
  {
    name: 'Brazil',
    code: 'BRA',
    code2: 'BR',
  },
  {
    name: 'British Indian Ocean Territory',
    code: 'IOT',
    code2: 'IO',
  },
  {
    name: 'Brunei Darussalam',
    code: 'BRN',
    code2: 'BN',
  },
  {
    name: 'Bulgaria',
    code: 'BGR',
    code2: 'BG',
    currencyCode: CurrencyCode.BGN,
    currencyName: 'Bulgarian Lev',
    decimalPlaces: 2,
  },
  {
    name: 'Burkina Faso',
    code: 'BFA',
    code2: 'BF',
  },
  {
    name: 'Burundi',
    code: 'BDI',
    code2: 'BI',
  },
  {
    name: 'Cambodia',
    code: 'KHM',
    code2: 'KH',
  },
  {
    name: 'Cameroon',
    code: 'CMR',
    code2: 'CM',
  },
  {
    name: 'Canada',
    code: 'CAN',
    code2: 'CA',
    currencyName: 'Canadian dollar',
    currencyCode: CurrencyCode.CAD,
    decimalPlaces: 2,
  },
  {
    name: 'Cape Verde',
    code: 'CPV',
    code2: 'CV',
  },
  {
    name: 'Cayman Islands',
    code: 'CYM',
    code2: 'KY',
  },
  {
    name: 'Central African Republic',
    code: 'CAF',
    code2: 'CF',
  },
  {
    name: 'Chad',
    code: 'TCD',
    code2: 'TD',
  },
  {
    name: 'Chile',
    code: 'CHL',
    code2: 'CL',
  },
  {
    name: 'China',
    code: 'CHN',
    code2: 'CN',
    currencyCode: CurrencyCode.CNY,
    currencyName: 'Chinese Yuan',
    decimalPlaces: 2,
  },
  {
    name: 'Christmas Island',
    code: 'CXR',
    code2: 'CX',
  },
  {
    name: 'Cocos (Keeling) Islands',
    code: 'CCK',
    code2: 'CC',
  },
  {
    name: 'Colombia',
    code: 'COL',
    code2: 'CO',
  },
  {
    name: 'Comoros',
    code: 'COM',
    code2: 'KM',
  },
  {
    name: 'Congo',
    code: 'COG',
    code2: 'CG',
  },
  {
    name: 'Congo, The Democratic Republic of the',
    code: 'COD',
    code2: 'CD',
  },
  {
    name: 'Cook Islands',
    code: 'COK',
    code2: 'CK',
  },
  {
    name: 'Costa Rica',
    code: 'CRI',
    code2: 'CR',
  },
  {
    name: "Cote D'Ivoire",
    code: 'CIV',
    code2: 'CI',
  },
  {
    name: 'Croatia',
    code: 'HRV',
    code2: 'HR',
  },
  {
    name: 'Cuba',
    code: 'CUB',
    code2: 'CU',
  },
  {
    name: 'Cyprus',
    code: 'CYP',
    code2: 'CY',
  },
  {
    name: 'Czech Republic',
    code: 'CZE',
    code2: 'CZ',
    currencyCode: CurrencyCode.CZK,
    currencyName: 'Czech Koruna',
    decimalPlaces: 2,
  },
  {
    name: 'Denmark',
    code: 'DNK',
    code2: 'DK',
  },
  {
    name: 'Djibouti',
    code: 'DJI',
    code2: 'DJ',
  },
  {
    name: 'Dominica',
    code: 'DMA',
    code2: 'DM',
  },
  {
    name: 'Dominican Republic',
    code: 'DOM',
    code2: 'DO',
  },
  {
    name: 'Ecuador',
    code: 'ECU',
    code2: 'EC',
  },
  {
    name: 'Egypt',
    code: 'EGY',
    code2: 'EG',
  },
  {
    name: 'El Salvador',
    code: 'SLV',
    code2: 'SV',
  },
  {
    name: 'Equatorial Guinea',
    code: 'GNQ',
    code2: 'GQ',
  },
  {
    name: 'Eritrea',
    code: 'ERI',
    code2: 'ER',
  },
  {
    name: 'Estonia',
    code: 'EST',
    code2: 'EE',
  },
  {
    name: 'Ethiopia',
    code: 'ETH',
    code2: 'ET',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    code: 'FLK',
    code2: 'FK',
  },
  {
    name: 'Faroe Islands',
    code: 'FRO',
    code2: 'FO',
  },
  {
    name: 'Fiji',
    code: 'FJI',
    code2: 'FJ',
  },
  {
    name: 'Finland',
    code: 'FIN',
    code2: 'FI',
  },
  {
    name: 'France',
    code: 'FRA',
    code2: 'FR',
  },
  {
    name: 'French Guiana',
    code: 'GUF',
    code2: 'GF',
  },
  {
    name: 'French Polynesia',
    code: 'PYF',
    code2: 'PF',
  },
  {
    name: 'French Southern Territories',
    code: 'ATF',
    code2: 'TF',
  },
  {
    name: 'Gabon',
    code: 'GAB',
    code2: 'GA',
  },
  {
    name: 'Gambia',
    code: 'GMB',
    code2: 'GM',
  },
  {
    name: 'Georgia',
    code: 'GEO',
    code2: 'GE',
  },
  {
    name: 'Germany',
    code: 'DEU',
    code2: 'DE',
  },
  {
    name: 'Ghana',
    code: 'GHA',
    code2: 'GH',
  },
  {
    name: 'Gibraltar',
    code: 'GIB',
    code2: 'GI',
  },
  {
    name: 'Greece',
    code: 'GRC',
    code2: 'GR',
  },
  {
    name: 'Greenland',
    code: 'GRL',
    code2: 'GL',
  },
  {
    name: 'Grenada',
    code: 'GRD',
    code2: 'GD',
  },
  {
    name: 'Guadeloupe',
    code: 'GLP',
    code2: 'GP',
  },
  {
    name: 'Guam',
    code: 'GUM',
    code2: 'GU',
  },
  {
    name: 'Guatemala',
    code: 'GTM',
    code2: 'GT',
  },
  {
    name: 'Guernsey',
    code: 'GGY',
    code2: 'GG',
  },
  {
    name: 'Guinea',
    code: 'GIN',
    code2: 'GN',
  },
  {
    name: 'Guinea-Bissau',
    code: 'GNB',
    code2: 'GW',
  },
  {
    name: 'Guyana',
    code: 'GUY',
    code2: 'GY',
  },
  {
    name: 'Haiti',
    code: 'HTI',
    code2: 'HT',
  },
  {
    name: 'Heard Island and Mcdonald Islands',
    code: 'HMD',
    code2: 'HM',
  },
  {
    name: 'Holy See (Vatican City State)',
    code: 'VAT',
    code2: 'VA',
  },
  {
    name: 'Honduras',
    code: 'HND',
    code2: 'HN',
  },
  {
    name: 'Hong Kong',
    code: 'HKG',
    code2: 'HK',
    currencyCode: CurrencyCode.HKD,
    currencyName: 'Hong Kong Dollar',
    decimalPlaces: 2,
  },
  {
    name: 'Hungary',
    code: 'HUN',
    code2: 'HU',
  },
  {
    name: 'Iceland',
    code: 'ISL',
    code2: 'IS',
  },
  {
    name: 'India',
    code: 'IND',
    code2: 'IN',
  },
  {
    name: 'Indonesia',
    code: 'IDN',
    code2: 'ID',
  },
  {
    name: 'Iran, Islamic Republic Of',
    code: 'IRN',
    code2: 'IR',
  },
  {
    name: 'Iraq',
    code: 'IRQ',
    code2: 'IQ',
  },
  {
    name: 'Ireland',
    code: 'IRL',
    code2: 'IE',
  },
  {
    name: 'Isle of Man',
    code: 'IMN',
    code2: 'IM',
  },
  {
    name: 'Israel',
    code: 'ISR',
    code2: 'IL',
  },
  {
    name: 'Italy',
    code: 'ITA',
    code2: 'IT',
  },
  {
    name: 'Jamaica',
    code: 'JAM',
    code2: 'JM',
  },
  {
    name: 'Japan',
    code: 'JPN',
    code2: 'JP',
    currencyCode: CurrencyCode.JPY,
    currencyName: 'Japanese Yen',
    decimalPlaces: 0,
  },
  {
    name: 'Jersey',
    code: 'JEY',
    code2: 'JE',
  },
  {
    name: 'Jordan',
    code: 'JOR',
    code2: 'JO',
  },
  {
    name: 'Kenya',
    code: 'KEN',
    code2: 'KE',
  },
  {
    name: 'Kiribati',
    code: 'KIR',
    code2: 'KI',
  },
  {
    name: "Korea, Democratic People'S Republic of",
    code: 'PRK',
    code2: 'KP',
  },
  {
    name: 'Korea, Republic of',
    code: 'KOR',
    code2: 'KR',
  },
  {
    name: 'Kuwait',
    code: 'KWT',
    code2: 'KW',
  },
  {
    name: 'Kyrgyzstan',
    code: 'KGZ',
    code2: 'KG',
  },
  {
    name: "Lao People'S Democratic Republic",
    code: 'LAO',
    code2: 'LA',
  },
  {
    name: 'Latvia',
    code: 'LVA',
    code2: 'LV',
  },
  {
    name: 'Lebanon',
    code: 'LBN',
    code2: 'LB',
  },
  {
    name: 'Lesotho',
    code: 'LSO',
    code2: 'LS',
  },
  {
    name: 'Liberia',
    code: 'LBR',
    code2: 'LR',
  },
  {
    name: 'Libyan Arab Jamahiriya',
    code: 'LBY',
    code2: 'LY',
  },
  {
    name: 'Liechtenstein',
    code: 'LIE',
    code2: 'LI',
  },
  {
    name: 'Lithuania',
    code: 'LTU',
    code2: 'LT',
  },
  {
    name: 'Luxembourg',
    code: 'LUX',
    code2: 'LU',
  },
  {
    name: 'Macao',
    code: 'MAC',
    code2: 'MO',
  },
  {
    name: 'Macedonia, The Former Yugoslav Republic of',
    code: 'MKD',
    code2: 'MK',
  },
  {
    name: 'Madagascar',
    code: 'MDG',
    code2: 'MG',
  },
  {
    name: 'Malawi',
    code: 'MWI',
    code2: 'MW',
  },
  {
    name: 'Malaysia',
    code: 'MYS',
    code2: 'MY',
  },
  {
    name: 'Maldives',
    code: 'MDV',
    code2: 'MV',
  },
  {
    name: 'Mali',
    code: 'MLI',
    code2: 'ML',
  },
  {
    name: 'Malta',
    code: 'MLT',
    code2: 'MT',
  },
  {
    name: 'Marshall Islands',
    code: 'MHL',
    code2: 'MH',
  },
  {
    name: 'Martinique',
    code: 'MTQ',
    code2: 'MQ',
  },
  {
    name: 'Mauritania',
    code: 'MRT',
    code2: 'MR',
  },
  {
    name: 'Mauritius',
    code: 'MUS',
    code2: 'MU',
  },
  {
    name: 'Mayotte',
    code: 'MYT',
    code2: 'YT',
  },
  {
    name: 'Mexico',
    code: 'MEX',
    code2: 'MX',
    currencyCode: CurrencyCode.MXN,
    currencyName: 'Mexican Peso',
    decimalPlaces: 2,
  },
  {
    name: 'Micronesia, Federated States of',
    code: 'FSM',
    code2: 'FM',
  },
  {
    name: 'Moldova, Republic of',
    code: 'MDA',
    code2: 'MD',
  },
  {
    name: 'Monaco',
    code: 'MCO',
    code2: 'MC',
  },
  {
    name: 'Mongolia',
    code: 'MNG',
    code2: 'MN',
  },
  {
    name: 'Montserrat',
    code: 'MSR',
    code2: 'MS',
  },
  {
    name: 'Morocco',
    code: 'MAR',
    code2: 'MA',
  },
  {
    name: 'Mozambique',
    code: 'MOZ',
    code2: 'MZ',
  },
  {
    name: 'Myanmar',
    code: 'MMR',
    code2: 'MM',
  },
  {
    name: 'Namibia',
    code: 'NAM',
    code2: 'NA',
  },
  {
    name: 'Nauru',
    code: 'NRU',
    code2: 'NR',
  },
  {
    name: 'Nepal',
    code: 'NPL',
    code2: 'NP',
  },
  {
    name: 'Netherlands',
    code: 'NLD',
    code2: 'NL',
  },
  {
    name: 'New Caledonia',
    code: 'NCL',
    code2: 'NC',
  },
  {
    name: 'New Zealand',
    code: 'NZL',
    code2: 'NZ',
    currencyCode: CurrencyCode.NZD,
    currencyName: 'New Zealand Dollar',
    decimalPlaces: 2,
  },
  {
    name: 'Nicaragua',
    code: 'NIC',
    code2: 'NI',
  },
  {
    name: 'Niger',
    code: 'NER',
    code2: 'NE',
  },
  {
    name: 'Nigeria',
    code: 'NGA',
    code2: 'NG',
  },
  {
    name: 'Niue',
    code: 'NIU',
    code2: 'NU',
  },
  {
    name: 'Norfolk Island',
    code: 'NFK',
    code2: 'NF',
  },
  {
    name: 'Northern Mariana Islands',
    code: 'MNP',
    code2: 'MP',
  },
  {
    name: 'Norway',
    code: 'NOR',
    code2: 'NO',
    currencyCode: CurrencyCode.NOK,
    currencyName: 'Norwegian Krone',
    decimalPlaces: 2,
  },
  {
    name: 'Oman',
    code: 'OMN',
    code2: 'OM',
  },
  {
    name: 'Pakistan',
    code: 'PAK',
    code2: 'PK',
  },
  {
    name: 'Palau',
    code: 'PLW',
    code2: 'PW',
  },
  {
    name: 'Palestinian Territory, Occupied',
    code: 'PSE',
    code2: 'PS',
  },
  {
    name: 'Panama',
    code: 'PAN',
    code2: 'PA',
  },
  {
    name: 'Papua New Guinea',
    code: 'PNG',
    code2: 'PG',
  },
  {
    name: 'Paraguay',
    code: 'PRY',
    code2: 'PY',
  },
  {
    name: 'Peru',
    code: 'PER',
    code2: 'PE',
  },
  {
    name: 'Philippines',
    code: 'PHL',
    code2: 'PH',
    currencyCode: CurrencyCode.PHP,
    currencyName: 'Philippine Pesos',
    decimalPlaces: 2,
  },
  {
    name: 'Pitcairn',
    code: 'PCN',
    code2: 'PN',
  },
  {
    name: 'Poland',
    code: 'POL',
    code2: 'PL',
    currencyCode: CurrencyCode.PLN,
    currencyName: 'Polish Zloty',
    decimalPlaces: 2,
  },
  {
    name: 'Portugal',
    code: 'PRT',
    code2: 'PT',
  },
  {
    name: 'Puerto Rico',
    code: 'PRI',
    code2: 'PR',
  },
  {
    name: 'Qatar',
    code: 'QAT',
    code2: 'QA',
  },
  {
    name: 'Reunion',
    code: 'REU',
    code2: 'RE',
  },
  {
    name: 'Romania',
    code: 'ROU',
    code2: 'RO',
    currencyCode: CurrencyCode.RON,
    currencyName: 'Romanian New Leu',
    decimalPlaces: 2,
  },
  {
    name: 'Russian Federation',
    code: 'RUS',
    code2: 'RU',
    currencyCode: CurrencyCode.RUB,
    currencyName: 'Russian Rouble',
    decimalPlaces: 2,
  },
  {
    name: 'Rwanda',
    code: 'RWA',
    code2: 'RW',
  },
  {
    name: 'Saint Helena',
    code: 'SHN',
    code2: 'SH',
  },
  {
    name: 'Saint Kitts and Nevis',
    code: 'KNA',
    code2: 'KN',
  },
  {
    name: 'Saint Lucia',
    code: 'LCA',
    code2: 'LC',
  },
  {
    name: 'Saint Pierre and Miquelon',
    code: 'SPM',
    code2: 'PM',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    code: 'VCT',
    code2: 'VC',
  },
  {
    name: 'Samoa',
    code: 'WSM',
    code2: 'WS',
  },
  {
    name: 'San Marino',
    code: 'SMR',
    code2: 'SM',
  },
  {
    name: 'Sao Tome and Principe',
    code: 'STP',
    code2: 'ST',
  },
  {
    name: 'Saudi Arabia',
    code: 'SAU',
    code2: 'SA',
  },
  {
    name: 'Senegal',
    code: 'SEN',
    code2: 'SN',
  },
  {
    name: 'Seychelles',
    code: 'SYC',
    code2: 'SC',
  },
  {
    name: 'Sierra Leone',
    code: 'SLE',
    code2: 'SL',
  },
  {
    name: 'Singapore',
    code: 'SGP',
    code2: 'SG',
    currencyCode: CurrencyCode.SGD,
    currencyName: 'Singapore Dollar',
    decimalPlaces: 2,
  },
  {
    name: 'Slovakia',
    code: 'SVK',
    code2: 'SK',
  },
  {
    name: 'Slovenia',
    code: 'SVN',
    code2: 'SI',
  },
  {
    name: 'Solomon Islands',
    code: 'SLB',
    code2: 'SB',
  },
  {
    name: 'Somalia',
    code: 'SOM',
    code2: 'SO',
  },
  {
    name: 'South Africa',
    code: 'ZAF',
    code2: 'ZA',
    currencyCode: CurrencyCode.ZAR,
    currencyName: 'South African Rand',
    decimalPlaces: 2,
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    code: 'SGS',
    code2: 'GS',
  },
  {
    name: 'Spain',
    code: 'ESP',
    code2: 'ES',
  },
  {
    name: 'Sri Lanka',
    code: 'LKA',
    code2: 'LK',
  },
  {
    name: 'Sudan',
    code: 'SDN',
    code2: 'SD',
  },
  {
    name: 'Suriname',
    code: 'SUR',
    code2: 'SR',
  },
  {
    name: 'Svalbard and Jan Mayen',
    code: 'SJM',
    code2: 'SJ',
  },
  {
    name: 'Swaziland',
    code: 'SWZ',
    code2: 'SZ',
  },
  {
    name: 'Sweden',
    code: 'SWE',
    code2: 'SE',
    currencyCode: CurrencyCode.SEK,
    currencyName: 'Swedish Krona',
    decimalPlaces: 2,
  },
  {
    name: 'Switzerland',
    code: 'CHE',
    code2: 'CH',
    currencyCode: CurrencyCode.CHF,
    currencyName: 'Swiss Franc',
    decimalPlaces: 2,
  },
  {
    name: 'Syrian Arab Republic',
    code: 'SYR',
    code2: 'SY',
  },
  {
    name: 'Taiwan, Province of China',
    code: 'TWN',
    code2: 'TW',
  },
  {
    name: 'Tajikistan',
    code: 'TJK',
    code2: 'TJ',
  },
  {
    name: 'Tanzania, United Republic of',
    code: 'TZA',
    code2: 'TZ',
  },
  {
    name: 'Thailand',
    code: 'THA',
    code2: 'TH',
    currencyCode: CurrencyCode.THB,
    currencyName: 'Thai Baht',
    decimalPlaces: 2,
  },
  {
    name: 'Timor-Leste',
    code: 'TLS',
    code2: 'TL',
  },
  {
    name: 'Togo',
    code: 'TGO',
    code2: 'TG',
  },
  {
    name: 'Tokelau',
    code: 'TKL',
    code2: 'TK',
  },
  {
    name: 'Tonga',
    code: 'TON',
    code2: 'TO',
  },
  {
    name: 'Trinidad and Tobago',
    code: 'TTO',
    code2: 'TT',
  },
  {
    name: 'Tunisia',
    code: 'TUN',
    code2: 'TN',
  },
  {
    name: 'Turkey',
    code: 'TUR',
    code2: 'TR',
    currencyCode: CurrencyCode.TRY,
    currencyName: 'Turkish Lira',
    decimalPlaces: 2,
  },
  {
    name: 'Turkmenistan',
    code: 'TKM',
    code2: 'TM',
  },
  {
    name: 'Turks and Caicos Islands',
    code: 'TCA',
    code2: 'TC',
  },
  {
    name: 'Tuvalu',
    code: 'TUV',
    code2: 'TV',
  },
  {
    name: 'Uganda',
    code: 'UGA',
    code2: 'UG',
  },
  {
    name: 'Ukraine',
    code: 'UKR',
    code2: 'UA',
  },
  {
    name: 'United Arab Emirates',
    code: 'ARE',
    code2: 'AE',
    currencyCode: CurrencyCode.AED,
    currencyName: 'United Arab Emirates Dirham',
    decimalPlaces: 2,
  },
  {
    name: 'United Kingdom',
    code: 'GBR',
    code2: 'GB',
    currencyCode: CurrencyCode.GBP,
    currencyName: 'British Pound',
    decimalPlaces: 2,
  },
  {
    name: 'United States',
    code: 'USA',
    code2: 'US',
    currencyCode: CurrencyCode.USD,
    currencyName: 'United States Dollar',
    decimalPlaces: 2,
  },
  {
    name: 'United States Minor Outlying Islands',
    code: 'UMI',
    code2: 'UM',
  },
  {
    name: 'Uruguay',
    code: 'URY',
    code2: 'UY',
  },
  {
    name: 'Uzbekistan',
    code: 'UZB',
    code2: 'UZ',
  },
  {
    name: 'Vanuatu',
    code: 'VUT',
    code2: 'VU',
  },
  {
    name: 'Venezuela',
    code: 'VEN',
    code2: 'VE',
  },
  {
    name: 'Viet Nam',
    code: 'VNM',
    code2: 'VN',
  },
  {
    name: 'Virgin Islands, British',
    code: 'VGB',
    code2: 'VG',
  },
  {
    name: 'Virgin Islands, U.S.',
    code: 'VIR',
    code2: 'VI',
  },
  {
    name: 'Wallis and Futuna',
    code: 'WLF',
    code2: 'WF',
  },
  {
    name: 'Western Sahara',
    code: 'ESH',
    code2: 'EH',
  },
  {
    name: 'Yemen',
    code: 'YEM',
    code2: 'YE',
  },
  {
    name: 'Zambia',
    code: 'ZMB',
    code2: 'ZM',
  },
  {
    name: 'Zimbabwe',
    code: 'ZWE',
    code2: 'ZW',
  },
]

const Euro = {
  name: 'Euro',
  code: 'EUR',
  code2: 'EUR',
  currencyCode: CurrencyCode.EUR,
  currencyName: 'Euro',
  decimalPlaces: 2,
} as CountryListType

export const Currencies = [...COUNTRIES_LIST.filter((country) => 'currencyCode' in country), Euro]

export const getCurrencyNameByCurrencyCode = (currencyCode: CurrencyCode) =>
  Currencies.find((c) => currencyCode === c.currencyCode)?.currencyName as string

export const getCountryNameByCurrencyCode = (currencyCode: CurrencyCode) =>
  Currencies.find((c) => currencyCode === c.currencyCode)?.name as string

export const getCountryCodeByCurrencyCode = (currencyCode: CurrencyCode) =>
  Currencies.find((c) => currencyCode === c.currencyCode)?.code as CountryCode

export const SupportedCurrenciesList = [
  ...COUNTRIES_LIST.filter((country) => isAllowedCurrency(country?.currencyCode || '')),
  Euro,
]

export type CountryCode =
  | 'EUR'
  | 'AND'
  | 'ARE'
  | 'ATG'
  | 'AIA'
  | 'ALB'
  | 'ARM'
  | 'AGO'
  | 'ATA'
  | 'ARG'
  | 'ASM'
  | 'AUT'
  | 'AUS'
  | 'ABW'
  | 'ALA'
  | 'AZE'
  | 'BIH'
  | 'BRB'
  | 'BGD'
  | 'BEL'
  | 'BES'
  | 'BFA'
  | 'BGR'
  | 'BHR'
  | 'BDI'
  | 'BEN'
  | 'BLM'
  | 'BMU'
  | 'BRN'
  | 'BOL'
  | 'BRA'
  | 'BHS'
  | 'BTN'
  | 'BWA'
  | 'BLR'
  | 'BLZ'
  | 'CAN'
  | 'CCK'
  | 'COD'
  | 'CAF'
  | 'COG'
  | 'CHE'
  | 'CIV'
  | 'COK'
  | 'CHL'
  | 'CMR'
  | 'CHN'
  | 'COL'
  | 'CRI'
  | 'CUB'
  | 'CPV'
  | 'CUW'
  | 'CXR'
  | 'CYP'
  | 'CZE'
  | 'DEU'
  | 'DJI'
  | 'DNK'
  | 'DMA'
  | 'DOM'
  | 'DZA'
  | 'ECU'
  | 'EST'
  | 'EGY'
  | 'ESH'
  | 'ERI'
  | 'ESP'
  | 'ETH'
  | 'FIN'
  | 'FJI'
  | 'FLK'
  | 'FSM'
  | 'FRO'
  | 'FRA'
  | 'GAB'
  | 'GBR'
  | 'GRD'
  | 'GEO'
  | 'GUF'
  | 'GGY'
  | 'GHA'
  | 'GIB'
  | 'GRL'
  | 'GMB'
  | 'GIN'
  | 'GLP'
  | 'GNQ'
  | 'GRC'
  | 'SGS'
  | 'GTM'
  | 'GUM'
  | 'GNB'
  | 'GUY'
  | 'HKG'
  | 'HMD'
  | 'HND'
  | 'HRV'
  | 'HTI'
  | 'HUN'
  | 'IDN'
  | 'IRL'
  | 'ISR'
  | 'IMN'
  | 'IND'
  | 'IOT'
  | 'IRQ'
  | 'IRN'
  | 'ISL'
  | 'ITA'
  | 'JEY'
  | 'JAM'
  | 'JOR'
  | 'JPN'
  | 'KEN'
  | 'KGZ'
  | 'KHM'
  | 'KIR'
  | 'COM'
  | 'KNA'
  | 'PRK'
  | 'KOR'
  | 'KWT'
  | 'CYM'
  | 'KAZ'
  | 'LAO'
  | 'LBN'
  | 'LCA'
  | 'LIE'
  | 'LKA'
  | 'LBR'
  | 'LSO'
  | 'LTU'
  | 'LUX'
  | 'LVA'
  | 'LBY'
  | 'MAR'
  | 'MCO'
  | 'MDA'
  | 'MNE'
  | 'MAF'
  | 'MDG'
  | 'MHL'
  | 'MKD'
  | 'MLI'
  | 'MMR'
  | 'MNG'
  | 'MAC'
  | 'MNP'
  | 'MTQ'
  | 'MRT'
  | 'MSR'
  | 'MLT'
  | 'MUS'
  | 'MDV'
  | 'MWI'
  | 'MEX'
  | 'MYS'
  | 'MOZ'
  | 'MYT'
  | 'NAM'
  | 'NCL'
  | 'NER'
  | 'NFK'
  | 'NGA'
  | 'NIC'
  | 'NLD'
  | 'NOR'
  | 'NPL'
  | 'NRU'
  | 'NIU'
  | 'NZL'
  | 'OMN'
  | 'PAN'
  | 'PER'
  | 'PYF'
  | 'PNG'
  | 'PHL'
  | 'PAK'
  | 'POL'
  | 'SPM'
  | 'PCN'
  | 'PRI'
  | 'PSE'
  | 'PRT'
  | 'PLW'
  | 'PRY'
  | 'QAT'
  | 'REU'
  | 'ROU'
  | 'SRB'
  | 'RUS'
  | 'RWA'
  | 'SAU'
  | 'SLB'
  | 'SYC'
  | 'SDN'
  | 'SWE'
  | 'SGP'
  | 'SHN'
  | 'SVN'
  | 'SJM'
  | 'SVK'
  | 'SLE'
  | 'SMR'
  | 'SEN'
  | 'SOM'
  | 'SUR'
  | 'SSD'
  | 'STP'
  | 'SLV'
  | 'SXM'
  | 'SYR'
  | 'SWZ'
  | 'TCA'
  | 'TCD'
  | 'ATF'
  | 'TGO'
  | 'THA'
  | 'TJK'
  | 'TKL'
  | 'TLS'
  | 'TKM'
  | 'TUN'
  | 'TON'
  | 'TUR'
  | 'TTO'
  | 'TUV'
  | 'TWN'
  | 'TZA'
  | 'UKR'
  | 'UGA'
  | 'UMI'
  | 'USA'
  | 'URY'
  | 'UZB'
  | 'VAT'
  | 'VCT'
  | 'VEN'
  | 'VGB'
  | 'VIR'
  | 'VNM'
  | 'VUT'
  | 'WLF'
  | 'WSM'
  | 'YEM'
  | 'ZAF'
  | 'ZMB'
  | 'ZWE'
  | 'AFG'
  | 'BVT'

export const COUNTRIES_ALPHA_2_TO_ALPHA_3: Record<string, CountryCode> = {
  AF: 'AFG',
  AL: 'ALB',
  DZ: 'DZA',
  AS: 'ASM',
  AD: 'AND',
  AO: 'AGO',
  AI: 'AIA',
  AQ: 'ATA',
  AG: 'ATG',
  AR: 'ARG',
  AM: 'ARM',
  AW: 'ABW',
  AU: 'AUS',
  AT: 'AUT',
  AZ: 'AZE',
  BS: 'BHS',
  BH: 'BHR',
  BD: 'BGD',
  BB: 'BRB',
  BY: 'BLR',
  BE: 'BEL',
  BZ: 'BLZ',
  BJ: 'BEN',
  BM: 'BMU',
  BT: 'BTN',
  BO: 'BOL',
  BA: 'BIH',
  BW: 'BWA',
  BV: 'BVT',
  BR: 'BRA',
  IO: 'IOT',
  BN: 'BRN',
  BG: 'BGR',
  BF: 'BFA',
  BI: 'BDI',
  CV: 'CPV',
  KH: 'KHM',
  CM: 'CMR',
  CA: 'CAN',
  KY: 'CYM',
  CF: 'CAF',
  TD: 'TCD',
  CL: 'CHL',
  CN: 'CHN',
  CX: 'CXR',
  CC: 'CCK',
  CO: 'COL',
  KM: 'COM',
  CD: 'COD',
  CG: 'COG',
  CK: 'COK',
  CR: 'CRI',
  HR: 'HRV',
  CU: 'CUB',
  CW: 'CUW',
  CY: 'CYP',
  CZ: 'CZE',
  CI: 'CIV',
  DK: 'DNK',
  DJ: 'DJI',
  DM: 'DMA',
  DO: 'DOM',
  EC: 'ECU',
  EG: 'EGY',
  SV: 'SLV',
  GQ: 'GNQ',
  ER: 'ERI',
  EE: 'EST',
  SZ: 'SWZ',
  ET: 'ETH',
  FK: 'FLK',
  FO: 'FRO',
  FJ: 'FJI',
  FI: 'FIN',
  FR: 'FRA',
  GF: 'GUF',
  PF: 'PYF',
  TF: 'ATF',
  GA: 'GAB',
  GM: 'GMB',
  GE: 'GEO',
  DE: 'DEU',
  GH: 'GHA',
  GI: 'GIB',
  GR: 'GRC',
  GL: 'GRL',
  GD: 'GRD',
  GP: 'GLP',
  GU: 'GUM',
  GT: 'GTM',
  GG: 'GGY',
  GN: 'GIN',
  GW: 'GNB',
  GY: 'GUY',
  HT: 'HTI',
  HM: 'HMD',
  VA: 'VAT',
  HN: 'HND',
  HK: 'HKG',
  HU: 'HUN',
  IS: 'ISL',
  IN: 'IND',
  ID: 'IDN',
  IR: 'IRN',
  IQ: 'IRQ',
  IE: 'IRL',
  IM: 'IMN',
  IL: 'ISR',
  IT: 'ITA',
  JM: 'JAM',
  JP: 'JPN',
  JE: 'JEY',
  JO: 'JOR',
  KZ: 'KAZ',
  KE: 'KEN',
  KI: 'KIR',
  KP: 'PRK',
  KR: 'KOR',
  KW: 'KWT',
  KG: 'KGZ',
  LA: 'LAO',
  LV: 'LVA',
  LB: 'LBN',
  LS: 'LSO',
  LR: 'LBR',
  LY: 'LBY',
  LI: 'LIE',
  LT: 'LTU',
  LU: 'LUX',
  MO: 'MAC',
  MG: 'MDG',
  MW: 'MWI',
  MY: 'MYS',
  MV: 'MDV',
  ML: 'MLI',
  MT: 'MLT',
  MH: 'MHL',
  MQ: 'MTQ',
  MR: 'MRT',
  MU: 'MUS',
  YT: 'MYT',
  MX: 'MEX',
  FM: 'FSM',
  MD: 'MDA',
  MC: 'MCO',
  MN: 'MNG',
  ME: 'MNE',
  MS: 'MSR',
  MA: 'MAR',
  MZ: 'MOZ',
  MM: 'MMR',
  NA: 'NAM',
  NR: 'NRU',
  NP: 'NPL',
  NL: 'NLD',
  NC: 'NCL',
  NZ: 'NZL',
  NI: 'NIC',
  NE: 'NER',
  NG: 'NGA',
  NU: 'NIU',
  NF: 'NFK',
  MP: 'MNP',
  NO: 'NOR',
  OM: 'OMN',
  PK: 'PAK',
  PW: 'PLW',
  PS: 'PSE',
  PA: 'PAN',
  PG: 'PNG',
  PY: 'PRY',
  PE: 'PER',
  PH: 'PHL',
  PN: 'PCN',
  PL: 'POL',
  PT: 'PRT',
  PR: 'PRI',
  QA: 'QAT',
  MK: 'MKD',
  RO: 'ROU',
  RU: 'RUS',
  RW: 'RWA',
  RE: 'REU',
  BL: 'BLM',
  SH: 'SHN',
  KN: 'KNA',
  LC: 'LCA',
  MF: 'MAF',
  PM: 'SPM',
  VC: 'VCT',
  WS: 'WSM',
  SM: 'SMR',
  ST: 'STP',
  SA: 'SAU',
  SN: 'SEN',
  RS: 'SRB',
  SC: 'SYC',
  SL: 'SLE',
  SG: 'SGP',
  SX: 'SXM',
  SK: 'SVK',
  SI: 'SVN',
  SB: 'SLB',
  SO: 'SOM',
  ZA: 'ZAF',
  GS: 'SGS',
  SS: 'SSD',
  ES: 'ESP',
  LK: 'LKA',
  SD: 'SDN',
  SR: 'SUR',
  SJ: 'SJM',
  SE: 'SWE',
  CH: 'CHE',
  SY: 'SYR',
  TW: 'TWN',
  TJ: 'TJK',
  TZ: 'TZA',
  TH: 'THA',
  TL: 'TLS',
  TG: 'TGO',
  TK: 'TKL',
  TO: 'TON',
  TT: 'TTO',
  TN: 'TUN',
  TR: 'TUR',
  TM: 'TKM',
  TC: 'TCA',
  TV: 'TUV',
  UG: 'UGA',
  UA: 'UKR',
  AE: 'ARE',
  GB: 'GBR',
  UM: 'UMI',
  US: 'USA',
  UY: 'URY',
  UZ: 'UZB',
  VU: 'VUT',
  VE: 'VEN',
  VN: 'VNM',
  VG: 'VGB',
  VI: 'VIR',
  WF: 'WLF',
  EH: 'ESH',
  YE: 'YEM',
  ZM: 'ZMB',
  ZW: 'ZWE',
  AX: 'ALA',
}

export const getAlpha3Code = (code: string) => {
  return code.length === 3 ? code : COUNTRIES_ALPHA_2_TO_ALPHA_3[code]
}
