import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const helpers = createMultiStyleConfigHelpers(['container', 'content', 'label', 'supportingText', 'arrow'])

export const List = helpers.defineMultiStyleConfig({
  defaultProps: {
    size: 'lg',
  },
  baseStyle: ({ colorMode }: any) => ({
    container: {
      alignItems: 'center',
      cursor: 'pointer',
      width: '100%',
      border: '1px solid',
      borderColor: colorMode === 'light' ? '_primary.100' : '_primary.800',
      _hover: {
        bgColor: colorMode === 'light' ? '_primary.50' : '_primary.800',
      },
      _focus: {
        outlineStyle: 'solid',
        outlineWidth: '1',
        outlineColor: colorMode === 'light' ? 'accent.500' : 'accent.400',
      },
      _disabled: {
        color: colorMode === 'light' ? '_primary.300' : '_primary.800',
        bgColor: colorMode === 'light' ? '_primary.50' : '_primary.900',
        outlineStyle: 'none',
        cursor: 'not-allowed',
      },
    },
    content: {
      flex: 1,
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    label: {
      color: colorMode === 'light' ? '_primary.900' : '_primary.300',
      _disabled: {
        color: colorMode === 'light' ? '_primary.300' : '_primary.800',
      },
    },
    supportingText: {
      color: colorMode === 'light' ? '_primary.500' : '_primary.500',
      _disabled: {
        color: colorMode === 'light' ? '_primary.300' : '_primary.800',
      },
    },
    arrow: {
      w: '1.5rem',
      h: '1.5rem',
      transition: '0.2s',
      '[data-part-name="container"]:hover &': {
        transform: 'translateX(8px)',
      },
      _disabled: {
        color: colorMode === 'light' ? '_primary.300' : '_primary.800',
        '[data-part-name="container"]:hover &': {
          transform: 'translateX(0px)',
        },
      },
    },
  }),
  sizes: {
    lg: {
      container: {
        borderRadius: 'md',
        paddingY: '0.62rem',
        paddingLeft: '1.25rem',
        paddingRight: '1.5rem',
      },
      content: {
        marginX: '1rem',
      },
      label: {
        fontSize: '1.125rem',
        fontWeight: 500,
        lineHeight: '1.5rem',
        letterSpacing: '-0.0225rem',
      },
      supportingText: {
        fontSize: '0.75rem',
        fontWeight: 400,
        lineHeight: '1.125rem',
        marginTop: '0.125rem',
      },
    },
    xl: {
      container: {
        borderRadius: 'lg',
        paddingY: '1.5rem',
        paddingX: '2rem',
      },
      content: {
        marginX: '1.5rem',
      },
      label: {
        fontSize: '1.75rem',
        fontWeight: 500,
        lineHeight: '2rem',
        letterSpacing: '-0.035rem',
      },
      supportingText: {
        fontSize: '1.125rem',
        fontWeight: 400,
        lineHeight: '1.5rem',
        letterSpacing: '-0.0225rem',
        marginTop: '0.25rem',
      },
    },
  },
})
