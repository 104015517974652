import { useFundingsByBusinessIdPaginatedQuery } from '@/gql'

export const useHasCapitalFundings = () => {
  const { data, loading: isLoading } = useFundingsByBusinessIdPaginatedQuery({
    variables: {
      limit: 1,
      page: 1,
      financingChannel: 'CAPITAL',
    },
  })

  const { total: hasFundings } = data?.fundingsByBusinessIdPaginated ?? {}

  return {
    hasFundings: Boolean(hasFundings),
    isLoading,
  }
}
