import { useSession } from 'next-auth/react'
import { isDefined } from 'ui-lib'

export const useInternalUser = () => {
  const { data } = useSession()
  const user = data?.user.internal

  if (!isDefined(user)) {
    return null
  }

  return user
}
