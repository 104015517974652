import { ChakraProvider, localStorageManager } from '@chakra-ui/react'
import React from 'react'

import { theme } from './theme'

interface ThemeProviderProps {
  children: React.ReactNode
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  return (
    <ChakraProvider colorModeManager={localStorageManager} theme={theme}>
      {children}
    </ChakraProvider>
  )
}
