import { AnimatePresence, motion } from 'framer-motion'
import React, { useCallback } from 'react'
import { useTimeoutWhen } from 'rooks'
import { useBoolean } from 'ui-lib'

import { defaultProp } from '@/src/utils/defaultProp'

const spring = {
  type: 'spring',
  damping: 5,
  stiffness: 100,
}

interface Props {
  show?: boolean
  onAnimationEnded?: () => void
  skipDelay?: boolean
  exitDelayMs?: number
}

export default function LoadingIcon(props: Props) {
  const show = defaultProp(props.show, true)
  const onAnimationEnded = defaultProp(props.onAnimationEnded, () => {})
  const skipDelay = defaultProp(props.skipDelay, false)
  const exitDelayMs = defaultProp(props.exitDelayMs, 200)
  const animationEndDelay = skipDelay ? 0 : exitDelayMs
  const [trigger, { on }] = useBoolean(false)

  useTimeoutWhen(
    () => {
      onAnimationEnded()
    },
    animationEndDelay,
    trigger
  )

  const onExitComplete = useCallback(() => {
    on()
  }, [on])

  return (
    <AnimatePresence onExitComplete={onExitComplete}>
      {show && (
        <motion.svg
          width="58"
          height="58"
          viewBox="31 40 60 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          initial={{ opacity: 0 }}
          transition={spring}
          exit={{
            transition: {
              duration: 0.4,
            },
            opacity: 0,
            scale: 1.7,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.4,
            },
          }}
          style={{
            originX: '50%',
            originY: '50%',
            shapeRendering: 'geometricPrecision',
          }}
        >
          <motion.path
            d="M77.1606 55.1846L71.4888 49.6582L77.7943 43.1865L83.4661 48.7025C85.1304 50.3218 86.46 52.2531 87.3779 54.386C88.2959 56.5189 88.7847 58.8117 88.8165 61.1335C88.8484 63.4554 88.4226 65.7607 87.5635 67.918C86.7043 70.0752 85.4287 72.0422 83.8095 73.7064C82.1902 75.3707 80.259 76.6998 78.1261 77.6177C75.9932 78.5356 73.7004 79.0245 71.3786 79.0563C66.6894 79.1206 62.1668 77.3195 58.8057 74.0493L53.134 68.5229L59.4395 62.0512L65.1112 67.5672C66.3339 68.7628 67.885 69.5672 69.5667 69.8781C71.2483 70.1889 72.9845 69.9919 74.5538 69.3123C75.5966 68.8667 76.5397 68.2168 77.3274 67.401C78.523 66.1782 79.3275 64.6271 79.6383 62.9455C79.9491 61.2638 79.7521 59.5276 79.0726 57.9583C78.6269 56.9155 77.9764 55.9723 77.1606 55.1846Z"
            fill="#EAEAEA"
          />
          <motion.path
            d="M63.0125 47.0087C59.6514 43.7385 55.1288 41.9374 50.4396 42.0017C45.7505 42.066 41.279 43.9904 38.0087 47.3515C34.7385 50.7127 32.9374 55.2353 33.0017 59.9244C33.066 64.6136 34.9904 69.0851 38.3515 72.3553L44.0234 77.8715L50.3289 71.3998L44.657 65.8421C43.4388 64.6547 42.5986 63.1338 42.2419 61.4704C41.8852 59.8071 42.0279 58.0754 42.6521 56.4929C43.0656 55.4354 43.6913 54.4737 44.4908 53.6674C45.281 52.8518 46.2232 52.199 47.2644 51.7456C48.3084 51.2966 49.4314 51.06 50.5678 51.0496C51.7021 51.0337 52.8284 51.2421 53.882 51.6629C54.9356 52.0836 55.8956 52.7083 56.707 53.5012L56.712 53.4961L62.3784 59.0069L68.6839 52.5352L63.0125 47.0087Z"
            fill="#EAEAEA"
          />
          <motion.mask
            id="mask"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="33"
            y="42"
            width="58"
            height="38"
          >
            <motion.path
              d="M77.1606 55.1846L71.4888 49.6582L77.7943 43.1865L83.4661 48.7025C85.1304 50.3218 86.46 52.2531 87.3779 54.386C88.2959 56.5189 88.7847 58.8117 88.8165 61.1335C88.8484 63.4554 88.4226 65.7607 87.5635 67.918C86.7043 70.0752 85.4287 72.0422 83.8095 73.7064C82.1902 75.3707 80.259 76.6998 78.1261 77.6177C75.9932 78.5356 73.7004 79.0245 71.3786 79.0563C66.6894 79.1206 62.1668 77.3195 58.8057 74.0493L53.134 68.5229L59.4395 62.0512L65.1112 67.5672C66.3339 68.7628 67.885 69.5672 69.5667 69.8781C71.2483 70.1889 72.9845 69.9919 74.5538 69.3123C75.5966 68.8667 76.5397 68.2168 77.3274 67.401C78.523 66.1782 79.3275 64.6271 79.6383 62.9455C79.9491 61.2638 79.7521 59.5276 79.0726 57.9583C78.6269 56.9155 77.9764 55.9723 77.1606 55.1846Z"
              fill="white"
            />
            <motion.path
              d="M63.0125 47.0087C59.6514 43.7385 55.1288 41.9374 50.4396 42.0017C45.7505 42.066 41.279 43.9904 38.0087 47.3515C34.7385 50.7127 32.9374 55.2353 33.0017 59.9244C33.066 64.6136 34.9904 69.0851 38.3515 72.3553L44.0234 77.8715L50.3289 71.3998L44.657 65.8421C43.4388 64.6547 42.5986 63.1338 42.2419 61.4704C41.8852 59.8071 42.0279 58.0754 42.6521 56.4929C43.0656 55.4354 43.6913 54.4737 44.4908 53.6674C45.281 52.8518 46.2232 52.199 47.2644 51.7456C48.3084 51.2966 49.4314 51.06 50.5678 51.0496C51.7021 51.0337 52.8284 51.2421 53.882 51.6629C54.9356 52.0836 55.8956 52.7083 56.707 53.5012L56.712 53.4961L62.3784 59.0069L68.6839 52.5352L63.0125 47.0087Z"
              fill="white"
            />
          </motion.mask>
          <motion.g mask="url(#mask)">
            <motion.rect
              x="32"
              y="38"
              height="44"
              fill="url(#myGradient)"
              initial={{
                width: 58,
              }}
              // animate={show ? 'active' : 'hidden'}
              // variants={{
              //   active: {
              //     transition: {
              //       times: [0, 0.5, 0.9, 1.2],

              //       ease: 'easeInOut',
              //       duration: 2,
              //     },
              //     width: [0, 20, 40, 70],
              //   },
              //   hidden: {
              //     transition: {
              //       ease: 'easeOut',
              //       duration: 0.3,
              //     },
              //     width: 100,
              //   },
              // }}
            />
          </motion.g>
          <defs>
            <linearGradient id="myGradient" x1="-300" y1="5" x2="300" y2="5" gradientUnits="userSpaceOnUse">
              <stop offset="0" stopColor="#FFE6A5" />
              <stop offset="0.25" stopColor="#DB57B6" />
              <stop offset="0.5" stopColor="#FFE6A5" />
              <stop offset="0.75" stopColor="#DB57B6" />
              <stop offset="1" stopColor="#FFE6A5" />
              <animate attributeName="x1" from="-300" to="0" dur="2s" repeatCount="indefinite" restart="always" />
              <animate attributeName="x2" from="300" to="500" dur="2s" repeatCount="indefinite" restart="always" />
            </linearGradient>
          </defs>
        </motion.svg>
      )}
    </AnimatePresence>
  )
}
