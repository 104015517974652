export const legacyTextStyles = {
  t1: {
    semibold: {
      fontSize: '50px',
      fontWeight: '600',
      lineHeight: '54px',
      letterSpacing: '-0.05em',
    },
    regular: {
      fontSize: '50px',
      fontWeight: '400',
      lineHeight: '56px',
      letterSpacing: '-0.05em',
    },
  },
  t2: {
    regular: {
      fontSize: '34px',
      fontWeight: '500',
      lineHeight: '50px',
      letterSpacing: '-0.05em',
    },
    semibold: {
      fontSize: ['24px', '34px'],
      fontWeight: '600',
      lineHeight: '50px',
      letterSpacing: '-0.05em',
    },
  },
  h1: {
    semibold: {
      fontSize: '28px',
      fontWeight: '600',
      lineHeight: '32px',
      letterSpacing: '-0.02em',
    },
    medium: {
      fontSize: '28px',
      fontWeight: '500',
      lineHeight: '32px',
      letterSpacing: '-0.02em',
    },
    mobile: {
      semibold: {
        fontSize: '24px',
        fontWeight: '600',
        lineHeight: '33px',
        letterSpacing: '-0.02em',
      },
    },
  },
  h2: {
    regular: {
      fontSize: '20px',
      fontWeight: '400',
      lineHeight: '24px',
      letterSpacing: '-0.02em',
    },
    semibold: {
      fontSize: '20px',
      fontWeight: '600',
      lineHeight: '24px',
      letterSpacing: '-0.02em',
    },
  },
  sectionHeader: {
    fontSize: '24px',
    fontWeight: '600',
    lineHeight: '32px',
    letterSpacing: '-0.02em',
  },
  h3: {
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '-0.02em',
  },
  caption: {
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '32px',
    letterSpacing: '-0.02em',
  },
  caption1: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '24px',
    letterSpacing: '-0.02em',
  },
  caption2: {
    regular: {
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '16px',
      letterSpacing: '-0.02em',
    },
    medium: {
      fontSize: '12px',
      fontWeight: '500',
      lineHeight: '16px',
      letterSpacing: '-0.02em',
    },
  },
  smallText: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    letterSpacing: '-0.02em',
  },
  regularText: {
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '-0.02em',
  },
  mediumText: {
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '24px',
    letterSpacing: '-0.02em',
  },
  semiboldText: {
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '24px',
    letterSpacing: '-0.02em',
  },
  outstandingText: {
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '24px',
    letterSpacing: '-0.02em',
  },
  step: {
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '24px',
    marginLeft: '14px',
  },
  button: {
    semibold: {
      fontSize: '20px',
      fontWeight: '600',
      lineHeight: '24px',
      letterSpacing: '-0.03em',
    },
    secondaryMedium: {
      fontSize: '20px',
      fontWeight: '500',
      lineHeight: '24px',
      letterSpacing: '-0.02em',
    },
  },
  sub: {
    semibold: {
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '24px',
      letterSpacing: '-0.02em',
    },
    medium: {
      fontSize: '16px',
      fontWeight: '500',
      lineHeight: '24px',
      letterSpacing: '-0.03em',
    },
    regular: {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
      letterSpacing: '-0.04em',
    },
  },
  stepper: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    letterSpacing: '-0.02em',
  },
  subLink: {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '24px',
    letterSpacing: '-0.02em',
  },
  heading: {
    xl: {
      regular: {
        fontSize: '4rem',
        fontWeight: '400',
        lineHeight: '5rem',
        letterSpacing: '-4%',
      },
      medium: {
        fontSize: '4rem',
        fontWeight: '500',
        lineHeight: '5rem',
        letterSpacing: '-4%',
      },
      semibold: {
        fontSize: '4rem',
        fontWeight: '600',
        lineHeight: '5rem',
        letterSpacing: '-4%',
      },
    },
    lg: {
      regular: {
        fontSize: '3rem',
        fontWeight: '400',
        lineHeight: '3.75rem',
        letterSpacing: '-2%',
      },
      medium: {
        fontSize: '3rem',
        fontWeight: '500',
        lineHeight: '3.75rem',
        letterSpacing: '-2%',
      },
      semibold: {
        fontSize: '3rem',
        fontWeight: '600',
        lineHeight: '3.75rem',
        letterSpacing: '-2%',
      },
    },
    md: {
      regular: {
        fontSize: '2rem',
        fontWeight: '400',
        lineHeight: '2.5rem',
        letterSpacing: '-2%',
      },
      medium: {
        fontSize: '2rem',
        fontWeight: '500',
        lineHeight: '2.5rem',
        letterSpacing: '-2%',
      },
      semibold: {
        fontSize: '2rem',
        fontWeight: '600',
        lineHeight: '2.5rem',
        letterSpacing: '-2%',
      },
    },
    sm: {
      regular: {
        fontSize: '1.75rem',
        fontWeight: '400',
        lineHeight: '2rem',
        letterSpacing: '-2%',
      },
      medium: {
        fontSize: '1.75rem',
        fontWeight: '500',
        lineHeight: '2rem',
        letterSpacing: '-2%',
      },
      semibold: {
        fontSize: '1.75rem',
        fontWeight: '600',
        lineHeight: '2rem',
        letterSpacing: '-2%',
      },
    },
    xs: {
      regular: {
        fontSize: '1.25rem',
        fontWeight: '400',
        lineHeight: '1.5rem',
        letterSpacing: '-2%',
      },
      medium: {
        fontSize: '1.25rem',
        fontWeight: '500',
        lineHeight: '1.5rem',
        letterSpacing: '-2%',
      },
      semibold: {
        fontSize: '1.25rem',
        fontWeight: '600',
        lineHeight: '1.5rem',
        letterSpacing: '-2%',
      },
    },
  },
  text: {
    lg: {
      regular: {
        fontSize: '1.125rem',
        fontWeight: '400',
        lineHeight: '1.5rem',
      },
      medium: {
        fontSize: '1.125rem',
        fontWeight: '500',
        lineHeight: '1.5rem',
      },
      semibold: {
        fontSize: '1.125rem',
        fontWeight: '600',
        lineHeight: '1.5rem',
      },
    },
    md: {
      regular: {
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '1.5rem',
      },
      medium: {
        fontSize: '1rem',
        fontWeight: '500',
        lineHeight: '1.5rem',
      },
      semibold: {
        fontSize: '1rem',
        fontWeight: '600',
        lineHeight: '1.5rem',
      },
    },
    sm: {
      regular: {
        fontSize: '0.875rem',
        fontWeight: '400',
        lineHeight: '1.25rem',
      },
      medium: {
        fontSize: '0.875rem',
        fontWeight: '500',
        lineHeight: '1.25rem',
      },
      semibold: {
        fontSize: '0.875rem',
        fontWeight: '600',
        lineHeight: '1.25rem',
      },
    },
    xs: {
      regular: {
        fontSize: '0.75rem',
        fontWeight: '400',
        lineHeight: '1.125rem',
      },
      medium: {
        fontSize: '0.75rem',
        fontWeight: '500',
        lineHeight: '1.125rem',
      },
      semibold: {
        fontSize: '0.75rem',
        fontWeight: '600',
        lineHeight: '1.125rem',
      },
    },
  },
}
