// src/constants/geography.ts
var DEFAULT_COUNTRY = "CAN";
var CANADIAN_PROVINCES = {
  ONTARIO: "Ontario",
  BRITISH_COLUMBIA: "British Columbia",
  ALBERTA: "Alberta",
  MANITOBA: "Manitoba",
  NOVA_SCOTIA: "Nova Scotia",
  NEW_BRUNSWICK: "New Brunswick",
  SASKATCHEWAN: "Saskatchewan",
  NEWFOUNDLAND_AND_LABRADOR: "Newfoundland and Labrador",
  PRINCE_EDWARD_ISLAND: "Prince Edward Island",
  "NORTHWEST TERRITORIES": "Northwest Territories",
  YUKON: "Yukon",
  NUNAVUT: "Nunavut",
  QUEBEC: "Quebec"
};
var CanadianProvinces = [
  {
    name: CANADIAN_PROVINCES["NORTHWEST TERRITORIES"],
    code: "NT"
  },
  {
    name: CANADIAN_PROVINCES.ALBERTA,
    code: "AB"
  },
  {
    name: CANADIAN_PROVINCES.BRITISH_COLUMBIA,
    code: "BC"
  },
  {
    name: CANADIAN_PROVINCES.MANITOBA,
    code: "MB"
  },
  {
    name: CANADIAN_PROVINCES.NEW_BRUNSWICK,
    code: "NB"
  },
  {
    name: CANADIAN_PROVINCES.NEWFOUNDLAND_AND_LABRADOR,
    code: "NL"
  },
  {
    name: CANADIAN_PROVINCES.NOVA_SCOTIA,
    code: "NS"
  },
  {
    name: CANADIAN_PROVINCES.NUNAVUT,
    code: "NU"
  },
  {
    name: CANADIAN_PROVINCES.ONTARIO,
    code: "ON"
  },
  {
    name: CANADIAN_PROVINCES.PRINCE_EDWARD_ISLAND,
    code: "PE"
  },
  {
    name: CANADIAN_PROVINCES.QUEBEC,
    code: "QC"
  },
  {
    name: CANADIAN_PROVINCES.SASKATCHEWAN,
    code: "SK"
  },
  {
    name: CANADIAN_PROVINCES.YUKON,
    code: "YT"
  }
];
var QUEBEC_WITH_ACCENT = "Qu\xE9bec";
var QUEBEC_STATE_CODE = "QC";
var RESTRICTED_PROVINCES = [
  // Quebec is the only restricted province. Yet in can be represented with and without the accent.
  CANADIAN_PROVINCES.QUEBEC,
  QUEBEC_WITH_ACCENT,
  QUEBEC_STATE_CODE
];
var RESTRICTED_PROVINCES_TO_LOWER = RESTRICTED_PROVINCES.map((e) => e.toLowerCase());
var cities = [
  { name: "Banff", provinceCode: "AB" },
  { name: "Brooks", provinceCode: "AB" },
  { name: "Calgary", provinceCode: "AB" },
  { name: "Edmonton", provinceCode: "AB" },
  { name: "Fort McMurray", provinceCode: "AB" },
  { name: "Grande Prairie", provinceCode: "AB" },
  { name: "Jasper", provinceCode: "AB" },
  { name: "Lake Louise", provinceCode: "AB" },
  { name: "Lethbridge", provinceCode: "AB" },
  { name: "Medicine Hat", provinceCode: "AB" },
  { name: "Red Deer", provinceCode: "AB" },
  { name: "Saint Albert", provinceCode: "AB" },
  { name: "Sherwood Park", provinceCode: "AB" },
  { name: "Barkerville", provinceCode: "BC" },
  { name: "Burnaby", provinceCode: "BC" },
  { name: "Campbell River", provinceCode: "BC" },
  { name: "Chilliwack", provinceCode: "BC" },
  { name: "Courtenay", provinceCode: "BC" },
  { name: "Cranbrook", provinceCode: "BC" },
  { name: "Dawson Creek", provinceCode: "BC" },
  { name: "Delta", provinceCode: "BC" },
  { name: "Esquimalt", provinceCode: "BC" },
  { name: "Fort Saint James", provinceCode: "BC" },
  { name: "Fort Saint John", provinceCode: "BC" },
  { name: "Hope", provinceCode: "BC" },
  { name: "Kamloops", provinceCode: "BC" },
  { name: "Kelowna", provinceCode: "BC" },
  { name: "Kimberley", provinceCode: "BC" },
  { name: "Kitimat", provinceCode: "BC" },
  { name: "Langley", provinceCode: "BC" },
  { name: "Nanaimo", provinceCode: "BC" },
  { name: "Nelson", provinceCode: "BC" },
  { name: "New Westminster", provinceCode: "BC" },
  { name: "North Vancouver", provinceCode: "BC" },
  { name: "Oak Bay", provinceCode: "BC" },
  { name: "Penticton", provinceCode: "BC" },
  { name: "Powell River", provinceCode: "BC" },
  { name: "Prince George", provinceCode: "BC" },
  { name: "Prince Rupert", provinceCode: "BC" },
  { name: "Quesnel", provinceCode: "BC" },
  { name: "Revelstoke", provinceCode: "BC" },
  { name: "Rossland", provinceCode: "BC" },
  { name: "Trail", provinceCode: "BC" },
  { name: "Vancouver", provinceCode: "BC" },
  { name: "Vernon", provinceCode: "BC" },
  { name: "Victoria", provinceCode: "BC" },
  { name: "West Vancouver", provinceCode: "BC" },
  { name: "White Rock", provinceCode: "BC" },
  { name: "Brandon", provinceCode: "MB" },
  { name: "Churchill", provinceCode: "MB" },
  { name: "Dauphin", provinceCode: "MB" },
  { name: "Flin Flon", provinceCode: "MB" },
  { name: "Kildonan", provinceCode: "MB" },
  { name: "Saint Boniface", provinceCode: "MB" },
  { name: "Swan River", provinceCode: "MB" },
  { name: "Thompson", provinceCode: "MB" },
  { name: "Winnipeg", provinceCode: "MB" },
  { name: "York Factory", provinceCode: "MB" },
  { name: "Bathurst", provinceCode: "NB" },
  { name: "Caraquet", provinceCode: "NB" },
  { name: "Dalhousie", provinceCode: "NB" },
  { name: "Fredericton", provinceCode: "NB" },
  { name: "Miramichi", provinceCode: "NB" },
  { name: "Moncton", provinceCode: "NB" },
  { name: "Saint John", provinceCode: "NB" },
  { name: "Argentia", provinceCode: "NL" },
  { name: "Bonavista", provinceCode: "NL" },
  { name: "Channel-Port aux Basques", provinceCode: "NL" },
  { name: "Corner Brook", provinceCode: "NL" },
  { name: "Ferryland", provinceCode: "NL" },
  { name: "Gander", provinceCode: "NL" },
  { name: "Grand Falls\u2013Windsor", provinceCode: "NL" },
  { name: "Happy Valley\u2013Goose Bay", provinceCode: "NL" },
  { name: "Harbour Grace", provinceCode: "NL" },
  { name: "Labrador City", provinceCode: "NL" },
  { name: "Placentia", provinceCode: "NL" },
  { name: "Saint Anthony", provinceCode: "NL" },
  { name: "St. John\u2019s", provinceCode: "NL" },
  { name: "Wabana", provinceCode: "NL" },
  { name: "Fort Smith", provinceCode: "NT" },
  { name: "Hay River", provinceCode: "NT" },
  { name: "Inuvik", provinceCode: "NT" },
  { name: "Tuktoyaktuk", provinceCode: "NT" },
  { name: "Yellowknife", provinceCode: "NT" },
  { name: "Baddeck", provinceCode: "NS" },
  { name: "Digby", provinceCode: "NS" },
  { name: "Glace Bay", provinceCode: "NS" },
  { name: "Halifax", provinceCode: "NS" },
  { name: "Liverpool", provinceCode: "NS" },
  { name: "Louisbourg", provinceCode: "NS" },
  { name: "Lunenburg", provinceCode: "NS" },
  { name: "Pictou", provinceCode: "NS" },
  { name: "Port Hawkesbury", provinceCode: "NS" },
  { name: "Springhill", provinceCode: "NS" },
  { name: "Sydney", provinceCode: "NS" },
  { name: "Yarmouth", provinceCode: "NS" },
  { name: "Iqaluit", provinceCode: "NU" },
  { name: "Bancroft", provinceCode: "ON" },
  { name: "Barrie", provinceCode: "ON" },
  { name: "Belleville", provinceCode: "ON" },
  { name: "Brampton", provinceCode: "ON" },
  { name: "Brantford", provinceCode: "ON" },
  { name: "Brockville", provinceCode: "ON" },
  { name: "Burlington", provinceCode: "ON" },
  { name: "Cambridge", provinceCode: "ON" },
  { name: "Chatham", provinceCode: "ON" },
  { name: "Chatham-Kent", provinceCode: "ON" },
  { name: "Cornwall", provinceCode: "ON" },
  { name: "Elliot Lake", provinceCode: "ON" },
  { name: "Etobicoke", provinceCode: "ON" },
  { name: "Fort Erie", provinceCode: "ON" },
  { name: "Fort Frances", provinceCode: "ON" },
  { name: "Gananoque", provinceCode: "ON" },
  { name: "Guelph", provinceCode: "ON" },
  { name: "Hamilton", provinceCode: "ON" },
  { name: "Iroquois Falls", provinceCode: "ON" },
  { name: "Kapuskasing", provinceCode: "ON" },
  { name: "Kawartha Lakes", provinceCode: "ON" },
  { name: "Kenora", provinceCode: "ON" },
  { name: "Kingston", provinceCode: "ON" },
  { name: "Kirkland Lake", provinceCode: "ON" },
  { name: "Kitchener", provinceCode: "ON" },
  { name: "Laurentian Hills", provinceCode: "ON" },
  { name: "London", provinceCode: "ON" },
  { name: "Midland", provinceCode: "ON" },
  { name: "Mississauga", provinceCode: "ON" },
  { name: "Moose Factory", provinceCode: "ON" },
  { name: "Moosonee", provinceCode: "ON" },
  { name: "Niagara Falls", provinceCode: "ON" },
  { name: "Niagara-on-the-Lake", provinceCode: "ON" },
  { name: "North Bay", provinceCode: "ON" },
  { name: "North York", provinceCode: "ON" },
  { name: "Oakville", provinceCode: "ON" },
  { name: "Orillia", provinceCode: "ON" },
  { name: "Oshawa", provinceCode: "ON" },
  { name: "Ottawa", provinceCode: "ON" },
  { name: "Parry Sound", provinceCode: "ON" },
  { name: "Perth", provinceCode: "ON" },
  { name: "Peterborough", provinceCode: "ON" },
  { name: "Picton", provinceCode: "ON" },
  { name: "Port Colborne", provinceCode: "ON" },
  { name: "Saint Catharines", provinceCode: "ON" },
  { name: "Saint Thomas", provinceCode: "ON" },
  { name: "Sarnia-Clearwater", provinceCode: "ON" },
  { name: "Sault Sainte Marie", provinceCode: "ON" },
  { name: "Scarborough", provinceCode: "ON" },
  { name: "Simcoe", provinceCode: "ON" },
  { name: "Stratford", provinceCode: "ON" },
  { name: "Sudbury", provinceCode: "ON" },
  { name: "Temiskaming Shores", provinceCode: "ON" },
  { name: "Thorold", provinceCode: "ON" },
  { name: "Thunder Bay", provinceCode: "ON" },
  { name: "Timmins", provinceCode: "ON" },
  { name: "Toronto", provinceCode: "ON" },
  { name: "Trenton", provinceCode: "ON" },
  { name: "Waterloo", provinceCode: "ON" },
  { name: "Welland", provinceCode: "ON" },
  { name: "West Nipissing", provinceCode: "ON" },
  { name: "Windsor", provinceCode: "ON" },
  { name: "Woodstock", provinceCode: "ON" },
  { name: "York", provinceCode: "ON" },
  { name: "Borden", provinceCode: "PE" },
  { name: "Cavendish", provinceCode: "PE" },
  { name: "Charlottetown", provinceCode: "PE" },
  { name: "Souris", provinceCode: "PE" },
  { name: "Summerside", provinceCode: "PE" },
  { name: "Asbestos", provinceCode: "QC" },
  { name: "Baie-Comeau", provinceCode: "QC" },
  { name: "Beloeil", provinceCode: "QC" },
  { name: "Cap-de-la-Madeleine", provinceCode: "QC" },
  { name: "Chambly", provinceCode: "QC" },
  { name: "Charlesbourg", provinceCode: "QC" },
  { name: "Ch\xE2teauguay", provinceCode: "QC" },
  { name: "Chibougamau", provinceCode: "QC" },
  { name: "C\xF4te-Saint-Luc", provinceCode: "QC" },
  { name: "Dorval", provinceCode: "QC" },
  { name: "Gasp\xE9", provinceCode: "QC" },
  { name: "Gatineau", provinceCode: "QC" },
  { name: "Granby", provinceCode: "QC" },
  { name: "Havre-Saint-Pierre", provinceCode: "QC" },
  { name: "Hull", provinceCode: "QC" },
  { name: "Jonqui\xE8re", provinceCode: "QC" },
  { name: "Kuujjuaq", provinceCode: "QC" },
  { name: "La Salle", provinceCode: "QC" },
  { name: "La Tuque", provinceCode: "QC" },
  { name: "Lachine", provinceCode: "QC" },
  { name: "Laval", provinceCode: "QC" },
  { name: "L\xE9vis", provinceCode: "QC" },
  { name: "Longueuil", provinceCode: "QC" },
  { name: "Magog", provinceCode: "QC" },
  { name: "Matane", provinceCode: "QC" },
  { name: "Montreal", provinceCode: "QC" },
  { name: "Montr\xE9al-Nord", provinceCode: "QC" },
  { name: "Perc\xE9", provinceCode: "QC" },
  { name: "Port-Cartier", provinceCode: "QC" },
  { name: "Quebec", provinceCode: "QC" },
  { name: "Rimouski", provinceCode: "QC" },
  { name: "Rouyn-Noranda", provinceCode: "QC" },
  { name: "Saguenay", provinceCode: "QC" },
  { name: "Saint-Eustache", provinceCode: "QC" },
  { name: "Saint-Hubert", provinceCode: "QC" },
  { name: "Sainte-Anne-de-Beaupr\xE9", provinceCode: "QC" },
  { name: "Sainte-Foy", provinceCode: "QC" },
  { name: "Sainte-Th\xE9r\xE8se", provinceCode: "QC" },
  { name: "Sept-\xCEles", provinceCode: "QC" },
  { name: "Sherbrooke", provinceCode: "QC" },
  { name: "Sorel-Tracy", provinceCode: "QC" },
  { name: "Trois-Rivi\xE8res", provinceCode: "QC" },
  { name: "Val-d\u2019Or", provinceCode: "QC" },
  { name: "Waskaganish", provinceCode: "QC" },
  { name: "Batoche", provinceCode: "SK" },
  { name: "Cumberland House", provinceCode: "SK" },
  { name: "Estevan", provinceCode: "SK" },
  { name: "Flin Flon", provinceCode: "SK" },
  { name: "Moose Jaw", provinceCode: "SK" },
  { name: "Prince Albert", provinceCode: "SK" },
  { name: "Regina", provinceCode: "SK" },
  { name: "Saskatoon", provinceCode: "SK" },
  { name: "Uranium City", provinceCode: "SK" },
  { name: "Dawson", provinceCode: "YT" },
  { name: "Watson Lake", provinceCode: "YT" },
  { name: "Whitehorse", provinceCode: "YT" }
];
var getCityOptionsByProvinceCode = (provinceCode) => {
  return cities.filter((city) => city.provinceCode === provinceCode).map((city) => ({ label: city.name, value: city.name }));
};
var getStateOrProvinceByCode = (code) => {
  const lowerCaseCode = code.toLowerCase();
  return CanadianProvinces.find((province) => province.code.toLowerCase() === lowerCaseCode);
};
var getStateOrProvinceByName = (name) => {
  const lowercaseName = name.toLowerCase();
  return CanadianProvinces.find((province) => {
    return province.name.toLowerCase() === lowercaseName;
  });
};
var isRestrictedProvince = (province) => {
  return RESTRICTED_PROVINCES_TO_LOWER.includes(province.toLowerCase());
};
var PROVINCES_OPTIONS = CanadianProvinces.map((province) => ({
  label: province.name,
  value: province.code
}));
var BLOCKED_COUNTRIES_FOR_SHIPPING = [
  "BIH",
  "BLR",
  "COD",
  "CUB",
  "IRN",
  "IRQ",
  "LBN",
  "LBY",
  "MKD",
  "MNE",
  "PRK",
  "RUS",
  "SDN",
  "SOM",
  "SRB",
  "SSD",
  "SYR",
  "TUR",
  "UKR",
  "YEM",
  "ZWE"
];

export { BLOCKED_COUNTRIES_FOR_SHIPPING, CANADIAN_PROVINCES, CanadianProvinces, DEFAULT_COUNTRY, PROVINCES_OPTIONS, RESTRICTED_PROVINCES, RESTRICTED_PROVINCES_TO_LOWER, getCityOptionsByProvinceCode, getStateOrProvinceByCode, getStateOrProvinceByName, isRestrictedProvince };
