import { BusinessStatus } from 'db-schema'
import { useRouter } from 'next/router'
import { signIn, useSession } from 'next-auth/react'
import React, { useCallback, useEffect } from 'react'
import { Center, useBoolean } from 'ui-lib'
import { isDefined } from 'utils'

import { AUTH_PAGE_REDIRECT_TO, AuthPageAction } from '@/src/constants/auth'
import { ACCOUNTANT_PAGES, CARD_HOLDER_PAGES, PageType } from '@/src/constants/pages'
import usePrevious from '@/src/hooks/usePrevious'
import { getUserStatus } from '@/src/utils/user-session'

import LoadingIcon from './Loading/LoadingIcon'

const authActions: Record<AuthPageAction, string> = {
  [AUTH_PAGE_REDIRECT_TO.LOGIN]: '/login',
  [AUTH_PAGE_REDIRECT_TO.APPLICATION_V2]: '/application/v2',
  [AUTH_PAGE_REDIRECT_TO.MISSING_INFORMATION]: '/application/v2/missing-information',
  [AUTH_PAGE_REDIRECT_TO.CONFIRM_EMAIL]: '/sign-up/confirm-email',
  [AUTH_PAGE_REDIRECT_TO.DENIED]: '/application/denied',
  [AUTH_PAGE_REDIRECT_TO.SUBMITTED]: '/application/submitted',
  [AUTH_PAGE_REDIRECT_TO.SUBMITTED_V2]: '/application/v2/submitted',
  [AUTH_PAGE_REDIRECT_TO.ADDITIONAL_INFO]: '/application/additional-documents',
  [AUTH_PAGE_REDIRECT_TO.ONBOARDING]: '/onboarding',
  [AUTH_PAGE_REDIRECT_TO.APPLICATION]: '/application',
  [AUTH_PAGE_REDIRECT_TO.HOME]: '/',
  [AUTH_PAGE_REDIRECT_TO.RELINK_PLAID_BUSINESS]: '/relink-bank',
  [AUTH_PAGE_REDIRECT_TO.RELINK_PLAID_APPLICATION]: '/application/plaid',
  [AUTH_PAGE_REDIRECT_TO.INVITATION_SUBMITTED]: '/employee-invitation/submitted',
  [AUTH_PAGE_REDIRECT_TO.INVITATION_REJECTED]: '/employee-invitation/rejected',
  [AUTH_PAGE_REDIRECT_TO.SCREENING_REJECTED]: '/employee-invitation/rejected-screening',
  [AUTH_PAGE_REDIRECT_TO.NONE]: '',
}

/**
 * Checks if a given route is contained in a list of pages, whether using
 * strict or non-strict route matching.
 *
 * Strict route matching means that the route must be exactly the same as a
 * route in the list of pages. Non-strict route matching means that the
 * route can start with a route in the list of pages.
 *
 * Example:
 * ```
 * - Strict route matching: '/banking' in ['/banking'] => true
 * - Strict route matching: '/banking/cad' in ['/banking'] => false
 * - Non-strict route matching: '/banking' in ['/banking'] => true
 * - Non-strict route matching: '/banking/cad' in ['/banking'] => true
 * ```
 *
 * @param routeIn Route to check
 * @param pagesList List of pages to check against
 * @param useStrictMatching Whether to check for strict route match or not.
 * Defaults to false
 * @returns Whether the route is in the list of pages or not
 */
const routeInPages = (routeIn: string, pagesList: PageType[], useStrictMatching: boolean = false): boolean => {
  return isDefined(
    pagesList.find(({ route }) => {
      if (route === '/') {
        return route === routeIn
      }
      return useStrictMatching ? route === routeIn : routeIn.startsWith(route)
    })
  )
}

export const determineAuthAction = (session: any, isLoading: boolean, route: string): AuthPageAction => {
  const {
    userExists,
    userIsVerified,
    userIsCardHolder,
    userIsAccountant,
    userIsAdminAccount,
    userIsFounder,
    userHasOnboarded,
    userHasApprovedApplication,
    userHasSubmittedApplication,
    userHasCompletedApplicationOnboarding,
    userHasInReviewApplication,
    userHasDeniedApplication,
    userBusinessStatus,
    userHasPendingApproval,
    userApprovalWasRejected,
    userApplicationV2,
  } = getUserStatus(session)

  const isRouteOnboardingOrApplication = route.startsWith('/application') || route.startsWith('/onboarding')

  const isRouteRelinkBankAccount = route === '/relink-bank-account' || route.startsWith('/bank-connection')

  const shouldRedirectToApplication =
    userIsFounder &&
    (!userHasApprovedApplication ||
      ([BusinessStatus.DRAFT, BusinessStatus.VERIFIED, BusinessStatus.UNDERWRITTEN] as BusinessStatus[]).includes(
        userBusinessStatus
      ))

  const shouldRedirectToSubmittedPage =
    userHasSubmittedApplication ||
    userHasInReviewApplication ||
    (userHasApprovedApplication && userBusinessStatus === BusinessStatus.UNDERWRITTEN) ||
    (userHasApprovedApplication && userBusinessStatus === BusinessStatus.VERIFIED)

  const isRouteDocumentUploader = route.startsWith('/upload')
  // Note: ORDER MATTERS FOR THIS SWITCH TO WORK
  switch (true) {
    case isLoading:
      return AUTH_PAGE_REDIRECT_TO.NONE
    case userHasCompletedApplicationOnboarding && isRouteOnboardingOrApplication:
      return AUTH_PAGE_REDIRECT_TO.HOME
    case !userExists:
      return AUTH_PAGE_REDIRECT_TO.LOGIN
    //Application V2 should not redirect to confirm email
    case !userIsVerified && !userApplicationV2:
      return AUTH_PAGE_REDIRECT_TO.CONFIRM_EMAIL
    case userHasDeniedApplication:
      return AUTH_PAGE_REDIRECT_TO.DENIED
    case isRouteDocumentUploader:
      return AUTH_PAGE_REDIRECT_TO.NONE
    case isRouteRelinkBankAccount:
      return AUTH_PAGE_REDIRECT_TO.NONE
    case shouldRedirectToSubmittedPage:
      return AUTH_PAGE_REDIRECT_TO.SUBMITTED_V2
    case userBusinessStatus === BusinessStatus.BLOCKED:
      // TODO Blocked case
      return AUTH_PAGE_REDIRECT_TO.NONE
    case userApplicationV2 && shouldRedirectToApplication:
      return AUTH_PAGE_REDIRECT_TO.APPLICATION_V2
    case shouldRedirectToApplication:
      return AUTH_PAGE_REDIRECT_TO.APPLICATION
    case !userHasOnboarded:
      // we're in the onboarding flow
      if (route.startsWith('/onboarding') && route !== '/onboarding') {
        return AUTH_PAGE_REDIRECT_TO.NONE
      }
      // we need to be redirect to the onboarding flow
      return AUTH_PAGE_REDIRECT_TO.ONBOARDING
    case !userIsFounder && isRouteOnboardingOrApplication:
      return AUTH_PAGE_REDIRECT_TO.HOME
    case userIsCardHolder && !routeInPages(route, CARD_HOLDER_PAGES):
      return AUTH_PAGE_REDIRECT_TO.HOME
    case userIsAccountant && !routeInPages(route, ACCOUNTANT_PAGES):
      return AUTH_PAGE_REDIRECT_TO.HOME

    case userIsAdminAccount && userApprovalWasRejected:
      return AUTH_PAGE_REDIRECT_TO.INVITATION_REJECTED
    case userApprovalWasRejected:
      return AUTH_PAGE_REDIRECT_TO.SCREENING_REJECTED
    case userHasPendingApproval:
      return AUTH_PAGE_REDIRECT_TO.INVITATION_SUBMITTED
    default:
      return AUTH_PAGE_REDIRECT_TO.NONE
  }
}

interface Props {
  children: React.ReactElement
  skipAccountVerification?: boolean
}

const AuthOnly = ({ children, skipAccountVerification = false }: Props) => {
  const { push, route, asPath, query } = useRouter()
  const { data: session, status } = useSession({
    required: true,
    onUnauthenticated() {
      const currentPath = asPath
      const currentRedirect = query?.redirect
      const redirect = (currentRedirect ?? currentPath) as string
      const newQuery = ['/', '/session-expired'].includes(redirect) ? {} : { redirect }

      push({
        pathname: '/session-expired',
        query: newQuery,
      })
    },
  })

  const previousRoute = usePrevious(route)
  const { userExists, userIsVerified, userApplicationV2 } = getUserStatus(session)
  const [loadingAuthAction, setLoadingAuthAction] = useBoolean(true)

  const sessionReady = status !== 'loading'
  const shouldRenderDashboard = userExists && (userIsVerified || skipAccountVerification || userApplicationV2)

  const navigateTo = useCallback(
    async (nextRoute: string) => {
      if (!route.startsWith(nextRoute) || nextRoute === '/') {
        console.log(`Redirecting from ${route} to ${nextRoute}`)
        await push(nextRoute)
      }
    },
    [push, route]
  )

  // If the route changes, we need to set the loading state
  useEffect(() => {
    if (previousRoute !== route) {
      setLoadingAuthAction.on()
    }
  }, [previousRoute, route, setLoadingAuthAction])

  // If the session changes, we need to evaluate the redirect and turn off the loading state accordingly
  useEffect(() => {
    const evaluateRedirect = () => {
      // NextJS session is determining if the user is authenticated
      if (!sessionReady) {
        return
      }

      // Do we need to redirect the user somewhere?
      const authAction = determineAuthAction(session, !sessionReady, route)
      const redirect = authActions[authAction]
      if (Boolean(redirect)) {
        if (redirect === authActions.LOGIN) {
          signIn()
          return
        }

        navigateTo(redirect)
      }

      // Do we need to turn off the loading state?
      const noRedirectionNeeded = redirect === ''
      const redirectIsNotDashboard = redirect !== '/'
      const newRouteIsChildrenOfRoute = route.startsWith(redirect)

      if (((redirectIsNotDashboard && newRouteIsChildrenOfRoute) || noRedirectionNeeded) && sessionReady) {
        setLoadingAuthAction.off()
      }
    }
    evaluateRedirect()
  }, [setLoadingAuthAction, route, session, sessionReady, navigateTo])

  if (!loadingAuthAction && shouldRenderDashboard) {
    return children
  }

  // Session is being fetched, or no user.
  // If no user, useEffect() will redirect.
  return (
    <Center width="100vw" height="100vh">
      <LoadingIcon />
    </Center>
  )
}

export default AuthOnly
