import { tabsAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const helpers = createMultiStyleConfigHelpers([...tabsAnatomy.keys])

export const Tabs = helpers.defineMultiStyleConfig({
  baseStyle: {},
  variants: {
    default: {
      tablist: {
        background: 'bg-neutral',
        borderRadius: 'md',
        width: 'max-content',
        padding: 2,
      },
      tab: {
        paddingX: 4,
        paddingY: 1,
        borderRadius: 'sm',
        color: 'text-secondary',
        textStyle: 'title-xs',
        border: '1px solid',
        borderColor: 'transparent',
        position: 'relative',

        _selected: {
          background: 'bg-primary',
          borderColor: 'border-soft',
          boxShadow: 'xs',
          color: 'text-primary',
        },
        _disabled: {
          color: 'text-disabled',
          cursor: 'not-allowed',
        },

        '&:not(:first-child)': {
          marginLeft: 2,
        },

        '&:not(:last-child)': {
          marginRight: '2',
        },

        '&:not(:last-child)::before': {
          content: '""',
          position: 'absolute',
          right: '-10px',
          width: '1px',
          height: '18px',
          bg: 'border-soft',
        },
      },
    },
    legacy: {
      tabpanel: {
        p: 0,
      },
      tab: {
        height: '100%',
        boxSizing: 'border-box',
        userSelect: 'none',
        font: 'Inter',
        fontSize: 'paragraph.lg',
        fontWeight: 600,
        lineHeight: '1.5rem',
        letterSpacing: '-0.025rem',
        color: '_primary.500',
        _hover: {
          color: '_primary.500',
          cursor: 'pointer',
          marginBottom: '-4px',
          borderBottomWidth: '4px',
          borderColor: 'currentColor',
        },
        _active: {
          color: '_primary.900',
          marginBottom: '1px',
          borderBottomWidth: '4px',
          borderColor: 'currentColor',
        },
        _selected: {
          color: '_primary.900',
          marginBottom: '-4px',
          borderBottomWidth: '4px',
          borderColor: 'currentColor',
        },
        _disabled: {
          cursor: 'not-allowed',
          color: '_primary.200',
          marginBottom: 0,
          opacity: 1,
          _hover: {
            cursor: 'not-allowed',
            color: '_primary.200',
            marginBottom: 0,
            border: 'none',
          },
        },
      },
    },
  },
  defaultProps: {
    variant: 'default',
  },
})
