import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { FeatureFlags, KeepFeatureFlag } from 'services/src/featureFlag/featureFlags'

import isDefined from '@/src/utils/dataShaping/isDefined'
import { InternalUser } from '@/types/next-auth'

const getAllFeatureFlagStatus = (user: InternalUser) => {
  const availableFeatureFlags = Object.keys(FeatureFlags).map(async (flag) => {
    const flagValidator = FeatureFlags[flag as KeepFeatureFlag]
    try {
      const flagEnabled = await flagValidator(user?.business)
      return {
        featureFlag: flag as KeepFeatureFlag,
        enabled: flagEnabled,
      }
    } catch (error) {
      return {
        featureFlag: flag as KeepFeatureFlag,
        enabled: false,
      }
    }
  })
  return Promise.all(availableFeatureFlags)
}

export const setFeatureFlags = createAsyncThunk(
  'featureFlags/setFeatureFlags',
  async (user: InternalUser, { rejectWithValue }) => {
    try {
      const allFlags = await getAllFeatureFlagStatus(user)
      return allFlags
    } catch (error) {
      console.log('Error evaluating featureFlags', error)
      rejectWithValue(error)
    }
  }
)

const featureFlags = createSlice({
  name: 'featureFlags',
  initialState: {
    flags: [] as KeepFeatureFlag[],
    ready: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setFeatureFlags.fulfilled, (state, action) => {
      if (isDefined(action.payload)) {
        state.flags = action.payload.filter((flag) => flag.enabled).map((flag) => flag.featureFlag)
        state.ready = true
      }
    })
  },
})

export default featureFlags
