import { can } from '@try-keep/auth'
import Image from 'next/image'
import Link from 'next/link'
import React, { FC, PropsWithChildren } from 'react'
import { Box, Button, Flex, Text } from 'ui-lib'

import { useInternalUser } from '@/src/hooks/misc'

const ItemMedia: FC<PropsWithChildren> = ({ children }) => (
  <Flex alignItems="center" display={{ base: 'none', lg: 'flex' }}>
    {children}
  </Flex>
)

const ItemText: FC<PropsWithChildren> = ({ children }) => (
  <Flex direction="column" flex={1} justifyContent="center">
    {children}
  </Flex>
)

const ItemActions: FC<PropsWithChildren> = ({ children }) => (
  <Flex justify={{ base: 'flex-start', lg: 'flex-end' }} align="center">
    {children}
  </Flex>
)

const Container: FC<PropsWithChildren> = ({ children }) => (
  <Box
    display="flex"
    flexDirection={{ base: 'column', lg: 'row' }}
    borderRadius="16px"
    borderColor="border-soft"
    borderWidth={1}
    w="100%"
    overflow="hidden"
    p={3}
    pl={6}
    pr={6}
    gap={4}
    shadow="md"
  >
    {children}
  </Box>
)

interface GetStartedBannerProps {
  getStartedLink: string
  onGetStarted?: () => void
  preApprovedCapital: string
}

export const GetStartedBanner: FC<GetStartedBannerProps> = ({ getStartedLink, onGetStarted, preApprovedCapital }) => {
  const user = useInternalUser()

  return (
    <Container>
      <ItemMedia>
        <Image width={80} height={80} src="/images/capital/success-coin.png" alt="success-coin" />
      </ItemMedia>
      <ItemText>
        <Text textStyle="title-lg" color="text-primary">
          You have been pre-approved for financing of {preApprovedCapital}.
        </Text>
        <Text textStyle="paragraph-md" color="text-secondary">
          Complete our 3 step process and get funds in your Keep CAD Account within 24 hours!
        </Text>
      </ItemText>
      <ItemActions>
        {can(user, 'create', 'Capital') && (
          <Button as={Link} href={getStartedLink} onClick={onGetStarted}>
            Get started
          </Button>
        )}
      </ItemActions>
    </Container>
  )
}
