import { ComponentProps } from 'react'
import { chakra } from 'ui-lib'

const BankIcon = (props?: ComponentProps<typeof chakra.svg>) => {
  return (
    <chakra.svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      marginTop="2px"
      {...props}
    >
      <path
        d="M1.5 16.5H16.5M3.16667 16.5V11.5H4.83333M13.1667 16.5V11.5H14.8333M8.16667 16.5V11.5H9.83333M15.6667 5.66667V8.16667H2.33333V5.66667L9 1.5L15.6667 5.66667Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </chakra.svg>
  )
}

export default BankIcon
