import { KeepFeatureFlags } from 'services/src/featureFlag/featureFlags'

import { PageType } from '@/src/constants/pages'
import { StepType } from '@/src/constants/steps'

export const CAPITAL_APPLICATION_STEPS: StepType[] = [
  {
    label: 'Purpose',
    route: '/capital/apply',
  },
  {
    label: 'Financing plan',
    route: '/capital/plan',
  },
  {
    label: 'Review',
    route: '/capital/confirmation',
  },
]

export const CAPITAL_APPLICATION_PAGES: PageType[] = [
  {
    section: 'Capital - Apply',
    route: '/capital/apply',
    page: 'capital-apply',
    featureFlags: [KeepFeatureFlags.CAPITAL_ACCESS],
  },
  {
    section: 'Capital - Plan',
    route: '/capital/plan',
    page: 'capital-plan',
    featureFlags: [KeepFeatureFlags.CAPITAL_ACCESS],
  },
  {
    section: 'Capital - Confirmation',
    route: '/capital/confirmation',
    page: 'capital-confirmation',
    featureFlags: [KeepFeatureFlags.CAPITAL_ACCESS],
  },
]
export const CAPITAL_APPLICATION_ACTIVE_STEP_BY_PAGE: {
  [key: string]: number
} = {
  'capital-apply': 0,
  'capital-plan': 1,
  'capital-confirmation': 2,
}

export const CAPITAL_APPLICATION_PAGES_PAYMENT_EXTENSION: PageType[] = [
  {
    section: 'Capital - Plan',
    route: '/capital/plan',
    page: 'capital-plan',
    featureFlags: [KeepFeatureFlags.CAPITAL_ACCESS],
  },
  {
    section: 'Capital - Confirmation',
    route: '/capital/confirmation',
    page: 'capital-confirmation',
    featureFlags: [KeepFeatureFlags.CAPITAL_ACCESS],
  },
]

export const CAPITAL_APPLICATION_ACTIVE_STEP_BY_PAGE_PAYMENT_EXTENSION: {
  [key: string]: number
} = {
  'capital-plan': 0,
  'capital-confirmation': 1,
}

export const FUNDING_STATE_NAMES: Record<string, string> = {
  SUBMITTED: 'SUBMITTED',
  DEPOSITED: 'DEPOSITED',
  PAID: 'PAID',
  VOID: 'VOID',
  REFINANCED: 'REFINANCED',
  CANCELLED: 'CANCELLED',
}

export const FUNDING_STATUS_STYLES: Record<string, string> = {
  SUBMITTED: 'info',
  DEPOSITED: 'positive',
  PAID: 'positive',
  VOID: 'neutral',
  REFINANCED: 'neutral',
  CANCELLED: 'neutral',
}

export const INSTALLMENT_STATE_NAMES: Record<string, string> = {
  PAID: 'PAID',
  PENDING: 'PENDING',
  OVERDUE: 'OVERDUE',
  VOID: 'VOID',
  IN_PROGRESS: 'IN PROGRESS',
  FAILED: 'FAILED',
  REFINANCED: 'REFINANCED',
  CANCELLED: 'CANCELLED',
}

export const INSTALLMENT_STATUS_STYLES: Record<string, string> = {
  PAID: 'positive',
  PENDING: 'info',
  OVERDUE: 'neutral',
  VOID: 'soft',
  IN_PROGRESS: 'info',
  FAILED: 'negative',
  REFINANCED: 'soft',
  CANCELLED: 'soft',
}
