import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material/styles'
import { deepmerge } from '@mui/utils'
import React from 'react'
import { theme, ThemeProvider } from 'ui-lib'

interface Props {
  cookies?: string | undefined
  children: React.ReactNode
}

const AppThemeProvider = (props: Props) => {
  const muiTheme = createTheme({
    typography: {
      fontFamily: `"Inter", "Helvetica", "Arial", sans-serif`,
      fontSize: 14,
      fontWeightRegular: 400,
    },
  })

  const mergedTheme = deepmerge(theme, muiTheme)

  const muiTheme2 = createTheme({
    ...mergedTheme,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  })

  return (
    <MUIThemeProvider theme={muiTheme2}>
      <ThemeProvider>{props.children}</ThemeProvider>
    </MUIThemeProvider>
  )
}

export default AppThemeProvider
