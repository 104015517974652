import { RadioGroup as ChakraRadioGroup, RadioGroupProps as ChakraRadioGroupProps, Stack } from '@chakra-ui/react'
import React from 'react'

export const RadioGroup = ({ children, ...props }: ChakraRadioGroupProps) => (
  <ChakraRadioGroup {...props}>
    <Stack direction={'row'} gap="6">
      {children}
    </Stack>
  </ChakraRadioGroup>
)
