import * as Sentry from '@sentry/nextjs'
import LogRocket from 'logrocket'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { useEffect, useRef } from 'react'
import { filterObjectEmptyValues, isDefined } from 'utils'

import { getLogRocketOptions } from '@/src/utils/logrocket/options'
import { NextConfig } from '@/types/helpers'

const { publicRuntimeConfig } = getConfig() as NextConfig
const recordingWhitelist = ['mobile-verification-v2']

export const useLogRocket = () => {
  const router = useRouter()
  const { data: session } = useSession()
  const logRocketInitialized = useRef(false)
  const shouldSendData = useRef(false) // Initially set to false to prevent data sending

  // Initialize LogRocket
  useEffect(() => {
    if (!logRocketInitialized.current && publicRuntimeConfig.logRocket.enabled) {
      const logRocketOptions = getLogRocketOptions({
        shouldSendData: () => shouldSendData.current, // Required to keep reference to the original value
      })

      LogRocket.init(publicRuntimeConfig.logRocket.appId, logRocketOptions)
      logRocketInitialized.current = true
    }
  }, [])

  // Identify user after login
  useEffect(() => {
    if (logRocketInitialized.current && !shouldSendData.current) {
      if (isDefined(session) && isDefined(session.user) && isDefined(session.user.internal)) {
        shouldSendData.current = true // Enable data sending once the user logs in

        const user = session.user.internal
        const userData = {
          id: user.id,
          businessId: user.businessId ?? '',
          email: user.email,
          firstName: user.firstName ?? '',
          lastName: user.lastName ?? '',
          phoneNumber: user.phoneNumber ?? '',
        }

        const filteredData = filterObjectEmptyValues(userData)

        LogRocket.identify(user.id, filteredData)
        LogRocket.getSessionURL((sessionURL) => {
          Sentry.configureScope((scope) => {
            scope.setExtra('LogRocket session', sessionURL)
            scope.setUser(filteredData)
          })
        })
        // Go over the whitelist and check if the current route is in the list
      } else if (recordingWhitelist.some((route) => router.pathname.includes(route))) {
        shouldSendData.current = true
      }
    }
  }, [session, router.pathname])
}
