import { chakra } from '@chakra-ui/react'
import React, { ComponentProps } from 'react'

const CircleCloseFill = (props?: ComponentProps<typeof chakra.svg>) => {
  return (
    <chakra.svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_814_7071)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 0.833344C4.9374 0.833344 0.833344 4.9374 0.833344 10C0.833344 15.0626 4.9374 19.1667 10 19.1667C15.0626 19.1667 19.1667 15.0626 19.1667 10C19.1667 4.9374 15.0626 0.833344 10 0.833344ZM13.0893 8.08927C13.4147 7.76383 13.4147 7.23619 13.0893 6.91075C12.7638 6.58532 12.2362 6.58532 11.9108 6.91075L10 8.8215L8.08926 6.91076C7.76383 6.58533 7.23619 6.58533 6.91075 6.91076C6.58532 7.2362 6.58532 7.76384 6.91075 8.08928L8.82149 10L6.91076 11.9107C6.58532 12.2362 6.58532 12.7638 6.91076 13.0893C7.2362 13.4147 7.76383 13.4147 8.08927 13.0893L10 11.1785L11.9107 13.0893C12.2362 13.4147 12.7638 13.4147 13.0893 13.0893C13.4147 12.7638 13.4147 12.2362 13.0893 11.9108L11.1785 10L13.0893 8.08927Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_814_7071">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </chakra.svg>
  )
}

export default CircleCloseFill
