import { CurrencyCode, RoutingCodeType } from 'db-schema'
import { SupportedCurrenciesType } from 'services/src/currency/types'

import { FUNDING_ACCOUNTS_BY_CURRENCY as FUNDING_ACCOUNTS_BY_CURRENCY, PaymentType } from '@/src/constants/accounts'
import isDefined from '@/src/utils/dataShaping/isDefined'

export const getFundingAccountDetails = (
  currency: SupportedCurrenciesType,
  routingCodeType: RoutingCodeType | undefined | null,
  paymentType: PaymentType
) => {
  if (!(currency in FUNDING_ACCOUNTS_BY_CURRENCY)) {
    return null
  }
  if (!isDefined(routingCodeType)) {
    return null
  }
  const keyType = currency === CurrencyCode.EUR ? paymentType : routingCodeType
  const fundingAccountTypes = FUNDING_ACCOUNTS_BY_CURRENCY[currency]
  const fundingAccountDetails = isDefined(fundingAccountTypes) ? fundingAccountTypes[keyType] : null

  return fundingAccountDetails
}
