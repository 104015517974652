import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const helpers = createMultiStyleConfigHelpers([
  'arrow',
  'clearIcon',
  'clearButton',
  'errorContainer',
  'errorMessage',
  'helperText',
  'input',
  'inputGroup',
  'label',
  'labelContainer',
  'noItemsMessage',
  'option',
  'optionAdornment',
  'optionLabelContainer',
  'optionLabel',
  'optionSupportingText',
  'placeholder',
  'popoverBody',
  'selectedOption',
  'selectedOptionLabelContainer',
  'selectedOptionLabel',
  'selectedOptionSupportingText',
  'selectedOptionAdornment',
  'searchIcon',
  'searchInput',
  'virtualCardIcon',
])

export const Select = helpers.defineMultiStyleConfig({
  defaultProps: {
    size: 'lg',
  },
  baseStyle: {
    arrow: {
      transition: '0.3s',
      _expanded: {
        transform: 'rotate(180deg)',
      },
      _disabled: {
        color: '_primary.300',
      },
    },
    clearButton: {
      pointerEvents: 'all',
      color: 'basic.black',
      backgroundColor: 'transparent',
      borderRadius: '100%',
      _hover: { bg: '_primary.100' },
      _active: { bg: '_primary.200' },
      _focusWithin: { outline: 'none' },
    },
    errorContainer: {
      position: 'relative',
      zIndex: 0,
      width: 'full',
      alignItems: 'flex-end',
      borderBottomRadius: 'lg',
      height: '3.125rem',
      outline: '5px solid var(--chakra-colors-_error-50)',
      backgroundColor: '_error.50',
    },
    errorMessage: {
      color: '_error.500',
      textStyle: 'text.sm.regular',
      marginLeft: '1.5rem',
      marginBottom: '0.5rem',
    },
    helperText: {
      marginTop: 3,
      color: '_primary.500',
    },
    input: {
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'basic.white',
      position: 'relative',
      zIndex: 1,
      cursor: 'pointer',
      border: 'none',
      outline: 'none',
      boxShadow: 'none',
      height: '100%',
      width: '100%',
      _disabled: {
        backgroundColor: '_primary.50',
        cursor: 'not-allowed',
        pointerEvents: 'none',
      },
    },
    inputGroup: {
      display: 'flex',
      alignItems: 'center',
      paddingY: '0.375rem',
    },
    label: {
      fontWeight: 600,
      lineHeight: '1.5rem',
      letterSpacing: '-2%',
      pointerEvents: 'none',
      color: 'black.300',
      marginBottom: 0,
      marginInlineEnd: '0.55rem',
      _invalid: {
        color: '_error.500',
      },
      _disabled: {
        opacity: 1,
        color: '_primary.300',
      },
    },
    labelContainer: {
      marginBottom: '0.75rem',
      alignItems: 'center',
    },
    noItemsMessage: {
      padding: '8px 16px 10px 16px',
      color: 'grey.300',
    },
    option: {
      width: '100%',
      borderRadius: 'sm',
      cursor: 'pointer',
      color: 'basic.black',
      '&[data-focused="true"]': {
        outline: '2px solid var(--chakra-colors-accent-500)',
      },
      _hover: {
        backgroundColor: '_primary.50',
      },
      '&[data-active="true"]': {
        backgroundColor: '_primary.50',
      },
    },
    optionLabelContainer: {
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
    },
    optionLabel: {
      color: '_primary.900',
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
      fontWeight: 400,
      width: 'full',
      textAlign: 'start',
    },
    optionSupportingText: {
      color: '_primary.500',
      fontSize: '0.75rem',
      lineHeight: '1.125rem',
      fontWeight: 400,
      marginTop: '0.125rem',
    },
    optionAdornment: {
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    placeholder: {
      fontWeight: 400,
      lineHeight: '1.5rem',
      color: '_primary.500',
      _disabled: {
        color: '_primary.300',
      },
    },
    popoverContent: {
      border: '1px solid var(--chakra-colors-_primary-50)',
      borderRadius: 'md',
      boxShadow: 'lg',
      _focusVisible: {
        outline: 'none',
      },
    },
    popoverBody: {
      padding: '8px',
      overflow: 'scroll',
      _focusVisible: {
        outline: 'none',
      },
    },
    searchIcon: {
      height: 5,
      width: 5,
      marginLeft: 3,
    },
    searchInput: {
      paddingLeft: '1.685rem',
    },
    selectedOption: {
      width: 'full',
    },

    selectedOptionLabel: {
      fontSize: '1.125rem',
      fontWeight: 400,
      lineHeight: '1.5rem',
      letterSpacing: '-0.02rem',
      color: '_primary.900',
      _disabled: {
        color: '_primary.300',
      },
    },
    selectedOptionSupportingText: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '1.125rem',
      color: '_primary.500',
      _disabled: {
        color: '_primary.300',
      },
    },
    stack: {
      width: '100%',
    },
    title: {
      paddingX: '16px',
      paddingBottom: '8px',
      paddingTop: '12px',
      width: 'full',
    },
  },
  sizes: {
    sm: {
      arrow: {
        height: '0.45rem',
        width: '0.75rem',
      },
      clearButton: {
        marginX: 4,
      },
      input: {
        fontSize: '1rem',
        borderRadius: 'md',
        paddingX: '1.08rem',
        '&[data-left-element="true"]': {
          paddingLeft: '2.5rem',
        },
        '&[data-right-element="true"]': {
          paddingRight: '2.5rem',
        },
      },
      label: {
        fontSize: '1.125rem',
      },
      placeholder: {
        fontSize: '1rem',
      },
      option: {
        paddingX: 3,
        height: 10,
      },
      optionLabel: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
      },
      optionSupportingText: {
        display: 'none',
      },
      popoverBody: {
        height: '17.25rem',
      },
      clearIcon: {
        h: '0.5625rem',
        w: '0.5625rem',
      },
      errorContainer: {
        marginTop: '-1rem',
      },
      virtualCardIcon: {
        width: 5,
        height: '0.9375rem',
      },
    },
    md: {
      arrow: {
        height: '0.5rem',
        width: '0.75rem',
      },
      clearButton: {
        marginX: 5,
      },
      input: {
        fontSize: '1.125rem',
        borderRadius: 'md',
        paddingX: '1.104rem',
        '&[data-left-element="true"]': {
          paddingLeft: '3rem',
        },
        '&[data-right-element="true"]': {
          paddingRight: '3rem',
        },
      },
      label: {
        fontSize: '1.125rem',
      },
      placeholder: {
        fontSize: '1.125rem',
        letterSpacing: '-0.02em',
      },
      option: {
        paddingX: 4,
        height: 12,
      },
      optionLabel: {
        fontSize: '1.125rem',
        lineHeight: '1.5rem',
        leterSpacing: '-0.02em',
      },
      optionSupportingText: {
        display: 'none',
      },
      popoverBody: {
        height: '20.25rem',
      },
      clearIcon: {
        h: '0.625rem',
        w: '0.625rem',
      },
      errorContainer: {
        marginTop: '-1rem',
      },
      virtualCardIcon: {
        width: 8,
        height: 6,
      },
    },
    lg: {
      arrow: {
        height: '0.625rem',
        width: '0.875rem',
      },
      clearButton: {
        marginX: 6,
      },
      input: {
        paddingX: 6,
        borderRadius: 'lg',
        '&[data-clearable="true"]': {
          paddingRight: '4rem',
        },
        '&[data-position="inside"]': {
          paddingTop: '1.06rem',
        },
      },
      label: {
        '&[data-position="outside"]': {
          fontSize: '1.25rem',
        },
        '&[data-position="inside"]': {
          transition: 'all 0.2s ease-in-out',
          zIndex: 2,
          fontSize: '1.125rem',
          fontWeight: 400,
          position: 'absolute',
          top: '1.187rem',
          left: '1.625rem',
          color: '_primary.500',
          _invalid: {
            color: '_error.500',
          },
          '&[data-contracted="true"]': {
            top: '0.625rem',
            lineHeight: '1.125rem',
            fontSize: '0.75rem',
          },
        },
      },
      placeholder: {
        fontSize: '1.125rem',
        letterSpacing: '-0.02em',
      },
      option: {
        paddingX: 5,
        height: 16,
      },
      optionLabel: {
        fontSize: '1.125rem',
        lineHeight: '1.5rem',
        leterSpacing: '-0.02em',
      },
      optionSupportingText: {
        display: 'block',
      },
      popoverBody: {
        height: '22rem',
      },
      clearIcon: {
        h: 3,
        w: 3,
      },
      errorContainer: {
        marginTop: '-0.75rem',
      },
      virtualCardIcon: {
        width: 8,
        height: 6,
      },
    },
  },
})
